// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly only with media item 1`] = `undefined`;

exports[`renders correctly with documentItem and props 1`] = `undefined`;

exports[`renders correctly with postItem and props 1`] = `undefined`;

exports[`renders correctly with videoItem and props 1`] = `undefined`;

exports[`renders correctly without props 1`] = `undefined`;
