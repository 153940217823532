import React from 'react';
import renderer from 'react-test-renderer';

import CaseDigestText from '../../case_digest/CaseDigestText';

const text = '<div><p>This is a text</p></div>';

it('renders correctly with props', () => {
  const tree = renderer.create(<CaseDigestText
    className="scholar-text format-two"
    text={text}
    searchKeywords=""
    activeSearchIndex={0}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
