import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import Notifications from '../../../account/screens/Notifications';
import api from '../../../../services/api';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });
api.account.user.settings.put = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });

describe('account updated Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<Notifications caseLawEmailEnabled={false} toggleCaseLawEmail={() => {}} />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders modal and close click', async () => {
    const container = render(
      <Notifications caseLawEmailEnabled={false} toggleCaseLawEmail={() => {}} />,
    );
    const toggleButton = container.queryByTestId('toggle');
    expect(toggleButton).toBeInTheDocument();
    fireEvent.click(toggleButton);
  });
});
