// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with prefix_too_broad error 1`] = `
<div
  className="login-modal new-modal"
  data-testid="login-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
      onClick={[Function]}
    >
      <div
        className="circle"
      />
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Your search contains a prefix that is too broad. Please narrow the broad term or reach out to support using the chat function below or email us at
          <a
            href="mailto:support@ediscoveryassistant.com"
          >
             support@ediscoveryassistant.com 
          </a>
          for assistance.
        </p>
      </div>
    </div>
  </div>
</div>
`;

exports[`renders correctly without props 1`] = `
<div
  className="login-modal new-modal"
  data-testid="login-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
      onClick={[Function]}
    >
      <div
        className="circle"
      />
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Unable to perform search. Please try different filters or reach out to support using the chat function below or email us at
          <a
            href="mailto:support@ediscoveryassistant.com"
          >
             support@ediscoveryassistant.com 
          </a>
          for assistance.
        </p>
      </div>
    </div>
  </div>
</div>
`;
