import React from 'react';

// eslint-disable-next-line
export default function ({ children }) {
  return <div>{children}</div>;
}
export function MenuItem({ children }) {
  return <div>{children}</div>;
}
export function SubMenu({ children }) {
  return <div>{children}</div>;
}
export function Divider() {
  return <hr />;
}
