// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Checklist renders correctly with props 1`] = `
<div
  className="react-checklist"
>
  <div
    className="mobile-search-open"
    onClick={[Function]}
  />
  <div
    className="left-content"
  >
    <div
      className="item-details"
    >
      <div
        className="buttons"
      >
        <a
          className="back"
          href="/checklists"
        >
          ← Back to list
        </a>
      </div>
      <div
        className="category"
      >
        Checklists
      </div>
      <div
        className="title"
      >
        Meet and Confer/Rule 26 Preparation Checklist
      </div>
      <div
        className="description"
      >
        Issues to consider and have mapped out in preparation for your initial meet and confer with opposing counsel.
      </div>
      <div
        className="item-content"
      >
        <ul
          className="items-list"
        >
          <li
            style={Object {}}
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "color": undefined,
                }
              }
              viewBox="0 0 448 512"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
              />
            </svg>
            Have a strategy in place for dealing with eDiscovery and be prepared to discuss the appropriate amount of time needed and why
            <div
              className="item-tip"
            >
              When collecting social media, be sure to give yourself enough lead time to get the info from the third party company and review it before producing it.  Consider agreeing to a longer response time up front when requests are received, or even better, agree at the initial conference what to produce and get it early.
            </div>
            <div
              className="item-tip"
            >
              Data stored in the cloud often requires going through a third party to collect the data and can require longer than you have to meet a 30 day deadline. Find out if the client has a process or connection for getting data.  
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    className="right-content "
  >
    <div
      className="item-summary"
    >
      <div
        className="summary-checkbox selected"
      >
        <span
          className="MuiSwitch-root WithStyles(ForwardRef(Switch))-root-1"
        >
          <span
            aria-disabled={false}
            className="MuiButtonBase-root MuiIconButton-root PrivateSwitchBase-root-6 MuiSwitch-switchBase WithStyles(ForwardRef(Switch))-switchBase-2 MuiSwitch-colorSecondary PrivateSwitchBase-checked-7 Mui-checked WithStyles(ForwardRef(Switch))-checked-5"
            onBlur={[Function]}
            onDragLeave={[Function]}
            onFocus={[Function]}
            onKeyDown={[Function]}
            onKeyUp={[Function]}
            onMouseDown={[Function]}
            onMouseLeave={[Function]}
            onMouseUp={[Function]}
            onTouchEnd={[Function]}
            onTouchMove={[Function]}
            onTouchStart={[Function]}
            tabIndex={null}
          >
            <span
              className="MuiIconButton-label"
            >
              <input
                checked={true}
                className="PrivateSwitchBase-input-9 MuiSwitch-input"
                onChange={[Function]}
                type="checkbox"
              />
              <span
                className="MuiSwitch-thumb WithStyles(ForwardRef(Switch))-thumb-3"
              />
            </span>
          </span>
          <span
            className="MuiSwitch-track WithStyles(ForwardRef(Switch))-track-4"
          />
        </span>
        Show eDiscovery tips
      </div>
      <div
        className="buttons"
      >
        <div
          className="pdf"
          onClick={[Function]}
        >
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 448 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
            />
          </svg>
          Download PDF
        </div>
        <div
          className="bookmark"
          onClick={[Function]}
        >
          <div>
            <svg
              fill="currentColor"
              height="1em"
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "color": undefined,
                }
              }
              viewBox="0 0 384 512"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
              />
            </svg>
            Add To Bookmarks
          </div>
        </div>
      </div>
    </div>
    <div
      className="related"
    >
      Related Content
    </div>
    <div
      className="category"
    >
      No related content found.
    </div>
  </div>
</div>
`;
