import React from 'react';
import PropTypes from 'prop-types';
import { FaBookmark, FaRegBookmark, GoSearch } from 'react-icons/all';
import Autosuggest from 'react-autosuggest';
import ReactHtmlParser from 'react-html-parser';
import api from '../../services/api';
import SearchLoadingModal from '../search/SearchLoadingModal';

const _alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
  'U', 'V', 'W', 'X', 'Y', 'Z', 'All'];

class Glossary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTerm: '',
      filteredTerms: [],
      termSuggestions: [],
      glossaryTerms: [],
      termSearchValue: '',
      suggestionHighlighted: false,
      favorites: {},
      termCache: {},
      loading: true,
      mobileOpened: false,
    };
  }

  componentDidMount() {
    const { favorites } = this.props;
    this.setState({ favorites });
    api.glossary.get().then((res) => {
      this.setState({ loading: false, glossaryTerms: res.message }, () => this._filterByLetter('All'));
    });

    const termId = Number(new URLSearchParams(window.location.search).get('term'));

    if (termId) {
      this.setState({ loading: true });
      api.glossary.getTerm(termId).then(({ data }) => {
        const { termCache } = this.state;
        termCache[termId] = data;
        this.setState({ loading: false, termCache, selectedTerm: termCache[termId] });
      });
    }
  }

  _updateFavorites = () => {
    api.favorites.get().then((result) => {
      this.setState({ favorites: result.favorites });
    });
  };

  _toggleTermBookmark = (e, term) => {
    e.stopPropagation();
    const { favorites } = this.state;
    if (favorites.glossary_terms !== undefined && favorites.glossary_terms.some(
      (favoriteTerm) => favoriteTerm.id === term.glossary_term_id,
    )) {
      api.glossary.unfavorite(term.glossary_term_id).then(() => this._updateFavorites());
      return;
    }
    api.glossary.favorite(term.glossary_term_id).then(() => this._updateFavorites());
  };

  _toggleSuggestionHighlighted = ({ suggestion }) => {
    const { suggestionHighlighted } = this.state;
    if (suggestionHighlighted && suggestion === null) this.setState({ suggestionHighlighted: false });
    if (!suggestionHighlighted && suggestion !== null) this.setState({ suggestionHighlighted: true });
  };

  _filterByLetter = (letterFilter) => {
    const { glossaryTerms } = this.state;
    const _filteredTerms = glossaryTerms.filter((term) => letterFilter === 'All' || term.name.startsWith(letterFilter));

    this.setState({ filteredTerms: _filteredTerms });
  };

  _selectTerm = (term) => {
    window.history.pushState({}, '', `/glossary_terms${term ? `?term=${term.id}` : ''}`);

    if (!term) this.setState({ selectedTerm: null });

    const { termCache } = this.state;
    if (termCache[term.id]) {
      this.setState({ selectedTerm: termCache[term.id] });
      return;
    }

    this.setState({ loading: true });
    api.glossary.getTerm(term.id).then(({ data }) => {
      termCache[term.id] = data;
      this.setState({ loading: false, termCache, selectedTerm: termCache[term.id] });
    });
  };

  _setTermSearchValue = (e, { newValue }) => {
    this.setState({ termSearchValue: newValue });
  };

  _selectTermFromInput = (e) => {
    const { termSearchValue, suggestionHighlighted } = this.state;

    if (e.keyCode !== 13 || suggestionHighlighted) return;

    const _suggestions = this._getSuggestionsFromValue(termSearchValue);

    if (_suggestions.length !== 1) return;

    this._selectTerm(_suggestions[0]);
    this.setState({ termSearchValue: '' });
  };

  _setTermFromSuggestion = (e, { suggestion }) => {
    this._selectTerm(suggestion);
  };

  _clearSuggestions = () => {
    this.setState({ termSuggestions: [] });
  };

  _setTermSuggestions = ({ value }) => {
    const { glossaryTerms } = this.state;
    if (value === '') {
      this.setState({ termSuggestions: glossaryTerms });
      return;
    }

    this.setState({ termSuggestions: this._getSuggestionsFromValue(value) });
  };

  _getSuggestionsFromValue = (value) => {
    const { glossaryTerms } = this.state;
    const _inputValue = value.trim().toLowerCase();
    const _inputLength = _inputValue.length;
    return _inputLength === 0 ? [] : glossaryTerms.filter((term) => term.name.toLowerCase().slice(0, _inputLength) === _inputValue);
  };

  _renderTermSuggestion = (term) => (
    <div className="suggestion">
      { term.name }
    </div>
  );

  render() {
    const {
      termSearchValue,
      termSuggestions,
      selectedTerm,
      favorites,
      glossaryTerms,
      loading,
      filteredTerms,
      mobileOpened,
    } = this.state;

    const termInputProps = {
      placeholder: 'Search Glossary. Try “Image”',
      value: termSearchValue,
      onChange: this._setTermSearchValue,
      onKeyDown: this._selectTermFromInput,
    };

    return (
      <div className="react-glossary">
        <div className="mobile-search-open" onClick={() => this.setState({ mobileOpened: !mobileOpened })} />
        <div className={`left ${mobileOpened ? 'opened' : ''}`}>
          <div className="name">
            Glossary
          </div>
          <div className="search">
            <Autosuggest
              suggestions={termSuggestions}
              onSuggestionsFetchRequested={this._setTermSuggestions}
              onSuggestionsClearRequested={this._clearSuggestions}
              getSuggestionValue={(term) => term.name}
              renderSuggestion={this._renderTermSuggestion}
              inputProps={termInputProps}
              onSuggestionSelected={this._setTermFromSuggestion}
              onSuggestionHighlighted={this._toggleSuggestionHighlighted}
            />
            <GoSearch />
          </div>
          <div className="letters">
            { _alphabet.filter((letter) => letter === 'All' || glossaryTerms
              .some((term) => term.name.startsWith(letter))).map((letter) => (
                <div className="letter" key={letter} onClick={() => this._filterByLetter(letter)}>
                  { letter }
                </div>
            ))}
          </div>
          <div className="terms">
            {
              filteredTerms.map((term, index) => (
                <>
                  { (index === 0
                    || term.name.charAt(0).toUpperCase() !== filteredTerms[index - 1].name.charAt(0).toUpperCase()) && (
                    <div className="letter">{ term.name.charAt(0).toUpperCase() }</div>
                  )}
                  <div key={term.id}>
                    <div
                      className={`term${selectedTerm.id === term.id ? ' selected' : ''}`}
                      onClick={() => this._selectTerm(term)}
                    >
                      { term.name }
                      {
                        (favorites.glossary_terms !== undefined && favorites.glossary_terms
                          .some((favoriteTerm) => favoriteTerm.id === term.glossary_term_id))
                          ? (
                            <FaBookmark className="active" onClick={(e) => this._toggleTermBookmark(e, term)} />
                          ) : (
                            <FaRegBookmark onClick={(e) => this._toggleTermBookmark(e, term)} />
                          )
                      }
                    </div>
                  </div>
                </>
              ))
            }
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            { selectedTerm === '' ? (
              <div className="placeholder">
                You have not chosen a glossary term.
                <br />
                To view a term, click on the term you want or use
                <br />
                the Search to find a term.
                <div className="empty-image" />
              </div>
            ) : (
              <div className="term">
                <div className="top">
                  <div className="name">{selectedTerm.name}</div>
                  <div
                    className="bookmark-button"
                    onClick={(e) => this._toggleTermBookmark(e, selectedTerm)}
                  >
                    {
                        (favorites.glossary_terms !== undefined && favorites.glossary_terms
                          .some((favoriteTerm) => favoriteTerm.id === selectedTerm.glossary_term_id))
                          ? (
                            <div>
                              <FaBookmark onClick={(e) => this._toggleTermBookmark(e, selectedTerm)} />
                              Remove Bookmark
                            </div>
                          )
                          : (
                            <div>
                              <FaRegBookmark onClick={(e) => this._toggleTermBookmark(e, selectedTerm)} />
                              Add Bookmark
                            </div>
                          )
                      }
                  </div>
                </div>
                { selectedTerm.definition && (
                <div className="term-body">
                  <div className="definition">
                    { ReactHtmlParser(selectedTerm.definition) }
                  </div>
                  <div className="term-attribution">
                    { selectedTerm.attribution ? ReactHtmlParser(selectedTerm.attribution) : 'Definition drafted by the eDiscovery Assistant editorial team.' }
                  </div>
                </div>
                )}
                { selectedTerm.secondary_definition && (
                <div className="term-body">
                  <div className="definition">
                    { ReactHtmlParser(selectedTerm.secondary_definition) }
                  </div>
                  <div className="term-attribution">
                    { ReactHtmlParser(selectedTerm.secondary_attribution) }
                  </div>
                </div>
                )}
                { selectedTerm.tertiary_definition && (
                <div className="term-body">
                  <div className="definition">
                    { ReactHtmlParser(selectedTerm.tertiary_definition) }
                  </div>
                  <div className="term-attribution">
                    { ReactHtmlParser(selectedTerm.tertiary_attribution) }
                  </div>
                </div>
                )}
              </div>
            )}
          </div>
        </div>
        { loading && <SearchLoadingModal /> }
      </div>
    );
  }
}

Glossary.propTypes = {
  favorites: PropTypes.object.isRequired,
};

export default Glossary;
