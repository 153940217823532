// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="related-rule-list"
>
  <div
    className="name"
  >
    Federal Rules of Civil Procedure
  </div>
  <div
    className="rule-div"
    onClick={[Function]}
  >
    <a
      href="/federal_rules/1"
    >
      Number for Rule
    </a>
  </div>
  <div
    className="name"
  >
    State
  </div>
  <div
    className="rule-div"
    onClick={[Function]}
  >
    <a
      href="/state_rules/2"
    >
      Number for Rule
    </a>
  </div>
  <div
    className="name"
  >
    District
  </div>
  <div
    className="rule-div"
    onClick={[Function]}
  >
    <a
      href="/district_rules/3"
    >
      Number for Rule
    </a>
  </div>
</div>
`;
