import React from 'react';
import '@testing-library/jest-dom';
import { act, render, screen } from '@testing-library/react';

import userEvent from '@testing-library/user-event';
import NameEditModal from '../../../cite_lists/modals/NameEditModal';
import api from '../../../../services/api';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({}) });
api.cite_lists.update = jest.fn().mockResolvedValue({});

describe('NameEditModal', () => {
  it('renders correctly with props', () => {
    render(
      <NameEditModal
        citeList={{ id: 1 }}
        fetchCiteLists={() => {}}
        closeModal={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const fetchLists = jest.fn(() => {});
    const close = jest.fn(() => {});

    render(
      <NameEditModal
        citeList={{ id: 1 }}
        fetchCiteLists={fetchLists}
        closeModal={close}
      />,
    );

    const nameInput = screen.getByPlaceholderText('Name');
    expect(nameInput).toBeInTheDocument();
    await act(async () => {
      // test click with empty name
      await userEvent.type(nameInput, '');
    });

    const confirmButton = screen.getByText('Save Changes');
    expect(confirmButton).toBeInTheDocument();
    await act(async () => {
      userEvent.click(confirmButton);
    });

    await act(async () => {
      // test click with valid name
      await userEvent.type(nameInput, 'test');
      userEvent.click(confirmButton);
    });
    expect(fetchLists).toBeCalledTimes(1);

    const closeButton = screen.getByText('Cancel');
    expect(closeButton).toBeInTheDocument();
    userEvent.click(closeButton);
    expect(close).toBeCalledTimes(2);
  });
});
