import React from 'react';
import { FaArrowDown, FaPlus } from 'react-icons/all';
import moment from 'moment';
import { FaVideo } from 'react-icons/fa';
import { DecisionDateFormat } from '../../services/constants';
import TagList from '../tags/TagList';

export default class RelatedCaseDigest extends React.Component {
  render() {
    const {
      currentUser, relatedCase, onAddToList, onDownloadPdf, onUnauthenticated, openVideoModal,
    } = this.props;
    return (
      <a
        className="related-case-digest"
        href={currentUser ? (`/case_law/${relatedCase.slug}`) : '#'}
        onClick={(e) => {
          if (currentUser) return;
          e.preventDefault();
          onUnauthenticated(e);
        }}
        key={relatedCase.id}
      >
        <div className="decision-title">
          <div className="decision-name">
            {relatedCase.published_revision.name}
            { relatedCase.published_revision.video && (
            <FaVideo
              onClick={(e) => { e.stopPropagation(); e.preventDefault(); openVideoModal(relatedCase.published_revision.video); }}
              className="video"
            />
            )}
          </div>
          <div className="result-buttons">
            { onAddToList && (
              <button className="result-button" onClick={(e) => onAddToList(e)}>
                <FaPlus />
                To List
              </button>
            )}

            { onDownloadPdf && (
              <button
                className="result-button"
                onClick={(e) => onDownloadPdf(e)}
              >
                <FaArrowDown />
                PDF
              </button>
            )}
          </div>
        </div>
        <div className="decision-cite">{relatedCase.published_revision.cite}</div>
        <div className="decision-cite">
          {relatedCase.published_revision.decision_date && moment(relatedCase.published_revision.decision_date).format(DecisionDateFormat)}
        </div>
        <div onClick={(e) => {
          if (currentUser != null) return;
          e.preventDefault();
          onUnauthenticated(e);
        }}
        >
          {/*  { */}
          {/*  relatedCase.published_revision.is_per_curiam && ( */}
          {/*    <> */}
          {/*      Per Curiam */}
          {/*    </> */}
          {/*  ) */}
          {/* } */}
          {/*  { */}
          {/*  relatedCase.published_revision.is_panel && ( */}
          {/*    <> */}
          {/*      Panel members: */}
          {/*      {' '} */}
          {/*      {(relatedCase.published_revision.panel_members || []).map((member, idx) => ( */}
          {/*        <> */}
          {/*          <a className="cite" href={`/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=${member}`}> */}
          {/*            {member} */}
          {/*          </a> */}
          {/*          { idx < relatedCase.published_revision.panel_members.length - 1 ? ', ' : '' } */}
          {/*        </> */}
          {/*      ))} */}
          {/*    </> */}
          {/*  ) */}
          {/* } */}
          {
          Boolean(relatedCase.published_revision.judge) && (
            <>
              <a className="decision-cite judge-link" href={`/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=${relatedCase.published_revision.judge}`}>
                {relatedCase.published_revision.judge}
              </a>
              {`${relatedCase.published_revision.judge === 'Panel' && !relatedCase.published_revision.judge_type ? '' : ','}
                \u00a0${relatedCase.published_revision.judge_type}`}
            </>
          )
        }
        </div>
        <TagList tags={relatedCase.published_revision.tag_list} />
      </a>
    );
  }
}
