// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="side-section"
>
  <div
    className="side-section-header"
  >
    <h4
      className="side-section-title"
    >
      Issue Tags
      <button
        className="button-clear"
        data-testid="tags-close-button"
        onClick={[Function]}
        type="button"
      >
        ✕
      </button>
    </h4>
    <p
      className="side-section-description"
    >
      Click to select the most relevant issue tag(s) of your case search, mix and match with booleans if needed.
    </p>
  </div>
  <div
    className="side-section-body"
  >
    <div
      className="search"
    >
      <input
        onChange={[Function]}
        placeholder="Search Issue Tags"
        value=""
      />
      <svg
        fill="currentColor"
        height="1em"
        stroke="currentColor"
        strokeWidth="0"
        style={
          Object {
            "color": undefined,
          }
        }
        viewBox="0 0 16 16"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
          fillRule="evenodd"
        />
      </svg>
    </div>
    <div
      className="tag-category"
    >
      <p
        className="tag-category-title"
      >
        Other
      </p>
      <div
        className="tag "
        onClick={[Function]}
      >
        tag 1
      </div>
      <div
        className="tag "
        onClick={[Function]}
      >
        tag 2
      </div>
    </div>
  </div>
</div>
`;
