import React from 'react';
import range from 'lodash/range';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { FadeLoader } from 'react-spinners';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = { statesData: null, loadingModalOpen: false };
  }

  componentDidMount() {
    const { data } = this.props;

    window.d3.json('states.json').then((json) => {
      this.setState({ statesData: json }, () => {
        window.addEventListener('resize', () => {
          window.d3.select('#map').remove();
          this.drawMap(data, this.drawIcons);
        });
        this.init();
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { data, type } = this.props;
    if (data === prevProps.data && type === prevProps.type) return;
    this.init();
  }

  renderLoadingModal = () => (
    <div className="loading-modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="margin">
            <FadeLoader color="rgba(0, 14, 87, 1)" className="fade-loader" />
          </div>
          <div className="name">Loading</div>
        </div>
      </div>
    </div>
  );

  _goSearch = (d) => {
    const {
      federalCourts, type, stateCourts, results, data,
    } = this.props;
    let url = '';
    let tmpCourts = '';
    if (results) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('court_ids');
      const subUrl = searchParams.toString();
      url = `/case_law?${subUrl}&court_ids=`;
    } else {
      url = '/case_law?court_ids=';
    }
    if (type === 'circuit' && federalCourts) {
      if (results) {
        tmpCourts = new URLSearchParams(window.location.search).get('court_ids');
      }
      const federalCourt = federalCourts.find((court) => court.id === d.properties.CIRCUIT_COURT_ID);
      if (data && data.circuit_courts[d.properties.CIRCUIT] === undefined) return;
      federalCourt.courts.filter((court) => {
        if (d.properties.NAME !== 'Virgin Islands') return true;
        return court.abbreviation === 'D.V.I.';
      }).forEach((c) => {
        url += `${c.id}%2C`;
      });
    }
    if (type === 'state' && stateCourts) {
      tmpCourts = new URLSearchParams(window.location.search).get('court_ids');
      const stateCourt = stateCourts.find((state) => state[0] === d.properties.NAME);
      if (data && data.state_courts[d.properties.STATE_ID] === undefined) return;
      if (stateCourt[3].length > 0) {
        stateCourt[3].forEach((c) => {
          url += `${c}%2C`;
        });
      }
      url += `${stateCourt[1]}%2C`;
    }
    url += '&page=1&results_per_page=25';
    if (window.location.search.includes('court_ids=') && url.includes(`court_ids=${tmpCourts.replaceAll(',', '%2C')}`)) return;
    this.setState({ loadingModalOpen: true });
    window.location.href = url;
  };

  init = () => {
    const { data } = this.props;
    this.mapRef.current.innerHTML = '';

    this.drawMap(data, this.drawIcons);
  };

  drawIcons = (inputPath, data, type) => {
    const newData = data || { circuit_courts: {}, state_courts: {}, admin_courts: false };
    const svg = window.d3.select('#map');
    const goSearch = this._goSearch;

    svg.selectAll('path').each(function select(d) {
      // console.log(d)

      const state = window.d3.select(`.state-id-${d.properties.STATE_ID}`).datum();

      const centroid = inputPath.centroid(state);
      // Natural offset estimate due to dimensions of text.
      const cx = centroid[0] - 20;
      const cy = centroid[1] - 20;

      const info = svg.append('svg');
      let labelText;
      let digestCount;

      if (type === 'circuit') {
        labelText = d.properties.CIRCUIT_ABBR;
        if (isNil(newData.all)) {
          if (!isNil(newData.circuit_courts[d.properties.CIRCUIT])) {
            digestCount = newData.circuit_courts[d.properties.CIRCUIT];
          } else {
            digestCount = d.properties.CIRCUIT_DIGEST_COUNT;
          }
        } else {
          digestCount = d.properties.CIRCUIT_DIGEST_COUNT;
        }
      } else if (type === 'state') {
        labelText = d.properties.ABBR;
        if (isNil(newData.all)) {
          if (!isNil(newData.state_courts[d.properties.STATE_ID])) {
            digestCount = newData.state_courts[d.properties.STATE_ID];
          } else {
            digestCount = d.properties.STATE_DIGEST_COUNT;
          }
        } else {
          digestCount = d.properties.STATE_DIGEST_COUNT;
        }
      }

      // If there are coordinates calculated with GeoJSON data...
      if (!Number.isNaN(cx) && !Number.isNaN(cy) && !isNil(d.properties.STATE_ID)) {
        // If it's a circuit map being drawn and it has an anchor associated with the state itself...
        if (type === 'circuit' && d.properties.CIRCUIT_ANCHOR === true) {
          let renderCircle = false;

          // Render the circle only if there is no associated key in newData.
          if (newData.all === true) {
            renderCircle = true;
          } else if (!isNil(newData.circuit_courts[d.properties.CIRCUIT])) {
            renderCircle = true;
          }

          if (renderCircle && d.properties.CIRCUIT_ANCHOR === true) {
            const yOffset = (d.properties.CIRCUIT_POSITIONING.y * window.innerWidth) / 1400;
            const xOffset = (d.properties.CIRCUIT_POSITIONING.x * window.innerWidth) / 1400;
            const radius = (22 * window.innerWidth) / 1400;

            if (d.properties.DRAW_CIRCUIT_LINE) {
              const len = Math.sqrt(xOffset * xOffset + yOffset * yOffset);
              // Draw Lines
              const cxs = 0;
              const cys = 0;
              const cxe = xOffset - (xOffset * radius) / len;
              const cye = yOffset - (yOffset * radius) / len;

              const parent = window.d3.select(this.parentNode);

              parent.append('svg')
                .attr('x', cx + 25)
                .attr('y', cy + 25)
                .insert('line')
                .style('stroke', 'black') // colour the line
                .attr('class', 'mobile-stroke-width') // stroke width 0.3 for mobile devices
                .attr('x1', cxs) // x position of the first end of the line
                .attr('y1', cys) // y position of the first end of the line
                .attr('x2', cxe) // x position of the second end of the line
                .attr('y2', cye); // y position of the second end of the line
            }

            info.attr('class', 'circle outer')
              .attr('x', cx + xOffset)
              .attr('y', cy + yOffset)
              .style('fill', 'none')
              .style('stroke', 'black')
              .attr('class', 'mobile-stroke-width') // stroke width 0.3 for mobile devices
              .style('overflow', 'visible')
              .style('cursor', 'pointer')
              .append('circle')
              .attr('cx', 25)
              .attr('cy', 25)
              .attr('r', radius)
              .attr('class', `circle-${d.properties.STATE_ID}`)
              .on('click', () => goSearch(d));

            info.append('text')
              .attr('dx', () => 25)
              .attr('dy', () => 24)
              .attr('class', `state-circle-label-${d.properties.STATE_ID}`)
              .style('fill', 'black')
              .attr('text-anchor', 'middle')
              .style('font-size', (12 * window.innerWidth) / 1400)
              .text(labelText)
              .on('click', () => goSearch(d));

            info.append('text')
              .attr('dx', () => 25)
              .attr('dy', () => 23 + (15 * window.innerWidth) / 1400)
              .attr('class', `state-count-label-${d.properties.STATE_ID}`)
              .attr('text-anchor', 'middle')
              .style('fill', 'black')
              .style('font-size', (12 * window.innerWidth) / 1400)
              .text(digestCount)
              .on('click', () => goSearch(d));
          }
        } else if (type === 'circuit' && d.properties.CIRCUIT_ANCHOR === false) {
          // Render nothing
        } else if (type === 'state') {
          let renderCircle = false;

          // Render the circle only if there is an associated key in newData.
          if (newData.all === true) {
            renderCircle = true;
          } else if (d.properties.STATE_ID !== 9 && !isNil(newData.state_courts[d.properties.STATE_ID])) {
            renderCircle = true;
          }

          if (renderCircle) {
            if (d.properties.DRAW_LINE) {
              // Draw Lines
              const cxs = d.properties.STATE_OFFSETS.line.start_x;
              const cys = d.properties.STATE_OFFSETS.line.start_y;
              const cxe = d.properties.STATE_OFFSETS.line.end_x;
              const cye = d.properties.STATE_OFFSETS.line.end_y;

              // Offsets for Labels
              const cxo = (d.properties.STATE_OFFSETS.label.x_offset * window.innerWidth) / 1800;
              const cyo = (d.properties.STATE_OFFSETS.label.y_offset * window.innerWidth) / 1800;

              // Offsets for Lines
              const lox = d.properties.STATE_OFFSETS.line.offset.x;
              const loy = d.properties.STATE_OFFSETS.line.offset.y;

              const parent = window.d3.select(this.parentNode);

              if (window.innerWidth > 1000) {
                parent.append('svg')
                  .attr('x', cx + cxo + lox)
                  .attr('y', cy + cyo + loy)
                  .attr('class', `line-container-${d.properties.STATE_ID}`)
                  .insert('line')
                  .style('stroke', 'black') // colour the line
                  .attr('class', `line-${d.properties.STATE_ID}`)
                  .attr('x1', cxs - 20) // x position of the first end of the line
                  .attr('y1', cys - 20) // y position of the first end of the line
                  .attr('x2', cxe) // x position of the second end of the line
                  .attr('y2', cye); // y position of the second end of the line
              }

              info.attr('class', 'circle mobile-stroke-width')
                .attr('x', cx + cxo)
                .attr('y', cy + cyo)
                .style('fill', 'transparent')
                .append('circle')
                .attr('cx', 25)
                .attr('cy', 25)
                .attr('r', (22 * window.innerWidth) / 1400)
                .attr('class', `circle-${d.properties.STATE_ID}`);
            } else {
              info.attr('class', 'circle mobile-stroke-width')
                .attr('x', cx)
                .attr('y', cy)
                .style('fill', 'transparent')
                .append('circle')
                .attr('cx', 25)
                .attr('cy', 25)
                .attr('r', (22 * window.innerWidth) / 1400)
                .attr('class', `circle-${d.properties.STATE_ID}`);
            }

            // static modifier for mobile devices and wide screens
            let yModifier = 0;
            const xModifier = window.innerWidth < 1000 ? 4 : 0;
            if (window.innerWidth < 1000) yModifier = 7;
            if (window.innerWidth > 2000) yModifier = -15;

            info.append('text')
              .attr('dx', () => 25 - xModifier)
              .attr('dy', () => 22)
              .attr('class', `state-circle-label-${d.properties.STATE_ID}`)
              .style('fill', 'black')
              .attr('text-anchor', 'middle')
              .style('font-size', (12 * window.innerWidth) / 1400)
              .style('font-weight', 'bold')
              .text(labelText);

            info.append('text')
              .attr('dx', () => 25 - xModifier)
              .attr('dy', () => 37 - yModifier)
              .attr('class', `state-count-label-${d.properties.STATE_ID}`)
              .attr('text-anchor', 'middle')
              .style('fill', 'black')
              .style('font-size', (12 * window.innerWidth) / 1400)
              .style('font-weight', 'bold')
              .text(digestCount);

            info.append('text')
              .attr('dx', () => 16 - xModifier)
              .attr('dy', () => 30 - yModifier)
              .attr('class', `state-label-${d.properties.STATE_ID}`)
              .style('fill', 'transparent')
              .style('font-size', '12px')
              .style('font-weight', 'bold')
              .text(d.properties.ABBR);
          }
        }
      }
    });
  };

  drawMap(data, cb) {
    const { type } = this.props;
    const { statesData } = this.state;

    const newData = data || { circuit_courts: {}, state_courts: {} };
    const width = this.mapRef.current.offsetWidth;
    const height = this.mapRef.current.offsetWidth * 0.65;
    const scaleModifier = window.innerWidth > 1000 ? 0.95 : 1.4;

    window.d3.zoom().scaleExtent([1, 10]);

    // D3 Projection
    const projection = window.geoAlbersUsaTerritories.geoAlbersUsaTerritories()
      .translate([width / 2, height / 2]) // translate to center of screen
      .scale([((1000 * window.innerWidth) / 1150) * scaleModifier]); // scale things down so see entire US

    // Define path generator
    const path = window.d3.geoPath(projection);

    // Define linear scale for output
    const circuitColor = window.d3.scaleLinear()
      .range([
        'rgb(83, 101, 137)', // 0
        'rgb(107, 158, 163)', // 1
        'rgb(152, 186, 119)', //
        'rgb(216, 193, 108 )', // 3
        'rgb(255, 175, 109)', // 4
        'rgb(198, 77, 77)', // 5
        'rgb(206, 132, 132)', // 6
        'rgb(209, 185, 144)', // 7
        'rgb(244, 218, 112)', // 8
        'rgb(83, 101, 137)', // 9
        'rgb(108, 163, 106)', // 10
        'rgb(178, 178, 178)', /// 11
      ]);

    const stateColors = {
      5: 'rgb(108, 163, 106)',
      27: 'rgb(108, 163, 106)',
      19: 'rgb(108, 163, 106)',
      43: 'rgb(108, 163, 106)',
      50: 'rgb(108, 163, 106)',
      49: 'rgb(108, 163, 106)',
      46: 'rgb(108, 163, 106)',
      51: 'rgb(83, 101, 137)',
      12: 'rgb(108, 163, 106)',
      29: 'rgb(83, 101, 137)',
      23: 'rgb(83, 101, 137)',
      8: 'rgb(83, 101, 137)',
      2: 'rgb(83, 101, 137)',
      17: 'rgb(83, 101, 137)',
      24: 'rgb(244, 218, 112)',
      4: 'rgb(83, 101, 137)',
      10: 'rgb(83, 101, 137)',
      47: 'rgb(83, 101, 137)',
      33: 'rgb(83, 101, 137)',
      54: 'rgb(83, 101, 137)',
      38: 'rgb(244, 218, 112)',
      6: 'rgb(244, 218, 112)',
      16: 'rgb(83, 101, 137)',
      18: 'rgb(244, 218, 112)',
      1: 'rgb(244, 218, 112)',
      31: 'rgb(244, 218, 112)',
      22: 'rgb(244, 218, 112)',
      48: 'rgb(255, 169, 104)',
      3: 'rgb(255, 169, 104)',
      42: 'rgb(255, 169, 104)',
      37: 'rgb(255, 169, 104)',
      14: 'rgb(255, 169, 104)',
      11: 'rgb(255, 169, 104)',
      39: 'rgb(255, 169, 104)',
      40: 'rgb(255, 169, 104)',
      20: 'rgb(255, 169, 104)',
      99: 'rgb(255, 169, 104)',
      32: 'rgb(198, 77, 77)',
      13: 'rgb(198, 77, 77)',
      28: 'rgb(198, 77, 77)',
      25: 'rgb(198, 77, 77)',
      41: 'rgb(198, 77, 77)',
      21: 'rgb(198, 77, 77)',
      30: 'rgb(198, 77, 77)',
      15: 'rgb(198, 77, 77)',
      45: 'rgb(178, 178, 178)',
      35: 'rgb(178, 178, 178)',
      44: 'rgb(178, 178, 178)',
      26: 'rgb(178, 178, 178)',
      36: 'rgb(178, 178, 178)',
      34: 'rgb(178, 178, 178)',
      7: 'rgb(178, 178, 178)',
    };

    // var stateColor = d3.scale.category20();

    let color;
    if (type === 'state') {
      color = stateColors;
    } else {
      color = circuitColor;
      color.domain([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    }

    // Create SVG element and append map to the SVG
    const svg = window.d3.select(this.mapRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('id', 'map');

    // window.d3.select(this.mapRef.current)
    //   .append('div')
    //   .attr('class', 'data-tooltip')
    //   .style('opacity', 0);

    // Bind the data to the SVG and create one path per GeoJSON feature
    svg.selectAll('path')
      .data(statesData.features)
      .enter()
      .append('path')
      .attr('d', path)
      .attr('id', (d) => d.properties.STATE)
      .attr('class', (d) => `initial active state state-id-${d.properties.STATE_ID} mobile-stroke-width`)
      .style('stroke', '#fff')
      .style('stroke-width', '1')
      .style('opacity', (d) => {
        if (type === 'state') {
          if (newData.all === true) {
            return 1;
          } if (isNil(newData.state_courts[d.properties.STATE_ID])) {
            return 0.3;
          }
        } else if (type === 'circuit') {
          if (newData.all === true) {
            return 1;
          } if (isNil(newData.circuit_courts[d.properties.CIRCUIT])) {
            return 0.3;
          }
        }
        return 1;
      })
      .style('fill', (d) => {
        // Get data value
        let value;
        if (type === 'circuit') {
          value = d.properties.CIRCUIT;
        } else {
          value = d.properties.STATE_ID;
        }

        if (value) {
          if (type === 'circuit') {
            color.domain = range(1, 12);
            return color(value);
          }
          return color[value];
        }
        return 'rgb(0,0,0)';
      })
      .on('click', (d) => { this._goSearch(d); });
    cb(path, newData, type);
  }

  render() {
    const { loadingModalOpen } = this.state;
    return (
      <div ref={this.mapRef}>
        { loadingModalOpen && this.renderLoadingModal() }
      </div>
    );
  }
}

Map.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default Map;
