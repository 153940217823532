import React from 'react';

export default class RelatedGlossaryTerm extends React.Component {
  render() {
    const { relatedTerm } = this.props;
    return (
      <a
        className="related-term"
        href={`/glossary_terms?term=${relatedTerm.published_revision.id}`}
        key={relatedTerm.id}
      >
        <div className="related-term-name">{relatedTerm.published_revision.name}</div>
      </a>
    );
  }
}
