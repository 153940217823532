import React from 'react';
import renderer from 'react-test-renderer';

import { DetailsBreadcrumb } from '../../../learning_center/DetailsContent/DetailsBreadcrumb';
import { topics, currentUser } from '../../__mocks__/lc-data';

it('renders correctly with props', () => {
  const tree = renderer.create(
    <DetailsBreadcrumb
      currentUser={currentUser}
      topic={topics[0]}
      breadcrumb="progress"
    />,
  ).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<DetailsBreadcrumb />).toJSON();
  expect(tree).toMatchSnapshot();
});
