import React, { useEffect } from 'react';
import {
  GoKebabVertical, GoListUnordered,
  FaUserCircle, FaRegBookmark, FaHistory,
} from 'react-icons/all';

export default function SideHeader(props) {
  const [open, setOpen] = React.useState(true);

  const { currentUser } = props;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const bookmarksContent = document.querySelector('.main-content');
    if (open) {
      bookmarksContent.classList.add('side-header-open');
    } else {
      bookmarksContent.classList.remove('side-header-open');
    }
  }, [open]);

  return (
    <div className={`react-side-header ${open && 'open'}`}>
      {
          open ? (
            <div className="drawer-links">
              <a href="/" className="drawer-link sidebar-logo">
                <img src="/logo.svg" alt="" />
              </a>
              <a href="#" className="drawer-link muted small" onClick={handleDrawerClose}>
                <img className="icon" src="/minimize_bar.svg" alt="" />
                <p>Minimize Bar</p>
              </a>
            </div>
          ) : (
            <div className="drawer-links">
              <a href="/" className="drawer-link sidebar-logo">
                <img src="/bulb.png" alt="" />
              </a>
              <a href="#" className="drawer-link muted small" onClick={handleDrawerOpen}>
                <img className="icon" src="/maximize_bar.svg" alt="" />
              </a>
            </div>
          )
        }
      <div className="divider" />
      <div className="drawer-links">
        <a href="/account/edit#profile" className={`drawer-link ${window.location.pathname.includes('/account/edit') ? 'active' : ''}`}>
          <FaUserCircle className="icon" />
          { open && <p>{`${currentUser.first_name} ${currentUser.last_name}`}</p> }
        </a>
        <a href="/dashboard" className={`drawer-link ${window.location.pathname.includes('/dashboard') ? 'active' : ''}`}>
          <img src="/dashboard.svg" className="icon" alt="" />
          { open && <p>Dashboard</p> }
        </a>
        <a href="/cite_lists" className={`drawer-link ${window.location.pathname.includes('/cite_lists') ? 'active' : ''}`}>
          <GoListUnordered className="icon" />
          { open && <p>My Cite Lists</p> }
        </a>
        <a href="/favorites" className={`drawer-link ${window.location.pathname.includes('/favorites') ? 'active' : ''}`}>
          <FaRegBookmark className="icon" />
          { open && <p>Bookmarks</p> }
        </a>
        <a href="/history" className={`drawer-link ${window.location.pathname.includes('/history') ? 'active' : ''}`}>
          <FaHistory className="icon" />
          { open && <p>History</p> }
        </a>
        <a href="#" className="drawer-link muted">
          <GoKebabVertical className="icon" />
          { open && <p>More</p> }
        </a>
      </div>
      <div className="divider" />
      <div className="drawer-links">
        <a href="/case_law" className={`drawer-link muted ${window.location.pathname.includes('/case_law') ? 'active' : ''}`}>
          <img src="/case_law.svg" className="icon" />
          { open && <p>Case Law</p> }
        </a>
        <a href="/rules" className={`drawer-link muted ${window.location.pathname.includes('/rules') ? 'active' : ''}`}>
          <img src="/rules.svg" className="icon" />
          { open && <p>Rules</p> }
        </a>
        <a href="/checklists" className={`drawer-link muted ${window.location.pathname.includes('/checklists') ? 'active' : ''}`}>
          <img src="/checklists_and_forms.svg" className="icon" />
          { open && <p>Checklists & Forms</p> }
        </a>
        <a href="/glossary_terms" className={`drawer-link muted ${window.location.pathname.includes('/glossary_terms') ? 'active' : ''}`}>
          <img src="/glossary.svg" className="icon" />
          { open && <p>Glossary</p> }
        </a>
        <a href="https://www.ediscoveryassistant.com/blog/" className="drawer-link muted">
          <img src="/blog.svg" className="icon" />
          { open && <p>Blog</p> }
        </a>
      </div>
    </div>
  );
}
