/**
 * @jest-environment jsdom
 */

import React from 'react';
import { render } from '@testing-library/react';

import TagFilter from '../../../search/src/TagFilter';

const tags = [
  {
    id: 1,
    name: 'Spoliation',
    subtype: 'issue',
  },
  {
    id: 2,
    name: 'tag 2',
    subtype: 'issue',
  },
];

it('renders correctly with props', () => {
  const ref = React.createRef();
  const tree = render(<TagFilter
    ref={ref}
    tags={tags}
    openModal={() => {}}
    setSearchQuery={() => {}}
    searchQuery="Spoliation"
    onSubmit={() => {}}
    clearDashboardTags={() => {}}
  />);
  expect(tree).toMatchSnapshot();
});
