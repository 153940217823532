import React from 'react';

export default class RelatedChecklist extends React.Component {
  render() {
    const { relatedChecklist } = this.props;
    return (
      <a
        className="related-checklist"
        href={`/checklists/${relatedChecklist.id}`}
        key={relatedChecklist.id}
      >
        <div className="related-checklist-name">{relatedChecklist.published_revision.name}</div>
      </a>
    );
  }
}
