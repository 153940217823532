import React, { useState } from 'react';
import { FadeLoader } from 'react-spinners';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { GoSearch } from 'react-icons/go';
import TagList from '../../tags/TagList';

export default function DistrictRules({
  districtState, favorites, toggleRuleBookmark, districtFilter, loading, ruleCategories,
}) {
  const selectedDistrict = districtState ? districtState.district_courts
    ?.find((district) => district.name === districtFilter) : null;

  const [issuesQuery, setIssuesQuery] = useState('');
  const isMatchQuery = (rule) => issuesQuery === '' || rule.tag_list.find((tag) => tag.toLowerCase().includes(issuesQuery.toLowerCase()));

  const renderSearch = () => (
    <div className="case-search">
      <input type="text" placeholder="Search Issues, try: Sanctions" onChange={(e) => setIssuesQuery(e.target.value)} value={issuesQuery} />
      <GoSearch />
      <span style={{ flex: 1 }} />
    </div>
  );

  if (!selectedDistrict) {
    return (
      <div className="district-rules">
        <div className="select-state">
          <div className="empty-description">
            <span>There are no district court rules displayed.</span>
            <span>Select a filter on the left to start.</span>
          </div>
          <div className="empty-image" />
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="district-rules">
        <div className="loader">
          <FadeLoader color="rgba(0, 14, 87, 1)" />
          <div className="name">Loading</div>
        </div>
      </div>
    );
  }

  return (
    <div className="district-rules">
      <div className="district-rule-list" key={selectedDistrict.id}>
        <div className="title">
          { selectedDistrict.name }
          { renderSearch() }
        </div>
        <div className="summary">{ selectedDistrict.description }</div>
        <div className="results">
          {[
            ...new Map(
              ruleCategories
                .flatMap((category) => category.rules)
                .filter((rule) => isMatchQuery(rule))
                .map((rule) => [rule.rule_id, rule]),
            ).values(),
          ].map((rule) => (
            <a
              className="result"
              key={rule.rule_id}
              href={`/district_rules/${rule.rule_id}`}
            >
              <div className="top">
                <div className="result-number">
                  {rule.number}
                </div>
                {favorites.rules && favorites.rules.length > 0 && favorites.rules.some(
                  (favoriteRule) => favoriteRule?.id === rule.rule_id,
                ) ? (
                  <FaBookmark onClick={(e) => toggleRuleBookmark(e, rule)} />
                  ) : (
                    <FaRegBookmark
                      className="initially-hidden"
                      onClick={(e) => toggleRuleBookmark(e, rule)}
                    />
                  )}
              </div>
              <TagList className="result-tags" tags={rule.tag_list} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
