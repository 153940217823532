// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="tags"
>
  <div
    className="tag btn btn-flat"
  >
    tag A
  </div>
  <div
    className="tag btn btn-flat"
  >
    tag B
  </div>
</div>
`;
