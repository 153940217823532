import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import CiteLists from '../../cite_lists/CiteLists';
import api from '../../../services/api';

const currentUser = {
  id: 1121,
  email: 'blurrybloop@gmail.com',
  first_name: 'Maksym',
  last_name: 'Furgalo',
  account_id: 629,
};

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ message: {} }) });
api.cite_lists.get = jest.fn().mockResolvedValue({
  message: [
    {
      id: 752,
      name: '11111',
      created_at: '2022-05-12T07:56:09.753-06:00',
      updated_at: '2023-02-10T04:00:39.113-07:00',
      user_id: 1121,
      update_notification_sent_at: '2023-02-10T04:00:39.109-07:00',
      cases_count: 4,
      user: {
        id: 1121,
        email: 'blurrybloop@gmail.com',
        deprecated_god: null,
        created_at: '2021-11-17T01:49:57.798-07:00',
        updated_at: '2023-02-10T15:22:18.665-07:00',
        first_name: 'dfsd',
        last_name: 'dfsdf',
        organization: null,
        customer_id: null,
        preview_code_id: null,
        trial_start: null,
        unique_session_id: 'XzCs2wexwZeNX_Rr4QK2',
        extend_trial: false,
        extend_trial_date: null,
        deferred: true,
        mixpanel_id: null,
        imported: false,
        password_set: true,
        account_id: 629,
        disabled: false,
        utm_source: '',
        utm_campaign: '',
        hubspot_id: null,
        subscription_id: 720,
        download_limit: null,
        name: 'dfsd dfsdf',
      },
      cite_list_shares: [],
    },
  ],
});
api.cite_list_cases.get = jest.fn().mockResolvedValue({ message: [] });

describe('CiteLists', () => {
  it('renders correctly with props', () => {
    render(<CiteLists
      bookmarks={{ case_digests: [], cite_lists: [] }}
      currentUser={currentUser}
      citeListId={752}
      hasFullAccess
      numberOfDownloads={null}
      teamMembers={[]}
    />);
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with CiteLists', async () => {
    render(<CiteLists
      bookmarks={{ case_digests: [], cite_lists: [] }}
      currentUser={currentUser}
      citeListId={752}
      hasFullAccess
      numberOfDownloads={null}
      teamMembers={[]}
    />);

    await api.cite_lists.get();

    userEvent.click(screen.getByText(/Edit Name/i));
    userEvent.click(screen.getByText(/Delete Cite List/i));
    userEvent.type(screen.getByPlaceholderText(/Search cite lists/i), 'Text');
  });
});
