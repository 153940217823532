import React from 'react';
import '@testing-library/jest-dom';
import {
  render, screen, act, waitFor,
} from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import AddDynamicCiteListsSection from '../../cite_lists/AddDynamicCiteListSection';
import api from '../../../services/api';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({}) });
api.cite_lists.post = jest.fn().mockResolvedValue({ status: 'OK', message: '123' });
api.cite_lists.get = jest.fn().mockResolvedValue({
  message: [
    {
      id: 2313,
      name: 'Cite List from DB',
      cite_list_cases: [{ case_digest_id: 16542 }],
      user: {
        id: 1112,
      },
      cases_count: 1000,
      search_count: 0,
      search_query: null,
    },
    {
      id: 2314,
      name: 'Dynamic Cite List from DB',
      cite_list_cases: [],
      user: {
        id: 1112,
      },
      cases_count: 0,
      search_count: 2000,
      search_query: '?q=example_of_search_query',
    },
  ],
});

describe('AddToCiteListModal', () => {
  it('renders correctly with props', () => {
    render(<AddDynamicCiteListsSection closeSection={() => {}} />);
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with modal', async () => {
    await act(async () => {
      render(<AddDynamicCiteListsSection closeSection={() => {}} />);
    });

    const inputName = screen.getByPlaceholderText('Enter Cite List Name');
    const createButton = screen.getByText('Create Cite List');

    await waitFor(() => {
      expect(inputName).toBeInTheDocument();
      expect(createButton).toHaveAttribute('disabled');
    });

    await act(async () => {
      await userEvent.type(inputName, 'test');
    });

    await waitFor(() => {
      expect(createButton).not.toHaveAttribute('disabled');
    });

    await act(async () => {
      await userEvent.click(createButton);
    });

    // Ensure the asynchronous state updates within fetch are awaited
    await waitFor(async () => {
      expect(api.cite_lists.post).toHaveBeenCalled();
    });
  });
});
