import React from 'react';
import renderer from 'react-test-renderer';

import RuleSummary from '../../../rules/src/RuleSummary';

const favorites = {};

const ruleType = 'FederalRule';

const selectedRule = {
  summary: 'This is a summary',
  document: { url: '/path_to_pdf.pdf' },
};

it('renders correctly with props', () => {
  const tree = renderer.create(<RuleSummary
    favorites={favorites}
    ruleType={ruleType}
    selectedRule={selectedRule}
    highlights={false}
    toggleRuleBookmark={() => {}}
    toggleHighlights={() => {}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
