// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
Array [
  <div
    className="tabs"
  >
    <div
      className="tab active"
      onClick={[Function]}
    >
      About this
       
      post
    </div>
    <div
      className="tab false"
      onClick={[Function]}
    >
      Resources
    </div>
  </div>,
  <div
    className="tab-content"
  >
    <p
      className="description-header"
    >
      Description
    </p>
    <div
      className="description"
    >
      some description
    </div>
  </div>,
]
`;
