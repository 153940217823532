export default [
  { label: 'Attorney – Partner', value: '1' },
  { label: 'Attorney – Associate', value: '2' },
  { label: 'Attorney – Solo Practitioner', value: '3' },
  { label: 'Litigation Support Professional', value: '5' },
  { label: 'eDiscovery Consultant', value: '7' },
  { label: 'In-House Counsel', value: '15' },
  { label: 'Student', value: '11' },
  { label: 'Professor', value: '12' },
  { label: 'Paralegal', value: '4' },
  { label: 'Information Management or Research Services Professional', value: '6' },
  { label: 'Other', value: '' },
];
