import React, { useState } from 'react';
import {
  CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe,
} from 'react-stripe-elements';
import api from '../../../services/api';

const createOptions = () => ({
  classes: { base: 'form-control' },
  style: {
    base: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: 500,
      fontSize: `${parseFloat(getComputedStyle(document.documentElement).fontSize) * 1.4}px`,
      color: '#1F1F24',
      '::placeholder': {
        color: '#999999',
      },
    },
  },
});

export default injectStripe(
  ({
    stripe, onStatusUpdate, status, closeModal, isSubscription, subscriptionAction,
  }) => {
    const [formErrors, setFormErrors] = useState({});
    const [cardHolder, setCardHolder] = useState('');

    const handleStripeErrors = ({ code, message }) => {
      let field = '';
      switch (code) {
        case 'expired_card':
        case 'invalid_expiry_month':
        case 'invalid_expiry_year':
        case 'invalid_expiry_year_past':
        case 'incomplete_expiry':
          field = 'credit_card_exp';
          break;
        case 'invalid_cvc':
        case 'incomplete_cvc':
        case 'incorrect_cvc':
          field = 'credit_card_cvc';
          break;
        default:
          field = 'credit_card_num';
          break;
      }
      setFormErrors({ ...formErrors, [field]: message });
    };

    const handleStripeChange = () => {
      Object.keys(formErrors).forEach((field) => {
        if (!field.match(/^credit_card/)) return;
        setFormErrors({ ...formErrors, [field]: '' });
      });
    };

    const handleCardHolderChange = (e) => {
      setCardHolder(e.target.value);
      setFormErrors({ ...formErrors, credit_card_holder: e.target.value ? '' : 'Card Holder Missing' });
    };

    const handleSubmitPaymentMethod = () => {
      if (!stripe) return;

      if (!cardHolder) {
        setFormErrors({ ...formErrors, credit_card_holder: 'Card Holder Missing' });
        return;
      }

      const hasErrors = Object.values(formErrors).filter((e) => e).length;
      if (hasErrors) return;

      onStatusUpdate('updating');
      stripe.createToken({ name: cardHolder })
        .then(({ error, token }) => {
          if (error) throw error;
          api.account.updatePaymentMethod({ stripe_token: token.id })
            .then(() => {
              if (isSubscription) {
                window.location.href = `/account/edit?tab=billingInfo&modal=subscription&action_type=${subscriptionAction}`;
                return;
              }

              onStatusUpdate('success');
              window.location.href = '/account/edit?tab=billingInfo';
            })
            .catch(() => alert('payment method update error'));
        })
        .catch((error) => {
          handleStripeErrors(error);
        });
    };

    return (
      <div className="new-modal payment-method-modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-dialog modal-md" style={{ width: '500px' }}>
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">
                <h4>Update Payment Method</h4>
                <button
                  type="button"
                  className="button-clear pull-right"
                  onClick={closeModal}
                >
                  ✕
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className={`form-group ${formErrors.credit_card_num ? 'has-error' : ''}`}>
                    <label>Card No.</label>
                    <CardNumberElement placeholder="Card / Account Number" {...createOptions()} onChange={handleStripeChange} />
                    { formErrors.credit_card_num && <p className="help-block">{formErrors.credit_card_num}</p> }
                  </div>

                  <div className={`form-group ${formErrors.credit_card_holder ? 'has-error' : ''}`}>
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="credit_card_holder"
                      id="credit_card_holder"
                      value={cardHolder}
                      onChange={handleCardHolderChange}
                      placeholder="Card Holder Name"
                    />
                    { formErrors.credit_card_holder && <p className="help-block">{formErrors.credit_card_holder}</p> }
                  </div>

                  <div className="row">
                    <div className={`form-group col-md-6 ${formErrors.credit_card_exp ? 'has-error' : ''}`}>
                      <label>Exp. Date</label>
                      <CardExpiryElement placeholder="Expiry Date" {...createOptions()} onChange={handleStripeChange} />
                      { formErrors.credit_card_exp && <p className="help-block">{formErrors.credit_card_exp}</p> }
                    </div>
                    <div className={`form-group col-md-6 ${formErrors.credit_card_cvc ? 'has-error' : ''}`} style={{ margin: 0 }}>
                      <label>CVV</label>
                      <CardCVCElement placeholder="CVV" {...createOptions()} onChange={handleStripeChange} />
                      { formErrors.credit_card_cvc && <p className="help-block">{formErrors.credit_card_cvc}</p> }
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={handleSubmitPaymentMethod}
                type="button"
                className="modal-button"
                disabled={status === 'updating' || status === 'success'}
              >
                { status === 'updating' ? 'Updating' : 'Update' }
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
