import React from 'react';
import PropTypes from 'prop-types';

const moreButtonWidth = 35;

export default class TagList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleTags: Infinity,
    };

    this.tagsContainer = React.createRef();
  }

  componentDidMount() {
    this.hideOverflowedTags();
  }

  componentDidUpdate(prevProps) {
    const { tags } = this.props;
    const { visibleTags } = this.state;
    if (visibleTags >= (tags || []).length || prevProps.tags === tags) return;
    this.hideOverflowedTags();
  }

  expand = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ visibleTags: Infinity });
    return false;
  };

  hideOverflowedTags() {
    const { maxVisibleTags, isCotw } = this.props;
    const tagsContainer = this.tagsContainer.current;
    if (!tagsContainer) return null;
    const tags = tagsContainer.querySelectorAll('.tag');

    const { width: containerWidth, left: containerLeft, top: containerTop } = tagsContainer.getBoundingClientRect();
    let visibleTags = 2;
    for (let i = 0; i < tags.length; i += 1) {
      const tag = tags[i];
      const { right, top } = tag.getBoundingClientRect();
      if (right - containerLeft > containerWidth - moreButtonWidth || top > containerTop) break;
      visibleTags += 1;
    }

    if (isCotw) {
      visibleTags = 3;
    }

    this.setState({ visibleTags: Math.min(visibleTags, maxVisibleTags) });
    return null;
  }

  render() {
    const { tags, clickable, className } = this.props;
    const { visibleTags } = this.state;

    return (
      <div ref={this.tagsContainer} className={className || 'tags'}>
        {
          (tags || []).slice(0, visibleTags).map((tag) => (clickable ? (
            <a className="tag btn btn-flat" key={tag.id} href={`/case_law?tag_ids_query=${tag.id}`}>
              {tag.name}
            </a>
          ) : (
            <div className="tag btn btn-flat" key={typeof tag === 'string' ? tag : tag.id}>
              {typeof tag === 'string' ? tag : tag.name}
            </div>
          )))
        }
        { (tags || []).length > visibleTags && <a href="#" className="tag" onClick={this.expand} key="expand">...</a> }
      </div>
    );
  }
}

TagList.propTypes = {
  tags: PropTypes.array.isRequired,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  maxVisibleTags: PropTypes.number,
};

TagList.defaultProps = {
  className: '',
  clickable: false,
  maxVisibleTags: 5,
};
