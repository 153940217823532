// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="district-rules"
>
  <div
    className="district-rule-list"
  >
    <div
      className="title"
    >
      Eastern District of Arkansas
      <div
        className="case-search"
      >
        <input
          onChange={[Function]}
          placeholder="Search Issues, try: Sanctions"
          type="text"
          value=""
        />
        <svg
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 16 16"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
            fillRule="evenodd"
          />
        </svg>
        <span
          style={
            Object {
              "flex": 1,
            }
          }
        />
      </div>
    </div>
    <div
      className="summary"
    >
      The Eastern and Western District of Arkansas share the same local rules.  The Local Rules (Civil) do not contain provisions specifically designed to address electronic discovery, but Local Rule 26.1 contains an Outline for Fed. R. Civ. P. 26(f) Report. dfsfsdgsdf
    </div>
    <div
      className="results"
    >
      <a
        className="result"
        href="/district_rules/4889"
      >
        <div
          className="top"
        >
          <div
            className="result-number"
          >
            Local Rule 26.1, Outline for Fed.R.Civ.P. 26(f) Report
          </div>
          <svg
            fill="currentColor"
            height="1em"
            onClick={[Function]}
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 384 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"
            />
          </svg>
        </div>
        <div
          className="result-tags"
        >
          <div
            className="tag btn btn-flat"
          >
            some
          </div>
          <div
            className="tag btn btn-flat"
          >
            tags
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
`;

exports[`renders correctly without props 1`] = `
<div
  className="district-rules"
>
  <div
    className="select-state"
  >
    <div
      className="empty-description"
    >
      <span>
        There are no district court rules displayed.
      </span>
      <span>
        Select a filter on the left to start.
      </span>
    </div>
    <div
      className="empty-image"
    />
  </div>
</div>
`;

exports[`renders correctly without props with loader 1`] = `
<div
  className="district-rules"
>
  <div
    className="select-state"
  >
    <div
      className="empty-description"
    >
      <span>
        There are no district court rules displayed.
      </span>
      <span>
        Select a filter on the left to start.
      </span>
    </div>
    <div
      className="empty-image"
    />
  </div>
</div>
`;
