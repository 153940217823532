import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';
import ExpiredModal from '../../../account/account_modals/ExpiredModal';

describe('account updated Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<ExpiredModal plan={{ plan_type: 'check' }} account={{ account_type: 'Individual' }} closeModal={() => {}} />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders modal and close click', async () => {
    const close = jest.fn(() => {});
    const container = render(
      <ExpiredModal
        account={{ account_type: 'Individual' }}
        plan={{ plan_type: 'check' }}
        closeModal={close}
      />,
    );
    const closeButton = container.queryByText('✕');
    expect(container.queryByText('support@ediscoveryassistant.com')).toBeInTheDocument();
    expect(closeButton).toBeInTheDocument();
    fireEvent.click(closeButton);
    expect(close).toHaveBeenCalledTimes(1);
  });
});
