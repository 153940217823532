import PropTypes from 'prop-types';
import React from 'react';

export default function LoginModal({ closeModal }) {
  return (
    <div className="login-modal new-modal" onClick={closeModal} data-testid="login-modal">
      <div className="modal-dialog modal-md">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="circle" />
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            <p>
              Thank you for visiting eDiscovery Assistant.
              You are viewing this decision through the public
              link shared from our caselaw database. For access to
              additional content and features, log in to your account,
              create a new paid account, or contact us at
              <a href="mailto:support@ediscoveryassistant.com"> support@ediscoveryassistant.com</a>
            </p>
            <div className="button-row">
              <button className="modal-button">
                <a href="/registrations/new/individual">Create Account</a>
              </button>
              <button className="modal-button modal-button-outline">
                <a href="/login">Login</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
