import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import SubscriptionModal from '../../../account/account_modals/SubscriptionModal';
import { plan, plans, paymentMethod } from '../../__mocks__/account-data';

describe('Subscription Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<SubscriptionModal
      status="updating"
      closeModal={() => {}}
      plan={plan}
      paymentMethod={paymentMethod}
      onSubmit={() => {}}
      plans={plans}
      subscriptionAction="eda"
      account_type="individual"
    />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders correctly without props', () => {
    const tree = renderer.create(<SubscriptionModal />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders plan correctly', async () => {
    const container = render(
      <SubscriptionModal
        status=""
        closeModal={() => {}}
        plan={plan}
        paymentMethod={paymentMethod}
        onSubmit={() => {}}
        plans={plans}
        subscriptionAction="eda"
        account_type="individual"
      />,
    );
    expect(container.queryByText('Individual')).toBeInTheDocument();
  });

  it('renders plan correctly and submit without payment method', async () => {
    const submit = jest.fn(() => {});
    const container = render(
      <SubscriptionModal
        status=""
        closeModal={() => {}}
        plan={plan}
        paymentMethod={{}}
        onSubmit={submit}
        plans={plans}
        subscriptionAction="eda"
        account_type="individual"
      />,
    );
    const updateButton = container.queryByText('Update');
    expect(updateButton).toBeInTheDocument();
    fireEvent.click(updateButton);
    expect(submit).toHaveBeenCalledTimes(0);
  });
});
