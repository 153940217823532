import React from 'react';
import { FadeLoader } from 'react-spinners';

export default function SearchLoadingModal() {
  return (
    <div className="loading-modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="margin">
            <FadeLoader color="rgba(0, 14, 87, 1)" className="fade-loader" />
          </div>
          <div className="name">Loading</div>
        </div>
      </div>
    </div>
  );
}
