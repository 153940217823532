import React from 'react';
import renderer from 'react-test-renderer';

import SearchResults from '../../learning_center/SearchResults';
import {
  tags, topics, currentUser, isAdmin, mediaItems,
} from '../__mocks__/lc-data';

it('renders correctly with props', () => {
  const tree = renderer.create(
    <SearchResults
      currentUser={currentUser}
      isAdmin={isAdmin}
      topics={topics}
      tags={tags}
      results={mediaItems}
    />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<SearchResults />).toJSON();
  expect(tree.element).toMatchSnapshot();
});
