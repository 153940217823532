// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`subscription canceled Modal renders correctly with props 1`] = `
<div
  className="new-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Your subscription will be cancelled effective
           
          10/03/2019
          .
        </p>
        <div
          className="button-row"
        >
          <div
            className="modal-button"
            onClick={[Function]}
          >
            OK
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
