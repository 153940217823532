/**
 * @jest-environment jsdom
 */

import React from 'react';
import renderer from 'react-test-renderer';

import TagSection from '../../../search/src/TagSection';

const tags = [
  {
    id: 1,
    name: 'tag 1',
    subtype: 'issue',
  },
  {
    id: 2,
    name: 'tag 2',
    subtype: 'issue',
  },
];

it('renders correctly with props', () => {
  const tree = renderer.create(<TagSection
    tags={tags}
    closeSection={() => {}}
    selectTag={() => {}}
    unselectTag={() => {}}
    selectedTags={[]}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
