import React, { useEffect, useState } from 'react';
import { FiTrash, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { GoSettings, GoPlus } from 'react-icons/go';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';
import Select from '../Select';

const typeOptions = [{
  value: '',
  label: 'All Content',
}, {
  value: 'Video',
  label: 'Videos',
}, {
  value: 'Post',
  label: 'Posts',
}, {
  value: 'Article',
  label: 'Articles',
}, {
  value: 'Audio',
  label: 'Audio',
}, {
  value: 'Document',
  label: 'Documents',
}, {
  value: 'Ebook',
  label: 'Ebooks',
}];

export default function Index({ tags }) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [mediaItems, setMediaItems] = useState(null);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [tagIds, setTagIds] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortDir, setSortDir] = useState('');

  const fetchData = (opts) => {
    Api.admin.media_items.getAll(opts).then(({ status, data }) => {
      if (status !== 'OK') return;

      setMediaItems(data.mediaItems);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    });
  };

  const handlePage = (page) => {
    fetchData({
      page, title, type, tagIds, sortBy, sortDir,
    });
  };

  const handleFilter = () => {
    fetchData({
      page: 1, title, type, tagIds, sortBy, sortDir,
    });
  };

  const handleSort = (field, direction) => {
    setSortBy(field);
    setSortDir(direction);
    fetchData({
      page: 1, title, type, tagIds, sortBy: field, sortDir: direction,
    });
  };

  const handleDelete = (mediaItem) => {
    const res = window.confirm('Are you sure?');
    if (!res) return;
    Api.admin.media_items.delete(mediaItem.id).then(({ status }) => {
      if (status !== 'OK') return;

      Snackbar.show(`“${mediaItem.title}” has been deleted!`);
      fetchData({
        page: currentPage, title, type, tagIds, sortBy, sortDir,
      });
    });
  };

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  return (
    <div className={`admin-page react-admin-academy ${filtersOpen ? 'filters-open' : ''}`}>
      <div className="header">
        <h1>Academy</h1>
        <button type="button" className={`filters-button ${filtersOpen ? 'active' : ''}`} onClick={() => setFiltersOpen(!filtersOpen)}>
          <GoSettings />
        </button>
      </div>
      <div className="table">
        <div className="table-header">
          <a className="button" href="/admin/media_items/new?academy=1">
            Add New
            <GoPlus />
          </a>

          <a className="button" href="/admin/academy/modules">
            Manage Academy Modules
          </a>
        </div>

        <table>
          <thead>
            <tr>
              <th>#</th>
              <th className="sortable-row" onClick={() => handleSort('title', sortDir === 'asc' ? 'desc' : 'asc')}>
                Title
                { sortBy === 'title' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'title' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th className="sortable-row" onClick={() => handleSort('type', sortDir === 'asc' ? 'desc' : 'asc')}>
                Type
                { sortBy === 'type' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'type' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th>Categories</th>
              <th>Issues</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { (mediaItems || []).map((mediaItem, idx) => (
              <tr key={mediaItem.id}>
                <td width="30">{(currentPage - 1) * 25 + idx + 1}</td>
                <td>
                  <a href={`/admin/media_items/${mediaItem.id}/edit?academy=1`}>
                    {mediaItem.title}
                  </a>
                </td>
                <td>{mediaItem.type}</td>
                <td width="20%">{mediaItem.media_categories.map((c) => c.name).join(', ')}</td>
                <td width="20%">{mediaItem.tags?.map((c) => c.name).join(', ')}</td>
                <td>{mediaItem.active ? 'Active' : 'Inactive'}</td>
                <td>
                  <div className="buttons">
                    <button type="button" className="delete-button" onClick={() => handleDelete(mediaItem)}>
                      <FiTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage - 1)}
          >
            &lt;
          </button>
          <span>
            Page
            {' '}
            {currentPage}
            /
            {totalPages}
          </span>
          <button
            disabled={currentPage >= totalPages}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      </div>

      { filtersOpen && (
        <div className="sidebar">
          <div className="sidebar-header">
            <h2>Filter By</h2>
          </div>
          <div className="sidebar-body" onKeyDown={(e) => e.key === 'Enter' && handleFilter()}>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Type</label>
              <Select
                value={typeOptions.find((opt) => opt.value === type)}
                options={typeOptions}
                onChange={(opt) => setType(opt.value)}
              />
            </div>

            <div className="form-group">
              <label>Issues</label>
              <Select
                value=""
                options={tags.map((form) => ({
                  label: form.name,
                  value: form.id,
                }))}
                onChange={(tag) => setTagIds([...tagIds, tag.value])}
              />

              <div className="select-list-items">
                { tagIds.map((tag) => (
                  <div className="list-item">
                    <span>{tags.find((c) => c.id === tag)?.name}</span>
                    <button
                      type="button"
                      className="button delete-button"
                      onClick={() => setTagIds(tagIds.filter((c) => c !== tag))}
                    >
                      <FiTrash />
                    </button>
                  </div>
                )) }
              </div>
            </div>

            <button type="button" className="button" onClick={handleFilter}>Filter</button>
          </div>
        </div>
      )}
    </div>
  );
}
