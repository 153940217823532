import React from 'react';

export default function TrialExpiredModal({ closeModal, togglePaymentMethodModal }) {
  return (
    <div className="new-modal expired-subscription-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <button
            type="button"
            className="button-clear pull-right"
            onClick={closeModal}
          >
            ✕
          </button>
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
              <h4>
                Your free trial has expired. To continue using eDiscovery Assistant, click below to add payment method and sign up for an individual plan.
              </h4>
            </div>
          </div>
          <div className="modal-body">
            <p className="question">Continue using eDiscovery Assistant?</p>
            <button
              type="button"
              className="modal-button"
              onClick={togglePaymentMethodModal}
            >
              Sign Up for individual plan
            </button>
            <p className="note">
              If you want to upgrade to either Team or Enterprise, contact us via
              {' '}
              <a href="mailto:support@ediscoveryassistant.com">support@ediscoveryassistant.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
