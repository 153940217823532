import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';

import Results from '../../../search/src/Results';

const tags = [
  {
    id: 1,
    name: 'Issue #1',
    subtype: 'issue',
  },
  {
    id: 2,
    name: 'Issue #2',
    subtype: 'issue',
  },
];

const results = {
  case_digests: [
    {
      digest: {
        id: 34532,
        deleted: false,
        is_digested: false,
        pdf: 'dickinson_frozen_foods_v._fps_food_process_sols._corp.20210617-4-1i5xoae.pdf',
        tags,
      },
      published_revision: {
        id: 94135,
        name: 'Dickinson Frozen Foods v. FPS Food Process Sols. Corp.',
        full_text: null,
        cite: '2021 WL 2444157 (D. Idaho 2021)',
        judge: 'Nye, David C.',
        decision_date: '2021-06-15T00:00:00.000-06:00',
        case_digest_id: 34532,
        published: true,
        judge_type: 'United States District Judge',
        court_id: 2133,
        key_case: false,
        notes: null,
        cached_tag_list: 'Exclusion of Evidence, Protective Order, Search Terms, Proportionality, Cooperation of counsel, Initial Disclosures, Failure to Preserve, Sanctions, Spoliation, 30(b)(6) or corporate designee, Costs and Fees',
        linked_federal_rules: 'Fed. R. Civ. P. 30, Fed. R. Civ. P. 26, Fed. R. Civ. P. 37 -- effective 12/1/15',
        google_scholar_text_type: 'westlaw',
        court_display_name: 'United States District Court, D. Idaho',
        case_title: 'DICKINSON FROZEN FOODS, INC., Plaintiff,\r\nv.\r\nFPS FOOD PROCESS SOLUTIONS CORPORATION, Defendant',
        docket_number: 'Case No. 1:17-cv-00519-DCN',
        counsel: '',
        decision_title: 'MEMORANDUM DECISION AND ORDER',
        additional_dates: [],
        decision_date_label: 'Filed',
        disposition: 'Motion for Attorney Fees',
        is_panel: false,
        is_per_curiam: false,
        panel_members: null,
        tag_list: [
          'Exclusion of Evidence',
          'Protective Order',
          'Search Terms',
          'Proportionality',
          'Cooperation of counsel',
          'Initial Disclosures',
          'Failure to Preserve',
          'Sanctions',
          'Spoliation',
          '30(b)(6) or corporate designee',
          'Costs and Fees',
        ],
        tags,
      },
      slug: '34532-dickinson-frozen-foods-v-fps-food-process-sols-corp',
      full_text: null,
      highlighted_fragmen: null,
    },
  ],
  court_counts: {
    total_count: 1,
  },
};

const favorites = {
  rules: [
    {
      id: 4994,
      created_at: '2016-01-20T23:53:39.048-07:00',
      updated_at: '2023-08-02T03:08:06.228-06:00',
      deleted: false,
      state_court_id: null,
      district_court_id: null,
      regulatory_agency_id: 5,
    },
    {
      id: 4889,
      created_at: '2013-04-30T22:28:03.746-06:00',
      updated_at: '2017-08-04T16:09:01.494-06:00',
      deleted: false,
      state_court_id: null,
      district_court_id: 57,
      regulatory_agency_id: null,
    },
  ],
};

function createNodeMock(element) {
  const firstLink = document.createElement('a');
  const secondLink = document.createElement('a');
  if (element.type === 'div') {
    return {
      querySelectorAll: () => [firstLink, secondLink],
      getBoundingClientRect: () => ({
        bottom: 153.33333206176758,
        height: 28.11111068725586,
        left: 573.8194580078125,
        right: 1589.9027709960938,
        top: 125.22222137451172,
        width: 1016.083312988281,
        x: 573.8194580078125,
        y: 125.22222137451172,
      }),
    };
  }

  return null;
}

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ message: null }) });

it('renders correctly with props', () => {
  const tree = renderer.create(<Results
    search={() => {}}
    sortBy="des_decision_date"
    resultsPerPage="25"
    onSort={() => {}}
    onPerPage={() => {}}
    results={results}
    isAdmin={false}
    allResultIds={[34532]}
    federalCourts={[]}
    stateCourts={[]}
    selectedJurisdictions={() => {}}
    tags={tags}
    favorites={favorites}
  />, { createNodeMock }).toJSON();
  expect(tree).toMatchSnapshot();
});
