import React from 'react';

export default function PaymentMethodUpdatedModal() {
  return (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <h4>Payment Method updated</h4>
            </div>
          </div>
          <div className="modal-body">
            <p>Thank you for your payment.</p>
            <p>Your current subscription is Individual Plan, and auto-renews annually on one year from today.</p>
            <button
              type="button"
              className="modal-button modal-button-outline"
              onClick={() => window.location.reload()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
