// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div>
  <div>
    <div>
      <a
        href="/account/edit#profile"
      >
        <svg
          className="icon user-icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
              "height": 25,
              "width": 25,
            }
          }
          viewBox="0 0 496 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
          />
        </svg>
        Name
         
        Surename
      </a>
    </div>
    <hr />
    <div>
      <a>
        <svg
          className="icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 512 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
          />
        </svg>
        Send Feedback
      </a>
    </div>
    <hr />
    <div>
      <a
        href="/account/edit#profile"
      >
        <svg
          className="icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 496 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
          />
        </svg>
        Account Settings
      </a>
    </div>
    <div>
      <div>
        <a
          href="/admin/accounts"
        >
          Accounts
        </a>
      </div>
      <div>
        <a
          href="/admin/users"
        >
          Users
        </a>
      </div>
      <div>
        <a
          href="/admin/rules"
        >
          Rules
        </a>
      </div>
      <div>
        <a
          href="/admin/case_digests"
        >
          Case Law
        </a>
      </div>
      <div>
        <a
          href="/admin/categories"
        >
          Categories
        </a>
      </div>
      <div>
        <a
          href="/admin/checklists"
        >
          Checklists
        </a>
      </div>
      <div>
        <a
          href="/admin/templates"
        >
          Forms
        </a>
      </div>
      <div>
        <a
          href="/admin/glossary_terms"
        >
          Glossary Terms
        </a>
      </div>
      <div>
        <a
          href="/admin/resources"
        >
          Resources
        </a>
      </div>
      <div>
        <a
          href="/admin/tags"
        >
          Tags
        </a>
      </div>
      <div>
        <a
          href="/admin/tips"
        >
          Tips
        </a>
      </div>
      <div>
        <a
          href="/admin/categories"
        >
          Categories
        </a>
      </div>
      <div>
        <a
          href="/admin/academy"
        >
          Academy
        </a>
      </div>
    </div>
    <div>
      <a
        data-method="delete"
        href="/logout"
        rel="nofollow"
      >
        <svg
          className="icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 512 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
          />
        </svg>
        Logout
      </a>
    </div>
  </div>
</div>
`;

exports[`renders correctly without props 1`] = `
<div>
  <div>
    <div>
      <a
        href="/login"
      >
        <svg
          className="icon"
          fill="none"
          height="1em"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 24 24"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"
          />
          <polyline
            points="10 17 15 12 10 7"
          />
          <line
            x1="15"
            x2="3"
            y1="12"
            y2="12"
          />
        </svg>
        Login
      </a>
    </div>
  </div>
</div>
`;
