import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';

import { renderTotalPages } from '../../../services/pages';

describe('account updated Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(renderTotalPages(15, 7, 'about')).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('return null without props', () => {
    expect(renderTotalPages()).toBe(null);
  });
});
