import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import SessionModal from '../../header/SessionModal';
import api from '../../../services/api';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ message: {} }) });
api.cite_lists.get = jest.fn().mockResolvedValue({});

describe('account updated Modal', () => {
  it('renders correctly without props', () => {
    const tree = renderer.create(
      <SessionModal closeModal={() => {}} />,
    ).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders modal and close click', async () => {
    const close = jest.fn(() => {});
    const container = render(
      <SessionModal closeModal={close} />,
    );
    const button = container.queryByTestId('session-modal');
    expect(button).toBeInTheDocument();
    fireEvent.click(button);
    expect(close).toHaveBeenCalledTimes(1);
  });
});
