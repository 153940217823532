import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import Glossary from '../../glossary/Glossary';
import api from '../../../services/api';

const glossaryMessage = [
  {
    glossary_term_id: 3121,
    id: 312,
    name: 'A Term',
  },
  {
    glossary_term_id: 564,
    id: 2423,
    name: 'B Term',
  },
  {
    glossary_term_id: 978,
    id: 593,
    name: 'C Term',
  },
];

const checklists = [
  {
    checklist_id: 23,
    created_at: '2013-07-03T15:05:51.679-06:00',
    description: 'A list of hardware that should be used to ask custodians where data might be stored for collection.  ',
    id: 141,
    name: 'Common Hardware Types for Collection (Corporate)',
    non_subscription: true,
    published: true,
    tag_list: [],
    updated_at: '2013-07-22T10:49:05.312-06:00',
  },
];

const forms = [
  {
    autolinked_text: null,
    created_at: '2015-04-06T14:57:16.479-06:00',
    district_rule_id: null,
    document: { url: 'http://localhost:3000/uploads/template_revision/document/1190/chain_of_custody.pdf' },
    document_cache: null,
    id: 1190,
    last_reviewed_on: null,
    name: 'Chain of Custody Form ',
    non_subscription: false,
    pilot_project_rule_id: null,
    published: true,
    regulatory_rule_id: null,
    resource_id: null,
    summary: 'Use this form to capture all of the pertinent information about how data is collected. Chain of custody is crucial to protecting the integrity of the data and authenticating it at trial. Any claim that data has been tampered with will implicate the chain of custody.  ',
    tag_list: [],
    template_id: 45,
    text: '',
    updated_at: '2016-11-28T13:45:33.775-07:00',
    word_document: { url: null },
    word_document_cache: null,
  },
];

const rules = [
  {
    id: 432,
    rule_id: 6546,
    number: 'FederalRule #1',
  },
  {
    id: 3123,
    rule_id: 35435,
    number: 'State Rule #2',
  },
  {
    id: 654,
    rule_id: 6054,
    number: 'DistrictRule #3',
  },
  {
    id: 6450,
    rule_id: 65054,
    number: 'AbaModelRule #4',
  },
  {
    id: 6454,
    rule_id: 65054,
    number: 'RegulatoryRule #5',
  },
];

const glossary_terms = [
  {
    id: 5430,
    glossary_term_id: 3453,
    name: 'Glossary Term Name',
  },
  {
    glossary_term_id: 3121,
    id: 3121,
    name: 'A Term',
  },
];

const favorites = {
  checklists,
  forms,
  rules,
  glossary_terms,
};

const apiFavorites = {
  glossary_terms: [],
};

const term = {
  id: 312,
  attribution: 'This is an attribution',
  definition: 'This is a definition',
  glossary_term_id: 3121,
};

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ message: { some: () => [], filter: () => [] } }) });
api.glossary.get = jest.fn().mockResolvedValue({ status: 'OK', message: glossaryMessage });
api.glossary.getTerm = jest.fn().mockResolvedValue({ data: term });
api.glossary.favorite = jest.fn().mockResolvedValue({ json: () => Promise.resolve({}) });
api.glossary.favorite = jest.fn().mockResolvedValue({ json: () => Promise.resolve({}) });

api.favorites.get = jest.fn().mockResolvedValueOnce({ favorites: apiFavorites }).mockResolvedValue({ favorites });

it('renders correctly with props', () => {
  render(<Glossary favorites={{}} />);
  expect(screen).toMatchSnapshot();
});

it('correctly interacts with Glossary', async () => {
  render(<Glossary favorites={{}} />);

  await api.glossary.get();

  const aTerm = screen.getByText('A Term');
  const bTerm = screen.getByText('B Term');
  const cTerm = screen.getByText('C Term');

  expect(aTerm).toBeInTheDocument();
  expect(bTerm).toBeInTheDocument();
  expect(cTerm).toBeInTheDocument();

  userEvent.click(aTerm);
  await api.glossary.getTerm();
  expect(screen.getByText(/This is a definition/i)).toBeInTheDocument();

  const search = screen.getByPlaceholderText(/Search Glossary. Try “Image”/i);
  userEvent.type(search, 'term');

  const aLetters = screen.getAllByText('A');

  userEvent.click(aLetters[0]);

  expect(bTerm).not.toBeInTheDocument();
  expect(cTerm).not.toBeInTheDocument();
});
