import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FaArrowDown,
  FaBookmark,
  FaCheckSquare,
  FaRegBookmark,
  FaRegSquare,
} from 'react-icons/all';
import ReactHtmlParser from 'react-html-parser';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import DownloadModal from '../download_modal/DownloadModal';
import api from '../../services/api';
import { DecisionDateFormat } from '../../services/constants';
import PaywallModal from '../cite_lists/modals/PaywallModal';

const EdaSwitch = withStyles(() => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    marginRight: 6,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: '#FFF',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#FFF',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#515C9E',
        borderColor: 'transparent',
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 100,
    opacity: 1,
    backgroundColor: '#E5E5E8',
  },
  checked: {},
}))(Switch);

export default function Checklist(props) {
  const {
    checklist, plan_name, checklistItems, preview, account_name,
  } = props;
  const [tips, setTips] = useState(true);
  const [checkedItems, setCheckedItems] = useState({});
  const [favorites, setFavorites] = useState(props.favorites.checklists_and_forms);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [downloadLimit, setDownloadLimit] = useState(props.numberOfDownloads);
  const [mobileOpened, setMobileOpened] = useState(false);
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const hasRelatedItems = [
    ...checklist.case_digests,
    ...checklist.published_revision.checklists,
    ...checklist.published_revision.templates].length > 0;
  const [downloadAction, setDownloadAction] = useState(false);
  const hasDownloadLimit = plan_name === 'Trial' || plan_name === 'Individual Monthly';

  const toggleTips = () => setTips(!tips);

  const toggleItem = (item) => {
    if (preview) return;
    setCheckedItems({ ...checkedItems, [item.id]: !checkedItems[item.id] });
  };

  const updateFavorites = () => {
    api.favorites.get().then((result) => {
      setFavorites(result.favorites.checklists_and_forms);
    });
  };

  const isFavorite = () => {
    if (!favorites) return false;
    return favorites.some((favoriteTerm) => favoriteTerm.id === checklist.id);
  };

  const toggleBookmark = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (preview) return;

    if (isFavorite()) {
      api.checklists.unfavorite(checklist.id).then(() => updateFavorites());
      return;
    }
    api.checklists.favorite(checklist.id).then(() => updateFavorites());
  };

  const toggleDownloadModal = () => setDownloadModalOpen(!downloadModalOpen);

  const downloadPDF = async () => {
    if (preview) return;

    const { isACEDSTrial } = props;
    if (isACEDSTrial) {
      setPaywallModalOpen(true);
      return;
    }

    if (hasDownloadLimit && downloadLimit === 0) return;
    if (hasDownloadLimit) {
      toggleDownloadModal();
      setDownloadLimit(downloadLimit - 1);
      setDownloadAction(true);
    }

    await api.checklists.pdf(checklist.id, {
      extra_info: tips ? 'true' : 'false',
      checked_ids: Object.keys(checkedItems).filter((k) => checkedItems[k]).join(','),
    }).catch(() => alert('Download failed'));
  };

  const renderTree = (items) => (
    <ul className="items-list">
      {items.map((item) => {
        const hasChildren = item.children && item.children.length > 0;
        return (
          <li className={checkedItems[item.id] && 'checked'} style={item.heading === true ? { fontWeight: 'bold' } : {}} key={item.id}>
            { !hasChildren && (
              checkedItems[item.id]
                ? <FaCheckSquare onClick={() => toggleItem(item)} />
                : <FaRegSquare onClick={() => toggleItem(item)} />
            ) }
            { ReactHtmlParser(item.autolinked_text || item.text) }
            {hasChildren && renderTree(item.children)}
            { tips && item.tips.map((tip) => (
              <div className="item-tip" key={tip.id}>
                {tip.description}
              </div>
            ))}
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="react-checklist">
      <div className="mobile-search-open" onClick={() => setMobileOpened(!mobileOpened)} />
      <div className="left-content">
        <div className="item-details">
          { !preview && (
            <div className="buttons">
              <a className="back" href="/checklists">← Back to list</a>
            </div>
          )}
          <div className="category">Checklists</div>
          <div className="title">{ checklist.published_revision.name }</div>
          { checklist.published_revision.description && <div className="description">{ ReactHtmlParser(checklist.published_revision.description) }</div>}
          <div className="item-content">
            {renderTree(checklistItems)}
          </div>
        </div>
      </div>
      <div className={`right-content ${mobileOpened ? 'opened' : ''}`}>
        <div className="item-summary">
          <div className={`summary-checkbox${tips === true ? ' selected' : ''}`}>
            <EdaSwitch checked={tips} onChange={toggleTips} />
            Show eDiscovery tips
          </div>
          <div className="buttons">
            <div className="pdf" onClick={downloadPDF}>
              <FaArrowDown />
              Download PDF
            </div>
            <div className="bookmark" onClick={(e) => toggleBookmark(e, checklist)}>
              { isFavorite()
                ? (
                  <div>
                    <FaBookmark />
                    Remove Bookmark
                  </div>
                )
                : (
                  <div>
                    <FaRegBookmark />
                    Add To Bookmarks
                  </div>
                ) }
            </div>
          </div>
        </div>
        <div className="related">Related Content</div>
        { !hasRelatedItems && (<div className="category">No related content found.</div>) }

        { checklist.case_digests?.length > 0
        && (
        <div className="related-item">
          <div className="category">Case Digests</div>
          { checklist.case_digests?.map((caseItem) => (
            <>
              <a href={`/case_law/${caseItem.case_digest.slug}`}>
                <div className="title">{ caseItem.name }</div>
              </a>
              <div className="category">{ caseItem.cite }</div>
              <div className="category">{caseItem.decision_date && moment(caseItem.decision_date).format(DecisionDateFormat)}</div>
              <div className="category">
                { caseItem.judge }
                {`${caseItem.judge === 'Panel' && !caseItem.judge_type ? '' : ','}
                    \u00a0${caseItem.judge_type}`}
              </div>
            </>
          )) }
        </div>
        )}

        { checklist.published_revision.checklists?.length > 0
        && (
        <div className="related-item">
          <div className="category">Checklists</div>
          { checklist.published_revision.checklists?.map((checklistItem) => (
            <a href={`/checklists/${checklistItem.id}`}>
              <div className="title">{ checklistItem.published_revision.name }</div>
            </a>
          ))}
        </div>
        )}

        { checklist.published_revision.templates?.length > 0
        && (
        <div className="related-item">
          <div className="category">Forms</div>
          { checklist.published_revision.templates?.map((templateItem) => (
            <a href={`/forms/${templateItem.id}`}>
              <div className="title">{ templateItem.published_revision.name }</div>
            </a>
          ))}
        </div>
        )}
      </div>
      {(downloadModalOpen && (
        <DownloadModal
          closeModal={() => {
            toggleDownloadModal();
            setDownloadAction(false);
          }}
          numberOfDownloads={downloadLimit}
          isDownload={downloadAction}
          plan_name={plan_name}
        />
      ))}
      { paywallModalOpen && (
        <PaywallModal
          isDownload
          account_name={account_name}
          closeModal={() => {
            setPaywallModalOpen(false);
          }}
        />
      )}
    </div>
  );
}

Checklist.propTypes = {
  checklist: PropTypes.object.isRequired,
  checklistItems: PropTypes.array.isRequired,
  favorites: PropTypes.object.isRequired,
  preview: PropTypes.bool,
};

Checklist.defaultProps = {
  preview: null,
};
