import React, { useEffect, useState } from 'react';
import { GoSettings } from 'react-icons/go';
import { FadeLoader } from 'react-spinners';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import moment from 'moment';
import Api from '../../../services/api';

const renderSubscription = (subscription) => {
  if (subscription.status === 'cancelled') return 'Inactive';

  return (
    <>
      {subscription.plan.name}
      <br />
      {moment(subscription.effective_from).format('MM/DD/YY')}
      {' - '}
      {moment(subscription.effective_to).format('MM/DD/YY')}
    </>
  );
};

export default function UserLicenses({ accountTypes, plans }) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userLicenses, setUserLicenses] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [name, setName] = useState('');
  const [plan, setPlan] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');

  const [sortBy, setSortBy] = useState('name');
  const [sortDir, setSortDir] = useState('asc');

  const fetchData = (opts) => {
    setLoading(true);
    Api.admin.user_licenses.get(opts).then(({ status, data }) => {
      if (status !== 'OK') return;

      setUserLicenses(data.user_licenses);
      setTotalCount(data.totalCount);
      setActiveCount(data.activeCount);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
      setLoading(false);
    });
  };

  const handlePage = (page) => {
    fetchData({
      page, name, accountName, accountType, sortBy, sortDir, subscriptionStatus, plan,
    });
  };

  const handleFilter = () => {
    fetchData({
      page: 1, name, accountName, accountType, sortBy, sortDir, subscriptionStatus, plan,
    });
  };

  const handleSort = (field) => {
    let dir = 'asc';
    if (sortBy === field) {
      if (sortDir === 'asc') dir = 'desc';
      if (sortDir === 'desc') dir = 'asc';
    }
    setSortBy(field);
    setSortDir(dir);
    fetchData({
      page: currentPage, name, accountName, accountType, sortBy: field, sortDir: dir, subscriptionStatus, plan,
    });
  };

  useEffect(() => fetchData({ page: 1, sortBy, sortDir }), []);

  return (
    <div className={`admin-page react-admin-users ${filtersOpen ? 'filters-open' : ''}`} style={{ marginLeft: '0px' }}>
      <div className="header">
        <h1>User Licenses</h1>
        <button type="button" className={`filters-button ${filtersOpen ? 'active' : ''}`} onClick={() => setFiltersOpen(!filtersOpen)}>
          <GoSettings />
        </button>
      </div>
      <div className="tabs">
        <div
          className={`tab${currentTab === 'all' ? ' active' : ''}`}
          onClick={() => {
            setSubscriptionStatus('');
            setAccountType('');
            setCurrentTab('all');
            fetchData({});
          }}
        >
          All
        </div>
        <div
          className={`tab${currentTab === 'active_free_trial' ? ' active' : ''}`}
          onClick={() => {
            setSubscriptionStatus('active');
            setAccountType('free_trial');
            setCurrentTab('active_free_trial');
            fetchData({ accountType: 'free_trial', subscriptionStatus: 'active' });
          }}
        >
          Active Free Trial
        </div>
        <div
          className={`tab${currentTab === 'active_aceds_trial' ? ' active' : ''}`}
          onClick={() => {
            setSubscriptionStatus('active');
            setAccountType('aceds_trial');
            setCurrentTab('active_aceds_trial');
            fetchData({ accountType: 'aceds_trial', subscriptionStatus: 'active' });
          }}
        >
          Active ACEDS Trial
        </div>
        <div
          className={`tab${currentTab === 'active' ? ' active' : ''}`}
          onClick={() => {
            setSubscriptionStatus('active');
            setAccountType('');
            setCurrentTab('active');
            fetchData({ subscriptionStatus: 'active' });
          }}
        >
          Total Active
        </div>
        <div
          className={`tab${currentTab === 'inactive' ? ' active' : ''}`}
          onClick={() => {
            setSubscriptionStatus('cancelled');
            setAccountType('');
            setCurrentTab('inactive');
            fetchData({ subscriptionStatus: 'cancelled' });
          }}
        >
          Inactive
        </div>
      </div>
      <div className="table">
        <div className="table-header">
          <div className="stats">
            <div className="stat">
              Total Licenses
              {' '}
              <span>{totalCount}</span>
            </div>
            <div className="stat">
              Active
              {' '}
              <span>{activeCount}</span>
            </div>
          </div>
        </div>

        { loading ? (
          <div className="react-loader">
            <FadeLoader color="rgba(0, 14, 87, 1)" className="fade-loader" />
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort('name')}>
                  User Name
                  { sortBy === 'name' && sortDir === 'asc' && <FiChevronUp /> }
                  { sortBy === 'name' && sortDir === 'desc' && <FiChevronDown /> }
                </th>
                <th onClick={() => handleSort('account_name')}>
                  Firm / Organization
                  { sortBy === 'account_name' && sortDir === 'asc' && <FiChevronUp /> }
                  { sortBy === 'account_name' && sortDir === 'desc' && <FiChevronDown /> }
                </th>
                <th onClick={() => handleSort('account_type')}>
                  Account Type
                  { sortBy === 'account_type' && sortDir === 'asc' && <FiChevronUp /> }
                  { sortBy === 'account_type' && sortDir === 'desc' && <FiChevronDown /> }
                </th>
                <th onClick={() => handleSort('subscription')}>
                  Subscription
                  { sortBy === 'subscription' && sortDir === 'asc' && <FiChevronUp /> }
                  { sortBy === 'subscription' && sortDir === 'desc' && <FiChevronDown /> }
                </th>
                <th>Active?</th>
                <th />
              </tr>
            </thead>
            <tbody>
              { (userLicenses || []).map((license) => (
                <tr key={license.id}>
                  <td>
                    <a href={`/admin/users/${license.user.id}/edit`}>
                      {license.user.first_name}
                      {' '}
                      {license.user.last_name}
                      {`(${license.user.email})`}
                    </a>
                  </td>
                  <td>
                    <a href={`/admin/accounts/${license.user.account.id}/edit`}>
                      {license.user.account.name}
                    </a>
                  </td>
                  <td>{accountTypes?.find((t) => t.value === license.user.account.account_type)?.label}</td>
                  <td>{renderSubscription(license.subscription)}</td>
                  <td>{license.subscription.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <div className="pagination">
          <button
            disabled={currentPage === 1}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage - 1)}
          >
            &lt;
          </button>
          <span>
            Page
            {' '}
            {currentPage}
            /
            {totalPages || 1}
          </span>
          <button
            disabled={currentPage >= totalPages}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      </div>

      { filtersOpen && (
        <div className="sidebar">
          <div className="sidebar-header">
            <h2>Filter By</h2>
          </div>
          <div className="sidebar-body" onKeyDown={(e) => e.key === 'Enter' && handleFilter()}>
            <div className="form-group">
              <input
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

            <div className="form-group">
              <input
                className="form-control"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                placeholder="Account Name"
              />
            </div>

            <div className="form-group">
              <select className="form-control" value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                <option value="">Account Type</option>
                {(accountTypes || []).map((type) => (
                  <option value={type.value}>{type.label}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={plan} onChange={(e) => setPlan(e.target.value)}>
                <option value="">Subscription</option>
                {(plans || []).map((p) => (
                  <option value={p.id} key={p.id}>{p.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={subscriptionStatus} onChange={(e) => setSubscriptionStatus(e.target.value)}>
                <option value="">Subscription Status</option>
                <option value="active">Active</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>

            <button
              type="button"
              className="button"
              onClick={() => {
                setCurrentTab('');
                handleFilter();
              }}
            >
              Filter
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
