import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { GoSearch } from 'react-icons/all';

function TagSection({
  tags, closeSection, selectTag, unselectTag, selectedTags,
}) {
  const [search, setSearch] = useState('');
  const tagsByCategory = useMemo(() => {
    const result = new Map();
    tags.filter((tag) => !search || tag.name.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => {
        if (!a.issue_category) return 1;
        if (!b.issue_category) return -1;
        return a.issue_category.position - b.issue_category.position;
      }).forEach((tag) => {
        const { name } = tag.issue_category || {};
        if (!result.has(name)) result.set(name, []);
        result.get(name).push(tag);
      });
    return result;
  }, [tags, search]);

  return (
    <div className="side-section">
      <div className="side-section-header">
        <h4 className="side-section-title">
          Issue Tags
          <button
            type="button"
            className="button-clear"
            onClick={closeSection}
            data-testid="tags-close-button"
          >
            ✕
          </button>
        </h4>
        <p className="side-section-description">
          Click to select the most relevant issue tag(s) of your case search, mix and match with booleans if needed.
        </p>
      </div>
      <div className="side-section-body">
        <div className="search">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Issue Tags"
          />
          <GoSearch />
        </div>

        { Array.from(tagsByCategory.entries()).map(([key, value]) => (
          <div className="tag-category" key={key}>
            <p className="tag-category-title">{key || 'Other'}</p>
            { value.map((tag) => (
              <div
                key={tag.id}
                className={`tag ${selectedTags.includes(tag.name) ? 'active' : ''}`}
                onClick={() => (selectedTags.includes(tag.name) ? unselectTag(tag) : selectTag(tag))}
              >
                {tag.name}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

TagSection.propTypes = {
  tags: PropTypes.array.isRequired,
  closeSection: PropTypes.func.isRequired,
  selectTag: PropTypes.func.isRequired,
  unselectTag: PropTypes.func.isRequired,
  selectedTags: PropTypes.object.isRequired,
};

export default TagSection;
