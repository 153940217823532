import React, { useEffect, useState } from 'react';
import { FaBookmark } from 'react-icons/all';
import api from '../../services/api';
import CaseDigestPreview from '../case_digest/CaseDigestPreview';

function getFavoriteName(favorite) {
  if (favorite?.type && favorite.type.indexOf('Rule') !== 1) return favorite.number;

  return favorite.name;
}

export default function Bookmarks({ favorites: userFavorites }) {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortBy, setSortBy] = useState('favorited_at');
  const [favorites, setFavorites] = useState(userFavorites);

  const onSort = (value) => {
    setSortBy(value);
    if (value === 'favorited_at') {
      setFavorites([...favorites.sort((a, b) => new Date(b.favorited_at) - new Date(a.favorited_at))]);
    }

    if (value === 'name') {
      setFavorites([...favorites.sort((a, b) => getFavoriteName(a).localeCompare(getFavoriteName(b)))]);
    }
  };

  useEffect(() => {
    onSort('favorited_at');
  }, []);

  const removeCaseBookmark = (caseDigest) => {
    api.case_digests.unfavorite(caseDigest.case_digest_id).then(() => setFavorites(favorites.filter((c) => c !== caseDigest)));
  };

  const removeChecklistAndFormBookmark = (item) => {
    const type = item.checklist_id ? 'checklists' : 'forms';
    const id = item.checklist_id || item.template_id;

    api[type].unfavorite(id).then(() => setFavorites(favorites.filter((c) => c !== item)));
  };

  const toggleCiteListBookmark = (citeList) => {
    api.cite_lists.favorite(citeList.id)
      .then((res) => {
        if (res.success === true) {
          setFavorites(favorites.filter((favoriteList) => favoriteList !== citeList));
        }
      });
  };

  const removeGlossaryTermBookmark = (term) => {
    api.glossary.unfavorite(term.glossary_term_id).then((res) => {
      if (res.success === true) {
        setFavorites(favorites.filter((favoriteTerm) => favoriteTerm !== term));
      }
    });
  };

  const toggleRuleBookmark = (rule) => {
    api.rules.favorite(rule.rule_id).then((res) => {
      if (res.status === 'OK') setFavorites(favorites.filter((r) => r !== rule));
    });
  };

  const removeBookmark = (e, category, favorite) => {
    e.preventDefault();
    e.stopPropagation();
    switch (category) {
      case 'rules':
        toggleRuleBookmark(favorite);
        break;
      case 'glossary_terms':
        removeGlossaryTermBookmark(favorite);
        break;
      case 'cite_lists':
        toggleCiteListBookmark(favorite);
        break;
      case 'case_digests':
        removeCaseBookmark(favorite);
        break;
      case 'checklists_and_forms':
        removeChecklistAndFormBookmark(favorite);
        break;
      default:
        break;
    }
  };

  const transformCategory = (category) => {
    if (category === 'rules') return 'Rules';
    if (category === 'glossary_terms') return 'Glossary';
    if (category === 'checklists_and_forms') return 'Checklists & Forms';
    if (category === 'case_digests') return 'Case Law';
    if (category === 'cite_lists') return 'Cite Lists';
    return null;
  };

  const getLink = (favorite, category) => {
    if (category === 'checklists_and_forms' && favorite.checklist_id) return `/checklists/${favorite.checklist_id}`;
    if (category === 'checklists_and_forms' && favorite.template_id) return `/forms/${favorite.template_id}`;
    if (category === 'case_digests') return `/case_law/${favorite.slug}`;
    if (category === 'rules') {
      if (favorite.type === 'AbaModelRule') return `/aba_model_rules/${favorite.rule_id}`;
      if (favorite.type === 'AaaRule') return `/aaa_rules/${favorite.rule_id}`;
      if (favorite.type === 'InternationalRule') return `/international_rules/${favorite.rule_id}`;
      if (favorite.type === 'StateRule') return `/state_rules/${favorite.rule_id}`;
      if (favorite.type === 'FederalRule') return `/federal_rules/${favorite.rule_id}`;
      if (favorite.type === 'DistrictRule') return `/district_rules/${favorite.rule_id}`;
      if (favorite.type === 'RegulatoryRule') return `/regulatory_rules/${favorite.rule_id}`;
    }
    if (category === 'glossary_terms') return `/glossary_terms?term=${favorite.id}`;
    if (category === 'cite_lists') return `/cite_lists/${favorite.id}`;
    return '';
  };

  const renderNoBookmarks = () => (
    <div className="no-bookmarks">
      <div className="no-bookmarks-text">
        <span>You have no bookmarks added.</span>
        <span>Select a database below to start exploring.</span>
      </div>
      <div className="no-bookmarks-navigation">
        <a href="/case_law">
          <div className="no-bookmarks-link">
            <div className="background-case-law-image" style={{ height: 50, width: 50 }} />
            <span>Case Law</span>
          </div>
        </a>
        <a href="/rules">
          <div className="no-bookmarks-link">
            <div className="background-rules-image" style={{ height: 50, width: 50 }} />
            <span>Rules</span>
          </div>
        </a>
        <a href="/checklists">
          <div className="no-bookmarks-link">
            <div className="background-checklists-image" style={{ height: 50, width: 50 }} />
            <span>Checklists & Forms</span>
          </div>
        </a>
        <a href="/glossary_terms">
          <div className="no-bookmarks-link">
            <div className="background-glossary-image" style={{ height: 50, width: 50 }} />
            <span>Glossary</span>
          </div>
        </a>
        <a href="/cite_lists">
          <div className="no-bookmarks-link">
            <div className="background-cite-list-image" style={{ height: 50, width: 50 }} />
            <span>Cite Lists</span>
          </div>
        </a>
      </div>
    </div>
  );

  const renderRightContent = () => {
    const displayFavorites = selectedCategory === 'all' ? favorites : favorites.filter((f) => f.category === selectedCategory);

    return (
      <div className="right-content">
        <div className="selected-category-header">
          { selectedCategory === 'all' ? <span className="selected-category-title">All Results</span> : <span className="selected-category-title">{transformCategory(selectedCategory)}</span>}
          <span className="selected-category-count">{displayFavorites.length}</span>
          <div style={{ flex: 1 }} />
          <div className="results-sort tag">
            Sort By:
            <select
              value={sortBy}
              onChange={(e) => onSort(e.target.value)}
            >
              <option value="favorited_at">Recently Added</option>
              <option value="name">Alphabetical</option>
            </select>
          </div>
        </div>

        { displayFavorites.length === 0 && renderNoBookmarks() }
        { displayFavorites.length > 0 && (
        <div>
          <div className="list-header">
            <span className="list-header-entry">ENTRY</span>
            <span className="list-header-name">NAME / TITLE</span>
          </div>
          <div className="category">
            { displayFavorites.map((favorite) => (
              favorite.category === 'cite_lists' && favorite.published_revision ? (
                <div className="bookmark" key={favorite.id}>
                  <div className="top">
                    <div className="bookmark-type">Cite Lists</div>
                    <CaseDigestPreview
                      caseDigest={favorite}
                      onClick={(e) => e.stopPropagation()}
                      onSelect={() => {}}
                      slug={favorite.slug}
                      selectedCiteListCases={[]}
                      isCaseSelected={false}
                      hasTagList
                      isInBookmarks
                      isFavorite
                      onToggleFavorite={() => {}}
                    />
                  </div>
                </div>
              ) : (
                <div className="bookmark" key={favorite.id}>
                  <a href={getLink(favorite, favorite.category)} className="top">
                    <div className="bookmark-type">{transformCategory(favorite.category)}</div>
                    <div className="bookmark-name">
                      {getFavoriteName(favorite)}
                    </div>
                    <FaBookmark onClick={(e) => removeBookmark(e, favorite.category, favorite)} />
                    { (favorite.category === 'cite_lists' && Number(favorite.shared_users) > 0) ? (
                      <div className="shared-users tag">
                        Shared with
                        {' '}
                        { favorite.shared_users }
                        {' '}
                        users
                      </div>
                    ) : ''}
                  </a>
                </div>
              )
            ))}
          </div>
        </div>
        )}
      </div>
    );
  };

  return (
    <div className="react-bookmarks">
      <div className="left">
        <div className="bookmarks-header">
          <div className="name">
            Bookmarks
          </div>
          <div
            className={`category all-category ${selectedCategory === 'all' ? ' selected' : ''}`}
            onClick={() => setSelectedCategory('all')}
          >
            <div className="category-name">{`All Results (${favorites.length})`}</div>
          </div>
        </div>
        <div className="categories">
          { ['case_digests', 'rules', 'checklists_and_forms', 'glossary_terms', 'cite_lists'].map((category) => (
            <div
              className={`category${selectedCategory === category ? ' selected' : ''}`}
              key={category}
              onClick={() => setSelectedCategory(category)}
            >
              <div className="category-name">
                <div className="category-image">
                  <div className={category} style={{ height: 20, width: 20 }} />
                  <span>{ transformCategory(category) }</span>
                </div>
                <div className="category-count">
                  <span data-testid={category}>{`${favorites.filter((f) => f.category === category).length}`}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="right">
        <div className="right-content">
          {renderRightContent()}
        </div>
      </div>
    </div>
  );
}
