import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import DownloadModal from '../../download_modal/DownloadModal';

it('renders correctly with props', () => {
  const tree = renderer.create(<DownloadModal closeModal={() => {}} numberOfDownloads={2} />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders modal and test clicks', async () => {
  const close = jest.fn(() => {});
  const container = render(<DownloadModal closeModal={close} numberOfDownloads={2} />);
  fireEvent.click(container.queryByText('✕'));
  expect(close).toHaveBeenCalledTimes(1);
});
