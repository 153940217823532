import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FaArrowDown,
  FaBookmark,
  FaRegBookmark,
  FiChevronLeft,
  FiChevronRight,
} from 'react-icons/all';
import ReactHtmlParser from 'react-html-parser';
import { Document, Page } from 'react-pdf';
import { FadeLoader } from 'react-spinners';
import moment from 'moment';
import api from '../../services/api';
import DownloadModal from '../download_modal/DownloadModal';
import { DecisionDateFormat } from '../../services/constants';
import PaywallModal from '../cite_lists/modals/PaywallModal';
import {Pages} from "../learning_center/DetailsContent/Pages";

export default function Form(props) {
  const { form, plan_name, preview } = props;
  const [favorites, setFavorites] = useState(props.favorites.checklists_and_forms);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [loadingModalOpen, setLoadingModalOpen] = useState(false);
  const [downloadLimit, setDownloadLimit] = useState(props.numberOfDownloads);
  const [pages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [mobileOpened, setMobileOpened] = useState(false);
  const documentUrl = (form.published_revision.document || {}).url
    || (form.published_revision.word_document || {}).url;
  const hasRelatedItems = form.case_digests.length > 0;
  const [downloadAction, setDownloadAction] = useState(false);
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const hasDownloadLimit = plan_name === 'Trial' || plan_name === 'Individual Monthly';
  const pdfWidth = document.getElementsByClassName('react-pdf__Document')[0]?.clientWidth;

  const updateFavorites = () => {
    api.favorites.get().then((result) => {
      setFavorites(result.favorites.checklists_and_forms);
    });
  };

  const toggleDownloadModal = () => setDownloadModalOpen(!downloadModalOpen);

  const renderLoadingModal = () => (
    <div className="loading-modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="margin">
            <FadeLoader color="rgba(0, 14, 87, 1)" className="fade-loader" />
          </div>
          <div className="name">Loading</div>
        </div>
      </div>
    </div>
  );

  const isFavorite = () => {
    if (!favorites) return false;
    return favorites.some((favoriteTerm) => favoriteTerm.id === form.id);
  };

  const downloadPDF = async () => {
    if (preview) return;

    const { isACEDSTrial } = props;
    if (isACEDSTrial) {
      setPaywallModalOpen(true);
      return;
    }

    if (hasDownloadLimit && downloadLimit === 0) return;
    if (hasDownloadLimit) {
      toggleDownloadModal();
      setDownloadLimit(downloadLimit - 1);
      setDownloadAction(true);
    }

    setLoadingModalOpen(true);
    await api.forms.pdf(form.id).catch(() => alert('Download failed'));
    setLoadingModalOpen(false);
  };

  const toggleBookmark = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (preview) return;

    if (isFavorite()) {
      api.forms.unfavorite(form.id).then(() => updateFavorites());
      return;
    }
    api.forms.favorite(form.id).then(() => updateFavorites());
  };

  return (
    <div className="react-checklist-form">
      <div className="mobile-search-open" onClick={() => setMobileOpened(!mobileOpened)} />
      <div className="left-content">
        <div className="item-details">
          { !preview && (
            <div className="buttons">
              <a className="back" href="/checklists">← Back to list</a>
            </div>
          )}

          <div className="category">Forms</div>
          <div className="title">{ form.published_revision.name }</div>
          { form.published_revision.summary && (
            <div className="description">
              {ReactHtmlParser(form.published_revision.summary)}
            </div>
          )}
          <div className="item-content">
            { !form.published_revision.text && documentUrl ? (
              <>
                <Document
                  file={documentUrl}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                >
                  <Page
                    width={pdfWidth * 0.7}
                    pageNumber={pageNumber}
                    renderMode="svg"
                    renderAnnotationLayer={false}
                  />
                </Document>
                { pages && <Pages pageNumber={pageNumber} pages={pages} setPageNumber={setPageNumber} />}
              </>
            ) : (
              <div className="form-content">
                { ReactHtmlParser(form.published_revision.text) }
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={`right-content ${mobileOpened ? 'opened' : ''}`}>
        <div className="item-summary">
          <div className="buttons">
            { (form.published_revision.document || {}).url && (
              <a className="pdf" onClick={downloadPDF}>
                <FaArrowDown />
                Download PDF
              </a>
            )}
            <div className="bookmark" onClick={(e) => toggleBookmark(e, form)}>
              { isFavorite()
                ? (
                  <div>
                    <FaBookmark />
                    Remove Bookmark
                  </div>
                )
                : (
                  <div>
                    <FaRegBookmark />
                    Add To Bookmarks
                  </div>
                ) }
            </div>
          </div>
          { form.published_revision.tips?.length > 0
          && (

          <div className="related-tips">
            <div className="related">Tips</div>
            { form.published_revision.tips?.map((tip) => (
              <>
                <div className="title">{ tip.name }</div>
                <div className="category">{ tip.description }</div>
              </>
            ))}
          </div>
          )}

          <div className="related">Related Content</div>
          { !hasRelatedItems && (<div className="category">No related content found.</div>) }
          { form.case_digests?.length > 0
          && (
          <div className="related-item">
            <div className="category">Case Digests</div>
            { form.case_digests?.map((caseItem) => (
              <>
                <a href={`/case_law/${caseItem.case_digest.slug}`}>
                  <div className="title">{ caseItem.name }</div>
                </a>
                <div className="category">{ caseItem.cite }</div>
                <div className="category">{caseItem.decision_date && moment(caseItem.decision_date).format(DecisionDateFormat)}</div>
                <div className="category">
                  { caseItem.judge }
                  {`${caseItem.judge === 'Panel' && !caseItem.judge_type ? '' : ','}
                    \u00a0${caseItem.judge_type}`}
                </div>
              </>
            ))}
          </div>
          )}
        </div>
      </div>
      {(downloadModalOpen && (
        <DownloadModal
          closeModal={() => {
            toggleDownloadModal();
            setDownloadAction(false);
          }}
          numberOfDownloads={downloadLimit}
          isDownload={downloadAction}
          plan_name={plan_name}
        />
      ))}
      { paywallModalOpen && (
        <PaywallModal
          isDownload
          account_name={props.account_name}
          closeModal={() => {
            setPaywallModalOpen(false);
          }}
        />
      )}
      { loadingModalOpen && renderLoadingModal() }
    </div>
  );
}

Form.propTypes = {
  favorites: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
