// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<a
  className="related-form"
  href="/forms/1"
>
  <div
    className="related-form-name"
  >
    This is a name for Form
  </div>
</a>
`;
