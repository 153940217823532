// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<a
  className="related-case-digest"
  href="#"
  onClick={[Function]}
>
  <div
    className="decision-title"
  >
    <div
      className="decision-name"
    />
    <div
      className="result-buttons"
    />
  </div>
  <div
    className="decision-cite"
  >
    This is a case cite
  </div>
  <div
    className="decision-cite"
  />
  <div
    onClick={[Function]}
  >
    <a
      className="decision-cite judge-link"
      href="/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=This is a judge"
    >
      This is a judge
    </a>
    ,
                 Judge Type
  </div>
  <div
    className="tags"
  >
    <div
      className="tag btn btn-flat"
    >
      tag A
    </div>
    <div
      className="tag btn btn-flat"
    >
      tag B
    </div>
  </div>
</a>
`;
