import React from 'react';
import { FiSearch } from 'react-icons/fi';
import ReactSelect, { components } from 'react-select';

function Control({ children, ...props }) {
  return (
    <components.Control {...props}>
      <FiSearch />
      {' '}
      {children}
    </components.Control>
  );
}

export default function Select({
  searchable, clearable, value, onChange, options,
}) {
  return (
    <ReactSelect
      placeholder={searchable ? 'Search' : 'Select'}
      isSearchable={searchable || false}
      isClearable={clearable || false}
      value={value}
      onChange={onChange}
      options={options}
      components={searchable ? { Control } : {}}
      styles={{
        control: (styles) => ({
          ...styles,
          boxShadow: 'none',
          outline: 'none',
          border: '1px solid #E5E5E8',
          borderRadius: '.8rem',
          paddingLeft: '1.2rem',
          backgroundColor: '#fff',
        }),
        option: (styles) => ({
          ...styles,
          color: '#1F1F24',
          fontWeight: 500,
        }),
        menu: (styles) => ({
          ...styles,
          boxShadow: '0px 8px 24px rgba(0, 14, 87, 0.12)',
          border: 'none',
          borderRadius: '.6rem',
          overflow: 'hidden',
          zIndex: 10001,
        }),
        placeholder: () => ({
          color: '#909096',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        dropdownIndicator: (styles) => ({
          display: searchable ? 'none' : styles.display,
        }),
      }}
    />
  );
}
