import React from 'react';
import ReactAsyncSelect from 'react-select/async';

export default function AsyncSelect({ onChange, value, loadOptions }) {
  return (
    <ReactAsyncSelect
      placeholder="Search"
      value={value}
      onChange={onChange}
      loadOptions={loadOptions}
      styles={{
        control: (styles) => ({
          ...styles,
          boxShadow: 'none',
          outline: 'none',
          width: '100%',
          border: 'none',
          borderRadius: '.4rem',
          paddingLeft: '1.2rem',
          backgroundColor: 'rgba(52, 59, 100, 0.06)',
        }),
        option: (styles) => ({
          ...styles,
          color: '#000E57',
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
        }),
        menu: (styles) => ({
          ...styles,
          boxShadow: '0px 8px 24px rgba(0, 14, 87, 0.12)',
          border: 'none',
          borderRadius: '.8rem',
          overflow: 'hidden',
          zIndex: 10001,
        }),
        placeholder: () => ({
          color: '#909096',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
      }}
    />
  );
}
