// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="pagination"
>
  <a
    className="page arrow"
    data-testid="previous"
    onClick={[Function]}
  >
    <svg
      fill="none"
      height="1em"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      style={
        Object {
          "color": undefined,
        }
      }
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        points="15 18 9 12 15 6"
      />
    </svg>
  </a>
  <p>
    Page
     
    1
     
    of
     
    3
  </p>
  <a
    className="page arrow"
    data-testid="next"
    onClick={[Function]}
  >
    <svg
      fill="none"
      height="1em"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      style={
        Object {
          "color": undefined,
        }
      }
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        points="9 18 15 12 9 6"
      />
    </svg>
  </a>
</div>
`;
