import PropTypes from 'prop-types';
import React from 'react';
import VideoPlayer from './VideoPlayer';

export default class VideoModal extends React.Component {
  render() {
    const { closeModal, video } = this.props;
    return (
      <div className="video-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">
                <button
                  type="button"
                  className="button-clear pull-right"
                  onClick={closeModal}
                >
                  ✕
                </button>
              </div>
            </div>
            <div className="modal-body">
              <VideoPlayer video={video} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VideoModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
