import React from 'react';
import PropTypes from 'prop-types';
import { GoSearch } from 'react-icons/go';
import Search from '../search/Search';

class IssueDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIssue: null,
      showSearch: false,
      showAllIssues: false,
      searchTagIds: [],
      tagsByCategory: new Map(),
      issueSearchText: '',
      issueSearchCategory: null,
    };
  }

  componentDidMount() {
    const { tags } = this.props;
    this.setTags(tags);
  }

  setTags = (tags) => {
    const { issueCategories } = this.props;
    const tagsByCategory = new Map();
    tags.forEach((tag) => {
      const category = issueCategories.find((cat) => cat.id === tag.issue_category_id);
      if (tagsByCategory.has(category)) {
        tagsByCategory.get(category).push(tag);
      } else {
        tagsByCategory.set(category, [tag]);
      }
    });

    this.setState({ tagsByCategory });
  };

  findTagById = (tagId) => {
    const { tags } = this.props;
    const tag = tags.find((t) => t.id === tagId);
    return tag ? tag.name : '';
  };

  clearDashboardTags = () => this.setState({ searchTagIds: [] });

  showIssueDetails = (category, index) => {
    const { tags } = this.props;
    const { selectedIssue, tagsByCategory } = this.state;
    const totalTags = category === null ? tags.length : tagsByCategory.get(category).length;
    const selectedIndex = category === null ? tags.indexOf(selectedIssue) : tagsByCategory.get(category).indexOf(selectedIssue);

    if (selectedIssue === null || selectedIndex === -1) return false;
    if (index % 3 !== 2 && index !== totalTags - 1) return false;
    const rangeStart = index === totalTags - 1 ? Math.floor(index / 3) * 3 : index - 2;
    const rangeEnd = index === totalTags - 1 ? Math.floor(index / 3) * 3 + 2 : index;
    if (selectedIndex <= rangeEnd && selectedIndex >= rangeStart) return true;
    return false;
  };

  setSelectedIssue = (issue) => {
    const { selectedIssue } = this.state;
    if (selectedIssue?.id === issue?.id) return this.setState({ selectedIssue: null });
    return this.setState({ selectedIssue: issue });
  };

  handleIssueSearchTextChange = (e) => {
    const { tags } = this.props;
    const search = e.target.value;
    this.setState({ issueSearchText: search });
    this.setTags(tags.filter((t) => t.name.toLowerCase().includes(search.toLowerCase())));
  };

  handleIssueSearchCategoryChange = (e) => {
    const { issueCategories, tags } = this.props;
    const id = e.target.value;
    if (!id) {
      this.setState({ issueSearchCategory: null });
    } else {
      this.setState({ issueSearchCategory: issueCategories.find((cat) => cat.id === Number(id)) || null });
    }

    this.setTags(tags.filter((t) => !id || t.issue_category_id === Number(id)));
  };

  handleAllIssuesChange = (tag) => {
    const { selectedIssue } = this.state;

    if (selectedIssue?.id === tag?.id) {
      this.setState({ issueSearchText: '' });
      this.handleIssueSearchTextChange({ target: { value: '' } });
    } else {
      this.setState({ issueSearchText: tag.name });
      this.handleIssueSearchTextChange({ target: { value: tag.name } });
    }

    this.setSelectedIssue(tag);
  };

  render() {
    const { jurisdictions, issueCategories } = this.props;
    const {
      selectedIssue, showSearch, showAllIssues, searchTagIds, tagsByCategory, issueSearchText, issueSearchCategory,
    } = this.state;

    const tagsList = issueSearchText || issueSearchCategory
      ? [...tagsByCategory.entries()].sort((a, b) => {
        if (!a[0]) return 1;
        if (!b[0]) return -1;
        return a[0].position - b[0].position;
      })
      : [[null, this.props.tags]];

    return (
      <div className="issue-dashboard-row">
        <div className="react-issue-dashboard">
          <div className="empty-image-issue-dashboard" />
          <div className="issue-dashboard-title">Select Issues</div>
          <button
            type="button"
            className={`issue-all-button ${showAllIssues && 'active'}`}
            onClick={() => this.setState({ showAllIssues: !showAllIssues })}
          >
            View All +
          </button>
          <div className="issue-search-bar">
            <div className="issue-search-bar-input">
              <input
                value={issueSearchText}
                onChange={this.handleIssueSearchTextChange}
                placeholder="Search Issues"
              />
              { issueSearchText?.length > 0 && (
                <span className="clear-issues" onClick={() => this.handleAllIssuesChange(selectedIssue)}>
                  ✕
                </span>
              )}
              <GoSearch />
            </div>

            <div className="issue-search-bar-input">
              <select
                value={issueSearchCategory ? issueSearchCategory.id : null}
                onChange={this.handleIssueSearchCategoryChange}
              >
                <option value="">All Categories</option>
                {issueCategories.map((cat) => (
                  <option value={cat.id}>{cat.name}</option>
                ))}
              </select>
            </div>
          </div>
          { tagsList.map(([category, tags]) => (
            <div className="issue-list">
              { category !== null && <p className="issue-list-title">{category ? category.name : 'Unknown Category'}</p> }
              {
                tags.map((tag, index) => (

                  <div className="issue-block" key={tag.id}>
                    <div
                      className={`issue-container ${tag?.id === selectedIssue?.id ? 'selected' : ''}`}
                      onClick={() => this.setSelectedIssue(tag)}
                    >
                      <div className="issue-title-row">
                        <div className="issue-name">
                          {tag.name}
                        </div>
                        <a
                          className="taggings"
                          onClick={(e) => e.stopPropagation()}
                          href={`/case_law?sort_by=des_decision_date&page=1&results_per_page=25&include_unpublished=true&type=issue&tag_ids_query=${tag.id}`}
                        >
                          {tag.cases_count}
                          &nbsp;Cases
                        </a>
                      </div>
                      <div className="issue-description">
                        {tag.description}
                      </div>
                    </div>
                    {
                      (this.showIssueDetails(category, index) || (tag?.id === selectedIssue?.id && tags?.length === 1)) && (
                        <div className="issue-details-container">
                          <div className="issue-details-header">
                            <div className="issue-details">
                              {selectedIssue.description}
                            </div>
                            <div
                              className="issue-details-close-button"
                              onClick={() => { this.setState({ selectedIssue: null }); }}
                            >
                              &times;
                            </div>
                          </div>
                          <div className="issue-details-row">
                            {
                              (selectedIssue?.related_issues?.length > 0
                                || selectedIssue?.reverse_related_issues?.length > 0) && (
                                  <>
                                    <div>Related Issues:</div>
                                    <div className="related-issues">

                                      {
                                        selectedIssue.related_issues.map((relatedIssues) => (
                                          <div className="related-issue">{this.findTagById(relatedIssues.tag_b_id)}</div>
                                        ))
                                      }
                                      {
                                        selectedIssue.reverse_related_issues.map((revRelatedIssues) => (
                                          <div className="related-issue">{this.findTagById(revRelatedIssues.tag_a_id)}</div>
                                        ))
                                      }
                                    </div>
                                  </>
                              )
                            }
                            <div
                              className="issue-search-button"
                              onClick={() => {
                                searchTagIds.push(selectedIssue.id.toString());
                                this.setState({ showSearch: true, searchTagIds });
                                this.forceUpdate();
                              }}
                            >
                              <GoSearch />
                              &nbsp;&nbsp;Add to Case Law Search
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </div>
          ))}

        </div>
        {
          showAllIssues && (
          <div className="all-issues-window">
            <div className="all-issues-title-row">
              <div className="all-issues-title">View All Issues</div>
              <button type="button" className="button-clear" onClick={() => this.setState({ showAllIssues: false })}>✕</button>
            </div>
            {
              issueCategories.map((category) => (
                <div key={category.id}>
                  <hr />
                  <div className="all-issues-category">{category.name}</div>
                  <div className="all-issues-list">
                    {
                      category.tags.map((tag) => (
                        <div className={`all-issues-container ${selectedIssue?.id === tag?.id ? 'active' : ''}`}>
                          <div
                            className="all-issues-name"
                            onClick={() => this.handleAllIssuesChange(tag)}
                          >
                            {tag.name}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
          )
        }
        {
          showSearch && (
            <div key={searchTagIds.length} className="issue-dashboard-search">
              <button type="button" className="button-clear" onClick={() => this.setState({ showSearch: false })}>✕</button>
              <Search
                isAdmin={false}
                tags={this.props.tags}
                jurisdictions={jurisdictions}
                allResultIds={[]}
                caseDigests={[]}
                courtCounts={{
                  circuit_courts: {}, state_courts: {}, admin_courts: 0, total_count: 0, hide_all: false,
                }}
                page={1}
                query=""
                tagIdsQuery={searchTagIds.reduce((prev, current) => (prev ? `${prev} AND ${current}` : current), '')}
                includeUnpublishedDecisions
                clearDashboardTags={this.clearDashboardTags}
              />
            </div>
          )
        }
      </div>
    );
  }
}

IssueDashboard.propTypes = {
  tags: PropTypes.array.isRequired,
  jurisdictions: PropTypes.object.isRequired,
  issueCategories: PropTypes.array.isRequired,
};

export default IssueDashboard;
