import React, { Component } from 'react';
import moment from 'moment';

class NewForm extends Component {
  state = {
    new_subscription_form: {
      stripe_token: '',
      plan_id: this.props.tab === 'trial' ? '6' : '',
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().add(14, 'days').format('YYYY-MM-DD'),
      num_seats: 1,
      amount: null,
    },
    postal_code: '',
    errorMessage: '',
    loading: false,
    plan_type: this.props.tab === 'trial' ? 'trial' : '',
  };

  componentDidUpdate() {
    const _self = this;
    $('.datepicker')
      .datepicker({ dateFormat: 'yy-mm-dd' })
      .on('change', (e) => {
        _self.handleCalendar(e);
      });
  }

  handleSubscription = (event) => {
    const { value } = event.target;
    const { plans } = this.props;
    const plan = plans.find((p) => p.id === parseInt(value));

    this.setState((state) => ({
      new_subscription_form: {
        ...state.new_subscription_form,
        plan_id: value,
      },
      plan_type: plan.plan_type,
    }));
  };

  handleNumSeats = (event) => {
    const { value } = event.target;
    const { account_type } = this.props.account;
    if (value > 50 && (account_type === 'educational_paid' || account_type === 'individual')) return;

    this.setState((state) => ({
      new_subscription_form: {
        ...state.new_subscription_form,
        num_seats: value,
      },
    }));
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleCalendar = () => {
    const subscriptionStartDate = $('input[name="startDate"]').val();
    const subscriptionEndDate = $('input[name="endDate"]').val();
    this.setState((state) => ({
      new_subscription_form: {
        ...state.new_subscription_form,
        start_date: subscriptionStartDate,
        end_date: subscriptionEndDate,
      },
    }));
  };

  post = () => {
    const { new_subscription_form, postal_code } = this.state;
    const {
      authenticityToken, path, submitCallback, account,
    } = this.props;
    fetch(path, {
      body: JSON.stringify({
        new_subscription_form,
        postal_code,
      }),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticityToken,
        'X-Requested-With': 'XMLHttpRequest',
      },
      method: 'POST',
    })
      .then(async (res) => {
        if (res.ok) {
          const body = await res.json();
          if (submitCallback) {
            window[submitCallback](body);
          } else {
            window.location.href = `/admin/accounts/${account.id}/edit`;
          }
        } else if (res.status === 422) {
          const body = await res.json();
          this.setState({
            errorMessage: body.full_messages.join(', '),
          });
        } else {
          this.setState({
            errorMessage: 'Something went wrong',
          });
        }

        this.setState({
          loading: false,
        });
      })
      .catch((e) => console.error(e));
  };

  handleAmountChange = (event) => {
    const amount = parseInt(event.target.value);

    this.setState((state) => ({
      new_subscription_form: {
        ...state.new_subscription_form,
        amount,
      },
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    }, this.post);
  };

  getPlanName = (plan) => {
    if (plan.name === 'Academy Free' || plan.name === 'Academy Check' || plan.name === 'ACEDS Trial') {
      return plan.name;
    }
    if (plan.plan_type === 'trial') return 'Trial';
    if (plan.plan_type === 'check') return 'Check';
    if (plan.plan_type === 'free') return 'Free';
    return plan.name;
  };

  render() {
    const { plans } = this.props;
    const { account_type, name } = this.props.account;
    const {
      plan_type, errorMessage, new_subscription_form, loading,
    } = this.state;

    return (
      <div className="form-group">
        <fieldset>
          <legend>
            Choose Payment Type for
            { name }
          </legend>
          <form className="plan-form" onSubmit={this.handleSubmit}>
            <div className="toggle">
              {plans.map((plan) => (
                (plan.plan_type !== 'credit_card' && plan.name !== 'Academy')
                && (
                <React.Fragment key={plan.id}>
                  <input
                    type="radio"
                    name="subscriptionType"
                    onChange={this.handleSubscription}
                    value={plan.id}
                    key={plan.id}
                    id={plan.id}
                  />
                  <label key={`label${plan.id}`} htmlFor={plan.id}>
                    {this.getPlanName(plan)}
                  </label>
                </React.Fragment>
                )
              ))}
            </div>
            {!!plan_type && (
              <div className="checkout">
                <h3 className="header-ish">Subscription start date</h3>
                <input
                  name="startDate"
                  className="datepicker"
                  defaultValue={new_subscription_form.start_date}
                />
                <h3 className="header-ish">Subscription end date</h3>
                <input
                  name="endDate"
                  className="datepicker"
                  defaultValue={new_subscription_form.end_date}
                />
                <h3 className="header-ish">Number of seats</h3>
                <input
                  name="numSeats"
                  type="number"
                  min="1"
                  max={account_type === 'educational_paid' ? 50 : 99}
                  value={new_subscription_form.num_seats}
                  onChange={this.handleNumSeats}
                />
                {!!plan_type && plan_type === 'check' && (
                  <div>
                    <h3 className="header-ish">Amount</h3>
                    <span>$</span>
                    <input
                      type="number"
                      min="0"
                      pattern="[0-9]*"
                      value={this.state.amount}
                      onChange={this.handleAmountChange}
                    />
                  </div>
                )}
                <div className="error" role="alert">
                  {errorMessage}
                </div>
                {loading ? <div className="image-loader"><img src={this.props.loader} /></div>
                  : <input type="submit" name="commit" value="Submit" className="submitBtn" />}
              </div>
            )}
          </form>
        </fieldset>
      </div>
    );
  }
}

NewForm.propTypes = {
  // todo
};

export default NewForm;
