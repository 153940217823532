import React from 'react';

export default function Hero({ title, subtitle, children }) {
  return (
    <div className="react-lc-hero">
      <div className="overlay1" />
      <div className="overlay2" />
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      {children}
    </div>
  );
}
