import React, { useState } from 'react';

function TermsAndServices({
  status, data, onChange, onCancel, onSubmit,
}) {
  const [formErrors, setFormErrors] = useState({});

  const handleCheckboxChange = (e) => {
    const field = e.target.id;
    onChange({ ...data, [field]: e.target.checked });
  };

  const handleSubmit = () => {
    if (!data.terms_of_use || data.terms_of_use === false) {
      setFormErrors({ terms_of_use: 'You must accept Terms and Conditions' });
      return;
    }

    onSubmit();
  };

  return (
    <>
      <div className="terms-of-use">
        <h1>eDiscovery Assistant Terms of Use</h1>
        <h2>Effective March 1, 2021</h2>

        <p>
          The eDiscovery Assistant service is offered to you conditioned on your acceptance without
          modification of the terms, conditions, and notices contained herein (collectively, the &quot;Terms of
          Use&quot;). These Terms of Use are between you and eDiscovery Assistant LLC (
          <b>
            &quot;EDA&quot;, &quot;us,&quot;
            &quot;our,&quot;
          </b>
          {' '}
          or
          <b>&quot;we&quot;</b>
          ), and apply to your use of our
          <b>&quot;Services&quot;</b>
          , which include:
        </p>

        <ul>
          <li>
            Our eDiscovery Assistant e-discovery support and legal research platform and web application
            (&quot;
            <b>Platform</b>
            &quot;); and
          </li>
          <li>
            our corporate website at ediscoveryassistant.com, and other websites where we link to/post this
            Policy, including any subdomains or mobile versions (the &quot;
            <b>Sites(s)</b>
            &quot;).
          </li>
        </ul>

        <p>Your use of the Service indicates your acknowledgement of and agreement to these Terms of Use</p>

        <h3>1. Web Site Use Generally</h3>

        <ol>
          <li>
            <span>Description.</span>
            {' '}
            Our Service an online service providing curated legal resources for
            electronic discovery.
          </li>

          <li>
            <span>No Professional or Legal Advice.</span>
            {' '}
            the Service is not intended to be or contain legal
            advice. Any opinions and advice found on the Service are those of the author and not necessarily
            those of EDA. Any information supplied by any employee or agent of EDA, whether by telephone,
            e-mail, letter or other form of communication, is intended solely as general guidance on the use of
            the Service, and does not constitute professional or legal advice.
          </li>
        </ol>

        <h3>2. Eligibility, Access, Use and Service</h3>

        <ol>
          <li>
            <span>Eligibility Requirements.</span>
            {' '}
            You represent and warrant that you are at least 18 years of
            age, agree to these Terms of Use and the Privacy Policy, and that the information you include as
            part of the registration process is complete and accurate and you understand that EDA may deny you
            use of the Service in its sole discretion.
          </li>

          <li>
            <span>No Unlawful Or Prohibited Uses.</span>
            {' '}
            As a condition of your use of this Service, you
            warrant to EDA that you will not use this Service for any purpose that is unlawful or prohibited by
            these Terms of Use. YOU ARE ENTIRELY RESPONSIBLE FOR ALL ACTIVITIES CONDUCTED IN CONNECTION WITH THE
            USE OF THE SERVICE ON YOUR DEVICE(S).
          </li>

          <li>
            <span>Prohibited Uses Generally.</span>
            {' '}
            You are required to comply with all applicable law in
            connection with your use of the Service, and such further limitations as may be set forth herein and
            in any written or on-screen notice from EDA. Without limiting the foregoing, you agree not to:
            <ol className="parentheses">
              <li>
                use any device, software or routine to interfere or attempt to interfere, or which has the
                effect of interfering with the proper working of the Service or website;
              </li>
              <li>
                use or permit anyone to use the information provided through this Service for any unlawful or
                unauthorized purpose, or in any other manner not permitted in these Terms of Use;
              </li>
              <li>
                copy, modify, reproduce, republish, distribute, transmit or use for commercial or public
                purposes the Service, except to the extent required in order for you to use the Service in the
                manner expressly intended by EDA (including providing public links to case law); and
              </li>
              <li>
                infringe any patent, trademark, trade secret, copyright, right of publicity or privacy, or
                other right of any party, or distribute any content you do not have a right to make available
                under any law or under contractual or fiduciary relationships;
              </li>
              <li>
                attempt to use another user, person or entity, misrepresent your affiliation with a person or
                entity, including (without limitation) EDA or create or use a false identity;
              </li>
              <li>assist any third party in engaging in any activity prohibited by these Terms;</li>
              <li>
                send or promote any message that is unlawful, libelous, defamatory, abusive, sexually
                explicit, threatening, vulgar, obscene, profane, disparaging regarding racial, gender or ethnic
                background, any statement that you have reason to know is false or misleading, or otherwise
                objectionable messages, as determined by EDA in its sole discretion;
              </li>
              <li>
                use any incomplete, false or inaccurate biographical information or other information for
                purposes of registering as a customer or registering for any promotions offered through the
                Service or website.
              </li>
            </ol>
            You further agree not to violate or attempt to violate the security of the Service, including,
            without limitation,
            <ol className="parentheses">
              <li>
                access data not intended for you or logging into a server or account that you are not
                authorized to access or otherwise attempt to obtain unauthorized access to the Service or
                website, or portions thereof that are restricted from general access;
              </li>
              <li>
                attempt to interfere with the security of the Service or availability of the Service to any
                user, host or network, including, without limitation, by way of submitting a virus to, or
                overloading, &quot;flooding&quot;, &quot;spamming&quot;, &quot;mailbombing&quot; or &quot;crashing&quot;, the Service;
              </li>
              <li>send unsolicited e-mail, including promotions and/or advertising of products or services;</li>
              <li>
                use the Service to store or transmit code, files, scripts, agents or programs intended to do
                harm, including, for example, viruses, worms, time bombs or Trojan horses; or
              </li>
              <li>
                forge any TCP/IP packet header or any part of the header information in any e-mail or posting.
                Violations of system or network security may result in civil or criminal liability. EDA will
                investigate occurrences that may involve such violations and may involve, and cooperate with,
                law enforcement authorities in prosecuting users who are involved in such violations.
              </li>
            </ol>
            Further, without our prior written consent, you may not:
            <ol className="parentheses">
              <li>
                attempt to probe, scan or test the vulnerability of a system or network or to breach security
                or authentication measures;
              </li>
              <li>
                reproduce, duplicate, copy, sell, resell, create derivative works, or exploit for any
                commercial purpose any EDA content or any use of or access to the Service that is not otherwise
                publicly available;
              </li>
              <li>
                use any high volume, automated, or electronic means (including, without limitation, robots,
                spiders, scripts, or other automated devices) to access the Service or monitor or copy our web
                pages or the content contained thereon, unless in connection with indexing by search engines;
              </li>
              <li>
                deep link to the Service for any purpose; or frame the Service, place pop-up windows over any
                content, or otherwise affect the display of the Service;
              </li>
              <li>
                access the Service in order to build a competitive service or to benchmark with a non-EDA
                service; or
              </li>
              <li>
                decompile, reverse engineer, disassemble, rent, lease, loan, sublicense, create derivative
                works from the Service, which includes the information and software made available therein.
              </li>
            </ol>
          </li>

          <li>
            <span>Service Restrictions, Alterations and Terminations.</span>
            {' '}
            EDA shall not be responsible for
            any delays or interruptions of, or errors or omissions contained in, the Service. EDA reserves the
            right, but shall not be required, to correct and delays, interruptions, errors or omissions. EDA may
            discontinue or alter any aspect of this Service, including, but not limited to: (i) restricting the
            time of availability, (ii) restricting the availability and/or scope of the Service for certain
            users, (iii) restricting the amount of use permitted, and (iv) restricting or terminating any user&apos;s
            right to use this Service, at EDA&apos;s sole discretion and without prior notice or liability.
          </li>

          <li>
            <span>Linking to eDiscovery Assistant.</span>
            {' '}
            You may provide links to the Service from locations
            from locations outside the Service provided (a) you link only to the home page www.eDiscovery
            Assistant.com or to case law public links as provided, (b) you do not remove or obscure, by framing
            or otherwise, advertisements, the copyright notice or other notices in the Service, (c) you give
            provider notice of such link by sending an email message to support@eDiscoveryAssistant.com and (d)
            you discontinue providing links to the Service if notified by EDA.
          </li>
        </ol>

        <h3>3. Ownership; Rights to Use</h3>

        <ol>
          <li>
            <span>Copyright.</span>
            {' '}
            You acknowledge that, other than original government works and other
            public domain materials, this Service contains information, graphics and other material
            (collectively, &quot;Content&quot;) that are protected by copyrights, trademarks, trade secrets or other
            proprietary rights, and that these rights are valid and protected in all forms, media and
            technologies existing now or hereinafter developed. This Content may be subject to additional or
            supplemental license terms, and you agree to follow any such licenses applicable thereto. EDA owns a
            copyright in the selection, coordination, arrangement and enhancement of such Content. You may not
            modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any
            way exploit, any of the Content, in whole or in part. You may not upload, post, reproduce or
            distribute Content protected by copyright, or other proprietary right, without obtaining permission
            of EDA.
          </li>

          <li>
            <span>Trade and Service Mark Rights.</span>
            {' '}
            All rights in the product names, company names, trade
            names, logos, product packaging and designs of all eDiscovery Assistant.com or third-party products
            or services, whether or not appearing in large print or with the trademark symbol, belong
            exclusively to EDA or their respective owners, and are protected from reproduction, imitation,
            dilution or confusing or misleading uses under national and international trademark and copyright
            laws. The use or misuse of these trademarks or any materials, except as permitted herein, is
            expressly prohibited and nothing stated or implied on this Service confers on you any license or
            right under any patent or trademark of EDA or any third party.
          </li>

          <li>
            <span>Notice of Copyright Infringement.</span>
            {' '}
            If you believe that your work has been copied and
            is accessible on this Service in a way that constitutes copyright infringement, please provide EDA&apos;s
            Copyright Agent with the following information:
          </li>

          <ol className="parentheses">
            <li>identification of the copyrighted work claimed to have been infringed;</li>

            <li>
              identification of the allegedly infringing material on the Service that is requested to be
              removed;
            </li>

            <li>
              your name, address and daytime telephone number, and an e-mail address if available, so that EDA
              may contact you if necessary;
            </li>

            <li>
              a statement that you have a good faith belief that the use of the copyrighted work is not
              authorized by the copyright owner, its agent, or the law;
            </li>

            <li>
              a statement that the information in the notification is accurate, and under penalty of perjury,
              that the signatory is authorized to act on behalf of the owner of an exclusive copyright right
              that is allegedly infringed; and
            </li>

            <li>
              an electronic or physical signature of the copyright owner or someone authorized on the owner&apos;s
              behalf to assert infringement of copyright and to submit the statement.
            </li>
          </ol>

          <p>EDA&apos;s Copyright Agent may be reached as follows:</p>

          <div className="address-box">
            <p className="condensed">Copyright Agent</p>
            <p className="condensed">eDiscovery Assistant LLC</p>
            <p className="condensed">2945 Juilliard Street</p>
            <p className="condensed">Boulder, CO 80305</p>
            <p className="condensed">support@eDiscoveryassistant.com</p>
          </div>
          <p>
            EDA will remove any posted submission which infringes the copyright of any person under the laws of
            the United States upon receipt of such a statement (or, more specifically, any statement in
            conformance with 17 U.S.C. § 512(c)(3)). Additionally, EDA –reserves the right to block repeat
            infringers from use of the Service. United States law provides significant penalties for submitting
            such a statement falsely.
          </p>
        </ol>

        <h3>4. Usernames, Passwords and Security.</h3>

        <ol>
          <li>
            <span>Your User Identity.</span>
            {' '}
            Your username and password will be your identity for purposes of
            interacting with EDA and other users through the Service.
          </li>

          <li>
            <span>Username, Passwords, and Password Access.</span>
            {' '}
            You shall keep confidential, shall not
            disseminate, and shall use solely in accordance with this Agreement, your username and password or
            Preview Code for the Service. You shall immediately notify EDA if you learn of or suspect: (i) any
            loss or theft of your username or password, or (ii) any unauthorized use of your username or
            password or of the Service. In the event of such loss, theft, or unauthorized use, EDA may impose on
            you, at EDA&apos;s sole discretion, additional security obligations.
          </li>

          <li>
            <span>Security Breaches and Revision.</span>
            {' '}
            If any unauthorized person obtains access to the
            Service as a result of any act or omission by you, you shall use your best efforts to ascertain the
            source and manner of acquisition and shall fully and promptly brief EDA. You shall otherwise
            cooperate and assist in any investigation relating to any such unauthorized access.
          </li>
        </ol>

        <h3>5. Responsibility for Content; Other Representations, Warranties and Covenants.</h3>

        <ol>
          <li>
            <span>eDiscovery Assistant.</span>
            {' '}
            EDA shall not be responsible for any use that is or is not made
            of the Service. Without limiting the foregoing, EDA makes no representations, warranties or
            covenants regarding, and does not guarantee, the truthfulness, accuracy, relevancy, or reliability
            of any information or other material that are communicated through, or posted to, the Service. You
            acknowledge that any reliance on information or other material communicated through, or posted to,
            the Service will be at your own risk.

            <li>
              <span>Links to Third-Party Services.</span>
              {' '}
              This Service may contain links to other Services
              (&quot;Linked Services&quot;). The Linked Services are not under the control of EDA and EDA is not
              responsible for the contents of the Linked Services, including, without limitation, links
              contained on Linked Services, or any changes or updates to Linked Services. EDA is providing
              Linked Services to you only as a convenience, and the inclusion of such Linked Services is not an
              endorsement by EDA in favor of any company offering Internet services, products or services on the
              Linked Services.
            </li>

            <li>
              <span>DISCLAIMER OF WARRANTY.</span>
              {' '}
              YOU AGREE THAT USE OF THE SERVICE IS ENTIRELY AT YOUR OWN
              RISK. THE SERVICE IS PROVIDED &quot;AS IS,&quot; &quot;AS AVAILABLE&quot;, AND &quot;WITH ALL FAULTS&quot;, WITHOUT WARRANTY OF
              ANY KIND WHATSOEVER, EITHER EXPRESS OR IMPLIED, TO YOU OR ANY OTHER PERSON RELATING IN ANY WAY TO
              THE SERVICE, INCLUDING ANY PART THEREOF, OR ANY WEB SITE OR OTHER CONTENT OR SERVICE THAT MAY BE
              ACCESSIBLE DIRECTLY OR INDIRECTLY THROUGH THE SERVICE. WITHOUT LIMITING THE GENERALITY OF THE
              FOREGOING, EDA DISCLAIMS TO THE MAXIMUM EXTENT PERMITTED BY LAW ANY AND ALL (I) WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, (II) WARRANTIES AGAINST INFRINGEMENT OF ANY
              THIRD PARTY INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS, (III) WARRANTIES RELATING TO DELAYS,
              INTERRUPTIONS, ERRORS OR OMISSIONS IN THE SERVICE, OR ANY PARTY THEREOF, (IV) WARRANTIES RELATING
              TO THE TRANSMISSION OR DELIVERY OF THE SERVICE, AND (V) WARRANTIES OTHERWISE RELATED TO
              PERFORMANCE, NONPERFORMANCE, OR OTHER ACTS OR OMISSIONS BY EDA OR ANY THIRD PARTY.
            </li>

            <li>
              <span>LIMITATION OF LIABILITY.</span>
              {' '}
              UNDER NO CIRCUMSTANCES, WILL ANY OF EDA OR ITS OFFICERS,
              CONTRACTORS, EMPLOYEES, AGENTS, AFFILIATES AND RELATED PARTIES BE LIABLE TO YOU OR TO ANY PERSON
              OR ENTITY CLAIMING THROUGH YOU FOR ANY LOSS, INJURY, LIABILITY OR DAMAGES ARISING OUT OF OR IN
              CONNECTION WITH YOUR ACCESS TO, USE OF, INABILITY TO USE, OR RELIANCE ON ANY OF OUR SERVICES OR
              ANY CONTENT, PRODUCT OR SERVICE PROVIDED TO YOU THROUGH OR IN CONNECTION WITH ANY OF OUR SERVICES.
              THIS IS A COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL LOSSES AND DAMAGES OF ANY KIND
              WHATSOEVER, WHETHER DIRECT OR INDIRECT, GENERAL, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR
              OTHERWISE, INCLUDING WITHOUT LIMITATION, LOSS OF DATA, GOODWILL, REVENUE OR PROFITS. THIS
              LIMITATION OF LIABILITY APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, NEGLIGENCE,
              TORT, STRICT LIABILITY OR ANY OTHER BASIS; EVEN IF ANY COMPANY PARTY HAS BEEN ADVISED OF OR SHOULD
              HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES; AND WITHOUT REGARD TO THE SUCCESS OR EFFECTIVENESS
              OF OTHER REMEDIES. IF ANY PART OF THIS LIMITATION OF LIABILITY IS FOUND TO BE INVALID, ILLEGAL OR
              UNENFORCEABLE FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF THE COMPANY PARTIES UNDER SUCH
              CIRCUMSTANCES TO YOU OR ANY PERSON OR ENTITY CLAIMING THROUGH YOU FOR LIABILITIES THAT OTHERWISE
              WOULD HAVE BEEN LIMITED WILL NOT EXCEED ONE HUNDRED U.S. DOLLARS. SOME JURISDICTIONS DO NOT ALLOW
              THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES
              OF DAMAGES. ACCORDINGLY, SOME OF THE ABOVE DISCLAIMERS OF WARRANTIES AND LIMITATIONS OF LIABILITY
              MAY NOT APPLY TO YOU.
            </li>

            <li>
              <span>California Disclaimer.</span>
              {' '}
              You acknowledge that you may have or may in the future have
              claims against us which you do not know or suspect to exist in your favor when you agreed to these
              Terms and which if known, might materially affect your consent to these Terms. You expressly waive
              all rights you may have under Section 1542 of the California Civil Code, which states:
              <br />
              A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST
              IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
              MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.
              <br />
              IF THIS CLAUSE IS HELD TO BE UNENFORCEABLE IN WHOLE OR IN PART IN ANY JURISDICTION DUE TO RELEVANT
              LAWS, THEN IN NO EVENT SHALL OUR OR THE RELEASEES’ TOTAL LIABILITY TO YOU EXCEED $100.00. NOTHING
              IN THIS CLAUSE SHALL LIMIT OR EXCLUDE ANY LIABILITY FOR DEATH OR PERSONAL INJURY RESULTING FROM
              NEGLIGENCE.
            </li>
          </li>
        </ol>

        <h3>6. Indemnification.</h3>
        <p>
          You agree to defend, indemnify and hold harmless each of EDA, its partners, agents, executives,
          directors, employees and affiliates from any claims and expenses, including reasonable attorney&apos;s fees
          and court costs, arising out of or related to any violation by you, or any partner, agent, employee or
          affiliate of you, of this Terms of Use or your use of the Service or other use of our Service in a
          manner not permitted by these Terms of Use, including without limitation your actual or alleged
          violation of these Terms of Use, or infringement of a third party’s intellectual property or other
          rights by you or another user of our Service using your computer, mobile device or account.
        </p>

        <h3>7. Consent to Electronic Communications.</h3>
        <p>
          By using the Service, you agree that EDA may communicate with you electronically regarding your use
          of the Service and that any notices, agreements, disclosures or other communications that EDA sends to
          you electronically will satisfy any legal communication requirements, including that the
          communications be in writing. To withdraw your consent from receiving electronic notice, please notify
          EDA at support@ediscoveryassistant.com
        </p>

        <h3>8. Providing Feedback to eDA.</h3>
        <p>
          We welcome your comments and feedback about our Service. All information and materials submitted to
          EDA through the Service or otherwise, such as any comments, feedback, ideas, questions, designs, data
          or the like regarding or relating to the Service or the business of EDA
          (collectively,
          <b>&quot;Feedback&quot;</b>
          ), will be considered NON-CONFIDENTIAL and NON-PROPRIETARY with regard
          to you, but EDA reserves the right to treat any such Feedback as the confidential information of EDA.
          By submitting Feedback to EDA, you assign to the EDA Parties, free of charge, all worldwide rights,
          title and interest in all copyrights and other intellectual property rights in such Feedback. The EDA
          Parties will be entitled to use any Feedback you submit, and any ideas, concepts, know-how or
          techniques contained in any such Feedback, for any purpose whatsoever, including but not limited to
          developing, manufacturing and marketing products and services using such Feedback without restriction
          and without compensating you in any way. You are responsible for the information and other content
          contained in any Feedback you submit to us, including, without limitation, its truthfulness and
          accuracy.
        </p>

        <h3>9. Privacy Policy.</h3>
        {' '}
        <p>
          You acknowledge and agree to follow the EDA
          <a
            href="https://app.ediscoveryassistant.com/privacy-policy"
          >
            Privacy Policy.
          </a>
        </p>

        <h3>10. Governing law.</h3>
        {' '}
        <p>
          This Agreement shall be construed and controlled by the laws of the
          Colorado. By using this Service you agree that any dispute arising from or related to the terms of this
          agreement will be governed by the laws of the Colorado, without regard to its choice of law provisions.
          By using this Service, and subject to EDA’s rights in Section 11, below, you agree to personal
          jurisdiction by the state and federal courts sitting in Colorado.
        </p>

        <h3>11. Dispute Resolution.</h3>
        {' '}
        <p>
          EDA at its sole discretion may submit any controversy or claim
          arising out of or relating to these Terms of Use to binding arbitration conducted before one arbitrator
          who is knowledgeable in computer and cyberspace law. The site of any such arbitration will be in
          Colorado or at a place of EDA’s choosing. The arbitration will be conducted in accordance with the then
          applicable Rules of Civil Procedure for the selected jurisdiction. The award rendered by the arbitrator
          will be binding and conclusive on the parties and judgment upon such award may be entered in any court
          having jurisdiction thereof. Each party will bear its own costs and expenses, including fees and
          expenses of counsel, associated with the arbitration. The arbitrator will not be empowered to award
          punitive damages to either party.
          {' '}
        </p>

        <h3>12. Notice for California Users.</h3>
        {' '}
        <p>
          Under California Civil Code Section 1789.3, California
          users of our Services are entitled to the following specific consumer rights notice: The Complaint
          Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs
          may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916)
          445-1254 or (800) 952-5210.
        </p>

        <h3>13. Severability.</h3>
        {' '}
        <p>
          If any provision of these Terms of Use is held to be invalid or
          unenforceable, such provision shall be struck and the remaining provisions shall be enforced.
        </p>

        <h3>14. Entire Agreement.</h3>
        {' '}
        <p>
          This Agreement constitutes the entire agreement between the parties
          with respect to the subject matter contained herein and supersedes all previous and contemporaneous
          agreements, proposals and communications, written or oral, between EDA representatives and you. Upon
          notice published through this Service or otherwise, EDA may modify this Agreement at any time.
          Modifications will become effective immediately upon publication, without further notice to you. Your
          continued use of any of the Service after such modifications are posted constitutes your acknowledgement
          and acceptance of such modifications, and you may not amend these Terms of Use. USE OF THIS SERVICE IS
          UNAUTHORIZED IN ANY JURISDICTION THAT DOES NOT GIVE EFFECT TO ALL PROVISIONS OF THESE TERMS OF SERVICE,
          INCLUDING WITHOUT LIMITATION THIS PARAGRAPH.
        </p>

        <h3>15. Additional Terms.</h3>
        {' '}
        <p>
          Other Provisions that govern your use of the Service are set forth in
          online notices appearing in connection with certain information, products, software, services, or
          features of the Service, (collectively the &quot;Additional Terms&quot;), all of which are incorporated by
          reference herein. Your use of any information, products, software, or features of the Service that are
          subject to Additional Terms constitutes your acceptance of the respective Additional Terms.
        </p>

        <h3>Contact.</h3>
        {' '}
        <p>
          If you have any questions about these Terms of Use, please feel free to please
          contact us at: support@ediscoveryassistant.com.
        </p>
      </div>
      <div className="row">
        <div className={`text-center form-group col-sm-12 ${formErrors.terms_of_use ? 'has-error' : ''}`}>
          <label className="register-checkbox">
            <input
              type="checkbox"
              checked={data.terms_of_use}
              name="terms_of_use"
              id="terms_of_use"
              data-testid="terms_of_use"
              onChange={handleCheckboxChange}
            />
            I agree to the Terms and Conditions
          </label>
          { formErrors.terms_of_use && <p className="help-block">{formErrors.terms_of_use}</p> }
        </div>
      </div>
      <div className="register-form-actions" style={{ marginTop: '2.4rem' }}>
        <button type="button" className="btn btn-cancel" onClick={onCancel}>Back</button>
        <button
          type="button"
          className="btn btn-submit"
          onClick={handleSubmit}
        >
          {
            data.plan.plan_type === 'credit_card' ? 'Continue'
              : status === 'creating' ? 'Creating' : status === 'success' ? 'Logging In...' : 'Create Account'
          }
        </button>
      </div>
    </>
  );
}

export default TermsAndServices;
