import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import Registration from '../../auth/Registration';

const currentPlan = {
  name: 'Individual',
  plan_type: 'credit_card',
  default_amount: 124900,
  stripe_plan_id: 'some_id',
};

describe('Registration', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<Registration
      currentPlan={currentPlan}
    />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('shows password, render password errors', () => {
    const { getByPlaceholderText } = render(<Registration
      currentPlan={currentPlan}
    />);

    const passwordField = getByPlaceholderText('Password');
    expect(passwordField).toBeInTheDocument();

    userEvent.type(passwordField, 's');

    const showPassword = document.querySelector('svg');
    userEvent.click(showPassword);

    expect(screen.getByDisplayValue('s')).toBeInTheDocument();
    expect(screen.getByText('Password too short (minimum 8 characters)')).toBeInTheDocument();
  });
});
