// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Account renders correctly with props 1`] = `
<div
  className="react-account"
>
  <div
    className="left"
  >
    <div
      className="account-settings"
    >
      Account Settings
    </div>
    <div
      className="account-categories container-left"
    >
      <div
        className="category  selected"
        onClick={[Function]}
      >
        Personal Detail
      </div>
      <div
        className="category "
        onClick={[Function]}
      >
        Notifications
      </div>
      <div
        className="category "
        onClick={[Function]}
      >
        Billing Info
      </div>
    </div>
    <div
      className="account-row"
    >
      <div
        className="account-field"
      >
        <div
          className="field-title"
        >
          First Name
        </div>
        <input
          id="firstName"
          onChange={[Function]}
          type="text"
          value="First"
        />
      </div>
    </div>
    <div
      className="account-row"
    >
      <div
        className="account-field"
      >
        <div
          className="field-title"
        >
          Last Name
        </div>
        <input
          id="lastName"
          onChange={[Function]}
          type="text"
          value="Last"
        />
      </div>
    </div>
    <div
      className="account-row"
    >
      <div
        className="account-field"
      >
        <div
          className="field-title"
        >
          Email Address
        </div>
        <input
          id="email"
          onChange={[Function]}
          type="text"
          value="email@mail.com"
        />
      </div>
    </div>
    <div
      className="account-row"
    >
      <div
        className="account-field"
      >
        <div
          className="field-title"
        >
          Password
        </div>
        <a
          className="update"
          href="/registrations/edit_password"
          rel="noreferrer"
        >
          Reset Password
        </a>
      </div>
    </div>
    <div
      className="roles"
    >
      <div
        className="roles-name"
      >
        Tell us about yourself
      </div>
      <div
        className="roles-list"
      >
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Attorney – Partner
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Attorney – Associate
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Attorney – Solo Practitioner
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Litigation Support Professional
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          eDiscovery Consultant
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          In-House Counsel
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Student
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Professor
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Paralegal
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Paralegal  or Legal Support Professional
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Information Management or Librarian
        </div>
        <div
          className="role"
        >
          <div
            className="role-checkbox"
          >
            <svg
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "background": "#E5E5E8",
                  "borderRadius": 3,
                  "color": undefined,
                  "fill": "#E5E5E8",
                }
              }
              version="1.1"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0h-12c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM14 14h-12v-12h12v12z"
              />
            </svg>
          </div>
          Information Management Professional
        </div>
      </div>
    </div>
    <div
      className="update"
      onClick={[Function]}
    >
      Update Settings
    </div>
  </div>
</div>
`;
