import React from 'react';
import renderer from 'react-test-renderer';

import AbaRules from '../../../rules/src/AbaRules';

const abaRules = [
  {
    id: 4,
    rule_id: 1,
    published: true,
    number: 'ABA Rule #1',
  },
  {
    id: 5,
    rule_id: 2,
    published: true,
    number: 'ABA Rule #2',
  },
];

const rules = [
  {
    id: 1,
  },
];

it('renders correctly with props', () => {
  const tree = renderer.create(<AbaRules
    abaRules={abaRules}
    favorites={{ rules }}
    loading={false}
    toggleRuleBookmark={() => {}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
