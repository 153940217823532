// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="modal"
>
  <div
    className="modal-dialog modal-sm"
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <h4
          className="modal-title"
        >
          Payment History
          <button
            className="button-clear pull-right"
            onClick={[Function]}
            type="button"
          >
            ✕
          </button>
        </h4>
      </div>
      <div
        className="modal-body"
      >
        <div
          className="history"
        >
          <div
            className="history-column"
          >
            <div
              className="name"
            >
              DATE
            </div>
            <div
              className="history-data"
            >
              12-03-2023
            </div>
            <div
              className="history-data"
            >
              12-03-2023
            </div>
          </div>
          <div
            className="history-column"
          >
            <div
              className="name"
            >
              AMOUNT
            </div>
            <div
              className="history-data"
            >
              1399
            </div>
            <div
              className="history-data"
            >
              2399
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
