import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Api from '../../../services/api';

export default function EditSubscription({
  account, subscription,
}) {
  const [data, setData] = useState({
    effective_from: subscription.effective_from ? new Date(subscription.effective_from) : '',
    effective_to: subscription.effective_to ? new Date(subscription.effective_to) : '',
    number_of_seats: subscription.number_of_seats || '',
  });

  const handleChange = (field, value) => {
    const limitedAccounts = (account.account_type === 'educational_paid' || account.account_type === 'individual');
    if (field === 'number_of_seats' && value > 50 && limitedAccounts) return;
    setData({ ...data, [field]: value });
  };

  const handleUpdate = () => {
    Api.admin.subscriptions.update(subscription.id, data).then((res) => {
      let message = '';
      if (res.status === 'OK') message = 'Subscription updated';
      else message = res.message;
      sessionStorage.setItem('message', message);
      window.location.href = `/admin/accounts/${account.id}/edit`;
    });
  };

  return (
    <div className="admin-page react-admin-accounts-edit">
      <div className="header">
        <h2>
          <a href={`/admin/accounts/${account.id}/edit`} className="back">&lt;</a>
          Accounts / Edit /
          {' '}
          {subscription.plan.name}
        </h2>
        <button type="button" className="button" onClick={handleUpdate} disabled={!data.effective_from || !data.effective_to}>
          Update Subscription
        </button>

      </div>
      <div className="form">
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Subscription Start Date</label>
              <DatePicker
                className="form-control"
                selected={data.effective_from}
                onChange={(e) => handleChange('effective_from', e)}
                placeholderText="Start Date"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Subscription End Date</label>
              <DatePicker
                className="form-control"
                selected={data.effective_to}
                onChange={(e) => handleChange('effective_to', e)}
                placeholderText="End Date"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Subscription Seats</label>
              <input
                type="number"
                placeholder="Type Here"
                className="form-control"
                min="1"
                value={data.number_of_seats}
                onChange={(e) => handleChange('number_of_seats', e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
