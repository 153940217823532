import React from 'react';

const sortRulesByCategory = (rules) => {
  const ruleCategories = new Map();
  rules.filter((r) => r.type === 'FederalRule').forEach((rule) => {
    if (rule.rule_categories.length === 0) {
      if (!ruleCategories.has('Federal Rules')) {
        ruleCategories.set('Federal Rules', []);
      }
      ruleCategories.get('Federal Rules').push({ id: rule.id, name: rule.published_revision.number, type: 'federal' });
      return;
    }
    if (!ruleCategories.has(rule.rule_categories[0].name)) {
      ruleCategories.set(rule.rule_categories[0].name, []);
    }
    ruleCategories.get(rule.rule_categories[0].name).push({ id: rule.id, name: rule.published_revision.number, type: 'federal' });
  });
  rules.filter((r) => r.type === 'StateRule').forEach((rule) => {
    if (rule.rule_categories.length === 0) {
      if (!rule.published_revision.number) return;
      if (!ruleCategories.has('State Rules')) {
        ruleCategories.set('State Rules', []);
      }
      ruleCategories.get('State Rules').push({ id: rule.id, name: rule.published_revision.number, type: 'state' });
      return;
    }
    if (!ruleCategories.has(rule.rule_categories[0].name)) {
      ruleCategories.set(rule.rule_categories[0].name, []);
    }
    ruleCategories.get(rule.rule_categories[0].name).push({ id: rule.id, name: rule.published_revision.number, type: 'state' });
  });
  rules.filter((r) => r.type === 'DistrictRuleRevision' || r.type === 'DistrictRule').forEach((rule) => {
    if (rule.rule_categories.length === 0) {
      if (!ruleCategories.has('District Rules')) {
        ruleCategories.set('District Rules', []);
      }
      ruleCategories.get('District Rules').push({ id: rule.id, name: rule.published_revision.number, type: 'district' });
      return;
    }
    if (!ruleCategories.has(rule.rule_categories[0].name)) {
      ruleCategories.set(rule.rule_categories[0].name, []);
    }
    ruleCategories.get(rule.rule_categories[0].name).push({ id: rule.id, name: rule.published_revision.number, type: 'district' });
  });
  rules.filter((r) => r.type === 'RegulatoryRuleRevision' || r.type === 'RegulatoryRule').forEach((rule) => {
    if (rule.rule_categories.length === 0) {
      if (!ruleCategories.has('Regulatory Rules')) {
        ruleCategories.set('Regulatory Rules', []);
      }
      ruleCategories.get('Regulatory Rules').push({ id: rule.id, name: rule.published_revision.number, type: 'regulatory' });
      return;
    }
    if (!ruleCategories.has(rule.rule_categories[0].name)) {
      ruleCategories.set(rule.rule_categories[0].name, []);
    }
    ruleCategories.get(rule.rule_categories[0].name).push({ id: rule.id, name: rule.published_revision.number, type: 'regulatory' });
  });

  return ruleCategories;
};
export default function RelatedRules({
  currentUser, rules, onUnauthenticated,
}) {
  const ruleCategories = sortRulesByCategory(rules);
  return (
    <div className="related-rule-list">
      {
        Array.from(ruleCategories, ([category, ruleList]) => (
          <React.Fragment key={category}>
            <div className="name">{category}</div>
            {
              ruleList.map((rule) => (
                <div
                  key={rule.name}
                  className="rule-div"
                  onClick={(e) => {
                    if (currentUser != null) return;
                    e.preventDefault();
                    onUnauthenticated(e);
                  }}
                >
                  <a href={`/${rule.type}_rules/${rule.id}`} key={rule.name}>{rule.name}</a>
                </div>
              ))
            }
          </React.Fragment>
        ))
      }

    </div>
  );
}
