// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="scholar-text format-two"
>
  <div>
    <p>
      This is a text
    </p>
  </div>
</div>
`;
