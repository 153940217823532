/* istanbul ignore file */
export default {
  show(text, duration = 3000) {
    const snackbar = document.createElement('div');
    snackbar.className = 'snackbar';
    snackbar.innerHTML = text;
    document.body.appendChild(snackbar);
    setTimeout(() => {
      document.body.removeChild(snackbar);
    }, duration);
  },
};
