// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="flex-container"
>
  <div
    className="search-tags"
  >
    <ul
      className="tags tags-container search-tags-container"
    />
    <div
      aria-expanded={false}
      aria-haspopup="listbox"
      aria-owns="react-autowhatever-1"
      className="react-autosuggest__container"
      role="combobox"
    >
      <input
        aria-activedescendant={null}
        aria-autocomplete="list"
        aria-controls="react-autowhatever-1"
        autoComplete="off"
        className="react-autosuggest__input"
        onBlur={[Function]}
        onChange={[Function]}
        onFocus={[Function]}
        onKeyDown={[Function]}
        placeholder="Select Jurisdiction(s)"
        type="text"
        value=""
      />
      <div
        className="react-autosuggest__suggestions-container"
        id="react-autowhatever-1"
        role="listbox"
      />
    </div>
    <button
      className="jurisdiction-button"
      onClick={[Function]}
      type="button"
    >
      <svg
        fill="currentColor"
        height="1em"
        stroke="currentColor"
        strokeWidth="0"
        style={
          Object {
            "color": undefined,
          }
        }
        viewBox="0 0 448 512"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
        />
      </svg>
    </button>
    <span
      style={
        Object {
          "flex": 1,
        }
      }
    />
    <span
      className="clear-tags"
      onClick={[Function]}
    >
      ✕
    </span>
  </div>
</div>
`;
