import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';

export default function TagOption(props) {
  const { option, inputValue, active } = props;
  const matches = match(option.name, inputValue);
  const parts = parse(option.name, matches);
  return (
    <div className={`tag-option ${active ? 'active' : ''}`}>
      {parts.map((part, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index} style={{ fontWeight: part.highlight ? 500 : 400 }}>
          {part.text.replace(/^\s+/, '\u00A0').replace(/\s+$/, '\u00A0')}
        </span>
      ))}
    </div>
  );
}
