import React from 'react';
import renderer from 'react-test-renderer';

import Progress from '../../learning_center/Progress';
import { currentUser, isAdmin, mediaItems } from '../__mocks__/lc-data';

it('renders correctly with props', () => {
  const tree = renderer.create(
    <Progress
      currentUser={currentUser}
      isAdmin={isAdmin}
      progressItems={mediaItems}
      totalPages={2}
      currentPage={1}
      totalCount={mediaItems.length}
      tab="progress"
    />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<Progress />).toJSON();
  expect(tree.element).toMatchSnapshot();
});
