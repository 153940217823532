import React from 'react';

export function CardCVCElement({ children }) {
  return <div>{children}</div>;
}
export function CardExpiryElement({ children }) {
  return <div>{children}</div>;
}
export function CardNumberElement({ children }) {
  return <div>{children}</div>;
}
export const injectStripe = (cmp) => cmp;
export const StripeProvider = ({ children }) => children;
export const Elements = ({ children }) => children;
