import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';
import PaymentMethodUpdatedModal from '../../../account/account_modals/PaymentMethodUpdatedModal';

describe('account updated Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<PaymentMethodUpdatedModal />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders modal and close click', async () => {
    const mockWinReload = jest.fn();
    const oldWindowLocation = window.location;
    delete window.location;
    window.location = Object.defineProperties(
      {},
      {
        ...Object.getOwnPropertyDescriptors(oldWindowLocation),
        reload: {
          configurable: true,
          value: mockWinReload,
        },
      },
    );
    const container = render(
      <PaymentMethodUpdatedModal />,
    );
    const closeButton = container.queryByText('Close');
    expect(closeButton).toBeInTheDocument();
    fireEvent.click(closeButton);
    expect(mockWinReload).toBeCalledTimes(1);
    window.location = oldWindowLocation;
  });
});
