import React, { useEffect, useState } from 'react';
import { FiTrash, FiEdit3 } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';
import { GrDrag } from 'react-icons/gr';
import SortableTree, {
  isDescendant, removeNodeAtPath, defaultGetNodeKey, changeNodeAtPath,
} from 'react-sortable-tree';
import classnames from 'classnames';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';

export default function Modules({ modules }) {
  const [items, setItems] = useState(modules);
  const [editableItem, setEditableItem] = useState(null);

  const handleItemChange = (field, value) => {
    setEditableItem({ ...editableItem, [field]: value });
  };

  const handleEditItem = (item, path) => {
    setEditableItem({ ...item, path });
  };

  const handleSaveItem = () => {
    const { path, ...item } = editableItem;
    if (path) {
      setItems(
        changeNodeAtPath({
          treeData: items,
          path,
          newNode: { ...item, expanded: true },
          getNodeKey: defaultGetNodeKey,
        }),
      );
    } else {
      setItems([...items, { ...editableItem, expanded: true }]);
    }

    setEditableItem(null);
  };

  const handleAddItem = () => {
    setEditableItem({
      name: '',
      description: '',
      children: [],
    });
  };

  const handleSave = () => {
    Api.admin.media_categories.save({ items })
      .then(() => Snackbar.show('Academy Modules updated'))
      .catch(() => Snackbar.show('Error. Modules not updated'));
  };

  useEffect(() => handleSave(), [items]);

  const renderModules = () => {
    if (items.length === 0 && !editableItem) {
      return (
        <div className="modules-placeholder">
          <span>No modules added yet</span>
        </div>
      );
    }

    return (
      <div className="modules">
        { items.length > 0 && (
          <div className="modules-tree">
            <SortableTree
              maxDepth={1}
              treeData={items}
              onChange={setItems}
              isVirtualized={false}
              scaffoldBlockPxWidth={10}
              rowHeight={40}
              getNodeKey={defaultGetNodeKey}
              nodeContentRenderer={({ // eslint-disable-line react/no-unstable-nested-components
                connectDragPreview,
                connectDragSource,
                isDragging,
                canDrop,
                canDrag,
                node,
                draggedNode,
                className,
                style,
                didDrop,
                path,
                ...otherProps
              }) => {
                let handle;
                if (canDrag) {
                  handle = connectDragSource(<div className="drag-handle"><GrDrag /></div>, {
                    dropEffect: 'copy',
                  });
                }

                const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
                const isLandingPadActive = !didDrop && isDragging;

                return (
                  <div style={{ height: '100%', borderBottom: '1px solid #d3d3d3' }} {...otherProps}>
                    <div className="rst__rowWrapper">
                      {connectDragPreview(
                        <div
                          className={classnames(
                            'rst__row',
                            isLandingPadActive && 'rst__rowLandingPad',
                            isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                            className,
                          )}
                          style={{
                            opacity: isDraggedDescendant ? 0.5 : 1,
                            ...style,
                          }}
                        >
                          {handle}
                          <div className="module">
                            <p className="module-name">
                              {node.name}
                            </p>
                            <p className="module-description">
                              {node.description}
                            </p>
                            <button type="button" className="edit-button" onClick={() => handleEditItem(node, path)}>
                              <FiEdit3 />
                            </button>
                            <button
                              type="button"
                              className="delete-button"
                              onClick={() => {
                                setItems((treeData) => removeNodeAtPath({
                                  treeData,
                                  path,
                                  getNodeKey: defaultGetNodeKey,
                                }));
                              }}
                            >
                              <FiTrash />
                            </button>
                          </div>
                        </div>,
                      )}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}

        {items.length > 0 && Boolean(editableItem) && (
          <div className="separator" />
        )}

        { Boolean(editableItem) && (
          <div className="editable-module row">
            <div className="col-sm-10">
              <div className="form-group">
                <label>Module Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={editableItem.name}
                  onChange={(e) => handleItemChange('name', e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>Module Description</label>
                <textarea
                  className="form-control"
                  value={editableItem.description || ''}
                  onChange={(e) => handleItemChange('description', e.target.value)}
                />
              </div>
            </div>

            <div className="col-sm-2 module-buttons">
              <button type="button" className="button success" onClick={handleSaveItem}>
                Save
              </button>
              <button
                type="button"
                className="button danger"
                onClick={() => setEditableItem(null)}
              >
                <FiTrash />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="admin-page react-admin-academy-modules">
      <div className="header">
        <h1>Academy Modules</h1>
        <button type="button" className="button" onClick={handleAddItem}>
          Add New
          <GoPlus />
        </button>
        <button
          type="button"
          className="button"
          onClick={handleSave}
        >
          Save List
        </button>
      </div>
      {renderModules()}
    </div>
  );
}
