import React from 'react';
import Player from './Player';
import { Tabs } from './Tabs';

export function VideoContent({
  mediaItem, progress = 0, tab, setTab, isAdmin, linkedContent,
}) {
  return (
    <div className="content player-view">
      <div className="player">
        <Player mediaItem={mediaItem} progress={progress} />
      </div>
      <Tabs
        item={mediaItem}
        tab={tab}
        setTab={setTab}
        isAdmin={isAdmin}
        linkedContent={linkedContent}
      />
    </div>
  );
}
