// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <form
        class="react-search"
      >
        <div
          class="left"
        >
          <div
            class="empty-image"
          />
          <div
            class="search-page-title"
          >
            Create Your Case Law Search
          </div>
          <div
            class="search-page-subtitle"
          >
            Start by selecting Issue Search, Term Search, or Advanced to enter multiple filters at once.
            <br />
            You can build on your search at any time.
          </div>
          <div
            class="react-search-content"
          >
            <div
              class="steps"
            >
              <div
                class="tag active"
              >
                Issue Search
              </div>
              <div
                class="tag false"
              >
                Term Search
              </div>
              <div
                class="tag false"
              >
                Advanced Search
              </div>
            </div>
            <div
              class="step"
            >
              <div
                class="flex-container"
              >
                <div
                  class="eda-search-container"
                >
                  <div
                    class="input-container"
                  >
                    <textarea
                      autocomplete="off"
                      class="search-input"
                      id="search-input"
                      placeholder="Start typing issues or click on + sign to select issues"
                      spellcheck="false"
                    />
                    <div
                      class="overlay"
                    />
                  </div>
                  <button
                    class="search-tags-button"
                    data-testid="search-tags"
                    type="button"
                  >
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  class="suggestions"
                >
                  Try:  
                  <a
                    class="tag"
                    href="#"
                  >
                    Spoliation
                  </a>
                  <a
                    class="tag"
                    href="#"
                  >
                    Sanctions
                  </a>
                  <a
                    class="tag"
                    href="#"
                  >
                    Proportionality
                  </a>
                </div>
                <div
                  class="react-search-tips"
                >
                  <div
                    class="tips-title"
                  >
                    Boolean Search Tips:
                  </div>
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    AND
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    OR
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    NOT
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    (
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    )
                  </button>
                </div>
              </div>
              <div
                class="include-unpublished-label"
              >
                <svg
                  fill="currentColor"
                  height="1em"
                  stroke="currentColor"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
                  />
                </svg>
                Include Unpublished Decisions
              </div>
            </div>
          </div>
          <button
            class="next-button"
            disabled=""
          >
            <svg
              fill="currentColor"
              height="1em"
              stroke="currentColor"
              stroke-width="0"
              viewBox="0 0 16 16"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
                fill-rule="evenodd"
              />
            </svg>
            Search
          </button>
        </div>
      </form>
    </div>
    <div
      class="search-drop-down-container"
      id="drop-down"
      role="tooltip"
      style="position: fixed; top: 0px; left: 0px; display: none;"
    >
      <div
        class="drop-down"
      />
    </div>
  </body>,
  "container": <div>
    <form
      class="react-search"
    >
      <div
        class="left"
      >
        <div
          class="empty-image"
        />
        <div
          class="search-page-title"
        >
          Create Your Case Law Search
        </div>
        <div
          class="search-page-subtitle"
        >
          Start by selecting Issue Search, Term Search, or Advanced to enter multiple filters at once.
          <br />
          You can build on your search at any time.
        </div>
        <div
          class="react-search-content"
        >
          <div
            class="steps"
          >
            <div
              class="tag active"
            >
              Issue Search
            </div>
            <div
              class="tag false"
            >
              Term Search
            </div>
            <div
              class="tag false"
            >
              Advanced Search
            </div>
          </div>
          <div
            class="step"
          >
            <div
              class="flex-container"
            >
              <div
                class="eda-search-container"
              >
                <div
                  class="input-container"
                >
                  <textarea
                    autocomplete="off"
                    class="search-input"
                    id="search-input"
                    placeholder="Start typing issues or click on + sign to select issues"
                    spellcheck="false"
                  />
                  <div
                    class="overlay"
                  />
                </div>
                <button
                  class="search-tags-button"
                  data-testid="search-tags"
                  type="button"
                >
                  <svg
                    fill="currentColor"
                    height="1em"
                    stroke="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                    />
                  </svg>
                </button>
              </div>
              <div
                class="suggestions"
              >
                Try:  
                <a
                  class="tag"
                  href="#"
                >
                  Spoliation
                </a>
                <a
                  class="tag"
                  href="#"
                >
                  Sanctions
                </a>
                <a
                  class="tag"
                  href="#"
                >
                  Proportionality
                </a>
              </div>
              <div
                class="react-search-tips"
              >
                <div
                  class="tips-title"
                >
                  Boolean Search Tips:
                </div>
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  AND
                </button>
                 
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  OR
                </button>
                 
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  NOT
                </button>
                 
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  (
                </button>
                 
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  )
                </button>
              </div>
            </div>
            <div
              class="include-unpublished-label"
            >
              <svg
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
                />
              </svg>
              Include Unpublished Decisions
            </div>
          </div>
        </div>
        <button
          class="next-button"
          disabled=""
        >
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            stroke-width="0"
            viewBox="0 0 16 16"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
              fill-rule="evenodd"
            />
          </svg>
          Search
        </button>
      </div>
    </form>
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;

exports[`renders search correctly 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div
        class="case-law-header"
      >
        <a
          class="mobile-header-back"
          href="/case_law"
        />
        <div
          class="mobile-header-cases"
        >
          1 cases found
        </div>
        <div
          class="mobile-search-open"
        />
      </div>
      <div
        class="react-search-results "
      >
        <form
          class="search-fields  "
        >
          <div
            class="search-fields-inner "
          >
            <div
              class="search-field"
            >
              <div
                class="flex-container"
              >
                <p
                  class="search-field-name"
                >
                  Issue Tags
                </p>
                <div
                  class="eda-search-container"
                >
                  <div
                    class="input-container"
                  >
                    <textarea
                      autocomplete="off"
                      class="search-input"
                      id="search-input"
                      placeholder="Start typing issues or click on + sign to select issues"
                      spellcheck="false"
                    >
                      Issue #1 AND Issue #2
                    </textarea>
                    <div
                      class="overlay"
                    >
                      Issue #1 
                      <span
                        class="selected-value"
                      >
                        AND
                      </span>
                       Issue #2
                    </div>
                  </div>
                  <span
                    class="clear-tags"
                  >
                    ✕
                  </span>
                  <button
                    class="search-tags-button"
                    data-testid="search-tags"
                    type="button"
                  >
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  class="suggestions"
                >
                  Try:  
                  <a
                    class="tag"
                    href="#"
                  >
                    Spoliation
                  </a>
                  <a
                    class="tag"
                    href="#"
                  >
                    Sanctions
                  </a>
                  <a
                    class="tag"
                    href="#"
                  >
                    Proportionality
                  </a>
                </div>
                <div
                  class="react-search-tips"
                >
                  <div
                    class="tips-title"
                  >
                    Boolean Search Tips:
                  </div>
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    AND
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    OR
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    NOT
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    (
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    data-place="bottom"
                    type="button"
                  >
                    )
                  </button>
                </div>
              </div>
              <div
                class="include-unpublished-label selected"
              >
                <svg
                  fill="currentColor"
                  height="1em"
                  stroke="currentColor"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"
                  />
                </svg>
                Include Unpublished Decisions
              </div>
            </div>
            <div
              class="search-fields-extra"
            >
              <div
                class="search-field"
              >
                <div
                  class="flex-container"
                >
                  <div
                    class="case-search"
                  >
                    <input
                      placeholder="Search Terms"
                      type="text"
                      value=""
                    />
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
                        fill-rule="evenodd"
                      />
                    </svg>
                    <span
                      style="flex: 1;"
                    />
                  </div>
                  <div
                    class="react-search-tips"
                  >
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      AND
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      OR
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      NOT
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      ( )
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      “ ”
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      ?
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      *
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      w/
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="search-field"
              >
                <div
                  class="flex-container"
                >
                  <div
                    class="search-tags"
                  >
                    <div
                      aria-expanded="false"
                      aria-haspopup="listbox"
                      aria-owns="react-autowhatever-1"
                      class="react-autosuggest__container"
                      role="combobox"
                    >
                      <input
                        aria-autocomplete="list"
                        aria-controls="react-autowhatever-1"
                        autocomplete="off"
                        class="react-autosuggest__input"
                        placeholder="Select Jurisdiction(s)"
                        type="text"
                        value=""
                      />
                      <div
                        class="react-autosuggest__suggestions-container"
                        id="react-autowhatever-1"
                        role="listbox"
                      />
                    </div>
                    <button
                      class="jurisdiction-button"
                      type="button"
                    >
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 448 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="search-field"
              >
                <div
                  class="flex-container"
                >
                  <div
                    class="search-dates"
                  >
                    <div
                      class="datepickers"
                    >
                      <div>
                        <div
                          class="react-datepicker-wrapper"
                        >
                          <div
                            class="react-datepicker__input-container"
                          >
                            <input
                              class="search-datepicker"
                              placeholder="Start Date"
                              type="text"
                              value=""
                            />
                          </div>
                        </div>
                        
                        <svg
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                          />
                        </svg>
                      </div>
                      <div>
                        <div
                          class="react-datepicker-wrapper"
                        >
                          <div
                            class="react-datepicker__input-container"
                          >
                            <input
                              class="search-datepicker"
                              placeholder="End Date"
                              type="text"
                              value=""
                            />
                          </div>
                        </div>
                        
                        <svg
                          fill="currentColor"
                          height="1em"
                          stroke="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="search-field"
              >
                <div
                  class="flex-container"
                >
                  <div
                    class="search-case-title"
                  >
                    <div
                      class="search-case-title-input"
                    >
                      <input
                        placeholder="Case Title"
                        type="text"
                        value=""
                      />
                      <span
                        style="flex: 1;"
                      />
                    </div>
                    <div
                      class="react-search-tips"
                    >
                      <button
                        class="btn btn-flat tag"
                        type="button"
                      >
                        AND
                      </button>
                       
                      <button
                        class="btn btn-flat tag"
                        type="button"
                      >
                        OR
                      </button>
                       
                      <button
                        class="btn btn-flat tag"
                        type="button"
                      >
                        NOT
                      </button>
                       
                      <button
                        class="btn btn-flat tag"
                        type="button"
                      >
                        ( )
                      </button>
                       
                      <button
                        class="btn btn-flat tag"
                        type="button"
                      >
                        “ ”
                      </button>
                       
                      <button
                        class="btn btn-flat tag"
                        type="button"
                      >
                        ?
                      </button>
                       
                      <button
                        class="btn btn-flat tag"
                        type="button"
                      >
                        *
                      </button>
                       
                      <button
                        class="btn btn-flat tag"
                        type="button"
                      >
                        w/
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="search-field"
              >
                <div
                  class="flex-container"
                >
                  <div
                    class="search-judge"
                  >
                    <div
                      class="search-judge-input"
                    >
                      <input
                        placeholder="Add Judge"
                        type="text"
                        value=""
                      />
                      <span
                        style="flex: 1;"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            class="btn btn-flat search-button "
            type="submit"
          >
            Search
          </button>
        </form>
        <div
          class="results-container"
        >
          <div
            class="results"
          >
            <div
              class="header"
            >
              <div
                class="header-left"
              >
                <div
                  class="header-title"
                >
                  1 cases found
                </div>
                <ul
                  class="rc-menu options-item rc-menu-root rc-menu-horizontal"
                  role="menu"
                  tabindex="0"
                >
                  <li
                    class="rc-menu-submenu rc-menu-submenu-horizontal rc-menu-overflowed-submenu"
                    role="menuitem"
                    style="display: none;"
                  >
                    <div
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="rc-menu-submenu-title"
                    >
                      <span>
                        ···
                      </span>
                      <i
                        class="rc-menu-submenu-arrow"
                      />
                    </div>
                  </li>
                  <li
                    class="rc-menu-submenu rc-menu-submenu-horizontal settings"
                    role="menuitem"
                  >
                    <div
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="rc-menu-submenu-title"
                    >
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 3 16"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 2.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zm0 5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zM1.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                          fill-rule="evenodd"
                        />
                      </svg>
                      <i
                        class="rc-menu-submenu-arrow"
                      />
                    </div>
                  </li>
                  <li
                    class="rc-menu-submenu rc-menu-submenu-horizontal rc-menu-overflowed-submenu"
                    role="menuitem"
                    style="visibility: hidden; position: absolute; display: none;"
                  >
                    <div
                      aria-expanded="false"
                      aria-haspopup="true"
                      class="rc-menu-submenu-title"
                    >
                      <span>
                        ···
                      </span>
                      <i
                        class="rc-menu-submenu-arrow"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div
                class="header-buttons"
              >
                <div
                  class="results-sort-container"
                >
                  <div
                    class="results-sort tag"
                  >
                    Sort By:
                    <select>
                      <option
                        value="relevance"
                      >
                        Relevance
                      </option>
                      <option
                        value="des_decision_date"
                      >
                        Date descending
                      </option>
                      <option
                        value="asc_decision_date"
                      >
                        Date ascending
                      </option>
                    </select>
                  </div>
                  <div
                    class="results-sort tag"
                  >
                    Results/Page:
                    <select>
                      <option
                        value="25"
                      >
                        25
                      </option>
                      <option
                        value="50"
                      >
                        50
                      </option>
                      <option
                        value="100"
                      >
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="separator"
                />
                <div
                  class="results-view-select"
                >
                  <button
                    class="tag "
                    type="button"
                  >
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 384 512"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      />
                    </svg>
                    Map View
                  </button>
                  <button
                    class="tag active"
                    type="button"
                  >
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
                      />
                    </svg>
                    List View
                  </button>
                </div>
              </div>
            </div>
            <div
              class="cite-container"
            >
              <div
                class="all-results-button false"
              >
                Add
                 Result 
                to Cite List
              </div>
              <div
                class="all-results-button false"
              >
                Save Search as Dynamic Cite List
              </div>
            </div>
            <div>
              <div
                class="case-digest-preview flex-container"
                data-digest-id="34532"
              >
                <div
                  class="decision-title"
                >
                  <div
                    class="decision-name"
                  >
                    <a
                      href="/case_law/34532-dickinson-frozen-foods-v-fps-food-process-sols-corp"
                    >
                      Dickinson Frozen Foods v. FPS Food Process Sols. Corp.
                    </a>
                  </div>
                  <div
                    class="result-buttons"
                  >
                    <button
                      class="edit-button"
                      style="margin-right: 1rem;"
                    >
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                        />
                      </svg>
                    </button>
                    <button
                      class="bookmark-button"
                      type="button"
                    >
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 384 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div
                  class="case-digest-info"
                >
                  <div
                    class="decision-cite"
                  >
                    2021 WL 2444157 (D. Idaho 2021)
                  </div>
                  <div
                    class="separator"
                  />
                  <div
                    class="decision-cite"
                  >
                    June 15, 2021
                  </div>
                  <div
                    class="separator"
                  />
                  <a
                    class="decision-cite judge-link"
                    href="/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=Nye, David C."
                  >
                    Nye, David C.
                    ,
                     United States District Judge
                  </a>
                </div>
                <div
                  class="tags"
                >
                  <a
                    class="tag btn btn-flat"
                    href="/case_law?tag_ids_query=1"
                  >
                    Issue #1
                  </a>
                  <a
                    class="tag btn btn-flat"
                    href="/case_law?tag_ids_query=2"
                  >
                    Issue #2
                  </a>
                </div>
                <div
                  class="preview"
                />
              </div>
              <div
                class="page-buttons"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="search-drop-down-container"
      id="drop-down"
      role="tooltip"
      style="position: fixed; top: 0px; left: 0px; display: none;"
    >
      <div
        class="drop-down"
      >
        <li
          aria-disabled="false"
          class="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
          role="menuitem"
          tabindex="-1"
        >
          <div
            class="tag-option "
          >
            <span
              style="font-weight: 500;"
            >
              Issue
            </span>
            <span
              style="font-weight: 400;"
            >
               
            </span>
            <span
              style="font-weight: 500;"
            >
              #2
            </span>
          </div>
          <span
            class="MuiTouchRipple-root"
          />
        </li>
      </div>
    </div>
  </body>,
  "container": <div>
    <div
      class="case-law-header"
    >
      <a
        class="mobile-header-back"
        href="/case_law"
      />
      <div
        class="mobile-header-cases"
      >
        1 cases found
      </div>
      <div
        class="mobile-search-open"
      />
    </div>
    <div
      class="react-search-results "
    >
      <form
        class="search-fields  "
      >
        <div
          class="search-fields-inner "
        >
          <div
            class="search-field"
          >
            <div
              class="flex-container"
            >
              <p
                class="search-field-name"
              >
                Issue Tags
              </p>
              <div
                class="eda-search-container"
              >
                <div
                  class="input-container"
                >
                  <textarea
                    autocomplete="off"
                    class="search-input"
                    id="search-input"
                    placeholder="Start typing issues or click on + sign to select issues"
                    spellcheck="false"
                  >
                    Issue #1 AND Issue #2
                  </textarea>
                  <div
                    class="overlay"
                  >
                    Issue #1 
                    <span
                      class="selected-value"
                    >
                      AND
                    </span>
                     Issue #2
                  </div>
                </div>
                <span
                  class="clear-tags"
                >
                  ✕
                </span>
                <button
                  class="search-tags-button"
                  data-testid="search-tags"
                  type="button"
                >
                  <svg
                    fill="currentColor"
                    height="1em"
                    stroke="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                    />
                  </svg>
                </button>
              </div>
              <div
                class="suggestions"
              >
                Try:  
                <a
                  class="tag"
                  href="#"
                >
                  Spoliation
                </a>
                <a
                  class="tag"
                  href="#"
                >
                  Sanctions
                </a>
                <a
                  class="tag"
                  href="#"
                >
                  Proportionality
                </a>
              </div>
              <div
                class="react-search-tips"
              >
                <div
                  class="tips-title"
                >
                  Boolean Search Tips:
                </div>
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  AND
                </button>
                 
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  OR
                </button>
                 
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  NOT
                </button>
                 
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  (
                </button>
                 
                <button
                  class="btn btn-flat tag"
                  data-place="bottom"
                  type="button"
                >
                  )
                </button>
              </div>
            </div>
            <div
              class="include-unpublished-label selected"
            >
              <svg
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"
                />
              </svg>
              Include Unpublished Decisions
            </div>
          </div>
          <div
            class="search-fields-extra"
          >
            <div
              class="search-field"
            >
              <div
                class="flex-container"
              >
                <div
                  class="case-search"
                >
                  <input
                    placeholder="Search Terms"
                    type="text"
                    value=""
                  />
                  <svg
                    fill="currentColor"
                    height="1em"
                    stroke="currentColor"
                    stroke-width="0"
                    viewBox="0 0 16 16"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
                      fill-rule="evenodd"
                    />
                  </svg>
                  <span
                    style="flex: 1;"
                  />
                </div>
                <div
                  class="react-search-tips"
                >
                  <button
                    class="btn btn-flat tag"
                    type="button"
                  >
                    AND
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    type="button"
                  >
                    OR
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    type="button"
                  >
                    NOT
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    type="button"
                  >
                    ( )
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    type="button"
                  >
                    “ ”
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    type="button"
                  >
                    ?
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    type="button"
                  >
                    *
                  </button>
                   
                  <button
                    class="btn btn-flat tag"
                    type="button"
                  >
                    w/
                  </button>
                </div>
              </div>
            </div>
            <div
              class="search-field"
            >
              <div
                class="flex-container"
              >
                <div
                  class="search-tags"
                >
                  <div
                    aria-expanded="false"
                    aria-haspopup="listbox"
                    aria-owns="react-autowhatever-1"
                    class="react-autosuggest__container"
                    role="combobox"
                  >
                    <input
                      aria-autocomplete="list"
                      aria-controls="react-autowhatever-1"
                      autocomplete="off"
                      class="react-autosuggest__input"
                      placeholder="Select Jurisdiction(s)"
                      type="text"
                      value=""
                    />
                    <div
                      class="react-autosuggest__suggestions-container"
                      id="react-autowhatever-1"
                      role="listbox"
                    />
                  </div>
                  <button
                    class="jurisdiction-button"
                    type="button"
                  >
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="search-field"
            >
              <div
                class="flex-container"
              >
                <div
                  class="search-dates"
                >
                  <div
                    class="datepickers"
                  >
                    <div>
                      <div
                        class="react-datepicker-wrapper"
                      >
                        <div
                          class="react-datepicker__input-container"
                        >
                          <input
                            class="search-datepicker"
                            placeholder="Start Date"
                            type="text"
                            value=""
                          />
                        </div>
                      </div>
                      
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 448 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                        />
                      </svg>
                    </div>
                    <div>
                      <div
                        class="react-datepicker-wrapper"
                      >
                        <div
                          class="react-datepicker__input-container"
                        >
                          <input
                            class="search-datepicker"
                            placeholder="End Date"
                            type="text"
                            value=""
                          />
                        </div>
                      </div>
                      
                      <svg
                        fill="currentColor"
                        height="1em"
                        stroke="currentColor"
                        stroke-width="0"
                        viewBox="0 0 448 512"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="search-field"
            >
              <div
                class="flex-container"
              >
                <div
                  class="search-case-title"
                >
                  <div
                    class="search-case-title-input"
                  >
                    <input
                      placeholder="Case Title"
                      type="text"
                      value=""
                    />
                    <span
                      style="flex: 1;"
                    />
                  </div>
                  <div
                    class="react-search-tips"
                  >
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      AND
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      OR
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      NOT
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      ( )
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      “ ”
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      ?
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      *
                    </button>
                     
                    <button
                      class="btn btn-flat tag"
                      type="button"
                    >
                      w/
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="search-field"
            >
              <div
                class="flex-container"
              >
                <div
                  class="search-judge"
                >
                  <div
                    class="search-judge-input"
                  >
                    <input
                      placeholder="Add Judge"
                      type="text"
                      value=""
                    />
                    <span
                      style="flex: 1;"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="btn btn-flat search-button "
          type="submit"
        >
          Search
        </button>
      </form>
      <div
        class="results-container"
      >
        <div
          class="results"
        >
          <div
            class="header"
          >
            <div
              class="header-left"
            >
              <div
                class="header-title"
              >
                1 cases found
              </div>
              <ul
                class="rc-menu options-item rc-menu-root rc-menu-horizontal"
                role="menu"
                tabindex="0"
              >
                <li
                  class="rc-menu-submenu rc-menu-submenu-horizontal rc-menu-overflowed-submenu"
                  role="menuitem"
                  style="display: none;"
                >
                  <div
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="rc-menu-submenu-title"
                  >
                    <span>
                      ···
                    </span>
                    <i
                      class="rc-menu-submenu-arrow"
                    />
                  </div>
                </li>
                <li
                  class="rc-menu-submenu rc-menu-submenu-horizontal settings"
                  role="menuitem"
                >
                  <div
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="rc-menu-submenu-title"
                  >
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 3 16"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 2.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zm0 5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0zM1.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                        fill-rule="evenodd"
                      />
                    </svg>
                    <i
                      class="rc-menu-submenu-arrow"
                    />
                  </div>
                </li>
                <li
                  class="rc-menu-submenu rc-menu-submenu-horizontal rc-menu-overflowed-submenu"
                  role="menuitem"
                  style="visibility: hidden; position: absolute; display: none;"
                >
                  <div
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="rc-menu-submenu-title"
                  >
                    <span>
                      ···
                    </span>
                    <i
                      class="rc-menu-submenu-arrow"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="header-buttons"
            >
              <div
                class="results-sort-container"
              >
                <div
                  class="results-sort tag"
                >
                  Sort By:
                  <select>
                    <option
                      value="relevance"
                    >
                      Relevance
                    </option>
                    <option
                      value="des_decision_date"
                    >
                      Date descending
                    </option>
                    <option
                      value="asc_decision_date"
                    >
                      Date ascending
                    </option>
                  </select>
                </div>
                <div
                  class="results-sort tag"
                >
                  Results/Page:
                  <select>
                    <option
                      value="25"
                    >
                      25
                    </option>
                    <option
                      value="50"
                    >
                      50
                    </option>
                    <option
                      value="100"
                    >
                      100
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="separator"
              />
              <div
                class="results-view-select"
              >
                <button
                  class="tag "
                  type="button"
                >
                  <svg
                    fill="currentColor"
                    height="1em"
                    stroke="currentColor"
                    stroke-width="0"
                    viewBox="0 0 384 512"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                    />
                  </svg>
                  Map View
                </button>
                <button
                  class="tag active"
                  type="button"
                >
                  <svg
                    fill="currentColor"
                    height="1em"
                    stroke="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
                    />
                  </svg>
                  List View
                </button>
              </div>
            </div>
          </div>
          <div
            class="cite-container"
          >
            <div
              class="all-results-button false"
            >
              Add
               Result 
              to Cite List
            </div>
            <div
              class="all-results-button false"
            >
              Save Search as Dynamic Cite List
            </div>
          </div>
          <div>
            <div
              class="case-digest-preview flex-container"
              data-digest-id="34532"
            >
              <div
                class="decision-title"
              >
                <div
                  class="decision-name"
                >
                  <a
                    href="/case_law/34532-dickinson-frozen-foods-v-fps-food-process-sols-corp"
                  >
                    Dickinson Frozen Foods v. FPS Food Process Sols. Corp.
                  </a>
                </div>
                <div
                  class="result-buttons"
                >
                  <button
                    class="edit-button"
                    style="margin-right: 1rem;"
                  >
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
                      />
                    </svg>
                  </button>
                  <button
                    class="bookmark-button"
                    type="button"
                  >
                    <svg
                      fill="currentColor"
                      height="1em"
                      stroke="currentColor"
                      stroke-width="0"
                      viewBox="0 0 384 512"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                class="case-digest-info"
              >
                <div
                  class="decision-cite"
                >
                  2021 WL 2444157 (D. Idaho 2021)
                </div>
                <div
                  class="separator"
                />
                <div
                  class="decision-cite"
                >
                  June 15, 2021
                </div>
                <div
                  class="separator"
                />
                <a
                  class="decision-cite judge-link"
                  href="/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=Nye, David C."
                >
                  Nye, David C.
                  ,
                     United States District Judge
                </a>
              </div>
              <div
                class="tags"
              >
                <a
                  class="tag btn btn-flat"
                  href="/case_law?tag_ids_query=1"
                >
                  Issue #1
                </a>
                <a
                  class="tag btn btn-flat"
                  href="/case_law?tag_ids_query=2"
                >
                  Issue #2
                </a>
              </div>
              <div
                class="preview"
              />
            </div>
            <div
              class="page-buttons"
            />
          </div>
        </div>
      </div>
    </div>
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;
