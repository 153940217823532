import React from 'react';

export default function PaymentHistoryModal({ togglePaymentHistoryModalVisible, payments }) {
  return (
    <div className="modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Payment History
              <button
                type="button"
                className="button-clear pull-right"
                onClick={togglePaymentHistoryModalVisible}
              >
                ✕
              </button>
            </h4>
          </div>
          <div className="modal-body">
            <div className="history">
              <div className="history-column">
                <div className="name">DATE</div>
                { payments.map((payment) => <div key={`${payment.date + payment.amount}`} className="history-data">{payment.date}</div>) }
              </div>
              <div className="history-column">
                <div className="name">AMOUNT</div>
                { payments.map((payment) => <div key={`${payment.date + payment.amount}`} className="history-data">{payment.amount}</div>) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
