import React from 'react';

export default function SharedTo({ getSharedWithList, openShareDialog }) {
  const shares = getSharedWithList();

  if (shares.length === 0) return null;
  return (
    <button type="button" className="btn btn-shared-to" onClick={openShareDialog} data-testid="open-share">
      Shared with
      {' '}
      {shares.length}
      {' '}
      member
      {shares.length === 1 ? '' : 's'}
    </button>
  );
}
