// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="header"
>
  <a
    href="/media_category/17"
  >
    <svg
      fill="none"
      height="1em"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      style={
        Object {
          "color": undefined,
        }
      }
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        points="15 18 9 12 15 6"
      />
    </svg>
  </a>
  <h2>
    Soapbox
  </h2>
</div>
`;

exports[`renders correctly without props 1`] = `null`;
