import React from 'react';
import {
  FaBook, FaFile, FaMicrophone, FaRegNewspaper,
} from 'react-icons/all';

export const renderThumbnail = (data, videoUrl) => {
  if (!data) return <div />;

  let thumbnail;
  switch (data.type) {
    case 'Video':
      thumbnail = <img alt="thumbnail" src={videoUrl} />;
      break;
    case 'Post':
      thumbnail = data.post_photo.url ? <img alt="thumbnail" src={data.post_photo.url} /> : <FaRegNewspaper />;
      break;
    case 'Article':
      thumbnail = data.post_header_image.url ? <img alt="thumbnail" src={data.post_header_image.url} /> : <FaRegNewspaper />;
      break;
    case 'Audio':
      thumbnail = <FaMicrophone />;
      break;
    case 'Document':
      thumbnail = <FaFile />;
      break;
    case 'Ebook':
      thumbnail = <FaBook />;
      break;
    default:
      thumbnail = <div />;
  }

  return thumbnail;
};
