import React, { useState } from 'react';
import api from '../../../services/api';

export default function NameEditModal({ citeList, fetchCiteLists, closeModal }) {
  const [name, setName] = useState('');

  const editCiteListName = () => {
    if (name === '') return;

    api.cite_lists.update(citeList.id, { name }).then(() => {
      closeModal();
      fetchCiteLists();
    });
  };

  return (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Edit Cite List
            </h4>
          </div>
          <div className="modal-body">
            <input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
            <div className="button-row">
              <div className="modal-button" onClick={() => editCiteListName()}>
                Save Changes
              </div>
              <div className="modal-button modal-button-outline" onClick={closeModal}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
