import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render, screen } from '@testing-library/react';

import UserLicenses from '../../../admin/users/UserLicenses';
import api from '../../../../services/api';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });
api.admin.user_licenses.get = jest.fn().mockResolvedValue({
  status: 'OK',
  data: {
    user_licenses: [{
      id: 1,
      user: {
        id: 1,
        first_name: 'Name',
        last_name: 'Surname',
        email: 'email@gmail.com',
        account: {
          id: 1,
          name: 'Account Name',
          account_type: 'individual',
        },
      },
      subscription: {
        plan: {
          name: 'Individual',
        },
        effective_from: '2023-08-07',
        effective_to: '2024-08-08',
        status: 'active',
      },
    }],
    totalCount: 1000,
    activeCount: 500,
    totalPages: 10,
    currentPage: 1,
  },
});

const accountTypes = [
  { value: 'individual', lablel: 'Individual' },
  { value: 'free_trial', lablel: 'Trial' },
  { value: 'educational', lablel: 'Educational' },
];
const plans = [
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 12900,
    id: 9,
    interval: 'monthly',
    name: 'Monthly Subscription',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay1n6YGaEucre',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
  {
    active: true,
    created_at: '2019-08-20T18:08:22.597-06:00',
    default_amount: 0,
    id: 6,
    interval: 'none',
    name: 'Trial',
    plan_type: 'trial',
    stripe_plan_id: null,
    updated_at: '2019-08-20T18:08:22.597-06:00',
  },
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 118800,
    id: 10,
    interval: 'yearly',
    name: 'Annual Subscription',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay32J3e0wSmIZ',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
];

it('renders correctly with props', () => {
  const tree = renderer.create(<UserLicenses accountTypes={accountTypes} plans={plans} />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<UserLicenses />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders payments history with props', async () => {
  render(<UserLicenses accountTypes={accountTypes} plans={plans} />);
  const filtersButton = document.getElementsByClassName('filters-button ');
  expect(filtersButton.length).toBe(1);
  fireEvent.click(filtersButton[0]);
  const sort = await screen.findByText(/User Name/i);
  fireEvent.click(sort);
});
