// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  style={
    Object {
      "height": "100%",
      "width": "100%",
    }
  }
/>
`;
