import React from 'react';
import renderer from 'react-test-renderer';
import api from '../../../services/api';

import Dashboard from '../../learning_center/Dashboard';
import {
  currentUser, isAdmin, tags, topics, mediaItems,
} from '../__mocks__/lc-data';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });
api.media_items.vimeo_thumbnail = jest.fn().mockResolvedValue({ thumbnail_url: '' });

it('renders correctly with props', () => {
  const tree = renderer.create(
    <Dashboard
      currentUser={currentUser}
      isAdmin={isAdmin}
      tags={tags}
      topics={topics}
      recentItems={mediaItems}
      popularItems={mediaItems}
    />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly with props', () => {
  const tree = renderer.create(
    <Dashboard />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});
