// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Checklists renders correctly with props 1`] = `
<div
  className="react-checklists"
>
  <div
    className="mobile-search-open"
    onClick={[Function]}
  />
  <div
    className="left "
  >
    <div
      className="title"
    >
      Checklists & Forms
    </div>
    <div
      className="description"
    >
      The checklists and forms included here are intended to support planning, conducting, and analyzing the results of your electronic discovery efforts.
    </div>
    <div
      className="search"
    >
      <div
        aria-expanded={false}
        aria-haspopup="listbox"
        aria-owns="react-autowhatever-1"
        className="react-autosuggest__container"
        role="combobox"
      >
        <input
          aria-activedescendant={null}
          aria-autocomplete="list"
          aria-controls="react-autowhatever-1"
          autoComplete="off"
          className="react-autosuggest__input"
          onBlur={[Function]}
          onChange={[Function]}
          onFocus={[Function]}
          onKeyDown={[Function]}
          placeholder="Search checklists and forms"
          type="text"
          value=""
        />
        <div
          className="react-autosuggest__suggestions-container"
          id="react-autowhatever-1"
          role="listbox"
        />
      </div>
      <svg
        fill="currentColor"
        height="1em"
        stroke="currentColor"
        strokeWidth="0"
        style={
          Object {
            "color": undefined,
          }
        }
        viewBox="0 0 16 16"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7 13.3l-3.81-3.83A5.93 5.93 0 0 0 13 6c0-3.31-2.69-6-6-6S1 2.69 1 6s2.69 6 6 6c1.3 0 2.48-.41 3.47-1.11l3.83 3.81c.19.2.45.3.7.3.25 0 .52-.09.7-.3a.996.996 0 0 0 0-1.41v.01zM7 10.7c-2.59 0-4.7-2.11-4.7-4.7 0-2.59 2.11-4.7 4.7-4.7 2.59 0 4.7 2.11 4.7 4.7 0 2.59-2.11 4.7-4.7 4.7z"
          fillRule="evenodd"
        />
      </svg>
    </div>
    <div
      className="checklists-navigator"
    >
      <div
        className="category-list"
      >
        <div
          className="category selected"
          onClick={[Function]}
        >
          <div
            className="name"
          >
            All
          </div>
          <div
            className="item-count"
          >
            1
          </div>
        </div>
        <div
          className="category"
          onClick={[Function]}
        >
          <div
            className="name"
          >
            Getting Started
          </div>
          <div
            className="item-count"
          >
            7
          </div>
        </div>
        <div
          className="category"
          onClick={[Function]}
        >
          <div
            className="name"
          >
            Identifying Relevant Sources of ESI 
          </div>
          <div
            className="item-count"
          >
            8
          </div>
        </div>
        <div
          className="category"
          onClick={[Function]}
        >
          <div
            className="name"
          >
            Key Discovery Forms/Agreements
          </div>
          <div
            className="item-count"
          >
            7
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="right"
  >
    <div
      className="right-content"
    >
      <div>
        <div
          className="checklist-list"
        >
          <div
            className="title"
          >
            All
            <span
              className="selected-category-count"
            >
              1
            </span>
          </div>
          <div
            className="results"
          >
            <a
              className="result"
              href="checklists/3"
            >
              <div
                className="top"
              >
                <div
                  className="result-type"
                >
                  Checklists
                </div>
                <div
                  className="result-title"
                >
                  eDiscovery Metrics to Collect for a Matter 
                </div>
              </div>
              <div
                className="result-buttons"
              >
                <svg
                  className="bookmark"
                  fill="currentColor"
                  height="1em"
                  onClick={[Function]}
                  stroke="currentColor"
                  strokeWidth="0"
                  style={
                    Object {
                      "color": undefined,
                    }
                  }
                  viewBox="0 0 384 512"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
