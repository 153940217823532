import React from 'react';
import '@testing-library/jest-dom';
import renderer from 'react-test-renderer';
import {
  render, screen, act, waitFor,
} from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import AddToCiteListSection from '../../cite_lists/AddToCiteListSection';
import api from '../../../services/api';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({}) });
api.cite_lists.get = jest.fn().mockResolvedValue({
  message: [
    {
      id: 2313,
      name: 'Cite List from DB',
      cite_list_cases: [{ case_digest_id: 16542 }],
      user: {
        id: 1112,
      },
      cases_count: 1,
    },
    {
      id: 2314,
      name: 'Dynamic Cite List from DB',
      cite_list_cases: [],
      user: {
        id: 1112,
      },
      cases_count: 0,
      search_count: 2000,
      search_query: '?q=example_of_search_query',
    },
  ],
});
api.cite_lists.post = jest.fn().mockResolvedValue({
  cite_list: {
    id: 2316,
    name: 'newCiteList',
    cite_list_cases: [
      { case_digest_id: 1 },
      { case_digest_id: 2 },
      { case_digest_id: 3 },
    ],
    user: {
      id: 1112,
    },
    cases_count: 3,
  },
});
api.cite_list_cases.mass_assign = jest.fn().mockResolvedValue({
  message: {
    added_digests: [1, 2, 3],
    conflicting_digests: [],
    cite_list_name: 'Target List',
  },
});

describe('AddToCiteListModal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<AddToCiteListSection closeSection={() => {}} caseDigestIds={[]} />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('correctly interacts with modal', async () => {
    await act(async () => {
      render(<AddToCiteListSection closeSection={() => {}} caseDigestIds={[]} />);
    });

    await act(async () => {
      userEvent.click(screen.getByText('Add New'));
    });

    const inputName = screen.getByPlaceholderText('Enter Cite List Name');
    expect(inputName).toBeInTheDocument();

    const createButton = screen.getByText('Create Cite List');
    expect(createButton).toHaveAttribute('disabled');

    await act(async () => {
      userEvent.type(inputName, 'test');
    });

    await waitFor(() => {
      expect(createButton).not.toHaveAttribute('disabled');
    });

    await act(async () => {
      userEvent.click(createButton);
    });

    // Wait for the API call and any resulting state updates
    await waitFor(() => {
      expect(api.cite_lists.post).toHaveBeenCalled();
    });
  });

  it('correctly interacts with add to cite list', async () => {
    await act(async () => {
      render(<AddToCiteListSection closeSection={() => {}} caseDigestIds={[1, 2, 3]} />);
    });

    const addButton = screen.getAllByText('Add All')[0];
    await waitFor(() => {
      expect(addButton).toBeInTheDocument();
    });

    await act(async () => {
      userEvent.click(addButton);
    });

    const successMessage = screen.getByTestId('test-message');
    expect(successMessage).toBeInTheDocument();
    expect(successMessage).toHaveClass('success');
  });
});
