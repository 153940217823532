import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import RelatedCaseDigest from '../../related_items/RelatedCaseDigest';

const relatedCase = {
  id: 1,
  pdf: 'pdf.pdf',
  published_revision: {
    tag_list: ['tag A', 'tag B'],
    case_digest_id: 1,
    case_title: 'This is a case title',
    cite: 'This is a case cite',
    judge: 'This is a judge',
    judge_type: 'Judge Type',
  },
};

function createNodeMock(element) {
  const firstLink = document.createElement('a');
  const secondLink = document.createElement('a');
  if (element.type === 'div') {
    return {
      querySelectorAll: () => [firstLink, secondLink],
      getBoundingClientRect: () => ({
        bottom: 153.33333206176758,
        height: 28.11111068725586,
        left: 573.8194580078125,
        right: 1589.9027709960938,
        top: 125.22222137451172,
        width: 1016.083312988281,
        x: 573.8194580078125,
        y: 125.22222137451172,
      }),
    };
  }

  return null;
}

it('renders correctly with props', () => {
  const tree = renderer.create(<RelatedCaseDigest
    relatedCase={relatedCase}
  />, { createNodeMock }).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly with props', () => {
  render(<RelatedCaseDigest
    relatedCase={relatedCase}
    onDownloadPdf={() => {}}
    onAddToList={() => {}}
    onUnauthenticated={() => {}}
  />);

  userEvent.click(screen.queryByText('To List'));
  userEvent.click(screen.queryByText('PDF'));
  userEvent.click(screen.queryByText('This is a judge'));
});
