import React from 'react';
import PropTypes from 'prop-types';
import {
  FaMapMarkerAlt, FaList,
} from 'react-icons/fa';
import {
  GoChevronRight,
  GoKebabVertical,
} from 'react-icons/all';
import Menu, { MenuItem, SubMenu } from 'rc-menu';
import Map from './Map';
import AddToCiteListSection from '../../cite_lists/AddToCiteListSection';
import AddDynamicCiteListSection from '../../cite_lists/AddDynamicCiteListSection';
import DynamicCiteListNotAvailableModal from '../../search_modal/DynamicCiteListNotAvailableModal';
import api from '../../../services/api';
import VideoModal from '../../video/VideoModal';
import BulkModal from './BulkModal';
import CaseDigestPreview from '../../case_digest/CaseDigestPreview';

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'list',
      mapType: 'circuit',
      citeModalOpen: false,
      dynamicCiteModalOpen: false,
      citeModalResult: null,
      citeListNotAvailableModalOpen: false,
      clickedId: -1,
      videoModalOpen: false,
      videoModalResult: null,
      bulkModalOpen: false,
      favorites: props.favorites,
    };
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const _clickedId = urlSearchParams.get('clickedId');
    const id = parseInt(_clickedId);
    if (!Number.isNaN(id)) {
      document.querySelector(`[data-digest-id="${id}"]`)?.scrollIntoView();
    }

    this.setState({ clickedId: Number.isNaN(id) ? -1 : id });
  }

  updateFavorites = () => {
    api.favorites.get().then((result) => {
      this.setState({ favorites: result.favorites });
    });
  };

  setResultsView = (view) => {
    this.setState({ view });
  };

  setMapType = (type) => {
    this.setState({ mapType: type });
  };

  _closeModal = ({ citeModalOpen = false }) => {
    this.setState({
      citeModalOpen,
      citeModalResult: null,
      videoModalOpen: false,
      videoModalResult: null,
    });
  };

  _openCiteModal = (e, result) => {
    e.stopPropagation();
    this.setState({ citeModalOpen: true, citeModalResult: result });
  };

  _openDynamicCiteModal = (e) => {
    e.stopPropagation();
    if (this.props.hasDynamicAccess) {
      this.setState({ dynamicCiteModalOpen: true });
    } else {
      this.setState({ citeListNotAvailableModalOpen: true });
    }
  };

  _toggleBulkModal = () => {
    const { bulkModalOpen } = this.state;
    this.setState({ bulkModalOpen: !bulkModalOpen });
  };

  _renderCiteModal = (result) => (
    <div className="side-section-container-float">
      <AddToCiteListSection
        caseDigestIds={result}
        closeSection={this._closeModal}
      />
    </div>
  );

  _renderDynamicCiteModal = () => (
    <div className="side-section-container-float">
      <AddDynamicCiteListSection
        closeSection={() => this.setState({ dynamicCiteModalOpen: false })}
      />
    </div>
  );

  _pushClickHistoryAndNavigateToDigest = (e, result) => {
    this.setState({ clickedId: result.digest.id });
    console.log(e.target.innerText);
    const _url = new URL(window.location.href);
    _url.searchParams.set('clickedId', result.digest.id);
    window.history.pushState('', '', _url);
    const trimmedQuery = window.location.href.split('case_law?')[1].split('&')[0];
    if (e.target.tagName === 'HIGHLIGHT') {
      window.location.href = `/case_law/${result.slug}?${trimmedQuery}&highlighted=${e.target.innerText}`;
    } else {
      window.location.href = `/case_law/${result.slug}?${trimmedQuery}`;
    }
  };

  _renderVideoModal = (result) => (
    <VideoModal video={result} closeModal={this._closeModal} />
  );

  _openVideoModal = (video) => {
    this.setState({ videoModalOpen: true, videoModalResult: video });
  };

  _toggleFavorite = (item) => {
    const { favorites } = this.state;
    const isFavorite = favorites.case_digests !== undefined && favorites.case_digests.some((c) => c.id === item.digest.id);
    if (isFavorite) {
      api.case_digests.unfavorite(item.digest.id).then(() => this.updateFavorites());
    } else {
      api.case_digests.favorite(item.digest.id).then(() => this.updateFavorites());
    }
  };

  renderListView() {
    const { results, search, isAdmin } = this.props;
    const { clickedId, favorites } = this.state;

    return (
      <div>
        { results.case_digests.length === 0 && (
        <div className="placeholder">
          There are no available cases found.
          <br />
          Select a filter on the left to start.
          <div className="empty-image" />
        </div>
        )}

        {results.case_digests.map((result) => (
          <CaseDigestPreview
            caseDigest={result}
            isAdmin={isAdmin}
            clickedId={clickedId}
            onSelect={(e) => this._pushClickHistoryAndNavigateToDigest(e, result)}
            onClick={(e) => e.preventDefault()}
            openVideoModal={() => this._openVideoModal(result.digest?.video_preview)}
            openCiteModal={this._openCiteModal}
            hasEditButton
            hasUnpublishedDecision
            hasClickableTagList
            isFavorite={favorites?.case_digests !== undefined && favorites?.case_digests.some(
              (item) => item.id === result.digest.id,
            )}
            onToggleFavorite={() => this._toggleFavorite(result)}
          />
        ))}
        <div className="page-buttons">
          {(results.current_page > 1) && (
          <div className="previous" onClick={() => search(results.current_page - 1)}>Previous</div>
          )}
          {(results.court_counts.total_count > 25
                      && results.current_page < (Math.floor(results.court_counts.total_count / 25) + 1) && (
                      <div className="next" onClick={() => search(results.current_page + 1)}>Next</div>
          ))}
        </div>

      </div>
    );
  }

  renderMapView() {
    const {
      results, federalCourts, stateCourts, selectedJurisdictions,
    } = this.props;
    const { mapType } = this.state;
    return <Map type={mapType} data={results.court_counts} federalCourts={federalCourts} stateCourts={stateCourts} results selectedJurisdictions={selectedJurisdictions} />;
  }

  render() {
    const {
      results, allResultIds, isAdmin,
      onPerPage, resultsPerPage, sortBy, onSort,
    } = this.props;
    const { view, mapType } = this.state;
    return (
      <div className="results">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              { `${results.court_counts.total_count} cases found` }
            </div>
            { isAdmin && results.case_digests.length > 0 && (
              <Menu
                mode="horizontal"
                triggerSubMenuAction="click"
                expandIcon={<GoChevronRight style={{ verticalAlign: 'middle' }} />}
                className="options-item"
              >
                <SubMenu title={<GoKebabVertical />} className="settings" popupClassName="settings-submenu">
                  <MenuItem className="settings-item">
                    <div className="options-item" onClick={() => this._toggleBulkModal()}>
                      Bulk tag cases
                    </div>
                  </MenuItem>
                </SubMenu>
              </Menu>
            )}
          </div>
          <div className="header-buttons">
            { view === 'list' && (
              <div className="results-sort-container">
                <div className="results-sort tag">
                  Sort By:
                  <select
                    value={sortBy}
                    onChange={(e) => onSort(e.target.value)}
                  >
                    <option value="relevance">Relevance</option>
                    <option value="des_decision_date">Date descending</option>
                    <option value="asc_decision_date">Date ascending</option>
                  </select>
                </div>
                <div className="results-sort tag">
                  Results/Page:
                  <select
                    value={resultsPerPage}
                    onChange={(e) => onPerPage(e.target.value)}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            )}

            { view === 'map' && (
              <div className="mobile-switch">
                <button
                  type="button"
                  className={`tag ${mapType === 'circuit' ? 'active' : ''}`}
                  onClick={() => this.setMapType('circuit')}
                >
                  Federal
                </button>
                <div className="react-tab-separator" />
                <button
                  type="button"
                  className={`tag ${mapType === 'state' ? 'active' : ''}`}
                  onClick={() => this.setMapType('state')}
                >
                  State
                </button>
              </div>
            )}

            <div className="separator" />

            <div className="results-view-select">
              {/* <button */}
              {/*  type="button" */}
              {/*  className={`tag ${view === 'issues' ? 'active' : ''}`} */}
              {/*  onClick={() => this.setResultsView('issues')} */}
              {/* > */}
              {/*  Issues View */}
              {/*  <FaTag /> */}
              {/* </button> */}

              <button
                type="button"
                className={`tag ${view === 'map' ? 'active' : ''}`}
                onClick={() => this.setResultsView('map')}
              >
                <FaMapMarkerAlt />
                Map View
              </button>

              <button
                type="button"
                className={`tag ${view === 'list' ? 'active' : ''}`}
                onClick={() => this.setResultsView('list')}
              >
                <FaList />
                List View
              </button>
            </div>
          </div>
        </div>
        <div className="cite-container">
          <div
            className={`all-results-button ${results.case_digests.length === 0 && 'disabled'}`}
            onClick={(e) => this._openCiteModal(e, allResultIds)}
          >
            Add
            { (allResultIds && allResultIds.length) > 1 ? ' All Results ' : ' Result ' }
            to Cite List
          </div>
          <div
            className={`all-results-button ${results.case_digests.length === 0 && 'disabled'}`}
            onClick={(e) => this._openDynamicCiteModal(e)}
          >
            Save Search as Dynamic Cite List
          </div>
        </div>
        { view === 'map' && this.renderMapView() }
        { view === 'list' && this.renderListView() }
        { this.state.citeModalOpen && this._renderCiteModal(this.state.citeModalResult) }
        { this.state.dynamicCiteModalOpen && this._renderDynamicCiteModal() }
        { this.state.videoModalOpen && this._renderVideoModal(this.state.videoModalResult) }
        { this.state.bulkModalOpen && <BulkModal closeModal={this._toggleBulkModal} tags={this.props.tags} allResultIds={allResultIds} /> }
        { this.state.citeListNotAvailableModalOpen && <DynamicCiteListNotAvailableModal closeModal={() => this.setState({ citeListNotAvailableModalOpen: false })} /> }
      </div>
    );
  }
}

Results.propTypes = {
  search: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  resultsPerPage: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  onPerPage: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  allResultIds: PropTypes.array.isRequired,
};

export default Results;
