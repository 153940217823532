import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import api from '../../../services/api';

import Checklists from '../../checklists/Checklists';

const categories = [
  { id: 3, name: 'Getting Started', count: 7 },
  { id: 11, name: 'Identifying Relevant Sources of ESI ', count: 8 },
  { id: 12, name: 'Key Discovery Forms/Agreements', count: 7 },
];

const favorites = {
  checklists: {
    id: 3,
    some: () => true,
  },
};

const checklists = [
  {
    categories: [
      {
        deleted: false,
        description: 'Checklists and templates to use when reviewing what was done, what can be done better, where the issues came up and how to leverage what you learned in the next go round. ',
        name: 'Reviewing and Evaluating Your eDiscovery Process ',
      },
    ],
    checklist_id: 3,
    description: 'This checklist includes the metrics that should be captured to best evaluate costs moving forward.',
    id: 267,
    name: 'eDiscovery Metrics to Collect for a Matter ',
    non_subscription: false,
    published: true,
  },
];

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });
api.favorites.get = jest.fn().mockResolvedValue({ favorites: [] });

describe('Checklists', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<Checklists
      categories={categories}
      checklists={checklists}
      favorites={{}}
      plan_type="trial"
    />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('correctly interacts with Checklists', () => {
    const { queryByText } = render(<Checklists
      categories={categories}
      checklists={checklists}
      favorites={favorites}
      plan_type="trial"
    />);

    userEvent.click(queryByText(/Getting started/i));
    expect(queryByText(/No results/i)).toBeInTheDocument();

    userEvent.click(queryByText(/Identify/i));
    userEvent.click(queryByText(/All/i));

    userEvent.click(document.querySelectorAll('svg')[1]);

    expect(queryByText(/No results/i)).not.toBeInTheDocument();
  });
});
