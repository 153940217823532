import React, { useState } from 'react';
import Toggle from 'react-toggle';
import Snackbar from '../../../services/snackbar';
import api from '../../../services/api';
import DynamicCiteListNotAvailableModal from '../../search_modal/DynamicCiteListNotAvailableModal';

export default function Notifications({
  caseLawEmailEnabled, dynamicCiteListsEmailEnabled, toggleCaseLawEmail, toggleDynamicCiteListEmail, hasDynamicAccess,
}) {
  const [accessModal, setAccessModal] = useState(false);

  const updateCaseLawEmailPreferences = () => {
    api.account.user.settings.put({
      settings: {
        email_preferences: {
          send_case_law_email: !caseLawEmailEnabled,
          send_dynamic_cite_list_email: dynamicCiteListsEmailEnabled,
        },
      },
    }).then(() => {
      toggleCaseLawEmail();
      Snackbar.show('Case Law Email Preferences updated');
    });
  };

  const updateDynamicCiteListsEmailPreferences = () => {
    api.account.user.settings.put({
      settings: {
        email_preferences: {
          send_case_law_email: caseLawEmailEnabled,
          send_dynamic_cite_list_email: !dynamicCiteListsEmailEnabled,
        },
      },
    }).then(() => {
      toggleDynamicCiteListEmail();
      Snackbar.show('Dynamic Cite Lists Email Preferences updated');
    });
  };

  return (
    <div className="notifications">
      <div className="notifications-column">
        <div className="notifications-desc">Receive a weekly update with the latest cases.</div>
        <Toggle icons={false} checked={caseLawEmailEnabled} onChange={updateCaseLawEmailPreferences} data-testid="toggle" />
      </div>
      <br />
      <div
        className="notifications-column"
        onClick={(e) => {
          e.stopPropagation();
          if (!hasDynamicAccess) setAccessModal(true);
        }}
      >
        <div className="notifications-desc">Receive a daily update with the latest cases in dynamic cite lists.</div>
        <Toggle
          icons={false}
          checked={hasDynamicAccess ? dynamicCiteListsEmailEnabled : false}
          onChange={updateDynamicCiteListsEmailPreferences}
          disabled={!hasDynamicAccess}
          data-testid="dynamic-toggle"
        />
      </div>
      { accessModal && <DynamicCiteListNotAvailableModal closeModal={() => setAccessModal(false)} /> }
    </div>
  );
}
