import React from 'react';
import {
  FaArrowDown, FaPlus, FaCheckSquare, FaRegSquare,
} from 'react-icons/all';
import moment from 'moment';
import {
  FaBookmark, FaPencilAlt, FaRegBookmark, FaVideo,
} from 'react-icons/fa';
import { DecisionDateFormat } from '../../services/constants';
import TagList from '../tags/TagList';

export default class CaseDigestPreview extends React.Component {
  render() {
    const {
      onAddToList, onDownloadPdf, openVideoModal, isRelated, clickedId, isAdmin, openCiteModal, caseDigest, isCaseSelected, onSelect,
      hasToCiteListButton, hasEditButton, hasViewCaseButton, hasClickableTagList, hasUnpublishedDecision, hasToListButton, hasPDFButton,
      hasTagList, isCiteList, slug, onClick, isFavorite, onToggleFavorite, isDashboard,
    } = this.props;

    const setClassName = () => {
      const caseId = caseDigest.digest?.id || caseDigest?.id;
      const viewedClass = Number(clickedId) === Number(caseId) ? ' viewed' : '';
      if (isRelated) return 'related-case-digest';
      if (isCiteList) return `case-details${viewedClass}`;
      return `flex-container${viewedClass}`;
    };

    return (
      <div
        data-digest-id={caseDigest.digest?.id}
        className={`case-digest-preview ${setClassName()}`}
        key={caseDigest.published_revision.id}
        onClick={onSelect}
      >
        { isCiteList && (
        <div className={`case-checkbox${isCaseSelected ? ' selected' : ''}`}>
          { isCaseSelected
            ? <FaCheckSquare />
            : <FaRegSquare />}
        </div>
        )}

        <div className="decision-title">
          <div className="decision-name">
            <a
              href={`/case_law/${caseDigest.slug || slug}`}
              onClick={(e) => {
                if (onClick) onClick(e);
              }}
            >
              {caseDigest.published_revision.name}
              {hasUnpublishedDecision && caseDigest.published_revision.unpublished && <span className="unpublished">&nbsp;&nbsp;Unpublished Decision</span>}
              {caseDigest.digest?.video_preview?.url && (
                <FaVideo
                  onClick={(e) => { e.stopPropagation(); e.preventDefault(); openVideoModal(); }}
                  className="video"
                />
              )}
            </a>
          </div>
          <div className="result-buttons">
            { hasEditButton && isAdmin && (
              <button
                className="edit-button"
                style={{ marginRight: '1rem' }}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`/admin/case_digests/${caseDigest.digest.id}/edit`, '_blank').focus();
                }}
              >
                <FaPencilAlt />
              </button>
            )}
            { hasViewCaseButton && (
              <>
                <button className="digest-button">
                  View Case
                </button>
                { caseDigest.digest.is_digested && (
                <button className="digest-button">
                  View Case Digest
                </button>
                )}
              </>
            )}
            { hasToCiteListButton && (
              <button className="cite-button" onClick={(e) => openCiteModal(e, [caseDigest.id])}>
                + To Cite List
              </button>
            )}
            { hasToListButton && onAddToList && (
              <button className="result-button" onClick={(e) => onAddToList(e)}>
                <FaPlus />
                To List
              </button>
            )}
            { hasPDFButton && onDownloadPdf && (
              <button
                className="result-button"
                onClick={(e) => onDownloadPdf(e)}
              >
                <FaArrowDown />
                PDF
              </button>
            )}
            <button
              className="bookmark-button"
              type="button"
              onClick={(e) => { e.stopPropagation(); onToggleFavorite(); }}
            >
              { isFavorite ? <FaBookmark /> : <FaRegBookmark />}
            </button>
          </div>
        </div>
        <div className="case-digest-info">
          <div className="decision-cite">{caseDigest.published_revision.cite}</div>
          <div className="separator" />
          <div className="decision-cite">
            {caseDigest.published_revision.decision_date && moment(caseDigest.published_revision.decision_date).format(DecisionDateFormat)}
          </div>
          {
            Boolean(caseDigest.published_revision.judge) && (
              <>
                { !isDashboard && <div className="separator" /> }
                <a className="decision-cite judge-link" href={`/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=${caseDigest.published_revision.judge}`}>
                  {caseDigest.published_revision.judge}
                  {`${caseDigest.published_revision.judge === 'Panel' && !caseDigest.published_revision.judge_type ? '' : ','}
                    \u00a0${caseDigest.published_revision.judge_type}`}
                </a>
              </>
            )
          }
        </div>
        {
          hasTagList && <TagList tags={caseDigest.published_revision.tag_list} />
        }
        {
          hasClickableTagList && <TagList tags={caseDigest.published_revision.tags} clickable />
        }
        {
          // eslint-disable-next-line
          <div className="preview" dangerouslySetInnerHTML={{ __html: caseDigest.published_revision.full_text || caseDigest.preview }} />
        }
        {
          // eslint-disable-next-line
          caseDigest.highlighted_fragment && (<div className="snippet" dangerouslySetInnerHTML={{ __html: caseDigest.highlighted_fragment }} />)
        }
        {/* { */}
        {/*    caseDigest.published_revision.is_per_curiam && ( */}
        {/*    <> */}
        {/*      Per Curiam */}
        {/*    </> */}
        {/*    ) */}
        {/*  } */}
        {/* { */}
        {/*    caseDigest.published_revision.is_panel && ( */}
        {/*    <> */}
        {/*      Panel members: */}
        {/*      {' '} */}
        {/*      {(caseDigest.published_revision.panel_members || []).map((member, idx) => ( */}
        {/*        <> */}
        {/*          <a className="cite" href={`/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=${member}`}> */}
        {/*            {member} */}
        {/*          </a> */}
        {/*          { idx < caseDigest.published_revision.panel_members.length - 1 ? ', ' : '' } */}
        {/*        </> */}
        {/*      ))} */}
        {/*    </> */}
        {/*    ) */}
        {/*  } */}
      </div>
    );
  }
}
