import React from 'react';
import renderer from 'react-test-renderer';

import DistrictRules from '../../../rules/src/DistrictRules';

// districtStates, favorites, toggleRuleBookmark, districtFilter, loading,
const favorites = {
  rules: [
    {
      id: 4994,
      created_at: '2016-01-20T23:53:39.048-07:00',
      updated_at: '2023-08-02T03:08:06.228-06:00',
      deleted: false,
      state_court_id: null,
      district_court_id: null,
      regulatory_agency_id: 5,
    },
    {
      id: 4889,
      created_at: '2013-04-30T22:28:03.746-06:00',
      updated_at: '2017-08-04T16:09:01.494-06:00',
      deleted: false,
      state_court_id: null,
      district_court_id: 57,
      regulatory_agency_id: null,
    },
  ],
};

const disrictStates = [
  {
    id: 4,
    code: 'AR',
    name: 'Arkansas',
    description: null,
    district_courts: [
      {
        id: 57,
        name: 'Eastern District of Arkansas',
        description: 'The Eastern and Western District of Arkansas share the same local rules.  The Local Rules (Civil) do not contain provisions specifically designed to address electronic discovery, but Local Rule 26.1 contains an Outline for Fed. R. Civ. P. 26(f) Report. dfsfsdgsdf',
        state_id: 4,
        abbreviation: null,
        jurisdiction: null,
        court_type: null,
        display_name: null,
        district_rules: [
          {
            id: 4889,
            deleted: false,
            state_court_id: null,
            district_court_id: 57,
            regulatory_agency_id: null,
            published_revision: {
              id: 9164,
              rule_id: 4889,
              published: true,
              number: 'Local Rule 26.1, Outline for Fed.R.Civ.P. 26(f) Report',
              title: null,
              tag_list: [{ id: 1, name: 'some' }, { id: 2, name: 'tags' }],
            },
          },
        ],
      },
    ],
  },
];

const filter = 'Eastern District of Arkansas';

it('renders correctly without props', () => {
  const tree = renderer.create(<DistrictRules
    toggleRuleBookmark={() => {}}
    loading={false}
    favorites={{}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly without props with loader', () => {
  const tree = renderer.create(<DistrictRules
    toggleRuleBookmark={() => {}}
    loading
    favorites={{}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly with props', () => {
  const tree = renderer.create(<DistrictRules
    districtStates={disrictStates}
    districtFilter={filter}
    toggleRuleBookmark={() => {}}
    loading={false}
    favorites={favorites}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
