import React from 'react';
import Item from './Item';
import Header from './Header';
import Hero from './Hero';
import TableItem from './TableItem';
import Filters from './Filters';

export default function Dashboard({
  tags, topics, currentUser, recentItems, popularItems, isAdmin, academySubscription, subscription, userSession,
}) {
  return (
    <div className="react-lc-page lc-dashboard">
      <Header currentUser={currentUser} isAdmin={isAdmin} academySubscription={academySubscription} userSession={userSession} />
      <Hero
        title="eDiscovery Academy"
        subtitle="On-demand training and education in all things ediscovery"
      >
        <Filters tags={tags} topics={topics} />
      </Hero>

      <div className="section">
        <h3>Recently Added</h3>
        <div className="items">
          {recentItems?.map((item) => (
            <Item data={item} key={item.id} isAdmin={isAdmin} isAcademy={academySubscription} subscription={subscription} />
          ))}
        </div>
      </div>

      <div className="section alt">
        <h2>Modules</h2>
        <h4>Take your professional skills to the next level with us</h4>
        <div className="modules-list">
          {topics?.map((topic) => (
            <div className="module-container" key={topic.id}>
              <div className="backdrop1" />
              <div className="backdrop2" />
              <div className="module">
                <p className="name">{topic.name}</p>
                <p className="description">
                  {topic.description || ''}
                </p>
                <a href={`/media_category/${topic.id}`}>View Module</a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="section last">
        <h3>Most Popular</h3>
        <div className="table-items">
          {popularItems?.map((item) => (
            <div className="row" key={item.id}>
              <TableItem data={item} isAdmin={isAdmin} isAcademy={academySubscription} subscription={subscription} />
              <div>{item.type}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
