// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
Array [
  <div
    className="mobile-header"
  >
    <div
      className="mobile-header-open"
      onClick={[Function]}
    />
    <div
      className="mobile-header-page"
    >
      
    </div>
  </div>,
  <div
    className="react-header "
  >
    <a
      href="/"
    >
      <img
        alt=""
        className="react-header-logo"
        src="/logo.svg"
      />
    </a>
    <div
      className="close-mobile"
      onClick={[Function]}
    />
    <div
      className="mobile-separator"
    />
    <div
      className="pages"
      data-testid="eda-header"
      onClick={[Function]}
    >
      <a
        className=""
        href="/issue_dashboard"
      >
        <div
          className="icon mobile-icon issues"
        />
        Issues
      </a>
      <a
        className=""
        href="/case_law"
      >
        <div
          className="icon mobile-icon case_digests"
        />
        Case Law
      </a>
      <a
        className=""
        href="/rules"
      >
        <div
          className="icon mobile-icon rules"
        />
        Rules
      </a>
      <a
        className=""
        href="/checklists"
      >
        <div
          className="icon mobile-icon checklists_and_forms"
        />
        Checklists & Forms
      </a>
      <a
        className=""
        href="/glossary_terms"
      >
        <div
          className="icon mobile-icon glossary_terms"
        />
        Glossary
      </a>
      <a
        href="/academy"
        target="_blank"
      >
        <div
          className="icon mobile-icon academy"
        />
        Academy
      </a>
    </div>
    <div
      className="mobile-separator"
    />
    <div
      className="user-items"
    >
      <div
        className="cite-list user-item mobile-profile"
      >
        <svg
          className="icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 496 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
          />
        </svg>
          
        <a
          href="/account/edit#profile"
        >
          Name
           
          Surename
        </a>
      </div>
      <div
        className="cite-list user-item"
      >
        <svg
          className="icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 384 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
          />
        </svg>
          
        <a
          href="/favorites"
        >
          Bookmarks
        </a>
      </div>
      <div
        className="cite-list user-item"
      >
        <svg
          className="icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 512 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
          />
        </svg>
          
        <a
          href="/history"
        >
          History
        </a>
      </div>
      <div
        className="cite-list user-item"
      >
        <svg
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 12 16"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 13c0 .59 0 1-.59 1H.59C0 14 0 13.59 0 13c0-.59 0-1 .59-1h.81c.59 0 .59.41.59 1H2zm2.59-9h6.81c.59 0 .59-.41.59-1 0-.59 0-1-.59-1H4.59C4 2 4 2.41 4 3c0 .59 0 1 .59 1zM1.41 7H.59C0 7 0 7.41 0 8c0 .59 0 1 .59 1h.81c.59 0 .59-.41.59-1 0-.59 0-1-.59-1h.01zm0-5H.59C0 2 0 2.41 0 3c0 .59 0 1 .59 1h.81c.59 0 .59-.41.59-1 0-.59 0-1-.59-1h.01zm10 5H4.59C4 7 4 7.41 4 8c0 .59 0 1 .59 1h6.81c.59 0 .59-.41.59-1 0-.59 0-1-.59-1h.01zm0 5H4.59C4 12 4 12.41 4 13c0 .59 0 1 .59 1h6.81c.59 0 .59-.41.59-1 0-.59 0-1-.59-1h.01z"
            fillRule="evenodd"
          />
        </svg>
          
        <a
          href="/cite_lists"
        >
          My Cite Lists
        </a>
      </div>
      <div>
        <div>
          <div>
            <a
              href="/account/edit#profile"
            >
              <svg
                className="icon user-icon"
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                strokeWidth="0"
                style={
                  Object {
                    "color": undefined,
                    "height": 25,
                    "width": 25,
                  }
                }
                viewBox="0 0 496 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                />
              </svg>
              Name
               
              Surename
            </a>
          </div>
          <hr />
          <div>
            <a
              onClick={[Function]}
            >
              <svg
                className="icon"
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                strokeWidth="0"
                style={
                  Object {
                    "color": undefined,
                  }
                }
                viewBox="0 0 512 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                />
              </svg>
              Send Feedback
            </a>
          </div>
          <hr />
          <div>
            <a
              href="/account/edit#profile"
            >
              <svg
                className="icon"
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                strokeWidth="0"
                style={
                  Object {
                    "color": undefined,
                  }
                }
                viewBox="0 0 496 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                />
              </svg>
              Account Settings
            </a>
          </div>
          <div>
            <div>
              <a
                href="/admin/accounts"
              >
                Accounts
              </a>
            </div>
            <div>
              <a
                href="/admin/users"
              >
                Users
              </a>
            </div>
            <div>
              <a
                href="/admin/rules"
              >
                Rules
              </a>
            </div>
            <div>
              <a
                href="/admin/case_digests"
              >
                Case Law
              </a>
            </div>
            <div>
              <a
                href="/admin/categories"
              >
                Categories
              </a>
            </div>
            <div>
              <a
                href="/admin/checklists"
              >
                Checklists
              </a>
            </div>
            <div>
              <a
                href="/admin/templates"
              >
                Forms
              </a>
            </div>
            <div>
              <a
                href="/admin/glossary_terms"
              >
                Glossary Terms
              </a>
            </div>
            <div>
              <a
                href="/admin/resources"
              >
                Resources
              </a>
            </div>
            <div>
              <a
                href="/admin/tags"
              >
                Tags
              </a>
            </div>
            <div>
              <a
                href="/admin/tips"
              >
                Tips
              </a>
            </div>
            <div>
              <a
                href="/admin/categories"
              >
                Categories
              </a>
            </div>
            <div>
              <a
                href="/admin/academy"
              >
                Academy
              </a>
            </div>
          </div>
          <div>
            <a
              data-method="delete"
              href="/logout"
              rel="nofollow"
            >
              <svg
                className="icon"
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                strokeWidth="0"
                style={
                  Object {
                    "color": undefined,
                  }
                }
                viewBox="0 0 512 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
                />
              </svg>
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      className="mobile-separator"
    />
    <div
      className="pages mobile-block"
      onClick={[Function]}
    >
      <a
        onClick={[Function]}
      >
        <svg
          className="icon mobile-icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 512 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
          />
        </svg>
        Send Feedback
      </a>
      <a
        href="/admin"
      >
        <svg
          className="icon mobile-icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 496 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
          />
        </svg>
        Admin
      </a>
      <a
        data-method="delete"
        href="/logout"
        rel="nofollow"
      >
        <svg
          className="icon mobile-icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 512 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
          />
        </svg>
        Logout
      </a>
    </div>
  </div>,
]
`;

exports[`renders correctly without props 1`] = `
Array [
  <div
    className="mobile-header"
  >
    <div
      className="mobile-header-open"
      onClick={[Function]}
    />
    <div
      className="mobile-header-page"
    >
      
    </div>
  </div>,
  <div
    className="react-header "
  >
    <a
      href="/"
    >
      <img
        alt=""
        className="react-header-logo"
        src="/logo.svg"
      />
    </a>
    <div
      className="close-mobile"
      onClick={[Function]}
    />
    <div
      className="mobile-separator"
    />
    <div
      className="pages"
      data-testid="eda-header"
      onClick={[Function]}
    >
      <a
        className=""
        href="/issue_dashboard"
      >
        <div
          className="icon mobile-icon issues"
        />
        Issues
      </a>
      <a
        className=""
        href="/case_law"
      >
        <div
          className="icon mobile-icon case_digests"
        />
        Case Law
      </a>
      <a
        className=""
        href="/rules"
      >
        <div
          className="icon mobile-icon rules"
        />
        Rules
      </a>
      <a
        className=""
        href="/checklists"
      >
        <div
          className="icon mobile-icon checklists_and_forms"
        />
        Checklists & Forms
      </a>
      <a
        className=""
        href="/glossary_terms"
      >
        <div
          className="icon mobile-icon glossary_terms"
        />
        Glossary
      </a>
      <a
        href="/academy"
        target="_blank"
      >
        <div
          className="icon mobile-icon academy"
        />
        Academy
      </a>
    </div>
    <div
      className="mobile-separator"
    />
    <div
      className="user-items"
    >
      <div
        className="cite-list user-item"
      >
        <a
          href="/login"
        >
          Login
        </a>
      </div>
    </div>
    <div
      className="mobile-separator"
    />
    <div
      className="pages mobile-block"
      onClick={[Function]}
    >
      <a
        onClick={[Function]}
      >
        <svg
          className="icon mobile-icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 512 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
          />
        </svg>
        Send Feedback
      </a>
      <a
        href="/admin"
      >
        <svg
          className="icon mobile-icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 496 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
          />
        </svg>
        Admin
      </a>
      <a
        data-method="delete"
        href="/logout"
        rel="nofollow"
      >
        <svg
          className="icon mobile-icon"
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 512 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
          />
        </svg>
        Logout
      </a>
    </div>
  </div>,
]
`;
