import PropTypes from 'prop-types';
import React from 'react';

export default function AccessModal({ closeModal }) {
  const handleClick = (e) => {
    if ($(e.target).closest('.modal-content').length > 0) return;
    closeModal();
  };

  return (
    <div className="login-modal new-modal" data-testid="modal" onClick={handleClick}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="circle" />
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            <p>
              Thank you for visiting eDiscovery Academy.
              For access to additional content and features within the Academy and eDiscovery Assistant,
              please login, create a new paid account, or contact us at
              &nbsp;
              <a href="mailto: support@ediscoveryassistant.com?subject=Access to eDiscovery Academy">support@ediscoveryassistant.com</a>
              .
            </p>
            <div className="button-row">
              <button className="modal-button">
                <a href="/registrations/new/individual" target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                  Create Account
                </a>
              </button>
              <button className="modal-button modal-button-outline">
                <a href="/login" style={{ textDecoration: 'none', color: '#343B64' }}>Login</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AccessModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
