import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';

export default function Sidebar({
  category, onClose, onAdded, onUpdated, onDeleted,
}) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [associationsCount, setAssociationsCount] = useState(0);

  useEffect(() => {
    setName(category?.name || '');
    setDescription(category?.description || '');
    setAssociationsCount(0);
  }, [category]);

  const handleAdd = () => {
    Api.admin.categories.create({ name, description }).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show('New Category added');
      if (onAdded) onAdded();
    });
  };

  const handleUpdate = () => {
    Api.admin.categories.update(category.id, { name, description }).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show('1 Category updated');
      if (onUpdated) onAdded();
    });
  };

  const handleConfirmDelete = () => {
    setAssociationsCount(0);
    Api.admin.categories.delete(category.id).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show('1 Category removed');
      if (onDeleted) onDeleted();
    });
  };

  const handleDelete = () => {
    Api.admin.categories.get(category.id).then((res) => {
      if (res.status !== 'OK') return;
      if (res.data.associations_count > 0) {
        setAssociationsCount(res.data.associations_count);
      } else {
        handleConfirmDelete();
      }
    });
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>
          { category ? 'Edit Category' : 'Add New Category' }
        </h2>
        <button type="button" className="close" onClick={onClose}><GrClose /></button>
      </div>
      <div className="sidebar-body">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Category Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Category Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        { category ? (
          <button
            type="button"
            className="button"
            disabled={!name}
            onClick={handleUpdate}
          >
            Update Category
          </button>
        ) : (
          <button
            type="button"
            className="button"
            disabled={!name}
            onClick={handleAdd}
          >
            Save Category
          </button>
        )}

        { Boolean(category) && (
          <button type="button" className="button delete" onClick={handleDelete}>
            Delete Category
          </button>
        ) }

        { associationsCount > 0 && (
          <div className="note">
            <p>Removing a category will affect all relevant records.</p>
            <p className="danger">
              There are currently
              {' '}
              {associationsCount}
              {' '}
              records have been applied with this category.
            </p>
            <p>Do you wish to proceed? This action cannot be undone.</p>
            <div className="buttons">
              <button type="button" className="button danger" onClick={handleConfirmDelete}>Yes</button>
              <button type="button" className="button" onClick={() => setAssociationsCount(0)}>No</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
