// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="results"
>
  <div
    className="header"
  >
    <div
      className="header-left"
    >
      <div
        className="header-title"
      >
        1 cases found
      </div>
    </div>
    <div
      className="header-buttons"
    >
      <div
        className="results-sort-container"
      >
        <div
          className="results-sort tag"
        >
          Sort By:
          <select
            onChange={[Function]}
            value="des_decision_date"
          >
            <option
              value="relevance"
            >
              Relevance
            </option>
            <option
              value="des_decision_date"
            >
              Date descending
            </option>
            <option
              value="asc_decision_date"
            >
              Date ascending
            </option>
          </select>
        </div>
        <div
          className="results-sort tag"
        >
          Results/Page:
          <select
            onChange={[Function]}
            value="25"
          >
            <option
              value="25"
            >
              25
            </option>
            <option
              value="50"
            >
              50
            </option>
            <option
              value="100"
            >
              100
            </option>
          </select>
        </div>
      </div>
      <div
        className="separator"
      />
      <div
        className="results-view-select"
      >
        <button
          className="tag "
          onClick={[Function]}
          type="button"
        >
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 384 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
            />
          </svg>
          Map View
        </button>
        <button
          className="tag active"
          onClick={[Function]}
          type="button"
        >
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 512 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
            />
          </svg>
          List View
        </button>
      </div>
    </div>
  </div>
  <div
    className="cite-container"
  >
    <div
      className="all-results-button false"
      onClick={[Function]}
    >
      Add
       Result 
      to Cite List
    </div>
    <div
      className="all-results-button false"
      onClick={[Function]}
    >
      Save Search as Dynamic Cite List
    </div>
  </div>
  <div>
    <div
      className="case-digest-preview flex-container"
      data-digest-id={34532}
      onClick={[Function]}
    >
      <div
        className="decision-title"
      >
        <div
          className="decision-name"
        >
          <a
            href="/case_law/34532-dickinson-frozen-foods-v-fps-food-process-sols-corp"
            onClick={[Function]}
          >
            Dickinson Frozen Foods v. FPS Food Process Sols. Corp.
          </a>
        </div>
        <div
          className="result-buttons"
        >
          <button
            className="bookmark-button"
            onClick={[Function]}
            type="button"
          >
            <svg
              fill="currentColor"
              height="1em"
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "color": undefined,
                }
              }
              viewBox="0 0 384 512"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        className="case-digest-info"
      >
        <div
          className="decision-cite"
        >
          2021 WL 2444157 (D. Idaho 2021)
        </div>
        <div
          className="separator"
        />
        <div
          className="decision-cite"
        >
          June 15, 2021
        </div>
        <div
          className="separator"
        />
        <a
          className="decision-cite judge-link"
          href="/case_law?sort_by=decision_date&page=1&results_per_page=25&judge=Nye, David C."
        >
          Nye, David C.
          ,
                     United States District Judge
        </a>
      </div>
      <div
        className="tags"
      >
        <a
          className="tag btn btn-flat"
          href="/case_law?tag_ids_query=1"
        >
          Issue #1
        </a>
        <a
          className="tag btn btn-flat"
          href="/case_law?tag_ids_query=2"
        >
          Issue #2
        </a>
      </div>
      <div
        className="preview"
        dangerouslySetInnerHTML={
          Object {
            "__html": undefined,
          }
        }
      />
    </div>
    <div
      className="page-buttons"
    />
  </div>
</div>
`;
