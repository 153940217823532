import React from 'react';
import '@testing-library/jest-dom';
import { render, screen, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import UnshareModal from '../../../cite_lists/modals/UnshareModal';

describe('UnshareModal', () => {
  it('renders correctly with props', () => {
    render(
      <UnshareModal
        selectedCiteList={{ name: 'test' }}
        membersToUnshare="test members"
        submitUnshare={() => {}}
        cancelUnshare={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('renders correctly with props', () => {
    render(
      <UnshareModal
        selectedCiteList={{ name: 'test' }}
        submitUnshare={() => {}}
        cancelUnshare={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const close = jest.fn(() => {});
    const submit = jest.fn(() => {});

    render(
      <UnshareModal
        selectedCiteList={{ name: 'test' }}
        membersToUnshare={{ name: 'test members' }}
        submitUnshare={submit}
        cancelUnshare={close}
      />,
    );

    const submitButton = screen.getByText('Yes');
    expect(submitButton).toBeInTheDocument();

    await act(async () => {
      userEvent.click(submitButton);
    });

    expect(submit).toHaveBeenCalledTimes(1);

    const closeButton = screen.getByText('No');
    expect(closeButton).toBeInTheDocument();

    await act(async () => {
      userEvent.click(closeButton);
    });

    expect(close).toHaveBeenCalledTimes(1);
  });
});
