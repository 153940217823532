import React from 'react';
import renderer from 'react-test-renderer';

import Details from '../../learning_center/Details';
import {
  currentUser, mediaItems, isAdmin, videoItem, postItem, documentItem,
} from '../__mocks__/lc-data';

it('renders correctly with videoItem and props', () => {
  const tree = renderer.create(
    <Details
      currentUser={currentUser}
      isAdmin={isAdmin}
      mediaItem={videoItem}
      isFavorite
      progress={0.5}
      mediaItems={mediaItems}
      totalPages={2}
      currentPage={1}
      totalCount={mediaItems.length}
    />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly with postItem and props', () => {
  const tree = renderer.create(
    <Details
      currentUser={currentUser}
      isAdmin={isAdmin}
      mediaItem={postItem}
      isFavorite
      progress={0.5}
      mediaItems={mediaItems}
      totalPages={2}
      currentPage={1}
      totalCount={mediaItems.length}
    />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly with documentItem and props', () => {
  const tree = renderer.create(
    <Details
      currentUser={currentUser}
      isAdmin={isAdmin}
      mediaItem={documentItem}
      isFavorite
      progress={0.5}
      mediaItems={mediaItems}
      totalPages={2}
      currentPage={1}
      totalCount={mediaItems.length}
    />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<Details />).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly only with media item', () => {
  const tree = renderer.create(<Details mediaItem={videoItem} />).toJSON();
  expect(tree.element).toMatchSnapshot();
});
