import React from 'react';
import renderer from 'react-test-renderer';
import api from '../../../services/api';

import Bookmarks from '../../learning_center/Bookmarks';
import { currentUser, isAdmin, mediaItems } from '../__mocks__/lc-data';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });
api.media_items.vimeo_thumbnail = jest.fn().mockResolvedValue({ thumbnail_url: '' });

it('renders correctly with props', () => {
  const tree = renderer.create(
    <Bookmarks
      currentUser={currentUser}
      isAdmin={isAdmin}
      items={mediaItems}
      totalPages={2}
      currentPage={1}
      totalCount={mediaItems.length}
    />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<Bookmarks />).toJSON();
  expect(tree.element).toMatchSnapshot();
});
