import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import PaymentHistoryModal from '../../../account/account_modals/PaymentHistoryModal';

const payments = [{ date: '12-03-2023', amount: '1399' }, { date: '12-03-2023', amount: '2399' }];

describe('account updated Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<PaymentHistoryModal togglePaymentHistoryModalVisible={() => {}} payments={payments} />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders modal and close click', async () => {
    const close = jest.fn(() => {});
    const container = render(
      <PaymentHistoryModal
        togglePaymentHistoryModalVisible={close}
        payments={payments}
      />,
    );
    const closeButton = container.queryByText('✕');
    expect(container.queryByText('1399')).toBeInTheDocument();
    expect(container.queryByText('2399')).toBeInTheDocument();
    expect(closeButton).toBeInTheDocument();
    fireEvent.click(closeButton);
    expect(close).toHaveBeenCalledTimes(1);
  });
});
