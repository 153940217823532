import React from 'react';
import renderer from 'react-test-renderer';

import { BookChapters } from '../../../learning_center/DetailsContent/BookChapters';
import { videoItem, documentItem } from '../../__mocks__/lc-data';

const book = { ...documentItem, media_items: [videoItem] };

it('renders correctly with props', () => {
  const tree = renderer.create(
    <BookChapters
      mediaItem={book}
      item={book}
      currentItem={videoItem}
      setCurrentItem={() => {}}
    />,
  ).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<BookChapters />).toJSON();
  expect(tree).toMatchSnapshot();
});
