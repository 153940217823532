import React, { useMemo } from 'react';
import moment from 'moment';

export default function BillingHistory({
  plans, subscription, account, payments, user, paymentMethod, visa, setPaymentMethodModalVisible, setSubscriptionModalVisible, setSubscriptionAction, setIsSubscription, isExpired,
}) {
  const subscriptionPlan = useMemo(
    () => plans.find((plan) => plan.id === (subscription || {}).plan_id) || {},
    [plans, subscription],
  );
  const noEdaSubscription = !subscription || isExpired || subscriptionPlan.plan_type === 'free' || subscriptionPlan.plan_type === 'trial';
  const noAcademySubscription = !user.academy_subscription || user.academy_subscription.stripe_cancelled;

  const computePaymentMethod = (plan) => {
    const lastPayment = payments[payments.length - 1];
    if ((plan.plan_type === 'free' || plan.plan_type === 'check' || plan.plan_type === 'trial') && !paymentMethod) {
      return (
        <div className="payment-method">
          <div className="name">
            Payment Method
          </div>
          <div className="no-payment-method-container">
            <p className="text-danger">Connect your payment method before activating individual plan functions</p>
            <button type="button" onClick={() => setPaymentMethodModalVisible(true)}>
              Add Payment Method
            </button>
          </div>
        </div>
      );
    }

    if (!lastPayment && !paymentMethod) return null;
    const brand = paymentMethod ? paymentMethod.brand : lastPayment.card_brand;
    const last4 = paymentMethod ? paymentMethod.last4 : lastPayment.last4;

    return (
      <div className="payment-method">
        <div className="name">
          Payment Method
        </div>
        <div className="payment-method-container">
          <div className="card-container">
            <div className="card-brand">
              {
                brand === 'visa' && (
                  <img src={visa.image} alt="visa" />
                )
              }
            </div>
            <div className="last-4-numbers">
              {`•••• ${last4}`}
            </div>

            <button type="button" className="update-payment-method" onClick={() => setPaymentMethodModalVisible(true)}>
              {paymentMethod ? 'Change' : 'Update'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="subscription-container">
      <div className="subscription-container-left">
        <div className="subscription">
          <div className="container-full-width">
            <div className="name">Payment Plan</div>
            <div />
            <div className="plan-row">
              <p style={{ textTransform: 'capitalize' }}>
                Platform:
                {' '}
                { account.account_type?.replace('_', ' ') || 'No account plan available' }
              </p>
              { noEdaSubscription && (
                <button
                  type="button"
                  className="plan"
                  onClick={() => {
                    if (paymentMethod?.id) {
                      setSubscriptionAction('eda');
                      setSubscriptionModalVisible(true);
                    } else {
                      setSubscriptionAction('eda');
                      setIsSubscription(true);
                      setPaymentMethodModalVisible(true);
                    }
                  }}
                  data-testid="eda-toggle"
                >
                  { isExpired ? 'Renew' : 'Upgrade' }
                </button>
              )}
            </div>
            { (!account.account_type.toLowerCase().includes('education') && user.academy_subscription) && (
              <div className="plan-row">
                <p style={{ textTransform: 'capitalize' }}>
                  Academy:
                  {' '}
                  { (user.academy_subscription && !user.academy_subscription.stripe_cancelled) ? account.account_type?.replace('_', ' ') : 'No account plan available' }
                </p>
                { noAcademySubscription && (
                  <button
                    type="button"
                    className="plan"
                    onClick={() => {
                      if (paymentMethod?.id) {
                        setSubscriptionAction('academy');
                        setSubscriptionModalVisible(true);
                      } else {
                        setSubscriptionAction('academy');
                        setIsSubscription(true);
                        setPaymentMethodModalVisible(true);
                      }
                    }}
                    data-testid="academy-toggle"
                  >
                    Upgrade
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        { (account.account_type === 'professional') && (
        <div className="professional-cancel">
          <p>Want to cancel subscription?</p>
          <button type="button" className="plan">
            <a href="mailto:support@ediscoveryassistant.com">Contact Us</a>
          </button>
        </div>
        )}
        { (account.account_type === 'enterprise') && (
        <>
          <div className="enterprise-seats">
            <p>Number of seats</p>
            <p>{subscription?.number_of_seats}</p>
          </div>
          <div className="professional-cancel enterprise-upgrade">
            <p>Want to upgrade?</p>
            <a href="mailto:support@ediscoveryassistant.com">Contact Us</a>
          </div>
        </>
        )}

        { computePaymentMethod(subscriptionPlan) }

        { (subscriptionPlan.plan_type === 'free' || subscriptionPlan.plan_type === 'trial') && (
        <div className="additional-info">
          <p>&#x2022; Upgrade to Individual plan for unlimited downloads.</p>
          <p>
            &#x2022; To add other members from your team, contact us at
            &nbsp;
            <a href="mailto:support@ediscoveryassistant.com">support@ediscoveryassistant.com</a>
          </p>
        </div>
        )}
        { payments.length > 0 && (
        <div className="payment-history">
          <div className="name">
            Payment History
          </div>

          { Boolean(subscriptionPlan && subscriptionPlan.plan_type !== 'free' && subscriptionPlan.plan_type !== 'trial' && subscription?.effective_to && !subscription?.stripe_cancelled) && (
          <div className="next-payment-date">
            <span>Next payment date: </span>
            <span>
              { moment(subscription.effective_to).format('LL') }
            </span>
          </div>
          )}

          <div className="payment-history-container">
            <div className="payment-history-header">
              <span className="header-date">PAYMENT DATE</span>
              <span className="header-amount">AMOUNT</span>
              <span className="header-amount">PRODUCT</span>
              <span className="header-status">STATUS</span>
            </div>
            { payments.map((payment) => (
              <div className="payment-container" key={payment.date + payment.amount}>
                <div className="payment-date">{payment.date}</div>
                <span className="amount">{`${payment.amount} ${payment.coupon ? (`(-${payment.percent_off}% with ${payment.coupon}) `) : ''}`}</span>
                <span className="amount">{payment.product}</span>
                <div>
                  <span className="status">{`Paid ${payment.cancellation_reason && payment.cancellation_reason === 'cancellation_requested' ? `(cancelled at ${payment.cancelled_at})` : ''}`}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="additional-info">
            <p>
              If you wish to proceed with cancelling your subscription, please email
              &nbsp;
              <a href="mailto:support@ediscoveryassistant.com">support@ediscoveryassistant.com</a>
              &nbsp; and our team will assist you.
            </p>
          </div>
        </div>
        )}

        { (!noEdaSubscription || !noAcademySubscription) && (
          <div className="subscription" style={{ marginTop: '4rem' }}>
            <div className="container-full-width">
              <div className="plan-row">
                <button
                  type="button"
                  className="plan"
                >
                  <a href="mailto:support@ediscoveryassistant.com?Subject=Cancel My Subscription">Cancel My Subscription</a>
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}
