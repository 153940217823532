import React, { useRef, useState } from 'react';

export default function Tags({ items, maxVisibleTags = 5 }) {
  const tagsContainer = useRef();
  const [visibleTags, setVisibleTags] = useState(maxVisibleTags);

  const expand = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setVisibleTags(Infinity);
    return false;
  };

  return (
    <div className="tags" ref={tagsContainer}>
      { items?.slice(0, visibleTags).map((tag) => (
        <span className="tag" key={tag?.id}>{tag.name}</span>
      ))}
      { items?.length > visibleTags && (
        <button className="tag more" onClick={expand}>
          +
          {' '}
          {(items?.length || 0) - visibleTags}
          {' '}
          More
        </button>
      ) }
    </div>
  );
}
