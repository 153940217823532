import React from 'react';
import '@testing-library/jest-dom';
import { render, screen, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import PdfModal from '../../../cite_lists/modals/PdfModal';

describe('PdfModal', () => {
  it('renders correctly with props', () => {
    render(
      <PdfModal
        citeListName="test"
        closeModal={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const close = jest.fn(() => {});

    render(
      <PdfModal
        citeListName="test"
        closeModal={close}
      />,
    );

    const closeButton = screen.getByText('Close');
    expect(closeButton).toBeInTheDocument();

    await act(async () => {
      userEvent.click(closeButton);
    });

    expect(close).toHaveBeenCalledTimes(1);
  });
});
