import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';

import RelatedRules from '../../related_items/RelatedRules';

const rules = [
  {
    rule_categories: [
      {
        name: 'Federal Rules of Civil Procedure',
      },
    ],
    published_revision: {
      number: 'Number for Rule',
    },
    type: 'FederalRule',
    id: 1,
  },
  {
    rule_categories: [
      {
        name: 'State',
      },
    ],
    published_revision: {
      number: 'Number for Rule',
    },
    type: 'StateRule',
    id: 2,
  },
  {
    rule_categories: [
      {
        name: 'District',
      },
    ],
    published_revision: {
      number: 'Number for Rule',
    },
    type: 'DistrictRule',
    id: 3,
  },
];

it('renders correctly with props', () => {
  const tree = renderer.create(<RelatedRules
    rules={rules}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
