import React from 'react';
import Select from 'react-select';
import roles from './roles';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.roles = roles;

    this.referralTypes = [
      { label: 'Internet research', value: 'internet_research' },
      { label: 'A Friend or Colleague', value: 'someone' },
      { label: 'Above the Law', value: 'above_the_law' },
      { label: 'Podcast', value: 'podcast' },
      { label: 'EDRM', value: 'edrm' },
      { label: 'eDiscovery Today blog', value: 'eda_today_blog' },
      { label: 'ACEDS', value: 'aceds' },
      { label: 'Other', value: 'other' },
    ];

    this.state = { formErrors: {} };
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    const field = e.target.id;
    onChange({ [field]: e.target.value }, () => this.validateField(field));
  };

  handleRolesChange = (e) => {
    const { onChange } = this.props;
    onChange({ user_roles: [e.value] });
  };

  handleOtherRoleChange = (e) => {
    const { onChange } = this.props;
    onChange({ user_roles: [e.target.value] });
  };

  handleReferralTypeChange = (e) => {
    const { onChange } = this.props;
    onChange({ referral_type: e.value }, () => this.validateField('referral_type'));
  };

  handleCompanyTypeChange = (e) => {
    const { onChange } = this.props;
    onChange({ company_type: e.value }, () => this.validateField('company_type'));
  };

  handleSubmit = () => {
    const { data, onSubmit } = this.props;
    Object.keys(data).forEach((field) => this.validateField(field));

    const { formErrors } = this.state;
    const hasErrors = Object.values(formErrors).filter((e) => e).length;
    if (hasErrors) return;
    onSubmit();
  };

  validateField(fieldName) {
    const { data } = this.props;
    const { formErrors } = this.state;
    const value = data[fieldName];
    let valid;

    switch (fieldName) {
      case 'referral_type':
        valid = !!value;
        formErrors[fieldName] = valid ? '' : 'Please fill this field';
        break;
      case 'company_type':
        valid = !!value;
        formErrors[fieldName] = valid ? '' : 'Please fill this field';
        break;
      case 'referral_other':
        valid = (data.referral_type !== 'other' && data.referral_type !== 'other_resource') || !!value;
        formErrors[fieldName] = valid ? '' : 'Please fill this field';
        break;
      case 'referral_name':
      case 'referral_company':
        valid = data.referral_type !== 'someone' || !!value;
        formErrors[fieldName] = valid ? '' : 'Please fill this field';
        break;
      default:
        break;
    }
    this.setState({ formErrors });
  }

  render() {
    const { data, onCancel, accountCategories } = this.props;
    const { formErrors } = this.state;
    const companyTypes = accountCategories
      .sort((a, b) => {
        if (a.name === 'Other') return 1;
        if (b.name === 'Other') return -1;
        return 0;
      })
      .map((c) => ({ value: c.id, label: c.name }));

    const isCustomRole = data.user_roles[0] === '' || (data.user_roles[0] && !this.roles.find((r) => r.value === data.user_roles[0]));

    const selectStyles = (field) => ({
      option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? '#FFF' : '#000E57',
        backgroundColor: state.isSelected ? 'rgba(0, 14, 87, 0.8)' : styles.color,
        '&:hover': {
          color: '#FFF',
          backgroundColor: 'rgba(0, 14, 87, 0.6)',
        },
      }),
      control: (styles, state) => ({
        ...styles,
        boxShadow: state.isFocused ? '0 0 0 0.2rem #515C9E' : 0,
        width: '100%',
        border: 'none',
        borderRadius: 0,
        borderBottom: state.isFocused ? 'none' : `1px solid ${formErrors[field] ? '#C93D20' : 'rgba(55, 56, 68, 0.16)'}`,
        paddingLeft: '.4rem',
        backgroundColor: formErrors[field] ? '#FCCFCF' : '',
      }),
      placeholder: () => ({
        color: formErrors[field] ? '#C93D20' : '#999999',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: () => ({
        color: formErrors[field] ? '#C93D20' : '#000E57',
        lineHeight: 0,
      }),
      singleValue: (styles) => ({
        ...styles,
        color: '#000E57',
      }),
    });

    return (
      <>
        <div>
          <div className="row">
            <div className="col-sm-6">
              <div className={`form-group ${formErrors.referral_type ? 'has-error' : ''}`}>
                <label className="register-form-title control-label" htmlFor="referral_type">How Did You Hear About Us?</label>
                <Select
                  placeholder="Select"
                  isSearchable={false}
                  value={data.referral_type === '' ? '' : this.referralTypes.find((p) => p.value === data.referral_type)}
                  onChange={this.handleReferralTypeChange}
                  options={this.referralTypes}
                  styles={selectStyles('referral_type')}
                />
                { formErrors.referral_type && <p className="help-block">{formErrors.referral_type}</p> }
              </div>
            </div>
          </div>

          { (data.referral_type === 'other' || data.referral_type === 'other_resource') && (
          <div className="row">
            <div className="col-sm-6">
              <div className={`form-group ${formErrors.referral_other ? 'has-error' : ''}`}>
                <label className="register-form-title control-label" htmlFor="referral_other">Tell Us More</label>
                <input
                  className="form-control"
                  type="text"
                  name="referral_other"
                  id="referral_other"
                  value={data.referral_other}
                  onChange={this.handleChange}
                />
                { formErrors.referral_other && <p className="help-block">{formErrors.referral_other}</p> }
              </div>
            </div>
          </div>
          )}

          { (data.referral_type === 'someone') && (
          <>
            <div className="row">
              <div className="col-sm-6">
                <div className={`form-group ${formErrors.referral_name ? 'has-error' : ''}`}>
                  <label className="register-form-title control-label" htmlFor="referral_name">What&apos;s their name?</label>
                  <input
                    className="form-control"
                    type="text"
                    name="referral_name"
                    id="referral_name"
                    value={data.referral_name}
                    onChange={this.handleChange}
                  />
                  { formErrors.referral_name && <p className="help-block">{formErrors.referral_name}</p> }
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className={`form-group ${formErrors.referral_company ? 'has-error' : ''}`}>
                  <label className="register-form-title control-label" htmlFor="referral_company">What company?</label>
                  <input
                    className="form-control"
                    type="text"
                    name="referral_company"
                    id="referral_company"
                    value={data.referral_company}
                    onChange={this.handleChange}
                  />
                  { formErrors.referral_company && <p className="help-block">{formErrors.referral_company}</p> }
                </div>
              </div>
            </div>
          </>
          )}

          <div className="row">
            <div className="col-sm-6">
              <div className={`form-group ${formErrors.company_type ? 'has-error' : ''}`}>
                <label className="register-form-title control-label" htmlFor="company_type">
                  Tell Us About Your Organization
                </label>
                <Select
                  placeholder="Select"
                  isSearchable={false}
                  value={data.company_type === '' ? '' : companyTypes.find((p) => p.value.toString() === data.company_type?.toString())}
                  onChange={this.handleCompanyTypeChange}
                  options={companyTypes}
                  styles={selectStyles('company_type')}
                />
                { formErrors.company_type && <p className="help-block">{formErrors.company_type}</p> }
              </div>
            </div>

            <div className="col-sm-6">
              <div className={`form-group role-selector ${formErrors.roles ? 'has-error' : ''}`}>
                <Select
                  placeholder="Select Your Role at the Organization"
                  isSearchable={false}
                  value={isCustomRole ? this.roles.find((p) => p.value === '') : this.roles.find((p) => p.value === data.user_roles[0])}
                  onChange={this.handleRolesChange}
                  options={this.roles}
                  styles={selectStyles('roles')}
                />
              </div>

              { isCustomRole && (
                <div className={`form-group ${formErrors.roles ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    placeholder="Enter Your Role at the Organization"
                    type="text"
                    name="other_role"
                    id="other_role"
                    value={data.user_roles[0] || ''}
                    onChange={this.handleOtherRoleChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="register-form-actions">
          <button type="button" className="btn btn-cancel" onClick={onCancel}>Back</button>
          <button type="button" className="btn btn-submit" onClick={this.handleSubmit}>Continue</button>
        </div>
      </>
    );
  }
}

export default About;
