import React from 'react';
import renderer from 'react-test-renderer';

import RelatedGlossaryTerm from '../../related_items/RelatedGlossaryTerm';

const glossaryTerm = {
  published_revision: {
    id: 1,
    name: 'This is a name for Glossary Term',
  },
};

it('renders correctly with props', () => {
  const tree = renderer.create(<RelatedGlossaryTerm
    relatedTerm={glossaryTerm}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
