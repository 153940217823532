// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly without props 1`] = `
<div
  className="login-modal new-modal"
  data-testid="login-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
      onClick={[Function]}
    >
      <div
        className="circle"
      />
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Thank you for visiting eDiscovery Assistant. You are viewing this decision through the public link shared from our caselaw database. For access to additional content and features, log in to your account, create a new paid account, or contact us at
          <a
            href="mailto:support@ediscoveryassistant.com"
          >
             support@ediscoveryassistant.com
          </a>
        </p>
        <div
          className="button-row"
        >
          <button
            className="modal-button"
          >
            <a
              href="/registrations/new/individual"
            >
              Create Account
            </a>
          </button>
          <button
            className="modal-button modal-button-outline"
          >
            <a
              href="/login"
            >
              Login
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
