import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import About from '../../auth/About';

const data = {
  name: 'Name',
  company: 'Company',
  email: 'email',
  password: 'password',
  referral_company: '',
  user_roles: [],
  plan: {
    name: 'Individual',
    default_amount: 12000,
    plan_type: 'credit_card',
  },
};

const accountCategories = [
  {
    id: 4,
    name: 'Corporate',
    created_at: '2021-05-12T11:18:24.227-06:00',
    updated_at: '2021-05-12T11:18:24.227-06:00',
  },
  {
    id: 5,
    name: 'Education',
    created_at: '2021-05-12T11:18:24.228-06:00',
    updated_at: '2021-05-12T11:18:24.228-06:00',
  },
  {
    id: 3,
    name: 'Government',
    created_at: '2021-05-12T11:18:24.225-06:00',
    updated_at: '2021-05-12T11:18:24.225-06:00',
  },
  {
    id: 2,
    name: 'Law Firm',
    created_at: '2021-05-12T11:18:24.223-06:00',
    updated_at: '2022-05-23T08:10:13.481-06:00',
  },
  {
    id: 1,
    name: 'Service Provider',
    created_at: '2021-05-12T11:18:24.220-06:00',
    updated_at: '2022-05-23T08:10:13.478-06:00',
  },
  {
    id: 6,
    name: 'Other',
    created_at: '2021-05-12T11:18:24.230-06:00',
    updated_at: '2021-05-12T11:18:24.230-06:00',
  },
];

describe('About', () => {
  it('renders correctly with props', () => {
    render(<About
      data={data}
      accountCategories={accountCategories}
      onChange={() => {}}
      onCancel={() => {}}
      onSubmit={() => {}}
    />);
    expect(screen).toMatchSnapshot();
  });

  it('correctly validates fields', () => {
    render(<About
      data={data}
      accountCategories={accountCategories}
      onChange={() => {}}
      onCancel={() => {}}
      onSubmit={() => {}}
    />);

    const continueButton = screen.getByText('Continue');
    userEvent.click(continueButton);
    // expect(document.getElementsByClassName('has-error').length).toBeGreaterThan(0);
  });
});
