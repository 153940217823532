import React from 'react';
import Form from '../../checklists/Form';

export default function Preview({ form }) {
  return (
    <div className="admin-page react-admin-checklists-preview">
      <div className="header">
        <a type="button" className="back" href={`/admin/templates/${form.id}/edit`}>
          &lt; Back To Form
        </a>
        <span className="attention">ATTENTION: THIS IS FOR PREVIEW PURPOSE ONLY, NOTHING CAN BE CLICKED AND UPDATED</span>
        <span className="mode">Preview Mode</span>
      </div>
      <Form
        form={form}
        favorites={{}}
        preview
      />
    </div>
  );
}
