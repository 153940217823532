import React from 'react';

export default function AccountUpdatedModal({ toggleAccountUpdatedModalVisible }) {
  return (
    <div className="modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Update Successful
              <button
                type="button"
                className="button-clear pull-right"
                onClick={toggleAccountUpdatedModalVisible}
              >
                ✕
              </button>
            </h4>
          </div>
          <div className="modal-body">
            Account updated!
          </div>
        </div>
      </div>
    </div>
  );
}
