import React from 'react';
import PropTypes from 'prop-types';
import {
  FaCheck, FaTimes, FaCheckSquare, FaRegSquare,
} from 'react-icons/all';

class JurisdictionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'federal',
    };
  }

  _setTab = (tab) => {
    this.setState({ tab });
  };

  _renderJurisdictionButtons = () => {
    const { tab } = this.state;
    return (
      <div className="side-section-tabs">
        <button
          type="button"
          className={`tag ${tab === 'federal' ? 'active' : ''}`}
          onClick={() => this._setTab('federal')}
        >
          Federal
        </button>
        <button
          type="button"
          className={`tag ${tab === 'state' ? 'active' : ''}`}
          onClick={() => this._setTab('state')}
        >
          State
        </button>
        <button
          type="button"
          className={`tag ${tab === 'admin' ? 'active' : ''}`}
          onClick={() => this._setTab('admin')}
        >
          Administrative
        </button>
      </div>
    );
  };

  _selectBankruptcyCourts = (circuit) => {
    const { selectJurisdiction } = this.props;
    return circuit.courts
      .filter((jur) => jur.type === 'bankruptcy')
      .forEach((jur) => selectJurisdiction(jur, 'federal'));
  };

  _renderCircuit = (circuit) => {
    const {
      selectJurisdictionsInCircuit,
      selectedJurisdictionsContainsCircuit,
      selectedJurisdictions,
      selectJurisdiction,
    } = this.props;
    return (
      <div key={circuit.name}>
        <div
          className={`summary-checkbox bold${selectedJurisdictionsContainsCircuit(circuit) ? ' selected' : ''}`}
          onClick={() => selectJurisdictionsInCircuit(circuit)}
        >
          {selectedJurisdictionsContainsCircuit(circuit) === true
            ? <FaCheckSquare />
            : <FaRegSquare />}
          {circuit.name}
        </div>
        <div className="circuit-courts">
          {circuit.courts.map((jurisdiction) => !jurisdiction.name.startsWith('Bankr.') && (
          <div
            key={jurisdiction.name}
            onClick={() => selectJurisdiction(jurisdiction, 'federal')}
            className={`tag ${selectedJurisdictions.federal.indexOf(jurisdiction) !== -1 ? ' active' : ''}`}
          >
            {jurisdiction.name}
          </div>
          ))}
          {
            circuit.courts.some((jurisdiction) => jurisdiction.name.startsWith('Bankr.')) && (
            <div
              className={selectedJurisdictions.federal
                .some((jurisdiction) => jurisdiction.name.startsWith('Bankr.')
                        && circuit.courts.includes(jurisdiction))
                ? 'tag active' : 'tag'}
              onClick={() => this._selectBankruptcyCourts(circuit)}
            >
              Bankruptcy Courts
            </div>
            )
          }
        </div>
      </div>
    );
  };

  render() {
    const {
      closeSection,
      selectJurisdiction,
      selectedJurisdictions,
      jurisdictions,
      selectAllJurisdictions,
      clearAllJurisdictions,
    } = this.props;
    const { tab } = this.state;

    const bucketSize = 6;
    const buckets = [];
    [
      ...jurisdictions.Federal.filter((c) => /^\d+/.test(c.name)),
      jurisdictions.Federal.filter((c) => !/^\d+/.test(c.name)),
    ]
      .forEach((c, i) => {
        const bucketIdx = Math.floor(i / bucketSize);
        buckets[bucketIdx] = buckets[bucketIdx] || [];
        buckets[bucketIdx].push(c);
      });

    return (
      <div className="side-section">
        <div className="side-section-header">
          <h4 className="side-section-title">
            Jurisdiction
            <button
              type="button"
              className="button-clear"
              onClick={closeSection}
            >
              ✕
            </button>
          </h4>
        </div>
        <div className="side-section-body">
          {this._renderJurisdictionButtons()}
          { tab === 'state' && (
            <div className="section-state">
              <div className="tag button" onClick={() => selectAllJurisdictions(tab)}>
                <FaCheck />
                Select All
              </div>
              <div className="tag button" onClick={() => clearAllJurisdictions(tab)}>
                <FaTimes />
                Clear All
              </div>

                { jurisdictions.State.map((state) => (
                  <div
                    key={state[0]}
                    className={`tag ${selectedJurisdictions.state.indexOf(state) !== -1 ? 'active' : ''}`}
                    onClick={() => selectJurisdiction(state, 'state')}
                  >
                    {state[0]}
                  </div>
                ))}
            </div>
          )}
          { tab === 'federal' && (
            <div className="section-federal">
              { buckets.map((bucket, i) => (
                <React.Fragment key={i.toString()}>
                  <div className="federal-row">
                    {
                          bucket.map((circuit, idx) => (
                            <div key={idx.toString()}>
                              {
                                Array.isArray(circuit)
                                  ? circuit.map(this._renderCircuit)
                                  : this._renderCircuit(circuit)
                              }
                            </div>
                          ))
                        }
                  </div>
                </React.Fragment>
              )) }
            </div>
          )}
          { tab === 'admin' && (
            <div className="section-state">
              <div className="tag button" onClick={() => selectAllJurisdictions(tab)}>
                <FaCheck />
                Select All
              </div>
              <div className="tag button" onClick={() => clearAllJurisdictions(tab)}>
                <FaTimes />
                Clear All
              </div>

                { jurisdictions.Administrative.map((admin) => (
                  <div
                    key={admin[0]}
                    className={`tag ${selectedJurisdictions.admin.indexOf(admin) !== -1 ? 'active' : ''}`}
                    onClick={() => selectJurisdiction(admin, 'admin')}
                  >
                    {admin[0]}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

JurisdictionSection.propTypes = {
  jurisdictions: PropTypes.object.isRequired,
  selectedJurisdictions: PropTypes.object.isRequired,
  closeSection: PropTypes.func.isRequired,
  selectJurisdiction: PropTypes.func.isRequired,
  selectJurisdictionsInCircuit: PropTypes.func.isRequired,
  selectedJurisdictionsContainsCircuit: PropTypes.func.isRequired,
  selectAllJurisdictions: PropTypes.func.isRequired,
  clearAllJurisdictions: PropTypes.func.isRequired,
};

export default JurisdictionSection;
