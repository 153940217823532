import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from '../../services/api';
import SearchLoadingModal from '../search/SearchLoadingModal';

function parseDateFilter(filter) {
  if (filter === '7days') {
    return { startDate: moment().subtract(7, 'days').toISOString() };
  }

  if (filter === '30days') {
    return { startDate: moment().subtract(30, 'days').toISOString() };
  }

  if (filter === 'year') {
    return { startDate: moment().subtract(1, 'year').toISOString() };
  }

  return {};
}

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 'all',
      rules: [],
      search: [],
      checklistsAndForms: [],
      glossaryTerms: [],
      page: 1,
      perPage: 25,
      resultsCount: 0,
      dateFilter: '',
      loading: false,
    };
  }

  componentDidMount() {
    const {
      selectedCategory, page, perPage, dateFilter,
    } = this.state;
    this._fetchCategoryPaginatedData(selectedCategory, dateFilter, page, perPage);
  }

  _setSelectedCategory = async (category) => {
    this.setState({ page: 1, loading: true, dateFilter: '' });
    await this._fetchCategoryPaginatedData(category, '');
    this.setState({ selectedCategory: category, loading: false });
  };

  _fetchCategoryPaginatedData = async (category, dateFilter, page = 1, perPage = 25) => {
    this.setState({ loading: true });

    const { startDate, endDate } = parseDateFilter(dateFilter);
    if (category === 'rules') {
      const res = await api.history.paginatedRules(startDate || '', endDate || '', page, perPage);
      this.setState({ rules: res.history, resultsCount: res.count });
    }
    if (category === 'search') {
      const res = await api.history.paginatedSearch(startDate || '', endDate || '', page, perPage);
      this.setState({ search: res.history, resultsCount: res.count });
    }
    if (category === 'checklists_and_forms') {
      const res = await api.history.paginatedChecklistsAndForms(startDate || '', endDate || '', page, perPage);
      this.setState({ checklistsAndForms: res.history, resultsCount: res.count });
    }
    if (category === 'glossary_terms') {
      const res = await api.history.paginatedGlossaryTerms(startDate || '', endDate || '', page, perPage);
      this.setState({ glossaryTerms: res.history, resultsCount: res.count });
    }
    if (category === 'all') {
      const res = await api.history.allPaginated(startDate || '', endDate || '', page, perPage);
      const rulesHistory = res.history.filter((history) => history.controller.includes('rules'));
      const searchHistory = res.history.filter((history) => history.controller.includes('case_digest'));
      const checklistsAndFormsHistory = res.history.filter((history) => history.controller.includes('templates') || history.controller.includes('checklist'));
      const glossaryTerms = res.history.filter((history) => history.controller.includes('glossary_terms'));
      this.setState({
        rules: rulesHistory, search: searchHistory, checklistsAndForms: checklistsAndFormsHistory, glossaryTerms, resultsCount: res.count,
      });
    }
    this.setState({ loading: false });
  };

  _handleNextPageClick = async () => {
    const {
      selectedCategory, page, perPage, dateFilter,
    } = this.state;
    this.setState((prevState) => ({ page: prevState.page + 1 }));
    await this._fetchCategoryPaginatedData(selectedCategory, dateFilter, page + 1, perPage);
  };

  _handlePreviousPageClick = async () => {
    const {
      selectedCategory, page, perPage, dateFilter,
    } = this.state;
    this.setState((prevState) => ({ page: prevState.page - 1 }));
    await this._fetchCategoryPaginatedData(selectedCategory, dateFilter, page - 1, perPage);
  };

  _handlePerPageChange = async (event) => {
    const perPage = event.target.value;
    const { selectedCategory, dateFilter } = this.state;
    this.setState({ perPage, page: 1 });
    await this._fetchCategoryPaginatedData(selectedCategory, dateFilter, 1, perPage);
  };

  _handleDateFilterChange = async (event) => {
    const dateFilter = event.target.value;
    const { selectedCategory, perPage } = this.state;
    this.setState({ dateFilter, page: 1 });
    await this._fetchCategoryPaginatedData(selectedCategory, dateFilter, 1, perPage);
  };

  _transformCategory = (category) => {
    if (category === 'rules') return 'Rules';
    if (category === 'checklists_and_forms') return 'Checklists & Forms';
    if (category === 'search') return 'Case Law Search';
    if (category === 'glossary_terms') return 'Glossary';
    return null;
  };

  _renderHistory = (history, historyType) => {
    const { stateCourtsCounter, administrativeCourtsCounter, federalCourtsCounter } = this.props;
    const tags = history.properties.jurisdiction_tags;
    let administrativeCounter = 0;
    let federalCounter = 0;
    let stateCounter = 0;

    if (tags) {
      tags.forEach((jurisdiction) => {
        if (jurisdiction.court.court_type === 'administrative') {
          administrativeCounter += 1;
        } else if (jurisdiction.court.jurisdiction === 'federal') {
          federalCounter += 1;
        } else if (jurisdiction.court.jurisdiction === 'state') {
          stateCounter += 1;
        }
      });
    }
    switch (historyType) {
      case 'rule':
        return (
          <a className="history" href={history.fullpath}>
            <div className="checklists-and-forms-container">
              <div className="entry-type">
                <span className="blue-color">Rules</span>
              </div>
              <div className="name-and-date">
                <div className="checklists-name">
                  <span className="blue-color">{history.properties.rule_number}</span>
                </div>
                <div className="date-container">
                  <span className="blue-color">{moment(history.created_at).format('MM/DD/YYYY hh:mmA')}</span>
                </div>
              </div>
            </div>
          </a>
        );
      case 'search':
        return (
          <a className="history" href={history.fullpath}>
            <div className="search-history-container">
              <div className="entry-type">
                <span className="blue-color">Case Law Search</span>
              </div>
              <div className="description-date">
                <div className="search-history-description">
                  <div className="issues-container">
                    <span className="description-margin">Issues:</span>
                    {
                        (history.properties.tag_names === undefined || history.properties.tag_names.length === 0)
                          ? <div className="history-tag">Not specified</div> : (
                            history.properties.tag_names.map((tag) => (
                              <div className="history-tag" key={tag}>{ tag }</div>
                            ))
                          )
                      }
                  </div>
                  <div className="search-terms-container">
                    <span className="description-margin">Search Term:</span>
                    <span className="blue-color">
                      {
                        history.params.q !== undefined ? history.params.q : '-N/A-'
                      }
                    </span>
                  </div>
                  {
                      history.properties.jurisdiction_tags === null
                        ? (
                          <div className="jurisdictions">
                            <span className="description-margin">Jurisdictions</span>
                            <div className="history-tag">Not specified</div>
                          </div>
                        )
                        : (
                          <div className="jurisdictions">
                            <span className="description-margin">Jurisdictions</span>

                            {history.properties.jurisdiction_tags.map((jurisdiction) => (
                              (jurisdiction.court.court_type === 'administrative' && administrativeCounter === administrativeCourtsCounter) ? (
                                <div />
                              )
                                : (jurisdiction.court.jurisdiction === 'federal' && jurisdiction.court.court_type !== 'administrative' && federalCounter === federalCourtsCounter) ? (
                                  <div />
                                )
                                  : (jurisdiction.court.jurisdiction === 'state' && stateCounter === stateCourtsCounter) ? (
                                    <div />
                                  )
                                    : jurisdiction.show
                                                && (
                                                <div className="history-tag" key={jurisdiction.id}>
                                                  {jurisdiction.court.court_type === 'state' ? jurisdiction.abbr.split(' ')[0] : jurisdiction.abbr}
                                                </div>
                                                )
                            ))}
                            {
                                  stateCounter === stateCourtsCounter
                                    ? <div className="history-tag">All States</div>
                                    : <div />
                                }
                            {
                                  federalCounter === federalCourtsCounter
                                    ? <div className="history-tag">All Federals</div>
                                    : <div />
                                }
                            {
                                  administrativeCounter === administrativeCourtsCounter
                                    ? <div className="history-tag">All Administrative</div>
                                    : <div />
                                }
                          </div>
                        )
                    }
                  <div className="dates">
                    <span className="description-margin">Dates:</span>
                    <div className="history-tag">
                      { (history.properties.date_filters.start_date === '' && history.properties.date_filters.end_date === '') ? 'Not Specified' : (`${history.properties.date_filters.start_date === '' ? 'Not specified'
                        : new Date(history.properties.date_filters.start_date)
                          .toLocaleDateString(
                            'en-US',
                            { year: 'numeric', month: 'short', day: 'numeric' },
                          )
                      } - ${
                        history.properties.date_filters.end_date === '' ? 'Not specified'
                          : new Date(history.properties.date_filters.end_date)
                            .toLocaleDateString(
                              'en-US',
                              { year: 'numeric', month: 'short', day: 'numeric' },
                            )}`)}
                    </div>
                  </div>
                  <div className="search-terms-container">
                    <span className="description-margin">Case Title:</span>
                    <span className="blue-color">
                      {
                          history.params.case_title !== undefined ? history.params.case_title : '-N/A-'
                        }
                    </span>
                  </div>
                  <div className="search-terms-container">
                    <span className="description-margin">Judge:</span>
                    <span className="blue-color">
                      {
                          history.params.judge !== undefined ? history.params.judge : '-N/A-'
                        }
                    </span>
                  </div>
                  <div className="results-container">
                    <span className="description-margin">Results:</span>
                    <span className="results-counter">{`${history.properties.total_count} Cases`}</span>
                  </div>
                </div>
                <div className="date-container" />
                <span className="blue-color">{moment(history.created_at).format('MM/DD/YYYY hh:mmA')}</span>
              </div>
            </div>
          </a>
        );
      case 'checklists_and_forms':
        return (
          <a className="history" href={history.fullpath}>
            <div className="checklists-and-forms-container">
              <div className="entry-type">
                {
                      history.properties.category === 'checklist' ? <span className="blue-color">Checklists</span> : <span className="blue-color">Forms</span>
                    }
              </div>
              <div className="name-and-date">
                <div className="checklists-name">
                  {
                      history.properties.category === 'checklist' ? <span className="blue-color">{history.properties.checklist_name}</span> : <span className="blue-color">{history.properties.form_name}</span>
                    }
                </div>
                <div className="date-container">
                  <span className="blue-color">{moment(history.created_at).format('MM/DD/YYYY hh:mmA')}</span>
                </div>
              </div>
            </div>
          </a>
        );
      case 'glossary_terms':
        return (
          <a className="history" href={`/glossary_terms?term=${history.params.id}`}>
            <div className="checklists-and-forms-container">
              <div className="entry-type">
                <span className="blue-color">Glossary</span>
              </div>
              <div className="name-and-date">
                <div className="checklists-name">
                  <span className="blue-color">{history.properties.glossary_term_name}</span>
                </div>
                <div className="date-container">
                  <span className="blue-color">{moment(history.created_at).format('MM/DD/YYYY hh:mmA')}</span>
                </div>
              </div>
            </div>
          </a>
        );
      default:
        return '';
    }
  };

  _renderDateSortedHistories = (search, rule, checklists_and_forms, glossary_terms) => {
    const dateSortedHistory = {};
    const {
      search: searchHistory = [], rules = [], checklistsAndForms = [], glossaryTerms = [],
    } = this.state;
    if (search === true) {
      searchHistory.forEach((history) => {
        const date = new Date(history.created_at)
          .toLocaleDateString(
            'en-US',
            { year: 'numeric', month: 'short', day: 'numeric' },
          );
        if (dateSortedHistory[date] === undefined) {
          dateSortedHistory[date] = [['search', history]];
        } else {
          dateSortedHistory[date].push(['search', history]);
        }
      });
    }
    if (rule === true) {
      rules.forEach((history) => {
        const date = new Date(history.created_at)
          .toLocaleDateString(
            'en-US',
            { year: 'numeric', month: 'short', day: 'numeric' },
          );
        if (dateSortedHistory[date] === undefined) {
          dateSortedHistory[date] = [['rule', history]];
        } else {
          dateSortedHistory[date].push(['rule', history]);
        }
      });
    }
    if (checklists_and_forms === true) {
      checklistsAndForms.forEach((history) => {
        const date = new Date(history.created_at)
          .toLocaleDateString(
            'en-US',
            { year: 'numeric', month: 'short', day: 'numeric' },
          );
        if (dateSortedHistory[date] === undefined) {
          dateSortedHistory[date] = [['checklists_and_forms', history]];
        } else {
          dateSortedHistory[date].push(['checklists_and_forms', history]);
        }
      });
    }
    if (glossary_terms === true) {
      glossaryTerms.forEach((history) => {
        const date = new Date(history.created_at)
          .toLocaleDateString(
            'en-US',
            { year: 'numeric', month: 'short', day: 'numeric' },
          );
        if (dateSortedHistory[date] === undefined) {
          dateSortedHistory[date] = [['glossary_terms', history]];
        } else {
          dateSortedHistory[date].push(['glossary_terms', history]);
        }
      });
    }
    return (
      <div className="category">
        { Object.keys(dateSortedHistory).map((date) => dateSortedHistory[date].map((historyArr) => (
          <div key={historyArr[1].id}>
            { this._renderHistory(historyArr[1], historyArr[0]) }
          </div>
        )))}
      </div>
    );
  };

  render() {
    const {
      searchHistoryCounter, ruleHistoryCounter, checklistAndFormHistoryCounter, glossaryTermHistoryCounter,
    } = this.props;

    const { selectedCategory, loading, resultsCount } = this.state;
    const historyCount = searchHistoryCounter + ruleHistoryCounter + checklistAndFormHistoryCounter + glossaryTermHistoryCounter;
    return (
      <div className="react-history">
        <div className="left">
          <div className="history-header">
            <div className="name">
              History
            </div>
            <div
              className={`category all-category ${selectedCategory === 'all' ? ' selected' : ''}`}
              onClick={() => this._setSelectedCategory('all')}
              data-testid="all"
            >
              <div className="category-name">{`All Results (${historyCount})`}</div>
            </div>
          </div>
          <div
            className={`category${selectedCategory === 'search' ? ' selected' : ''}`}
            onClick={() => this._setSelectedCategory('search')}
            data-testid="search"
          >
            <div className="category-name">
              <div className="category-image">
                <div className="case_digests" style={{ height: 20, width: 20 }} />
                <span>Case Law Search</span>
              </div>
              <div className="category-count">
                <span>{searchHistoryCounter}</span>
              </div>
            </div>
          </div>
          <div
            className={`category${selectedCategory === 'rules' ? ' selected' : ''}`}
            onClick={() => this._setSelectedCategory('rules')}
            data-testid="rules"
          >
            <div className="category-name">
              <div className="category-image">
                <div className="rules" style={{ height: 20, width: 20 }} />
                <span>Rules</span>
              </div>
              <div className="category-count">
                <span>{ruleHistoryCounter}</span>
              </div>
            </div>
          </div>
          <div
            className={`category${selectedCategory === 'checklists_and_forms' ? ' selected' : ''}`}
            onClick={() => this._setSelectedCategory('checklists_and_forms')}
            data-testid="checklists_and_forms"
          >
            <div className="category-name">
              <div className="category-image">
                <div className="checklists_and_forms" style={{ height: 20, width: 20 }} />
                <span>Checklists & Forms</span>
              </div>
              <div className="category-count">
                <span>{checklistAndFormHistoryCounter}</span>
              </div>
            </div>
          </div>
          <div
            className={`category${selectedCategory === 'glossary_terms' ? ' selected' : ''}`}
            onClick={() => this._setSelectedCategory('glossary_terms')}
            data-testid="glossary_terms"
          >
            <div className="category-name">
              <div className="category-image">
                <div className="glossary" style={{ height: 20, width: 20 }} />
                <span>Glossary</span>
              </div>
              <div className="category-count">
                <span>{glossaryTermHistoryCounter}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            {(resultsCount === 0 && !loading) ? (
              <div className="no-bookmarks">
                <div className="no-bookmarks-text">
                  <span>You have no history activity.</span>
                  <span>Select a database below to start exploring.</span>
                </div>
                <div className="no-bookmarks-navigation">
                  <a href="/case_law">
                    <div className="no-bookmarks-link">
                      <div className="background-case-law-image" style={{ height: 20, width: 20 }} />
                      <span>Case Law</span>
                    </div>
                  </a>
                  <a href="/rules">
                    <div className="no-bookmarks-link">
                      <div className="background-rules-image" style={{ height: 20, width: 20 }} />
                      <span>Rules</span>
                    </div>
                  </a>
                  <a href="/checklists">
                    <div className="no-bookmarks-link">
                      <div className="background-checklists-image" style={{ height: 20, width: 20 }} />
                      <span>Checklists & Forms</span>
                    </div>
                  </a>
                  <a href="/glossary_terms">
                    <div className="no-bookmarks-link">
                      <div className="background-glossary-image" style={{ height: 20, width: 20 }} />
                      <span>Glossary</span>
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <div className="selected-category-header">
                  { selectedCategory === 'all' ? (
                    <div className="header-info">
                      <span className="selected-category-title">All Results</span>
                      <span className="selected-category-count">{resultsCount}</span>
                    </div>
                  ) : (
                    <div className="header-info">
                      <span className="selected-category-title">{this._transformCategory(selectedCategory)}</span>
                      <span className="selected-category-count">{resultsCount}</span>
                    </div>
                  )}
                  <div className="filters">
                    <div className="filter-select">
                      <span>Per page:</span>
                      <select value={this.state.perPage} onChange={(event) => this._handlePerPageChange(event)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                      </select>
                    </div>
                    <div className="filter-select">
                      <select value={this.state.dateFilter} onChange={(event) => this._handleDateFilterChange(event)}>
                        <option value="7days">Last 7 Days</option>
                        <option value="30days">Last 30 Days</option>
                        <option value="year">Last Year</option>
                        <option value="">All Time</option>
                      </select>
                    </div>
                  </div>
                </div>

                { selectedCategory === 'all' && this._renderDateSortedHistories(true, true, true, false) }
                { selectedCategory === 'search' && this._renderDateSortedHistories(true, false, false, false) }
                { selectedCategory === 'rules' && this._renderDateSortedHistories(false, true, false, false) }
                { selectedCategory === 'checklists_and_forms' && this._renderDateSortedHistories(false, false, true, false) }
                { selectedCategory === 'glossary_terms' && this._renderDateSortedHistories(false, false, false, true) }

                <div className="pagination-buttons">
                  { this.state.page > 1 && <button onClick={this._handlePreviousPageClick}>Previous Page</button>}
                  <button onClick={this._handleNextPageClick}>Next Page</button>
                </div>
              </div>
            )}
          </div>
        </div>
        { loading && <SearchLoadingModal /> }
      </div>
    );
  }
}

History.propTypes = {
  searchHistoryCounter: PropTypes.number.isRequired,
  ruleHistoryCounter: PropTypes.number.isRequired,
  checklistAndFormHistoryCounter: PropTypes.number.isRequired,
  stateCourtsCounter: PropTypes.number.isRequired,
  federalCourtsCounter: PropTypes.number.isRequired,
  administrativeCourtsCounter: PropTypes.number.isRequired,
  glossaryTermHistoryCounter: PropTypes.number.isRequired,
};

export default History;
