// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div
        class="eda-search-container"
      >
        <div
          class="input-container"
        >
          <textarea
            autocomplete="off"
            class="search-input"
            id="search-input"
            placeholder="Start typing issues or click on + sign to select issues"
            spellcheck="false"
          >
            Spoliation
          </textarea>
          <div
            class="overlay"
          >
            Spoliation
          </div>
        </div>
        <span
          class="clear-tags"
        >
          ✕
        </span>
        <button
          class="search-tags-button"
          data-testid="search-tags"
          type="button"
        >
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            stroke-width="0"
            viewBox="0 0 448 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
            />
          </svg>
        </button>
      </div>
      <div
        class="suggestions"
      >
        Try:  
        <a
          class="tag"
          href="#"
        >
          Spoliation
        </a>
        <a
          class="tag"
          href="#"
        >
          Sanctions
        </a>
        <a
          class="tag"
          href="#"
        >
          Proportionality
        </a>
      </div>
      <div
        class="react-search-tips"
      >
        <div
          class="tips-title"
        >
          Boolean Search Tips:
        </div>
        <button
          class="btn btn-flat tag"
          data-place="bottom"
          type="button"
        >
          AND
        </button>
         
        <button
          class="btn btn-flat tag"
          data-place="bottom"
          type="button"
        >
          OR
        </button>
         
        <button
          class="btn btn-flat tag"
          data-place="bottom"
          type="button"
        >
          NOT
        </button>
         
        <button
          class="btn btn-flat tag"
          data-place="bottom"
          type="button"
        >
          (
        </button>
         
        <button
          class="btn btn-flat tag"
          data-place="bottom"
          type="button"
        >
          )
        </button>
      </div>
    </div>
    <div
      class="search-drop-down-container"
      id="drop-down"
      role="tooltip"
      style="position: fixed; top: 0px; left: 0px; display: none;"
    >
      <div
        class="drop-down"
      >
        <li
          aria-disabled="false"
          class="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
          role="menuitem"
          tabindex="-1"
        >
          <div
            class="tag-option "
          >
            <span
              style="font-weight: 500;"
            >
              Spoliation
            </span>
          </div>
          <span
            class="MuiTouchRipple-root"
          />
        </li>
      </div>
    </div>
  </body>,
  "container": <div>
    <div
      class="eda-search-container"
    >
      <div
        class="input-container"
      >
        <textarea
          autocomplete="off"
          class="search-input"
          id="search-input"
          placeholder="Start typing issues or click on + sign to select issues"
          spellcheck="false"
        >
          Spoliation
        </textarea>
        <div
          class="overlay"
        >
          Spoliation
        </div>
      </div>
      <span
        class="clear-tags"
      >
        ✕
      </span>
      <button
        class="search-tags-button"
        data-testid="search-tags"
        type="button"
      >
        <svg
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
          />
        </svg>
      </button>
    </div>
    <div
      class="suggestions"
    >
      Try:  
      <a
        class="tag"
        href="#"
      >
        Spoliation
      </a>
      <a
        class="tag"
        href="#"
      >
        Sanctions
      </a>
      <a
        class="tag"
        href="#"
      >
        Proportionality
      </a>
    </div>
    <div
      class="react-search-tips"
    >
      <div
        class="tips-title"
      >
        Boolean Search Tips:
      </div>
      <button
        class="btn btn-flat tag"
        data-place="bottom"
        type="button"
      >
        AND
      </button>
       
      <button
        class="btn btn-flat tag"
        data-place="bottom"
        type="button"
      >
        OR
      </button>
       
      <button
        class="btn btn-flat tag"
        data-place="bottom"
        type="button"
      >
        NOT
      </button>
       
      <button
        class="btn btn-flat tag"
        data-place="bottom"
        type="button"
      >
        (
      </button>
       
      <button
        class="btn btn-flat tag"
        data-place="bottom"
        type="button"
      >
        )
      </button>
    </div>
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;
