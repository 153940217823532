import React from 'react';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: '',
        password: '',
      },
      passwordVisible: false,
      csrfToken: (document.querySelector('meta[name=csrf-token]') || {}).content,
    };
  }

  handleChange = (e) => {
    const { user } = this.state;
    this.setState({ user: { ...user, [e.target.id]: e.target.value } });
  };

  render() {
    const { url, forgotPasswordPath, flash } = this.props;
    const { user, passwordVisible, csrfToken } = this.state;

    return (
      <div className="auth-page new-login">
        <form action={url} method="post" className="login-form">
          <img src="/logo.svg" className="trademark-header" />

          {flash.map(([type, message]) => (
            <div className="alert alert-warning" key={type}>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span
                  aria-hidden="true"
                >
                  &times;
                </span>
              </button>
              {message}
            </div>
          ))}
          <div className="form-group">
            <label className="control-label" htmlFor="email">Email</label>
            <input
              className="form-control"
              type="email"
              name="user[email]"
              id="email"
              value={user.email}
              onChange={this.handleChange}
              placeholder="example@email.com"
            />
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="password">Password</label>
            {passwordVisible ? (
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  name="user[password]"
                  value={user.password}
                  onChange={this.handleChange}
                  id="password"
                />
                <span className="input-group-addon" onClick={() => this.setState({ passwordVisible: false })}>
                  <FaRegEyeSlash />
                </span>
              </div>
            ) : (
              <div className="input-group">
                <input
                  className="form-control"
                  type="password"
                  name="user[password]"
                  value={user.password}
                  onChange={this.handleChange}
                  id="password"
                />
                <span className="input-group-addon" onClick={() => this.setState({ passwordVisible: true })}>
                  <FaRegEye />
                </span>
              </div>
            )}
          </div>

          <a className="forgot-password-link" href={forgotPasswordPath}>Forgot Password</a>

          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <button type="submit" className="btn btn-submit">Sign In</button>

          <div className="form-footer">
            Don’t have an account yet?
            {' '}
            <a href="https://www.ediscoveryassistant.com/pricing-plans/">Register</a>
          </div>
          <div className="form-footer">
            <a href="/terms_of_use">Terms and Conditions</a>
            &nbsp; &nbsp;
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </form>
      </div>
    );
  }
}

export default SignIn;
