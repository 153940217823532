import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { FaPlus } from 'react-icons/all';

class JurisdictionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdictionSearchValue: '',
      jurisdictionSuggestions: [],
      suggestionHighlighted: false,
    };
  }

  _toggleSuggestionHighlighted = ({ suggestion }) => {
    const { suggestionHighlighted } = this.state;
    if (suggestionHighlighted && suggestion === null) this.setState({ suggestionHighlighted: false });
    if (!suggestionHighlighted && suggestion !== null) this.setState({ suggestionHighlighted: true });
  };

  _selectJurisdictionFromSuggestion = (e, { suggestion }) => {
    const { selectJurisdiction } = this.props;

    if (!Array.isArray(suggestion.jurisdiction)) {
      suggestion.jurisdiction.courts.forEach((court) => selectJurisdiction(court, suggestion.type));
    } else {
      selectJurisdiction(suggestion.jurisdiction, suggestion.type);
    }

    this.setState({ jurisdictionSearchValue: '' });
  };

  _selectJurisdictionFromInput = (e) => {
    const { selectJurisdiction } = this.props;
    const { jurisdictionSearchValue, suggestionHighlighted } = this.state;

    if (e.keyCode !== 13 || suggestionHighlighted) return;

    const _suggestions = [];
    this._filterSuggestionsByValue(jurisdictionSearchValue, _suggestions);

    if (_suggestions.length !== 1) return;

    selectJurisdiction(_suggestions[0].jurisdiction, _suggestions[0].type);
    this.setState({ jurisdictionSearchValue: '' });
  };

  _clearJurisdictionSuggestions = () => {
    this.setState({ jurisdictionSuggestions: [] });
  };

  _setJurisdictionSearchValue = (e, { newValue }) => {
    this.setState({ jurisdictionSearchValue: newValue });
  };

  _filterSuggestionsByValue = (value, suggestions) => {
    const { jurisdictions } = this.props;
    const _inputValue = value.trim().toLowerCase();
    const _inputLength = _inputValue.length;

    (jurisdictions.State || []).forEach((jurisdiction) => jurisdiction[0].toLowerCase()
      .slice(0, _inputLength) === _inputValue && suggestions.push({ jurisdiction, type: 'state' }));
    (jurisdictions.Federal || []).forEach((jurisdiction) => jurisdiction.name.toLowerCase()
      .slice(0, _inputLength) === _inputValue && suggestions.push({ jurisdiction, type: 'federal' }));
    (jurisdictions.Administrative || []).forEach((jurisdiction) => jurisdiction[0].toLowerCase()
      .slice(0, _inputLength) === _inputValue && suggestions.push({ jurisdiction, type: 'admin' }));
  };

  _setJurisdictionSuggestions = ({ value }) => {
    const _suggestions = [];
    this._filterSuggestionsByValue(value, _suggestions);
    this.setState({ jurisdictionSuggestions: _suggestions });
  };

  _renderJurisdictionSuggestion = (suggestion) => (
    <div>
      { suggestion.type === 'federal' ? suggestion.jurisdiction.name : suggestion.jurisdiction[0]}
    </div>
  );

  render() {
    const { jurisdictionSearchValue, jurisdictionSuggestions } = this.state;
    const {
      resultsExist,
      selectJurisdiction,
      openModal,
      selectedJurisdictions,
      jurisdictions,
      clearAllJurisdictions,
      renderFederalJurisdictionTags,
    } = this.props;

    const selectedJurisdictionCount = selectedJurisdictions.state.length + selectedJurisdictions.federal.length
            + selectedJurisdictions.admin.length;

    const jurisdictionInputProps = {
      placeholder: 'Select Jurisdiction(s)',
      value: jurisdictionSearchValue,
      onChange: this._setJurisdictionSearchValue,
      onKeyDown: this._selectJurisdictionFromInput,
    };

    return (
      <div className="flex-container">
        <div className="search-tags">
          {
                        selectedJurisdictionCount !== 0 && (
                          <ul className="tags tags-container search-tags-container">
                              {selectedJurisdictions.state.length === jurisdictions.State.length
                                ? (
                                  <li>
                                    <a
                                      href="#"
                                      className="tag active"
                                      onClick={() => clearAllJurisdictions('state')}
                                    >
                                      All States&nbsp;&nbsp;&nbsp;✕
                                    </a>
                                  </li>
                                ) : selectedJurisdictions.state.map((jurisdiction) => (
                                  <li key={jurisdiction[0]}>
                                    <a href="#" className="tag active" onClick={() => selectJurisdiction(jurisdiction, 'state')}>
                                      {jurisdiction[0]}
                                  &nbsp;&nbsp;&nbsp;
                                      ✕
                                    </a>
                                  </li>
                                ))}
                              {renderFederalJurisdictionTags()}
                              {selectedJurisdictions.admin.length === jurisdictions.Administrative.length
                                ? (
                                  <li>
                                    <a
                                      href="#"
                                      className="tag active"
                                      onClick={() => clearAllJurisdictions('admin')}
                                    >
                                      All Administrative&nbsp;&nbsp;&nbsp;✕
                                    </a>
                                  </li>
                                ) : selectedJurisdictions.admin.map((jurisdiction) => (
                                  <li key={jurisdiction[0]}>
                                    <a href="#" className="tag active" onClick={() => selectJurisdiction(jurisdiction, 'admin')}>
                                      {jurisdiction[0]}
&nbsp;&nbsp;&nbsp;✕
                                    </a>
                                  </li>
                                ))}
                          </ul>
                        )
                    }
          {
                        ((!resultsExist && selectedJurisdictionCount < 3)
                            || (resultsExist && selectedJurisdictionCount < 1)) && (
                            <Autosuggest
                              suggestions={jurisdictionSuggestions}
                              onSuggestionsFetchRequested={this._setJurisdictionSuggestions}
                              onSuggestionsClearRequested={this._clearJurisdictionSuggestions}
                              getSuggestionValue={(suggestion) => (suggestion.type === 'federal'
                                ? suggestion.jurisdiction.name : suggestion.jurisdiction[0])}
                              renderSuggestion={this._renderJurisdictionSuggestion}
                              inputProps={jurisdictionInputProps}
                              onSuggestionSelected={this._selectJurisdictionFromSuggestion}
                              onSuggestionHighlighted={this._toggleSuggestionHighlighted}
                            />
                        )
                    }
          <button type="button" className="jurisdiction-button" onClick={openModal}>
            <FaPlus />
          </button>
          {selectedJurisdictionCount !== 0 && (
          <>
            <span style={{ flex: 1 }} />
            <span className="clear-tags" onClick={() => clearAllJurisdictions()}>✕</span>
          </>
          )}
        </div>
      </div>
    );
  }
}

JurisdictionFilter.propTypes = {
  resultsExist: PropTypes.bool.isRequired,
  jurisdictions: PropTypes.object.isRequired,
  selectJurisdiction: PropTypes.func.isRequired,
  selectedJurisdictions: PropTypes.object.isRequired,
  clearAllJurisdictions: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  renderFederalJurisdictionTags: PropTypes.func.isRequired,
};

export default JurisdictionFilter;
