import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import AccountUpdatedModal from '../../../account/account_modals/AccountUpdatedModal';

describe('account updated Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<AccountUpdatedModal toggleAccountUpdatedModalVisible={() => {}} />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders modal and close click', async () => {
    const close = jest.fn(() => {});
    const container = render(
      <AccountUpdatedModal toggleAccountUpdatedModalVisible={close} />,
    );
    const button = container.queryByText('✕');
    expect(button).toBeInTheDocument();
    fireEvent.click(button);
    expect(close).toHaveBeenCalledTimes(1);
  });
});
