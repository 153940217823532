// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="new-modal expired-subscription-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <button
        className="button-clear pull-right"
        onClick={[Function]}
        type="button"
      >
        ✕
      </button>
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
          <h4>
            Your
             
            Individual
             
            Plan has expired. To continue using eDiscovery Assistant, please renew your subscription using the button below, contact the Billing Contact for your account, or reach out to us at
             
            <a
              href="mailto:support@ediscoveryassistant.com"
            >
              support@ediscoveryassistant.com
            </a>
            .
          </h4>
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p
          className="question"
        >
          Continue using eDiscovery Assistant?
        </p>
        <button
          className="modal-button"
          onClick={[Function]}
          type="button"
        >
          Renew
        </button>
      </div>
    </div>
  </div>
</div>
`;
