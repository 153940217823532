// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="login-modal new-modal"
  data-testid="modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <div
        className="circle"
      />
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Thank you for visiting eDiscovery Academy. For access to additional content and features within the Academy and eDiscovery Assistant, please login, create a new paid account, or contact us at  
          <a
            href="mailto: support@ediscoveryassistant.com?subject=Access to eDiscovery Academy"
          >
            support@ediscoveryassistant.com
          </a>
          .
        </p>
        <div
          className="button-row"
        >
          <button
            className="modal-button"
          >
            <a
              href="/registrations/new/individual"
              style={
                Object {
                  "color": "white",
                  "textDecoration": "none",
                }
              }
              target="_blank"
            >
              Create Account
            </a>
          </button>
          <button
            className="modal-button modal-button-outline"
          >
            <a
              href="/login"
              style={
                Object {
                  "color": "#343B64",
                  "textDecoration": "none",
                }
              }
            >
              Login
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
