import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FiChevronDown, FiChevronUp, FiTrash } from 'react-icons/fi';
import { GoSettings, GoPlus } from 'react-icons/go';
import Api from '../../../services/api';

export default function Index({ categories }) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [forms, setForms] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState('');
  const [published, setPublished] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortDir, setSortDir] = useState('asc');

  const fetchData = (opts) => {
    Api.admin.templates.get(opts).then(({ status, data }) => {
      if (status !== 'OK') return;
      setForms(data.templates);
      setTotalCount(data.totalCount);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    });
  };

  const handlePage = (page) => {
    fetchData({
      page, category, published, sortBy, sortDir,
    });
  };

  const handleFilter = () => {
    fetchData({
      page: 1, category, published, sortBy, sortDir,
    });
  };

  const handleDelete = (form) => {
    const res = window.confirm('Are you sure?');
    if (!res) return;
    Api.admin.templates.delete(form.id).then(({ status }) => {
      if (status !== 'OK') return;
      fetchData({
        page: currentPage, category, published, sortBy, sortDir,
      });
    });
  };

  const handleSort = (field) => {
    let dir = 'asc';
    if (sortBy === field) {
      if (sortDir === 'asc') dir = 'desc';
      if (sortDir === 'desc') dir = 'asc';
    }
    setSortBy(field);
    setSortDir(dir);
    fetchData({
      page: currentPage, category, published, sortBy: field, sortDir: dir,
    });
  };

  useEffect(() => {
    fetchData({ page: 1, sortBy, sortDir });
  }, []);

  return (
    <div className={`admin-page react-admin-forms ${filtersOpen ? 'filters-open' : ''}`}>
      <div className="header">
        <h1>Forms</h1>
        <button type="button" className={`filters-button ${filtersOpen ? 'active' : ''}`} onClick={() => setFiltersOpen(!filtersOpen)}>
          <GoSettings />
        </button>
      </div>
      <div className="table">
        <div className="table-header">
          <h2>
            {totalCount}
            {' '}
            Results
          </h2>
          <a className="button" href="/admin/templates/new">
            Add New
            <GoPlus />
          </a>
        </div>

        <table>
          <thead>
            <tr>
              <th width="60%" onClick={() => handleSort('name')}>
                Form Name
                { sortBy === 'name' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'name' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th>Category</th>
              <th onClick={() => handleSort('updated_at')}>
                Last Updated
                { sortBy === 'updated_at' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'updated_at' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th onClick={() => handleSort('created_at')}>
                Creation Date
                { sortBy === 'created_at' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'created_at' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th onClick={() => handleSort('status')}>
                Status
                { sortBy === 'status' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'status' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            { (forms || []).map((form) => {
              const isPublished = form.published_revision.published;
              return (
                <tr key={form.id}>
                  <td width="60%"><a href={`/admin/templates/${form.id}/edit`}>{form.draft_revision.name}</a></td>
                  <td>{form.draft_revision.categories.map((c) => c.name).join(', ')}</td>
                  <td>{moment(form.draft_revision.updated_at).format('MM/DD/YY')}</td>
                  <td>{moment(form.draft_revision.created_at).format('MM/DD/YY')}</td>
                  <td>
                    <span className={`tag ${isPublished ? 'published' : 'draft'}`}>
                      { isPublished ? 'Published' : 'Draft' }
                    </span>
                  </td>
                  <td>
                    <button type="button" className="delete-button" onClick={() => handleDelete(form)}>
                      <FiTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage - 1)}
          >
            &lt;
          </button>
          <span>
            Page
            {' '}
            {currentPage}
            /
            {totalPages}
          </span>
          <button
            disabled={currentPage >= totalPages}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      </div>

      { filtersOpen && (
        <div className="sidebar">
          <div className="sidebar-header">
            <h2>Filter By</h2>
          </div>
          <div className="sidebar-body" onKeyDown={(e) => e.key === 'Enter' && handleFilter()}>
            <div className="form-group">
              <select className="form-control" value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="">Category</option>
                {categories.map((cat) => (
                  <option value={cat.id}>{cat.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={published} onChange={(e) => setPublished(e.target.value)}>
                <option value="">Published or Unpublished</option>
                <option value="1">Published</option>
                <option value="0">Unpublished</option>
              </select>
            </div>

            <button type="button" className="button" onClick={handleFilter}>Filter</button>
          </div>
        </div>
      )}
    </div>
  );
}
