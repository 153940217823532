import PropTypes from 'prop-types';
import React from 'react';

export default class VideoPlayer extends React.Component {
  render() {
    const { video, width, height } = this.props;
    const { domain, url } = video;
    let player;
    if (domain === 'youtube') {
      player = (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe width={width} height={height} src={`https://www.youtube.com/embed/${url.split('=')[1]}`} frameBorder="0" allowFullScreen />
      );
    }
    if (domain === 'vimeo') {
      player = (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe width={width} height={height} src={`https://player.vimeo.com/video/${url.split('/').pop()}`} frameBorder="0" mozallowfullscreen allowFullScreen />
      );
    }
    if (domain === 'wistia') {
      player = (
        <>
          <script
            src={`https://fast.wistia.com/embed/medias/${url.split('/').pop()}.jsonp`}
            async
          />
          <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
          <div
            className="wistia_responsive_padding"
            style={{
              padding: '56.25% 0 0 0', position: 'relative',
            }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                left: 0, position: 'absolute', top: 0, width, height,
              }}
            >
              <div
                className="wistia_embed wistia_async_o1q6sbw68r videoFoam=true"
                style={{ width, height }}
              >
                &nbsp;
              </div>
            </div>
          </div>
        </>
      );
    }
    return player;
  }
}

VideoPlayer.propTypes = {
  video: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

VideoPlayer.defaultProps = {
  width: '100%',
  height: 500,
};
