// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="new-modal expired-subscription-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <button
        className="button-clear pull-right"
        onClick={[Function]}
        type="button"
      >
        ✕
      </button>
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
          <h4>
            Your free trial has expired. To continue using eDiscovery Assistant, click below to add payment method and sign up for an individual plan.
          </h4>
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p
          className="question"
        >
          Continue using eDiscovery Assistant?
        </p>
        <button
          className="modal-button"
          onClick={[Function]}
          type="button"
        >
          Sign Up for individual plan
        </button>
        <p
          className="note"
        >
          If you want to upgrade to either Team or Enterprise, contact us via
           
          <a
            href="mailto:support@ediscoveryassistant.com"
          >
            support@ediscoveryassistant.com
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
`;
