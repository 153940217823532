import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import api from '../../../services/api';

import Bookmarks from '../../bookmarks/Bookmarks';

const currentUser = {
  account_id: 378,
  created_at: '2021-04-28T01:39:22.011-06:00',
  customer_id: null,
  deferred: true,
  deprecated_god: null,
  disabled: false,
  email: 'email@mail.com',
  extend_trial: false,
  extend_trial_date: null,
  first_name: 'First',
  hubspot_id: null,
  id: 1,
  isAdmin: true,
  imported: false,
  last_name: 'Second',
  mixpanel_id: null,
  organization: null,
  password_set: true,
  preview_code_id: null,
  subscription_id: 1,
  trial_start: null,
  unique_session_id: 'WyUFHmaafKdbsKgdx2Do',
  updated_at: '2021-06-04T00:04:58.952-06:00',
  utm_campaign: '',
  utm_source: '',
};

const favorites = [
  {
    id: 659,
    category: 'rules',
    type: 'StateRule',
    number: 'Alaska R. Civ. P. 16',
    published: true,
    rule_id: 10,
    favorited_at: '2022-06-10T06:45:53.329-06:00',
  },
  {
    id: 95880,
    category: 'case_digests',
    name: 'Estate of Jensen v. Cnty. of Snohomish',
    slug: '35410-rossbach-v-montefiore-med-ctr',
    published: true,
    case_digest_id: 35410,
    favorited_at: '2022-06-10T06:45:53.329-06:00',
  },
  {
    id: 169,
    category: 'checklists_and_forms',
    description: '<p>sddsfsddsgdgsf</p>',
    name: 'some checklist',
    published: true,
    checklist_id: 100,
    favorited_at: '2022-06-10T06:45:53.329-06:00',
  },
  {
    id: 231,
    category: 'glossary_terms',
    definition: 'ASCII is the standard format for computer text',
    name: 'ASCII (American Standard Code for Information Interchange)',
    published: true,
    glossary_term_id: 706,
    favorited_at: '2022-06-10T06:45:53.329-06:00',
  },
  {
    id: 757,
    category: 'cite_lists',
    name: 'My third Cite List',
    shared_users: 5,
    user_id: 1121,
  },
];

api.rules.federal.unfavorite = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ federal: [] }) });
api.rules.state.unfavorite = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ state: [] }) });
api.rules.district.unfavorite = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ district: [] }) });
api.rules.regulatory.unfavorite = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ regulatory: [] }) });
api.rules.aba.unfavorite = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ aba: [] }) });

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ rule: [] }) });

describe('Bookmarks', () => {
  it('renders correctly with props', () => {
    render(<Bookmarks
      favorites={favorites}
      currentUser={currentUser}
    />);
    expect(screen).toMatchSnapshot();
  });

  it('filters categories correctly', () => {
    render(<Bookmarks
      favorites={favorites}
      currentUser={currentUser}
    />);

    userEvent.click(screen.getByTestId('rules'));
    expect(screen.getByText('Alaska R. Civ. P. 16')).toBeInTheDocument();

    userEvent.click(screen.getByTestId('case_digests'));
    expect(screen.getByText('Estate of Jensen v. Cnty. of Snohomish')).toBeInTheDocument();

    userEvent.click(screen.getByTestId('checklists_and_forms'));
    expect(screen.getByText('some checklist')).toBeInTheDocument();

    userEvent.click(screen.getByTestId('glossary_terms'));
    expect(screen.getByText('ASCII (American Standard Code for Information Interchange)')).toBeInTheDocument();

    userEvent.click(screen.getByTestId('cite_lists'));
    expect(screen.getByText('My third Cite List')).toBeInTheDocument();
    expect(screen.getByText('Shared with 5 users')).toBeInTheDocument();
  });
});
