/**
 * @jest-environment jsdom
 */

import React from 'react';
import renderer from 'react-test-renderer';

import JurisdictionFilter from '../../../search/src/JurisdictionFilter';

const jurisdictions = {
  Administrative: [
    ['Administrative #1', '1'],
    ['Administrative #2', '2'],
  ],
  Federal: [
    { name: 'Federal #1', id: 'circuit-1' },
    { name: 'Federal #2', id: 'circuit-2' },
  ],
  State: [
    ['State #1', '3', ['3', '4']],
    ['State #2', '5', ['6', '7']],
  ],
};

const selectedJurisdictions = {
  federal: [
    { name: 'Federal #1', id: 'circuit-1' },
  ],
  state: [],
  admin: [],
};

it('renders correctly with props', () => {
  const tree = renderer.create(<JurisdictionFilter
    search={() => {}}
    resultsExist={false}
    jurisdictions={jurisdictions}
    selectedJurisdictions={selectedJurisdictions}
    selectJurisdiction={() => {}}
    openModal={() => {}}
    renderFederalJurisdictionTags={() => {}}
    clearAllJurisdictions={() => {}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
