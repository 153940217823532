import React from 'react';

export default function SharedModal({ sharedModalUsers, closeModal }) {
  return (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md delete-modal">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            { sharedModalUsers.length > 0 ? (
              <p className="delete-text">
                Your Cite List has been shared with
                {' '}
                {sharedModalUsers.map((u) => u.name).join(', ')}
                {' '}
                and the user(s) will receive an email notifying them.
                The Cite List will appear in Shared with Me under the User’s Cite Lists.
              </p>
            ) : (
              <p className="delete-text">
                Please select the user(s) you would like to share this list with.
              </p>
            )}
            <div className="button-row">
              <div className="modal-button" onClick={closeModal}>
                OK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
