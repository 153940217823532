import React from 'react';
import renderer from 'react-test-renderer';

import RelatedForm from '../../related_items/RelatedForm';

const form = {
  id: 1,
  published_revision: {
    name: 'This is a name for Form',
  },
};

it('renders correctly with props', () => {
  const tree = renderer.create(<RelatedForm
    relatedForm={form}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
