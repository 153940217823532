import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import api from '../../services/api';

export default function SessionModal({ closeModal }) {
  useEffect(() => {
    // logout user in 60 seconds
    const timeoutId = setTimeout(() => {
      const a = document.createElement('a');
      a.href = '/logout';
      a.dataset.method = 'delete';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }, 60_000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleClose = () => {
    api.account.user.put({ user: { current_sign_in_at: Date.now() } });
    closeModal();
  };

  return (
    <div className="login-modal new-modal">
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="circle" />
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            <p>
              Your session is about to expire. Do you wish to continue?
            </p>
            <div className="button-row">
              <button className="modal-button" onClick={handleClose} data-testid="session-modal">
                <div>Continue</div>
              </button>
              <button className="modal-button modal-button-outline">
                <a data-method="delete" href="/logout">Logout</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SessionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
