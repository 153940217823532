import React from 'react';
import Checklist from '../../checklists/Checklist';

export default function Preview({ checklist, items }) {
  return (
    <div className="admin-page react-admin-checklists-preview">
      <div className="header">
        <a type="button" className="back" href={`/admin/checklists/${checklist.id}/edit`}>
          &lt; Back To Checklist
        </a>
        <span className="attention">ATTENTION: THIS IS FOR PREVIEW PURPOSE ONLY, NOTHING CAN BE CLICKED AND UPDATED</span>
        <span className="mode">Preview Mode</span>
      </div>
      <Checklist
        checklist={checklist}
        checklistItems={items}
        favorites={{}}
        preview
      />
    </div>
  );
}
