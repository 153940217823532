import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render, screen } from '@testing-library/react';

import Account from '../../account/Account';

const account = {
  id: 1,
  name: 'User',
  account_type: 'individual',
};

const payments = [
  { date: 'June 04, 2021', amount: '$243.00' },
  { date: 'June 03, 2021', amount: '$243.00' },
];

const plans = [
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 12900,
    id: 9,
    interval: 'monthly',
    name: 'Monthly Subscription',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay1n6YGaEucre',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
  {
    active: true,
    created_at: '2019-08-20T18:08:22.597-06:00',
    default_amount: 0,
    id: 6,
    interval: 'none',
    name: 'Trial',
    plan_type: 'trial',
    stripe_plan_id: null,
    updated_at: '2019-08-20T18:08:22.597-06:00',
  },
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 118800,
    id: 10,
    interval: 'yearly',
    name: 'Annual Subscription',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay32J3e0wSmIZ',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
];

const user = {
  account_id: 1,
  email: 'email@mail.com',
  first_name: 'First',
  id: 1,
  last_name: 'Last',
  settings: {
    email_preferences: {
      send_case_law_email: true,
    },
  },
  subscription_id: 1,
  user_roles: [],
};

const userRoles = [
  {
    id: 1,
    name: 'Attorney – Partner',
  },
  {
    id: 2,
    name: 'Attorney – Associate',
  },
  {
    id: 3,
    name: 'Attorney – Solo Practitioner',
  },
  {
    id: 5,
    name: 'Litigation Support Professional',
  },
  {
    id: 7,
    name: 'eDiscovery Consultant',
  },
  {
    id: 15,
    name: 'In-House Counsel',
  },
  {
    id: 11,
    name: 'Student',
  },
  {
    id: 12,
    name: 'Professor',
  },
  {
    id: 4,
    name: 'Paralegal',
  },
  {
    id: 13,
    name: 'Paralegal  or Legal Support Professional',
  },
  {
    id: 14,
    name: 'Information Management or Librarian',
  },
  {
    id: 6,
    name: 'Information Management Professional',
  },
];

describe('Account', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<Account
      user={user}
      account={account}
      subscription={{}}
      plans={plans}
      userRoles={userRoles}
      payments={payments}
      editPasswordPath="/registrations/edit_password"
    />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders payments history with props', async () => {
    render(<Account
      editPasswordPath="/registrations/edit_password"
      user={user}
      userRoles={userRoles}
      subscription={{}}
      plans={plans}
      payments={payments}
      account={account}
    />);
    const paymentHistory = await screen.findByText(/Billing Info/i);
    fireEvent.click(paymentHistory);
    expect(screen.getByText(/Payment History/)).toBeInTheDocument();
  });

  it('renders payments notifications toggle with props', async () => {
    render(<Account
      editPasswordPath="/registrations/edit_password"
      user={user}
      userRoles={userRoles}
      subscription={{}}
      plans={plans}
      payments={payments}
      account={account}
    />);
    const notificationsTab = await screen.findByText(/Notifications/i);
    fireEvent.click(notificationsTab);
    expect(screen.getByText(/Receive a weekly update with the latest cases./)).toBeInTheDocument();
  });

  it('renders selected roles', async () => {
    const container = render(<Account editPasswordPath="/registrations/edit_password" user={user} userRoles={userRoles} subscription={{}} plans={plans} payments={payments} />);
    let svgImage = document.querySelectorAll('svg');
    fireEvent.click(svgImage[10]);
    svgImage = document.querySelectorAll('svg');
    fireEvent.click(svgImage[11]);
    expect(container.queryByText('Information Management Professional').firstElementChild).toHaveClass('selected');
    expect(container.queryByText('Information Management or Librarian').firstElementChild).toHaveClass('selected');
    svgImage = document.querySelectorAll('svg');
    fireEvent.click(svgImage[11]);
    expect(container.queryByText('Information Management Professional').firstElementChild).not.toHaveClass('selected');
  });
});
