import React from 'react';
import renderer from 'react-test-renderer';

import { VideoContent } from '../../../learning_center/DetailsContent/VideoContent';
import { videoItem } from '../../__mocks__/lc-data';

it('renders correctly with props', () => {
  const tree = renderer.create(
    <VideoContent
      mediaItem={videoItem}
      tab="about"
      setTab={() => {}}
      isAdmin
      linkedContent={[]}
    />,
  ).toJSON();
  expect(tree).toMatchSnapshot();
});
