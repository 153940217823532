import React from 'react';
import renderer from 'react-test-renderer';

import AudioPlayer from '../../audio/AudioPlayer';

it('renders correctly with props', () => {
  const tree = renderer.create(<AudioPlayer
    audio={{ url: 'https://music.com/audio.mp3' }}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
