import React, { useState } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import CreateProfile from './CreateProfile';
import Billing from './Billing';
import TermsAndServices from './TermsAndServices';
import api from '../../services/api';
import roles from './roles';

function splitFullName(name) {
  const nameArr = name.split(/\s+/);
  if (nameArr.length === 1) return { first_name: nameArr[0] };

  return {
    first_name: nameArr.slice(0, -1).join(' '),
    last_name: nameArr.pop(),
  };
}

const stepTitles = {
  1: 'Create Your Account',
  2: 'Complete Profile',
  3: 'Terms Agreement',
  4: 'Billing Method',
};

export default function Registration({
  stripeKey, currentPlan, accountId, accountName, utmSource, utmCampaign, currentUser,
}) {
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState({
    name: currentUser ? [currentUser.first_name, currentUser.last_name].filter(Boolean).join(' ') : '',
    company: currentUser ? currentUser.account.name : accountName || '',
    account_id: accountId || '',
    email: currentUser ? currentUser.account.primary_email : '',
    phone: currentUser ? currentUser.account.phone_number : '',
    newsletter: true,
    password: '',
    password2: '',
    referral_type: 'internet_research',
    referral_other: '',
    referral_name: '',
    referral_company: '',
    company_type: currentUser ? currentUser.account.category_id : '',
    user_roles: currentUser ? currentUser.user_roles.map((role) => {
      if (roles.find((r) => r.value === role.id.toString())) return role.id.toString();
      return role.name;
    }) : [],
    permission: 'billing_and_seat',
    terms_of_use: false,
    plan: currentPlan,
    stripe_token: null,
    utm_source: utmSource || '',
    utm_campaign: utmCampaign || '',
    promo_code: '',
    number_of_seats: currentUser ? currentUser.account.users.filter((user) => user.subscription_id).length : 1,
    seats: currentUser ? currentUser.account.users
      .filter((user) => user.subscription_id)
      .map((user) => ({
        first_name: user.first_name, last_name: user.last_name, email: user.email,
      })) : [{ first_name: '', last_name: '', email: '' }],
  });
  const planName = currentPlan?.plan_type === 'trial' ? '7 Day Trial' : currentPlan?.name;

  const nextStep = () => setCurrentStep(currentStep + 1);
  const previousStep = () => setCurrentStep(currentStep - 1);

  const handleSubmit = () => {
    setErrors(null);
    setStatus('creating');
    api.register({
      ...data,
      terms_of_use: data.terms_of_use ? '1' : '0',
      ...splitFullName(data.name),
    }).then((res) => {
      if (!res.errors) {
        setStatus('success');
        window.location.href = `/${data.plan.name.includes('Academy') ? 'academy' : ''}`;
        return;
      }

      setStatus('error');
      setErrors(Object.keys(res.errors).map((key) => `${key} ${res.errors[key]}`));
    });
  };

  return (
    <div className="auth-page new-register">
      <div className="register-header">
        <img src="/logo.svg" className="trademark-header" />
      </div>
      <div className="dots">
        <span className={`dot ${currentStep === 1 && 'active'}`} />
        <span className={`dot ${currentStep === 2 && 'active'}`} />
        { data.plan.plan_type !== 'trial' && <span className={`dot ${currentStep === 3 && 'active'}`} />}
      </div>
      <div className="registration-title">
        <h1>{stepTitles[currentStep]}</h1>
        <h2>{ `${planName} Plan` }</h2>
      </div>
      <StripeProvider apiKey={stripeKey}>
        <Elements fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Rubik' }]}>
          <form className="register-form container">
            { (errors || []).map((error, i) => (
              <div className="alert alert-danger" key={i.toString()}>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true"> &times; </span>
                </button>
                { error }
              </div>
            ))}
            { currentStep === 1 && (
              <CreateProfile
                data={data}
                currentUser={currentUser}
                onChange={setData}
                onSubmit={nextStep}
              />
            )}
            { currentStep === 2 && (
              <TermsAndServices
                status={status}
                data={data}
                onChange={setData}
                onCancel={previousStep}
                onSubmit={() => (data.plan.plan_type === 'trial' ? handleSubmit() : nextStep())}
              />
            )}
            { currentStep === 3 && (
              <Billing
                status={status}
                data={data}
                onChange={setData}
                onCancel={previousStep}
                onSubmit={handleSubmit}
              />
            )}
          </form>
        </Elements>
      </StripeProvider>
    </div>
  );
}
