import React from 'react';
import '@testing-library/jest-dom';
import {
  render, screen,
} from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import Billing from '../../auth/Billing';

const data = {
  name: 'Name',
  company: 'Company',
  email: 'email',
  password: 'password',
  referral_company: '',
  user_roles: [],
  plan: {
    name: 'Individual',
    default_amount: 12000,
    plan_type: 'credit_card',
  },
};

const validateCardData = {
  name: 'Name',
  company: 'Company',
  email: 'email',
  password: 'password',
  referral_company: '',
  user_roles: [],
  terms_of_use: true,
  plan: {
    name: 'Individual',
    default_amount: 12000,
    plan_type: 'credit_card',
  },
};

const stripe = {
  createToken: () => Promise.resolve({
    token: {
      id: 312311,
    },
  }),
};

describe('Billing', () => {
  it('renders correctly with props', () => {
    render(<Billing data={data} />);
    expect(screen).toMatchSnapshot();
  });

  it('correctly validates card field', () => {
    render(<Billing
      data={validateCardData}
      onChange={() => {}}
      onSubmit={() => {}}
      stripe={stripe}
    />);

    const createAccount = screen.getByText(/Create account/i);
    userEvent.click(createAccount);
    // expect(document.getElementsByClassName('has-error').length).toBeGreaterThan(0);
  });
});
