import React from 'react';
import renderer from 'react-test-renderer';
import { fireEvent, render } from '@testing-library/react';
import api from '../../../services/api';

import History from '../../history/History';

global.fetch = jest.fn().mockResolvedValue({ json: () => Promise.resolve({}) });
api.history.allPaginated = jest.fn().mockResolvedValue({
  count: 2157,
  history: [
    {
      id: 518201,
      user_id: 1121,
      controller: 'case_digests',
      action: 'index',
      fullpath: '/case_law?sort_by=des_decision_date&page=1&results_per_page=25&include_unpublished=true&type=issue&tag_ids_query=8',
      params: {
        sort_by: 'des_decision_date',
        page: '1',
        results_per_page: '25',
        include_unpublished: 'true',
        type: 'issue',
        tag_ids_query: '8',
        controller: 'case_digests',
        action: 'index',
        tag_ids: [],
        court_ids: [],
      },
      properties: {
        category: 'case_law_search',
        tag_names: [
          'Spoliation',
        ],
        and_tags: [],
        or_tags: [],
        excluded_tag: [],
        jurisdiction_tags: [{ court: { court_type: 'administrative' } }, { court: { court_type: 'federal' } }, { court: { court_type: 'state' } }],
        date_filters: {
          range: '',
          start_date: '',
          end_date: '',
        },
        total_count: 2298,
      },
      created_at: '2023-05-31T01:43:17.071-06:00',
      updated_at: '2023-05-31T01:43:17.071-06:00',
    },
    {
      id: 517985,
      user_id: 1121,
      controller: 'district_rules',
      action: 'show',
      fullpath: '/district_rules/4889',
      params: {
        controller: 'district_rules',
        action: 'show',
        id: '4889',
      },
      properties: {
        category: 'rule',
        rule_number: 'Local Rule 26.1, Outline for Fed.R.Civ.P. 26(f) Report',
        rule_type: 'district',
      },
      created_at: '2023-05-26T04:42:12.135-06:00',
      updated_at: '2023-05-26T04:42:12.135-06:00',
    },
  ],
  status: 'OK',
});

it('renders correctly with props', () => {
  const tree = renderer.create(<History
    searchHistoryCounter={1473}
    ruleHistoryCounter={226}
    checklistAndFormHistoryCounter={445}
    glossaryTermHistoryCounter={13}
    stateCourtsCounter={152}
    administrativeCourtsCounter={42}
    federalCourtsCounter={204}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('render header and click with current user', async () => {
  const container = render(
    <History
      searchHistoryCounter={1473}
      ruleHistoryCounter={226}
      checklistAndFormHistoryCounter={445}
      glossaryTermHistoryCounter={13}
      stateCourtsCounter={152}
      administrativeCourtsCounter={42}
      federalCourtsCounter={204}
    />,
  );
  fireEvent.click(container.getByTestId('all'));
  fireEvent.click(container.getByTestId('search'));
  fireEvent.click(container.getByTestId('rules'));
  fireEvent.click(container.getByTestId('checklists_and_forms'));
  fireEvent.click(container.getByTestId('glossary_terms'));
});
