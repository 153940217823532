import PropTypes from 'prop-types';
import React from 'react';
import api from '../../../services/api';

export default class BulkModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
    };
  }

  handleChange = (e) => {
    const value = Array.from(e.target.selectedOptions, (option) => parseInt(option.value));
    this.setState({ values: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { values } = this.state;
    const { allResultIds } = this.props;
    const data = { tag_ids: values, case_digest_ids: allResultIds };
    api.case_digests.bulkTags(data).then((res) => alert(res.response.message));
  };

  render() {
    const { closeModal, tags } = this.props;
    const tagsArray = tags.map((t) => ({
      id: t.id,
      name: t.name,
    }));
    return (
      <div className="new-modal bulk-modal">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">
                <button
                  type="button"
                  className="button-clear pull-right"
                  onClick={closeModal}
                >
                  ✕
                </button>
                <img className="react-header-logo" src="/logo.svg" alt="" />
              </div>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <form onSubmit={this.handleSubmit}>
                  <label>
                    Select tags to bulk:
                    <select multiple onChange={this.handleChange} className="form-control">
                      {tagsArray.map((tag) => <option key={tag.id} value={tag.id}>{tag.name}</option>)}
                      ;
                    </select>
                  </label>
                  <input type="submit" value="Apply" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BulkModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
