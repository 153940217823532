import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';
import api from '../../../../services/api';

import PaymentModal from '../../../account/account_modals/PaymentModal';
import { plan, paymentMethod } from '../../__mocks__/account-data';

const stripe = {
  createToken: () => Promise.resolve({
    token: {
      id: 312311,
    },
  }),
};

api.account.updatePaymentMethod = () => Promise.resolve({});

describe('Payment Modal snapshots', () => {
  it('renders correctly without props', () => {
    const tree = renderer.create(<PaymentModal
      status="updating"
      closeModal={() => {}}
      plans={[]}
      sripe={stripe}
      onSubmit={() => {}}
    />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders correctly with props', () => {
    const tree = renderer.create(<PaymentModal
      onSubmit={() => {}}
      closeModal={() => {}}
      status=""
      plan={plan}
      paymentMethod={paymentMethod}
      onStatusUpdate={() => {}}
      stripe={stripe}
    />).toJSON();
    expect(tree).toMatchSnapshot();
  });
});

describe('Payment Modal tests', () => {
  it('check for update button', () => {
    const container = render(
      <PaymentModal
        status=""
        closeModal={() => {}}
        plans={[]}
        stripe={stripe}
        onSubmit={() => {}}
      />,
    );
    expect(container.queryByText('Update')).toBeInTheDocument();
  });

  it('submit stipe form', () => {
    const container = render(
      <PaymentModal
        onSubmit={() => {}}
        closeModal={() => {}}
        status=""
        plan={plan}
        paymentMethod={paymentMethod}
        onStatusUpdate={() => {}}
        stripe={stripe}
      />,
    );
    const nameInput = container.queryByPlaceholderText('Card Holder Name');
    fireEvent.change(nameInput, { target: { value: 'Some Name' } });
    fireEvent.click(container.queryByText('Update'));
  });

  it('submit stipe without card holder', () => {
    const container = render(
      <PaymentModal
        onSubmit={() => {}}
        closeModal={() => {}}
        status=""
        plan={plan}
        paymentMethod={paymentMethod}
        onStatusUpdate={() => {}}
        stripe={stripe}
      />,
    );
    fireEvent.click(container.queryByText('Update'));
  });
});
