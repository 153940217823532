import React from 'react';
import renderer from 'react-test-renderer';

import TagList from '../../tags/TagList';

const tags = [
  { id: 1, name: 'tag A' },
  { id: 2, name: 'tag B' },
];

function createNodeMock(element) {
  const firstLink = document.createElement('a');
  const secondLink = document.createElement('a');
  if (element.type === 'div') {
    return {
      querySelectorAll: () => [firstLink, secondLink],
      getBoundingClientRect: () => ({
        bottom: 153.33333206176758,
        height: 28.11111068725586,
        left: 573.8194580078125,
        right: 1589.9027709960938,
        top: 125.22222137451172,
        width: 1016.083312988281,
        x: 573.8194580078125,
        y: 125.22222137451172,
      }),
    };
  }

  return null;
}

it('renders correctly with props', () => {
  const tree = renderer.create(<TagList tags={tags} />, { createNodeMock }).toJSON();
  expect(tree).toMatchSnapshot();
});
