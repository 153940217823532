// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Subscription Modal renders correctly with props 1`] = `
<div
  className="new-modal payment-method-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
    style={
      Object {
        "width": "500px",
      }
    }
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <div
          className="notifications-column"
        >
          Monthly
           
          <div
            className="react-toggle"
            onClick={[Function]}
            onTouchEnd={[Function]}
            onTouchMove={[Function]}
            onTouchStart={[Function]}
          >
            <div
              className="react-toggle-track"
            >
              <div
                className="react-toggle-track-check"
              />
              <div
                className="react-toggle-track-x"
              />
            </div>
            <div
              className="react-toggle-thumb"
            />
            <input
              checked={false}
              className="react-toggle-screenreader-only"
              data-testid="toggle"
              onBlur={[Function]}
              onChange={[Function]}
              onFocus={[Function]}
              type="checkbox"
            />
          </div>
           
          Annual
        </div>
        <div
          className="modal-title"
        >
          <h4>
            Add
             
            Individual
             
            Subscription
          </h4>
          <button
            className="button-clear pull-right"
            onClick={[Function]}
            type="button"
          >
            ✕
          </button>
        </div>
      </div>
      <div
        className="modal-body"
      >
        <div
          className="row"
        >
          <div
            className="col-md-12"
          >
            <div
              className="pricing"
              style={
                Object {
                  "marginTop": "1.1rem",
                }
              }
            >
              <div
                className="pricing-row plan"
              >
                <div>
                   Subscription plan 
                </div>
                <div>
                  Individual
                </div>
              </div>
              <div
                className="row"
              >
                <div
                  className="form-group col-md-8 "
                >
                  <input
                    className="form-control"
                    id="promo_code"
                    name="promo_code"
                    onChange={[Function]}
                    placeholder="Enter Promo Code..."
                    type="text"
                    value=""
                  />
                  
                </div>
                <div
                  className="form-group col-md-4"
                  style={
                    Object {
                      "marginTop": "0",
                    }
                  }
                >
                  <button
                    className="btn btn-promocode"
                    onClick={[Function]}
                    type="button"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div
                className="pricing-row total"
              >
                <div>
                  Sub total
                </div>
                <div>
                  $
                  1349.00
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="modal-button"
          disabled={true}
          onClick={[Function]}
          type="button"
        >
          Updating
        </button>
      </div>
    </div>
  </div>
</div>
`;

exports[`Subscription Modal renders correctly without props 1`] = `
<div
  className="new-modal payment-method-modal"
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <h4>
            No plan available.
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
`;
