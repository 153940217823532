import React from 'react';
import '@testing-library/jest-dom';
import { render } from '@testing-library/react';

import userEvent from '@testing-library/user-event';
import CreateProfile from '../../auth/CreateProfile';

const data = {
  name: 'Name',
  company: 'Company',
  email: 'email',
  password: 'password',
  referral_company: '',
  user_roles: [],
  plan: {
    name: 'Individual',
    default_amount: 12000,
    plan_type: 'credit_card',
  },
};

const validationData = {
  name: '',
  company: 'Company',
  email: 'email',
  password: 'password',
  referral_company: '',
  user_roles: [],
  plan: {
    name: 'Individual',
    default_amount: 12000,
    plan_type: 'credit_card',
  },
};

describe('Create profile', () => {
  it('renders correctly with props', () => {
    const tree = render(<CreateProfile
      data={data}
    />);
    expect(tree).toMatchSnapshot();
  });

  it('validates fields correctly', () => {
    const container = render(<CreateProfile
      data={validationData}
    />);

    const continueButton = container.getByText('Continue');
    userEvent.click(continueButton);
    // nothing changed
    expect(continueButton).toBeInTheDocument();
  });
});
