import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';

import DynamicSearchParams from '../../cite_lists/DynamicSearchParams';

const query_with_tags = '?court_ids=2164%2C2198%2C2109%2C2220&q=Facebook&daterange=between&start_date=12%2F13%2F2014&end_date=01%2F22%2F2024&sort_by=des_decision_date&results_per_page=25&case_title=Torgersen&include_unpublished=true&type=advanced&tag_ids_query=8+AND+14+AND+10&judge=some_judge';
const query = '?q=Facebook&daterange=between&start_date=12%2F13%2F2014&end_date=01%2F22%2F2024&sort_by=des_decision_date&results_per_page=25&case_title=Torgersen&include_unpublished=true&type=advanced';
const tags = [
  { id: 8, name: 'First Tag' },
  { id: 10, name: 'Second Tag' },
  { id: 14, name: null },
  { id: 15, name: 'Third Tag' },
];
const jurisdictions = {
  Administrative: [['Air Force Board of Review', '2164'], ['Air Force Court of Criminal Appeals', '2198']],
  Federal: [{
    courts: [{
      name: 'Fed. Cir.', type: 'circuit', id: '2109', class: 'circuit-2079',
    }],
    id: 'circuit-2079',
    name: 'Fed. …',
  }],
  State: [['Alabama', '2220', 1, [1992, 2081, 2441]]],
};

describe('Dynamic Params', () => {
  it('renders correctly with props', () => {
    render(<DynamicSearchParams tags={tags} query={query_with_tags} jurisdictions={jurisdictions} />);
    expect(screen).toMatchSnapshot();
  });

  it('renders correctly without props', () => {
    render(<DynamicSearchParams tags={tags} jurisdictions={jurisdictions} />);
    expect(screen).toMatchSnapshot();
  });

  it('renders correctly without tag_ids query', () => {
    render(<DynamicSearchParams tags={tags} query={query} jurisdictions={jurisdictions} />);
    expect(screen).toMatchSnapshot();
  });
});
