import React from 'react';
import renderer from 'react-test-renderer';

import RegulatoryRulesNavigator from '../../../rules/src/RegulatoryRulesNavigator';

const regulatoryAgencies = [
  {
    id: 1,
    name: 'Agency #1',
    regulatory_rules: [
      {
        id: 2,
        published_revision: {
          name: 'Name for revision #1',
          tag_list: [],
        },
      },
    ],
  },
];

it('renders correctly with props', () => {
  const tree = renderer.create(<RegulatoryRulesNavigator
    regulatoryAgencies={regulatoryAgencies}
    setRegulatoryFilter={() => {}}
    regulatoryFilter=""
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
