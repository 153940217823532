import React, { useEffect, useRef, useState } from 'react';

export function DropdownItem({ children, onClick }) {
  return (
    <button
      type="button"
      className="dropdown-item"
      onClick={(e) => { e.preventDefault(); onClick(); }}
    >
      {children}
    </button>
  );
}

function Dropdown({
  children, className, buttonContent, align,
}) {
  const actionMenuRef = useRef();

  const [opened, setOpened] = useState(false);
  useEffect(
    () => {
      const listener = (event) => {
        if (!actionMenuRef.current || actionMenuRef.current.contains(event.target)) {
          return;
        }

        setOpened(false);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [actionMenuRef],
  );

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`dropdown-container ${className || ''} ${opened ? 'opened' : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <button
        type="button"
        className="button"
        onClick={() => setOpened(true)}
      >
        {buttonContent}
      </button>

      { opened && (
        <div className={`dropdown ${align || 'left'}`} ref={actionMenuRef}>
          {
            React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  onClick: () => {
                    setOpened(false);
                    if (child.props.onClick) child.props.onClick();
                  },
                });
              }
              return child;
            })
          }
        </div>
      )}
    </div>
  );
}

export default Dropdown;
