import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render, screen } from '@testing-library/react';

import { Pages } from '../../../learning_center/DetailsContent/Pages';

it('renders correctly with props', () => {
  const tree = renderer.create(
    <Pages pageNumber={1} pages={3} setPageNumber={() => {}} />,
  ).toJSON();
  expect(tree).toMatchSnapshot();
});

it('render comitee notes', async () => {
  const setPage = jest.fn();
  render(<Pages pageNumber={1} pages={3} setPageNumber={setPage} />);

  const next = screen.getByTestId('next');
  const previous = screen.getByTestId('previous');

  expect(next).toBeInTheDocument();
  expect(previous).toBeInTheDocument();
  fireEvent.click(next);
  expect(setPage).toBeCalledTimes(1);
  fireEvent.click(previous);
  expect(setPage).toBeCalledTimes(2);
});
