import React from 'react';
import { render } from '@testing-library/react';

import TagOption from '../../../search/src/TagOption';

const props = {
  option: {
    id: 1,
    name: 'Spoliation',
    subtype: 'issue',
  },
  inputValue: 'Spol',
  active: true,
};

it('renders correctly with props', () => {
  const tree = render(<TagOption {...props} />);
  expect(tree).toMatchSnapshot();
});
