// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="content"
>
  <h1>
    2015 Summary of Federal Rules Amendments 1
    <button
      className="edit-button"
      data-testid="edit-button"
      onClick={[Function]}
      onMouseDown={[Function]}
    >
      <svg
        fill="currentColor"
        height="1em"
        stroke="currentColor"
        strokeWidth="0"
        style={
          Object {
            "color": undefined,
          }
        }
        viewBox="0 0 512 512"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
        />
      </svg>
    </button>
  </h1>
  <p>
    by Kelly Twigger, 
    
  </p>
  <div
    className="separator"
  />
  <div
    className="post-body"
  >
    <p>
      Some post text
    </p>
  </div>
  <div
    className="tabs"
  >
    <div
      className="tab active"
      onClick={[Function]}
    >
      About this
       
      post
    </div>
    <div
      className="tab false"
      onClick={[Function]}
    >
      Resources
    </div>
  </div>
  <div
    className="tab-content"
  >
    <p
      className="description-header"
    >
      Description
    </p>
    <div
      className="description"
    >
      some description
    </div>
  </div>
</div>
`;
