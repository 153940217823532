/* istanbul ignore file */
const getRegexp = () => /((\b(AND|OR|NOT)\b)|(\(+\s*)|(\s*\)+))/ig;

export const getValues = (query) => {
  if (!query) return [];
  const values = [];
  const val = query.replace(/".+?"/g, (match) => {
    values.push(match.replace(/^"/, '').replace(/"$/, ''));
    return '<quoted-value>';
  });

  let lastIndex = 0;
  let match = null;
  const regexp = getRegexp();

  // eslint-disable-next-line no-cond-assign
  while (match = regexp.exec(val)) {
    const value = val.slice(lastIndex, match.index).trim();
    if (value && value !== '<quoted-value>') values.push(value);
    lastIndex = regexp.lastIndex;
  }

  const value = val.slice(lastIndex).trim();
  if (value && value !== '<quoted-value>') {
    values.push(value);
  }

  return values;
};

export const endsWithOperator = (query) => Boolean(query.match(/((\b(AND|OR|NOT))|(\())\s*$/i));
export const startsWithOperator = (query) => Boolean(query.match(/^\s*((\b(AND|OR|NOT))|(\())/i));
const escapeValue = (value) => (getRegexp().test(value) && !(value.startsWith('"') && value.endsWith('"')) ? `"${value}"` : value);

export const formatOperators = (query, formatFn) => {
  const quotedValues = [];
  let val = query.replace(/".+?"/g, (match) => {
    quotedValues.push(match);
    return '<quoted-value>';
  });

  val = val.replace(getRegexp(), formatFn);
  val = val.replace(/<quoted-value>/g, () => quotedValues.shift());
  return val;
};

export const formatValues = (query, formatFn) => {
  const quotedValues = [];
  const val = query.replace(/".+?"/g, (match) => {
    quotedValues.push(match.replace(/^"/, '').replace(/"$/, ''));
    return '<quoted-value>';
  });

  let lastIndex = 0;
  let match = null;
  let result = '';
  const regexp = getRegexp();

  // eslint-disable-next-line no-cond-assign
  while (match = regexp.exec(val)) {
    const value = val.slice(lastIndex, match.index).trim();
    if (value) {
      result += escapeValue(formatFn(value === '<quoted-value>' ? quotedValues.shift() : value));
    }

    result += ` ${match[0]} `;
    lastIndex = regexp.lastIndex;
  }

  const value = val.slice(lastIndex).trim();
  if (value) {
    result += escapeValue(formatFn(value === '<quoted-value>' ? quotedValues.shift() : value));
  }

  return result.replace(/\s+/g, ' ');
};

const appendBlock = (initialQuery, tag, position = null) => {
  let tagPart = '';
  const queryStart = position === null ? initialQuery : initialQuery.slice(0, position);
  const queryEnd = position === null ? '' : initialQuery.slice(position);

  if (queryStart.trim() && !queryStart.endsWith(' ')) tagPart += ' ';
  tagPart += tag;
  if (queryEnd.trim() && !queryEnd.startsWith(' ')) tagPart += ' ';
  else if (!queryEnd.trim()) tagPart += ' ';

  return queryStart + tagPart + queryEnd;
};

export const appendValue = (query, value, position = null) => {
  let valuePart = '';
  const queryStart = position === null ? query : query.slice(0, position);
  const queryEnd = position === null ? '' : query.slice(position);
  if (queryStart.trim() && !endsWithOperator(queryStart)) valuePart += 'AND ';
  valuePart += escapeValue(value);
  if (queryEnd.trim() && !startsWithOperator(queryEnd)) valuePart += ' AND';
  return appendBlock(query, valuePart, position);
};

export const replaceLastValue = (query, value) => {
  let lastIndex = 0;
  const regexp = getRegexp();
  while (regexp.exec(query)) { lastIndex = regexp.lastIndex; }
  return appendBlock(query.slice(0, lastIndex).trimEnd(), getRegexp().test(value) ? escapeValue(value) : value);
};

export const removeValue = (query, value) => {
  let filteredQuery = formatValues(query, (val) => {
    if (val === value) return '';
    return val;
  });

  filteredQuery = filteredQuery.replace(/(\b(AND|OR|NOT)\s*){2,}/ig, (m0, m1) => {
    if (m1.trim() === 'NOT') return `${m0.split(/\b/)[0]} ${m1}`;
    return m1;
  });

  filteredQuery = filteredQuery.replace(/\(\s*(AND|OR|NOT)\b/ig, '(');
  filteredQuery = filteredQuery.replace(/\b(AND|OR|NOT)\s*\)/ig, ')');
  filteredQuery = filteredQuery.replace(/\(\s*\)/ig, '');
  filteredQuery = filteredQuery.replace(/^\s*(\b(AND|OR))/i, '');
  filteredQuery = filteredQuery.replace(/(\b(AND|OR|NOT))\s*$/i, '');
  return filteredQuery.trim();
};

export const appendOperator = (query, operator, position = null) => appendBlock(query, operator, position);

export const handleIssuesSearch = (tag, tags) => {
  if (!tag || !tags) return null;
  let tagId;

  // eslint-disable-next-line
  for (const item of tags) {
    if (item.name === tag) {
      tagId = item.id;
      break;
    }
  }

  if (tagId) {
    const url = `/case_law?sort_by=des_decision_date&page=1&results_per_page=25&include_unpublished=true&type=issue&tag_ids_query=${tagId}`;
    window.open(url, '_blank');
  }

  return null;
};
