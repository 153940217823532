import React, { useEffect, useState } from 'react';
import { FiArrowUpRight } from 'react-icons/fi';
import {
  FaUserCircle, FaPowerOff, FaExclamationCircle,
} from 'react-icons/all';
import Logo from '../../../assets/images/academy-logo.svg';
import { UserMenu } from './UserMenu';
import AccessModal from './AccessModal';
import FeedbackModal from '../header/FeedbackModal';
import SessionModal from '../header/SessionModal';

export default function Header({
  currentUser, isAdmin, academySubscription, userSession,
}) {
  const location = window.location.pathname;
  const [accessModalVisible, setAccessModalVisible] = useState(false);
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [sessionModalOpen, setSessionModalOpen] = useState(false);

  const setSessionTimeout = (date) => {
    if (!currentUser || isAdmin) return;

    const targetDate = new Date(date);
    const currentDate = Date.now();
    const dateDifference = currentDate - targetDate;
    // 90 minutes (90 * 60_000) = 5_400_000 milliseconds
    const parsedUserSession = Number(userSession || 90) * 60_000;

    if (dateDifference > parsedUserSession) {
      console.log('The target date is above 90 mins. Showing model.');
      setSessionModalOpen(true);
    } else {
      const logoutTime = new Date(targetDate.getTime() + parsedUserSession);
      console.log(`The target date is under 90 mins. Starting timeout. Logout at ${logoutTime}`);
      setTimeout(() => setSessionModalOpen(true), parsedUserSession - dateDifference);
    }
  };

  useEffect(() => setSessionTimeout(currentUser?.current_sign_in_at), []);

  const mobileHeaderToggle = () => {
    // disable page scroll if mobile header opened
    if (!mobileHeaderOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    setMobileHeaderOpen(!mobileHeaderOpen);
  };
  const convertToTitleCase = (str) => str.replace(/_/g, ' ').replace('/', '').replace(/\b\w/g, (char) => char.toUpperCase()).split('/')[0];

  return (
    <>
      <div className="mobile-header">
        <div className="mobile-header-open" onClick={mobileHeaderToggle} />
        <div className="mobile-header-page">{ convertToTitleCase(window.location.pathname) }</div>
      </div>
      <nav className={`react-lc-header ${mobileHeaderOpen ? 'opened' : ''}`}>
        <a href="/academy"><img className="logo" alt="logo" src={Logo} /></a>
        <div className="mobile-separator" />
        <div className="close-academy-mobile" onClick={mobileHeaderToggle} />
        <div className="links">
          <a
            href="/academy/progress"
            className={`link ${location === '/academy/progress' && 'active'}`}
            onClick={(e) => {
              if (academySubscription || isAdmin) return;
              e.preventDefault();
              setAccessModalVisible(!accessModalVisible);
            }}
          >
            My Progress
          </a>
          <a
            href="/academy/favorites"
            className={`link ${location === '/academy/favorites' && 'active'}`}
            onClick={(e) => {
              if (academySubscription || isAdmin) return;
              e.preventDefault();
              setAccessModalVisible(!accessModalVisible);
            }}
          >
            Bookmarks
          </a>
          <a
            href="/academy/modules"
            className={`link ${location === '/academy/modules' && 'active'}`}
          >
            Modules
          </a>
        </div>
        <a className="eda-link" target="_blank" href="/" rel="noreferrer">
          eDiscovery Assistant
          <FiArrowUpRight />
        </a>
        <div>
          <UserMenu currentUser={currentUser} isAdmin={isAdmin} openFeedbackModal={() => setFeedbackModalOpen(true)} />
        </div>
        <div className="mobile-separator" />
        <div
          className="pages mobile-block"
          data-testid="eda-header"
        >
          <a onClick={() => setFeedbackModalOpen(true)}>
            <FaExclamationCircle className="icon mobile-icon" />
            Send Feedback
          </a>
          <a href="/admin">
            <FaUserCircle className="icon mobile-icon" />
            Admin
          </a>
          <a rel="nofollow" data-method="delete" href="/logout">
            <FaPowerOff className="icon mobile-icon" />
            Logout
          </a>
        </div>
      </nav>
      { accessModalVisible && (<AccessModal closeModal={() => setAccessModalVisible(!accessModalVisible)} />)}
      { feedbackModalOpen && <FeedbackModal closeModal={() => setFeedbackModalOpen(false)} /> }
      { sessionModalOpen && (
        <SessionModal closeModal={() => {
          setSessionModalOpen(false);
          setSessionTimeout(Date.now());
        }}
        />
      )}
    </>
  );
}
