import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  FaCheckSquare,
  FaRegSquare,
  FaBookmark,
  FaRegBookmark,
  FaAngleUp,
  FaAngleDown, FaPencilAlt, FaArrowDown,
} from 'react-icons/all';
import { FaShareAlt } from 'react-icons/fa';
import DownloadModal from '../../download_modal/DownloadModal';
import PaywallModal from '../../cite_lists/modals/PaywallModal';
import api from '../../../services/api';
import download from "downloadjs";

export default function RuleSummary({
  selectedRule = null, toggleHighlights, highlights, ruleType, toggleRuleBookmark, favorites, isAdmin, isACEDSTrial,
  plan_name, account_name, numberOfDownloads,
}) {
  const [summaryVisible, setSummaryVisible] = useState(true);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const [downloadAction, setDownloadAction] = useState(false);
  const [downloadLimit, setDownloadLimit] = useState(numberOfDownloads);
  const rulePath = window.location.pathname;
  const fullPath = encodeURIComponent(`${window.location.href}?${Number(Date.now())}`);
  const hasDownloadLimit = plan_name === 'Trial' || plan_name === 'Individual Monthly';

  const downloadPdf = (e) => {
    e.preventDefault();

    if (isACEDSTrial) {
      setPaywallModalOpen(true);
      return;
    }

    if (hasDownloadLimit && downloadLimit === 0) {
      setDownloadModalOpen(true);
      setDownloadAction(false);
      return;
    }

    if (hasDownloadLimit) {
      setDownloadModalOpen(true);
      setDownloadLimit(downloadLimit - 1);
      setDownloadAction(true);
      api.account.user.updateDownloadLimit({ download_limit: downloadLimit - 1 })
        .catch(() => console.log('cant update user`s download limit'));
    }

    fetch(selectedRule.document.url)
      .then((response) => response.blob())
      .then((blob) => download(blob, `${selectedRule.number}.pdf`))
      .catch((err) => console.error('Failed to download PDF:', err));
  };

  return (
    <div className="rule-summary">
      <div className="name-row">
        <div className="name">
          { selectedRule.number }
        </div>
        { isAdmin && (
          <button
            className="edit-button"
            style={{ marginRight: '1rem' }}
            onClick={() => {
              window.open(`/admin${rulePath}/edit`, '_blank').focus();
            }}
          >
            <FaPencilAlt />
          </button>
        )}
        { !selectedRule.is_private && (
          <button
            className="edit-button"
            style={{ marginRight: '1rem' }}
            title="Share on LinkedIn"
            onClick={() => {
              window.open(`https://www.linkedin.com/feed/?shareActive=true&shareUrl=${fullPath}?${Number(Date.now())}`, '_blank').focus();
            }}
          >
            <FaShareAlt />
          </button>
        )}
        <div className="bookmark" onClick={(e) => toggleRuleBookmark(e, selectedRule)}>
          { favorites?.rules !== undefined && favorites.rules.some(
            (rule) => (rule.id === selectedRule.rule_id),
          )
            ? <FaBookmark />
            : <FaRegBookmark />}
        </div>
      </div>
      { (selectedRule.document && selectedRule.document.url) && (
        <div className="buttons">
          <a className="pdf" onClick={downloadPdf}>
            <FaArrowDown />
            Download PDF
          </a>
        </div>
      )}
      { (ruleType === 'FederalRule' || ruleType === 'StateRule' || ruleType === 'DistrictRule') && (
      <div
        className={`summary-checkbox${highlights === true ? ' selected' : ''}`}
        onClick={toggleHighlights}
      >
        {highlights === true
          ? <FaCheckSquare />
          : <FaRegSquare />}
        Show eDiscovery Highlights
      </div>
      )}
      { selectedRule.summary && (
      <div className="summary">
        <div className="summary-button" onClick={() => setSummaryVisible(!summaryVisible)}>
          Summary&nbsp;&nbsp;&nbsp;
          {' '}
          { summaryVisible ? <FaAngleDown /> : <FaAngleUp /> }
        </div>
        <div>
          { summaryVisible && ReactHtmlParser(selectedRule.summary) }
        </div>
      </div>
      )}
      {(downloadModalOpen && (
        <DownloadModal
          closeModal={() => {
            setDownloadAction(false);
            setDownloadModalOpen(false);
          }}
          numberOfDownloads={downloadLimit}
          isDownload={downloadAction}
          plan_name={plan_name}
        />
      ))}
      { paywallModalOpen && (
        <PaywallModal
          isDownload
          account_name={account_name}
          closeModal={() => {
            setPaywallModalOpen(false);
          }}
        />
      )}
    </div>
  );
}
