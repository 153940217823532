import React from 'react';
import '@testing-library/jest-dom';
import { render, screen, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import SharedTo from '../../../cite_lists/modals/SharedTo';

describe('SharedTo', () => {
  it('renders correctly with props', () => {
    render(
      <SharedTo
        getSharedWithList={() => [1, 2, 3]}
        openShareDialog={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('renders correctly with props', () => {
    render(
      <SharedTo
        getSharedWithList={() => [1]}
        openShareDialog={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('renders correctly with props', () => {
    render(
      <SharedTo
        getSharedWithList={() => []}
        openShareDialog={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const close = jest.fn(() => {});

    render(
      <SharedTo
        getSharedWithList={() => [1, 2, 3]}
        openShareDialog={close}
      />,
    );

    const closeButton = screen.getByTestId('open-share');
    expect(closeButton).toBeInTheDocument();

    await act(async () => {
      userEvent.click(closeButton);
    });

    expect(close).toHaveBeenCalledTimes(1);
  });
});
