// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Payment Modal snapshots renders correctly with props 1`] = `
<div
  className="new-modal payment-method-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
    style={
      Object {
        "width": "500px",
      }
    }
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <h4>
            Update Payment Method
          </h4>
          <button
            className="button-clear pull-right"
            onClick={[Function]}
            type="button"
          >
            ✕
          </button>
        </div>
      </div>
      <div
        className="modal-body"
      >
        <div
          className="row"
        >
          <div
            className="col-md-12"
          >
            <div
              className="form-group "
            >
              <label>
                Card No.
              </label>
              <div />
            </div>
            <div
              className="form-group "
            >
              <label>
                Name
              </label>
              <input
                className="form-control"
                id="credit_card_holder"
                name="credit_card_holder"
                onChange={[Function]}
                placeholder="Card Holder Name"
                type="text"
                value=""
              />
            </div>
            <div
              className="row"
            >
              <div
                className="form-group col-md-6 "
              >
                <label>
                  Exp. Date
                </label>
                <div />
              </div>
              <div
                className="form-group col-md-6 "
                style={
                  Object {
                    "margin": 0,
                  }
                }
              >
                <label>
                  CVV
                </label>
                <div />
              </div>
            </div>
          </div>
        </div>
        <button
          className="modal-button"
          disabled={false}
          onClick={[Function]}
          type="button"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
`;

exports[`Payment Modal snapshots renders correctly without props 1`] = `
<div
  className="new-modal payment-method-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
    style={
      Object {
        "width": "500px",
      }
    }
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <h4>
            Update Payment Method
          </h4>
          <button
            className="button-clear pull-right"
            onClick={[Function]}
            type="button"
          >
            ✕
          </button>
        </div>
      </div>
      <div
        className="modal-body"
      >
        <div
          className="row"
        >
          <div
            className="col-md-12"
          >
            <div
              className="form-group "
            >
              <label>
                Card No.
              </label>
              <div />
            </div>
            <div
              className="form-group "
            >
              <label>
                Name
              </label>
              <input
                className="form-control"
                id="credit_card_holder"
                name="credit_card_holder"
                onChange={[Function]}
                placeholder="Card Holder Name"
                type="text"
                value=""
              />
            </div>
            <div
              className="row"
            >
              <div
                className="form-group col-md-6 "
              >
                <label>
                  Exp. Date
                </label>
                <div />
              </div>
              <div
                className="form-group col-md-6 "
                style={
                  Object {
                    "margin": 0,
                  }
                }
              >
                <label>
                  CVV
                </label>
                <div />
              </div>
            </div>
          </div>
        </div>
        <button
          className="modal-button"
          disabled={true}
          onClick={[Function]}
          type="button"
        >
          Updating
        </button>
      </div>
    </div>
  </div>
</div>
`;
