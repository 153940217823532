import React from 'react';

export default function PaywallModal({ isDownload = false, closeModal, account_name = '' }) {
  const accountName = account_name.split('(')[0].trim();

  return (
    <div
      className="new-modal"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div className="modal-dialog modal-md delete-modal">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            { isDownload ? (
              <>
                <p className="delete-text">
                  As part of your
                  {' '}
                  { accountName }
                  {' '}
                  of eDiscovery Assistant
                  you do not have the ability to download.
                </p>
                <p className="delete-text">
                  To enable downloading, upgrade to a paid Individual Monthly (4 downloads/month)
                  subscription for $179/moth or an Individual Annual (unlimited downloads) subscription $1,895/year.
                </p>
              </>
            ) : (
              <p className="delete-text">
                This function is available on all Team and Enterprise Accounts.
                To upgrade your account, please contact us at
                <a href="mailto:support@ediscoveryassistant.com"> support@ediscoveryassistant.com</a>
                .
              </p>
            )}
            <div className="button-row">
              { isDownload ? (
                <a href="/account/edit?tab=billingInfo" className="modal-button modal-button-outline">
                  Upgrade
                </a>
              ) : (
                <div className="modal-button modal-button-outline" onClick={closeModal}>
                  Close
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
