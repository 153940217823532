import PropTypes from 'prop-types';
import React from 'react';

export default function DynamicCiteListNotAvailableModal({ closeModal }) {
  return (
    <div className="login-modal new-modal" onClick={closeModal} data-testid="login-modal">
      <div className="modal-dialog modal-md">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="circle" />
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            <p>
              Dynamic Cite Lists are not available on your current plan. To upgrade to a Team or Enterprise plan contact us at
              <a href="mailto:support@ediscoveryassistant.com"> support@ediscoveryassistant.com </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

DynamicCiteListNotAvailableModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
