import React from 'react';
import renderer from 'react-test-renderer';
import { fireEvent, render } from '@testing-library/react';

import { Tabs } from '../../../learning_center/DetailsContent/Tabs';
import { postItem, videoItem, linkedContent } from '../../__mocks__/lc-data';

it('renders correctly with props', () => {
  const tree = renderer.create(
    <Tabs
      item={postItem}
      tab="about"
      setTab={() => {}}
      isAdmin
      linkedContent={[]}
    />,
  ).toJSON();
  expect(tree).toMatchSnapshot();
});

it('render linked content', async () => {
  const container = render(
    <Tabs
      item={videoItem}
      tab="resources"
      setTab={() => {}}
      isAdmin
      linkedContent={linkedContent}
    />,
  );

  fireEvent.click(container.queryByText('About this video'));
  fireEvent.click(container.queryByText('Resources'));
  fireEvent.click(container.queryByText('Transcript'));
});

it('render about tab', async () => {
  render(
    <Tabs
      item={videoItem}
      tab="about"
      setTab={() => {}}
      isAdmin
      linkedContent={linkedContent}
    />,
  );
});

it('render transcript tab', async () => {
  render(
    <Tabs
      item={videoItem}
      tab="about"
      setTab={() => {}}
      isAdmin
      linkedContent={linkedContent}
    />,
  );
  // expect(container.queryByText('Some post text')).toBeInTheDocument();
});
