import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class StateRulesNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateSearchValue: '',
    };
  }

  componentDidMount() {
    const _stateFilter = new URLSearchParams(window.location.search).get('state') || '';

    this._setStateSearchValue({ value: _stateFilter, label: _stateFilter });
  }

  _setStateSearchValue = (newValue) => {
    const { setStateFilter } = this.props;
    if (newValue === null) return setStateFilter('');
    this.setState({ stateSearchValue: newValue.label });
    return setStateFilter(newValue.label);
  };

  render() {
    const {
      allStates, states, setStateRuleCategoryFilter, stateRuleCategoryFilter,
    } = this.props;
    const { stateSearchValue } = this.state;

    const customStyles = {
      option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? '#FFF' : '#000E57',
        backgroundColor: state.isSelected ? 'rgba(0, 14, 87, 0.8)' : styles.color,
        '&:hover': {
          color: '#FFF',
          backgroundColor: 'rgba(0, 14, 87, 0.6)',
        },
      }),
      control: (styles, state) => ({
        ...styles,
        boxShadow: state.isFocused ? '0 0 0 0.1rem rgba(0, 14, 87, 0.6)' : 0,
        width: '20rem',
        borderColor: state.isFocused ? 'rgba(0, 14, 87, 0.6)' : '#CED4DA',
        '&:hover': {
          borderColor: state.isFocused ? 'rgba(0, 14, 87, 0.6)' : '#CED4DA',
        },
      }),
      singleValue: (styles) => ({
        ...styles,
        color: '#000E57',
      }),
    };

    const stateRuleCategories = states && states[0] ? states[0].stateRuleCategories : [];
    const categoriesCount = stateRuleCategories.reduce((a, b) => a + b.rules.length, 0);

    return (
      <div className="state-rules-navigator">
        <div className="selector state-selector">
          <Select
            placeholder="Filter by State"
            isSearchable={false}
            value={stateSearchValue === '' ? '' : { value: stateSearchValue, label: stateSearchValue }}
            onChange={this._setStateSearchValue}
            options={allStates.map((state) => ({ value: state.name, label: state.name }))}
            styles={customStyles}
          />
        </div>

        <div className="category-list">
          <div
            className={`category${stateRuleCategoryFilter === 'All' ? ' selected' : ''}`}
            onClick={() => setStateRuleCategoryFilter('All')}
          >
            <div className="name">
              All
            </div>
            <div className="rule-count">
              { categoriesCount + (categoriesCount === 1 ? ' rule' : ' rules') }
            </div>
          </div>
          { stateRuleCategories.map((category) => (
            <div
              className={`category${category.name === stateRuleCategoryFilter ? ' selected' : ''}`}
              key={category.name}
              onClick={() => setStateRuleCategoryFilter(category.name)}
            >
              <div className="name">
                { category.name }
              </div>
              <div className="rule-count">
                { category.rules.length + (category.rules.length === 1 ? ' rule' : ' rules') }
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

StateRulesNavigator.propTypes = {
  setStateFilter: PropTypes.func.isRequired,
  states: PropTypes.array,
  setStateRuleCategoryFilter: PropTypes.func.isRequired,
  stateRuleCategoryFilter: PropTypes.string,
  allStates: PropTypes.array.isRequired,
};

StateRulesNavigator.defaultProps = {
  states: null,
  stateRuleCategoryFilter: '',
};

export default StateRulesNavigator;
