import React from 'react';
import '@testing-library/jest-dom';
import { render, screen, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import SharedModal from '../../../cite_lists/modals/SharedModal';

describe('SharedModal', () => {
  it('renders correctly with props', () => {
    render(
      <SharedModal
        sharedModalUsers={[]}
        closeModal={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const close = jest.fn(() => {});

    render(
      <SharedModal
        sharedModalUsers={[{ name: '1' }, { name: '2' }]}
        closeModal={close}
      />,
    );

    const closeButton = screen.getByText('OK');
    expect(closeButton).toBeInTheDocument();

    await act(async () => {
      userEvent.click(closeButton);
    });

    expect(close).toHaveBeenCalledTimes(1);
  });
});
