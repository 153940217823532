import React from 'react';
import PropTypes from 'prop-types';
import {
  FaArrowDown, FaBookmark, FaRegBookmark, GoSearch,
} from 'react-icons/all';
import Autosuggest from 'react-autosuggest';
import api from '../../services/api';
import DownloadModal from '../download_modal/DownloadModal';
import PaywallModal from '../cite_lists/modals/PaywallModal';

class Checklists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryFilter: 'All',
      searchSuggestions: [],
      searchValue: '',
      favorites: [],
      mobileOpened: false,
      downloadAction: false,
      downloadModalOpen: false,
      paywallModalOpen: false,
      downloadLimit: this.props.numberOfDownloads,
    };
  }

  componentDidMount() {
    const { favorites } = this.props;
    this.setState({ favorites });
  }

  setCategoryFilter = (filter) => {
    this.setState({ searchValue: '', categoryFilter: filter });
  };

  updateFavorites = () => {
    api.favorites.get().then((result) => {
      this.setState({ favorites: result.favorites });
    });
  };

  _toggleBookmark = (e, item) => {
    e.stopPropagation();
    e.preventDefault();

    const type = item.checklist_id ? 'checklists' : 'forms';
    const id = item.checklist_id || item.template_id;

    if (this.isFavorite(item)) {
      api[type].unfavorite(id).then(() => this.updateFavorites());
      return;
    }
    api[type].favorite(id).then(() => this.updateFavorites());
  };

  downloadForm = (e, item) => {
    e.stopPropagation();
    e.preventDefault();

    const { isACEDSTrial } = this.props;
    if (isACEDSTrial) {
      this.setState({ paywallModalOpen: true });
      return;
    }

    const { plan_name } = this.props;
    const { downloadLimit } = this.state;
    const hasDownloadLimit = plan_name === 'Trial' || plan_name === 'Individual Monthly';

    if (hasDownloadLimit && downloadLimit === 0) return;
    if (hasDownloadLimit) {
      this.setState({
        downloadModalOpen: true,
        downloadLimit: downloadLimit - 1,
        downloadAction: true,
      });
    }

    window.open(item.document.url, '_blank');
  };

  setSearchSuggestions = ({ value }) => {
    const { checklists } = this.props;

    const inputValue = value.trim().toLowerCase();
    const suggestions = inputValue.length === 0 ? [] : checklists.filter((item) => (item.checklist_id || item.template_id) && item.name.toLowerCase().includes(inputValue.toLowerCase()));

    this.setState({ searchSuggestions: suggestions });
  };

  clearSearchSuggestions = () => {
    this.setState({ searchSuggestions: [] });
  };

  selectSearchSuggestion = (e, { suggestion }) => {
    const type = suggestion.checklist_id ? 'checklists' : 'forms';
    const id = suggestion.checklist_id || suggestion.template_id;
    window.location.href = `/${type}/${id}`;
  };

  setSearchValue = (e, { newValue }) => {
    this.setState({
      searchValue: newValue,
      categoryFilter: newValue ? 'Search results' : 'All',
    });
  };

  mobileHeaderToggle = () => {
    const { mobileOpened } = this.state;
    // disable page scroll if mobile header opened
    if (!mobileOpened) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    this.setState({ mobileOpened: !mobileOpened });
  };

  isFavorite(item) {
    const { favorites } = this.state;
    const id = item.checklist_id || item.template_id;
    const type = 'checklists_and_forms';

    return favorites[type] !== undefined && favorites[type].some((favoriteTerm) => favoriteTerm.id === id);
  }

  renderSelectedChecklists = () => {
    const { categoryFilter, searchValue } = this.state;
    const { checklists } = this.props;
    const { plan_type } = this.props;

    const items = checklists.filter((item) => {
      if (!item.checklist_id && !item.template_id) return false;

      if (categoryFilter === 'All') return true;
      if (categoryFilter === 'Search results') return item.name.toLowerCase().includes(searchValue.toLowerCase());

      return item.categories.find((c) => c.name === categoryFilter);
    });

    return (
      <div>
        <div className="checklist-list">
          { items.length > 0 && (
          <div className="title">
            { categoryFilter }
            <span className="selected-category-count">{items.length}</span>
          </div>
          )}
          <div className="results">
            {
                  items.map((item) => (
                    <a
                      href={`${item.checklist_id ? 'checklists' : 'forms'}/${item.checklist_id || item.template_id}`}
                      className="result"
                      key={item.id}
                    >
                      <div className="top">
                        <div className="result-type">
                          { item.checklist_id ? 'Checklists' : 'Forms' }
                        </div>
                        <div className="result-title">
                          { item.name }
                        </div>
                      </div>
                      <div className="result-buttons">
                        {
                          plan_type !== 'trial' && item.template_id && item.document && item.document.url && (
                            <FaArrowDown onClick={(e) => this.downloadForm(e, item)} />
                          )
                        }
                        {
                          this.isFavorite(item)
                            ? <FaBookmark className="bookmark active" onClick={(e) => this._toggleBookmark(e, item)} />
                            : <FaRegBookmark className="bookmark" onClick={(e) => this._toggleBookmark(e, item)} />
                        }
                      </div>
                    </a>
                  ))
                }
            { !items.length && (
              <div className="no-results">
                No Results Found. Try using the categories to filter for
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderSearchSuggestion = (item) => (
    <div className="suggestion">
      { item.name }
    </div>
  );

  render() {
    const { categories, checklists } = this.props;
    const {
      categoryFilter, searchSuggestions, searchValue, mobileOpened,
    } = this.state;

    const searchInputProps = {
      placeholder: 'Search checklists and forms',
      value: searchValue,
      onChange: this.setSearchValue,
    };

    return (
      <div className="react-checklists">
        <div className="mobile-search-open" onClick={this.mobileHeaderToggle} />
        <div className={`left ${mobileOpened ? 'opened' : ''}`}>
          <div className="title">Checklists & Forms</div>
          <div className="description">
            The checklists and forms included here are intended to support planning,
            conducting, and analyzing the results of your electronic discovery efforts.
          </div>
          <div className="search">
            <Autosuggest
              suggestions={searchSuggestions}
              onSuggestionsFetchRequested={this.setSearchSuggestions}
              onSuggestionsClearRequested={this.clearSearchSuggestions}
              getSuggestionValue={(item) => item.name}
              renderSuggestion={this.renderSearchSuggestion}
              inputProps={searchInputProps}
              onSuggestionSelected={this.selectSearchSuggestion}
            />
            <GoSearch />
          </div>
          <div className="checklists-navigator">
            <div className="category-list">
              <div
                className={`category${categoryFilter === 'All' ? ' selected' : ''}`}
                onClick={() => this.setCategoryFilter('All')}
              >
                <div className="name">
                  All
                </div>
                <div className="item-count">
                  {checklists.length}
                </div>
              </div>
              { categories.map((category) => (
                <div
                  className={`category${category.name === categoryFilter ? ' selected' : ''}`}
                  key={category.name}
                  onClick={() => this.setCategoryFilter(category.name)}
                >
                  <div className="name">
                    { category.name }
                  </div>
                  <div className="item-count">
                    {category.count}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            { this.renderSelectedChecklists() }
          </div>
        </div>
        { this.state.downloadModalOpen && (
          <DownloadModal
            numberOfDownloads={this.state.downloadLimit}
            closeModal={() => {
              this.setState({ downloadModalOpen: false, downloadAction: false });
            }}
            isDownload={this.state.downloadAction}
            plan_name={this.props.plan_name}
          />
        )}
        { this.state.paywallModalOpen && (
          <PaywallModal
            isDownload
            account_name={this.props.account_name}
            closeModal={() => {
              this.setState({ paywallModalOpen: false });
            }}
          />
        )}
      </div>
    );
  }
}

Checklists.propTypes = {
  categories: PropTypes.array.isRequired,
  checklists: PropTypes.array.isRequired,
  favorites: PropTypes.object.isRequired,
};

export default Checklists;
