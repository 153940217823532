import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { renderThumbnail } from '../../services/thumbnail';
import Tags from './Tags';
import AccessModal from './AccessModal';

export default function TableItem({
  topicId, data, maxVisibleTags = 5, isAdmin = false, isAcademy = false, subscription = false,
}) {
  const [videoUrl, setVideoUrl] = useState('');
  const [accessModalVisible, setAccessModalVisible] = useState(false);
  const access = (subscription && data.access_level === 'subscribed') || isAdmin || isAcademy || data.access_level === 'public';

  useEffect(() => {
    if (data.domain === 'youtube') setVideoUrl(`https://img.youtube.com/vi/${data.url.split('=').pop()}/hqdefault.jpg`);
    if (data.domain === 'vimeo') {
      api.media_items.vimeo_thumbnail(data.url)
        .then((res) => setVideoUrl(res.thumbnail_url));
    }
  }, []);

  return (
    <>
      <a
        href={topicId ? `/academy/${data.id}?topic_id=${topicId}` : `/academy/${data.id}`}
        className="lc-table-item"
        onClick={(e) => {
          if (access) return;
          e.preventDefault();
          setAccessModalVisible(!accessModalVisible);
        }}
      >
        <div className="body">
          <div className="thumbnail">
            {renderThumbnail(data, videoUrl)}
          </div>
          <div style={{ flex: 1 }}>
            <p className="name">{data.title}</p>
            <Tags maxVisibleTags={maxVisibleTags} items={data.tags} />
          </div>
        </div>
      </a>
      { accessModalVisible && (<AccessModal closeModal={() => setAccessModalVisible(!accessModalVisible)} />)}
    </>
  );
}
