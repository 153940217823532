// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Create profile renders correctly with props 1`] = `
Object {
  "asFragment": [Function],
  "baseElement": <body>
    <div>
      <div
        class="row"
      >
        <div
          class="col-sm-6"
        >
          <div
            class="register-form-title"
          >
            Account Details
          </div>
          <div
            class="form-group "
          >
            <input
              class="form-control"
              id="name"
              name="name"
              placeholder="Full Name"
              type="text"
              value="Name"
            />
          </div>
          <div
            class="form-group "
          >
            <input
              class="form-control"
              id="email"
              name="email"
              placeholder="Email Address"
              type="email"
              value="email"
            />
          </div>
          <div
            class="form-group "
          >
            <input
              class="form-control"
              id="company"
              name="company"
              placeholder="Organization / Firm Name"
              type="text"
              value="Company"
            />
          </div>
          <div
            class="form-group "
          >
            <input
              class="form-control"
              id="phone"
              name="phone"
              placeholder="Phone Number Optional"
              type="number"
              value=""
            />
          </div>
          <label
            class="register-checkbox"
          >
            <input
              id="newsletter"
              name="newsletter"
              type="checkbox"
            />
            Sign me up for eDiscovery Assistant blog
          </label>
        </div>
        <div
          class="col-sm-6"
        >
          <div
            class="register-form-title"
          >
            Create Password
          </div>
          <div
            class="form-group "
          >
            <div
              class="input-group"
            >
              <input
                class="form-control"
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                value="password"
              />
              <span
                class="input-group-addon"
              >
                <svg
                  fill="currentColor"
                  height="1em"
                  stroke="currentColor"
                  stroke-width="0"
                  viewBox="0 0 576 512"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
                  />
                </svg>
              </span>
            </div>
            <div
              class="note"
            >
              Please add at least 1 Uppercase and 1 symbol for increasing password strength.
            </div>
          </div>
          <div
            class="form-group "
          >
            <input
              class="form-control"
              id="password2"
              name="password2"
              placeholder="Confirm Password"
              type="password"
              value=""
            />
          </div>
        </div>
      </div>
      <div
        class="register-form-actions"
      >
        <button
          class="btn btn-submit"
          type="button"
        >
          Continue
        </button>
      </div>
    </div>
  </body>,
  "container": <div>
    <div
      class="row"
    >
      <div
        class="col-sm-6"
      >
        <div
          class="register-form-title"
        >
          Account Details
        </div>
        <div
          class="form-group "
        >
          <input
            class="form-control"
            id="name"
            name="name"
            placeholder="Full Name"
            type="text"
            value="Name"
          />
        </div>
        <div
          class="form-group "
        >
          <input
            class="form-control"
            id="email"
            name="email"
            placeholder="Email Address"
            type="email"
            value="email"
          />
        </div>
        <div
          class="form-group "
        >
          <input
            class="form-control"
            id="company"
            name="company"
            placeholder="Organization / Firm Name"
            type="text"
            value="Company"
          />
        </div>
        <div
          class="form-group "
        >
          <input
            class="form-control"
            id="phone"
            name="phone"
            placeholder="Phone Number Optional"
            type="number"
            value=""
          />
        </div>
        <label
          class="register-checkbox"
        >
          <input
            id="newsletter"
            name="newsletter"
            type="checkbox"
          />
          Sign me up for eDiscovery Assistant blog
        </label>
      </div>
      <div
        class="col-sm-6"
      >
        <div
          class="register-form-title"
        >
          Create Password
        </div>
        <div
          class="form-group "
        >
          <div
            class="input-group"
          >
            <input
              class="form-control"
              id="password"
              name="password"
              placeholder="Password"
              type="password"
              value="password"
            />
            <span
              class="input-group-addon"
            >
              <svg
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                stroke-width="0"
                viewBox="0 0 576 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
                />
              </svg>
            </span>
          </div>
          <div
            class="note"
          >
            Please add at least 1 Uppercase and 1 symbol for increasing password strength.
          </div>
        </div>
        <div
          class="form-group "
        >
          <input
            class="form-control"
            id="password2"
            name="password2"
            placeholder="Confirm Password"
            type="password"
            value=""
          />
        </div>
      </div>
    </div>
    <div
      class="register-form-actions"
    >
      <button
        class="btn btn-submit"
        type="button"
      >
        Continue
      </button>
    </div>
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "unmount": [Function],
}
`;
