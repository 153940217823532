import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Mark from 'mark.js/dist/mark.es6';
import debounce from 'lodash/debounce';

class CaseDigestText extends React.Component {
  constructor(props) {
    super(props);
    this.textRef = React.createRef();
    this.state = { parsedText: null, prevText: null };

    this.mark = debounce(() => {
      const { searchKeywords, totalCountChanged } = this.props;
      const instance = new Mark(this.textRef.current);

      instance.unmark({
        done() {
          if (searchKeywords) {
            instance.mark(searchKeywords, {
              done(count) {
                totalCountChanged(count);
              },
            });
          }
        },
      });
    }, 400);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.text !== state.prevText) {
      return {
        parsedText: ReactHtmlParser(props.text),
        prevText: props.text,
      };
    }
    return null;
  }

  componentDidMount() {
    const { activeSearchIndex } = this.props;
    this.mark();
    this.goToIndex(activeSearchIndex);
  }

  componentDidUpdate(prevProps) {
    const { text, searchKeywords, activeSearchIndex } = this.props;
    if (prevProps.text !== text || prevProps.searchKeywords !== searchKeywords) {
      this.mark();
    }

    if (prevProps.text !== text || prevProps.searchKeywords !== searchKeywords || prevProps.activeSearchIndex !== activeSearchIndex) {
      this.goToIndex(activeSearchIndex, prevProps.activeSearchIndex);
    }
  }

  goToIndex = (index, prevIndex) => {
    if (!index) return;

    if (prevIndex) {
      const prevElement = this.textRef.current.querySelectorAll('mark')[prevIndex - 1];
      if (prevElement) prevElement.classList.remove('active');
    }

    const element = this.textRef.current.querySelectorAll('mark')[index - 1];
    if (element) {
      element.classList.add('active');
      element.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  };

  render() {
    const { className } = this.props;
    const { parsedText } = this.state;

    return (
      <div ref={this.textRef} className={className}>
        { parsedText }
      </div>
    );
  }
}

export default CaseDigestText;
