import React, { useEffect, useRef, useState } from 'react';
import {
  FiUpload, FiEye, FiPlus, FiEdit3, FiTrash, FiArrowLeft,
} from 'react-icons/fi';
import {
  GrAttachment,
} from 'react-icons/gr';
import Api from '../../../services/api';
import Select from '../Select';
import CategorySidebar from '../categories/Sidebar';
import TipsSidebar from '../tips/Sidebar';
import TagsSidebar from '../tags/Sidebar';
import EdaSwitch from '../Switch';

export default function Edit({
  form, published,
}) {
  const summary = useRef();
  const text = useRef();
  const fileInput = useRef();

  const [data, setData] = useState({
    name: form?.name || '',
    category_ids: form?.category_ids || [],
    tag_ids: form?.tag_ids || [],
    tip_ids: form?.tip_ids || [],
    document: form?.document || form?.word_document,
  });

  const [categories, setCategories] = useState([]);
  const [tips, setTips] = useState([]);
  const [tags, setTags] = useState([]);
  const [type, setType] = useState(form && !form.text && (form.document || form.word_document) ? 'file' : 'text');
  const [categorySidebarVisible, setCategorySidebarVisible] = useState(null);
  const [editableCategory, setEditableCategory] = useState(null);

  const [tipSidebarVisible, setTipSidebarVisible] = useState(null);
  const [editableTip, setEditableTip] = useState(null);

  const [tagSidebarVisible, setTagSidebarVisible] = useState(null);
  const [editableTag, setEditableTag] = useState(null);

  const fetchCategories = () => {
    Api.admin.categories.getAll().then((res) => {
      if (res.status !== 'OK') return;
      setCategories(res.data);
    });
  };

  const fetchTips = () => {
    Api.admin.tips.getAll().then((res) => {
      if (res.status !== 'OK') return;
      setTips(res.data);
    });
  };

  const fetchTags = () => {
    Api.admin.tags.getAll().then((res) => {
      if (res.status !== 'OK') return;
      setTags(res.data);
    });
  };

  useEffect(() => { fetchCategories(); }, []);
  useEffect(() => { fetchTips(); }, []);
  useEffect(() => { fetchTags(); }, []);

  useEffect(() => {
    summary.current.innerHTML = form?.summary || '';
  }, [summary]);

  useEffect(() => {
    text.current.innerHTML = form?.text || '';
  }, [text]);

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleSwitch = () => {
    setType(type === 'text' ? 'file' : 'text');
  };

  const handleUpdate = () => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('summary', summary.current.innerHTML);

    if (data.category_ids.length === 0) {
      formData.append('category_ids[]', '');
    } else {
      data.category_ids.forEach((id) => formData.append('category_ids[]', id));
    }

    if (data.tag_ids.length === 0) {
      formData.append('tag_ids[]', '');
    } else {
      data.tag_ids.forEach((id) => formData.append('tag_ids[]', id));
    }

    if (data.tip_ids.length === 0) {
      formData.append('tip_ids[]', '');
    } else {
      data.tip_ids.forEach((id) => formData.append('tip_ids[]', id));
    }

    if (data.document instanceof File) {
      const isWordDoc = data.document.type === 'application/msword' || data.document.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      formData.append('text', '');
      formData.append(isWordDoc ? 'word_document' : 'document', data.document);
      if (isWordDoc) formData.append('remove_document', '1');
      else formData.append('remove_word_document', '1');
    } else if (!data.document) {
      formData.append('remove_document', '1');
      formData.append('remove_word_document', '1');
      formData.append('text', text.current.innerHTML);
    } else {
      formData.append('text', text.current.innerHTML);
    }

    if (form) {
      Api.admin.templates.update(form.template_id, formData).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', 'Form updated');
        window.location.reload();
      });
    } else {
      Api.admin.templates.create(formData).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', 'Form created');
        window.location.href = `/admin/templates/${res.template.id}/edit`;
      });
    }
  };

  const handleCategoryUpdate = () => {
    setCategorySidebarVisible(false);
    setEditableCategory(null);
    fetchCategories();
  };

  const handleTipUpdate = () => {
    setTipSidebarVisible(false);
    setEditableTip(null);
    fetchTips();
  };

  const handleTagUpdate = () => {
    setTagSidebarVisible(false);
    setEditableTag(null);
    fetchTags();
  };

  const handlePublish = () => {
    if (!form) return;

    Api.admin.templates.publish(form.template_id).then((res) => {
      if (res.status !== 'OK') return;
      sessionStorage.setItem('message', 'Form has been published');
      window.location.reload();
    });
  };

  const handleUnpublish = () => {
    if (!form) return;

    Api.admin.templates.unpublish(form.template_id).then((res) => {
      if (res.status !== 'OK') return;
      sessionStorage.setItem('message', 'Form has been unpublished');
      window.location.reload();
    });
  };

  return (
    <div className={`admin-page react-admin-forms-edit ${(categorySidebarVisible || tipSidebarVisible) ? 'sidebar-open' : ''}`}>
      <div className="header">
        <h2>
          <span
            className="back"
            onClick={() => {
              // eslint-disable-next-line
            history.back();
            }}
          >
            &lt;
          </span>
          <a href="/admin/templates" className="back">Form</a>
          {' '}
          /
          {' '}
          { form && `${form.name} / ` }
          { form ? 'Edit' : 'Add New' }
          { published ? (
            <span className="tag published">Published</span>
          ) : (
            <span className="tag draft">Draft</span>
          ) }
        </h2>
        <button type="button" className="button" onClick={handleUpdate} disabled={!data.name}>
          { form ? 'Update' : 'Create' }
        </button>
        <button type="button" className="button" onClick={handlePublish}>
          Publish
          <FiUpload />
        </button>
        { published && (
          <button type="button" className="button danger" onClick={handleUnpublish}>
            Unpublish
            <FiArrowLeft />
          </button>
        )}
        { form && (
          <>
            <div className="separator" />
            <a type="button" className="button preview" href={`/admin/templates/${form.template_id}`}>
              Preview
              <FiEye />
            </a>
          </>
        )}
      </div>
      <div className="form">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            value={data.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Summary</label>
          <div
            ref={summary}
            className="form-control js-wysihtml5"
          />
        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <div className="label-row">
                <label>Tags</label>
                <button type="button" className="create-button" onClick={() => setTagSidebarVisible(true)}>
                  Create New
                  <FiPlus />
                </button>
              </div>
              <Select
                value=""
                options={tags.map((tag) => ({
                  label: (
                    <>
                      <span style={{ flex: 1 }}>{tag.name}</span>
                      <button
                        type="button"
                        className="category-edit-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTagSidebarVisible(true);
                          setEditableTag(tag);
                        }}
                      >
                        <FiEdit3 />
                      </button>
                    </>
                  ),
                  value: tag.id,
                }))}
                onChange={(tag) => handleChange('tag_ids', [...data.tag_ids, tag.value])}
              />
            </div>

            <div className="select-list-items">
              { data.tag_ids.map((tag) => (
                <div className="list-item">
                  <span>{tags.find((c) => c.id === tag)?.name}</span>
                  <button
                    type="button"
                    className="button delete-button"
                    onClick={() => handleChange('tag_ids', data.tag_ids.filter((c) => c !== tag))}
                  >
                    <FiTrash />
                  </button>
                </div>
              )) }
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <div className="label-row">
                <label>Categories</label>
                <button type="button" className="create-button" onClick={() => setCategorySidebarVisible(true)}>
                  Create New
                  <FiPlus />
                </button>
              </div>
              <Select
                value=""
                options={categories.map((cat) => ({
                  label: (
                    <>
                      <span style={{ flex: 1 }}>{cat.name}</span>
                      <button
                        type="button"
                        className="category-edit-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCategorySidebarVisible(true);
                          setEditableCategory(cat);
                        }}
                      >
                        <FiEdit3 />
                      </button>
                    </>
                  ),
                  value: cat.id,
                }))}
                onChange={(cat) => handleChange('category_ids', [...data.category_ids, cat.value])}
              />
            </div>

            <div className="select-list-items">
              { data.category_ids.map((cat) => (
                <div className="list-item">
                  <span>{categories.find((c) => c.id === cat)?.name}</span>
                  <button
                    type="button"
                    className="button delete-button"
                    onClick={() => handleChange('category_ids', data.category_ids.filter((c) => c !== cat))}
                  >
                    <FiTrash />
                  </button>
                </div>
              )) }
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <div className="label-row">
                <label>Tips</label>
                <button type="button" className="create-button" onClick={() => setTipSidebarVisible(true)}>
                  Create New
                  <FiPlus />
                </button>
              </div>
              <Select
                value=""
                options={tips.map((c) => ({
                  label: (
                    <>
                      <span style={{ flex: 1 }}>{c.name}</span>
                      <button
                        type="button"
                        className="category-edit-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setTipSidebarVisible(true);
                          setEditableTip(c);
                        }}
                      >
                        <FiEdit3 />
                      </button>
                    </>
                  ),
                  value: c.id,
                }))}
                onChange={(c) => handleChange('tip_ids', [...data.tip_ids, c.value])}
              />
            </div>
            <div className="select-list-items">
              { data.tip_ids.map((tip) => (
                <div className="list-item">
                  <span>{tips.find((c) => c.id === tip)?.name}</span>
                  <button
                    type="button"
                    className="button delete-button"
                    onClick={() => handleChange('tip_ids', data.tip_ids.filter((c) => c !== tip))}
                  >
                    <FiTrash />
                  </button>
                </div>
              )) }
            </div>
          </div>
        </div>

        <div className="checklist-items-header">
          <div className="switch">
            <span className={type === 'text' ? 'active' : ''}>Custom Form Text</span>
            <EdaSwitch checked={type === 'file'} onChange={handleSwitch} />
            <span className={type === 'file' ? 'active' : ''}>Upload Form File</span>
          </div>
        </div>

        <div className="form-group" hidden={type !== 'text'}>
          <label>Text</label>
          <div
            ref={text}
            className="form-control js-wysihtml5"
          />
        </div>

        { type === 'file' && (
          <div className="form-group">
            <label>Upload File</label>
            { data.document ? (
              <div className="file-uploader-container">
                <label className="file-uploader" htmlFor="file-upload">
                  { typeof data.document === 'string' ? data.document : data.document.name }
                </label>
                <button
                  type="button"
                  className="button delete-button"
                  onClick={() => {
                    fileInput.current.value = '';
                    handleChange('document', null);
                  }}
                >
                  <FiTrash />
                </button>
              </div>
            ) : (
              <label className="file-uploader" htmlFor="file-upload">
                <span>– Click to upload file here –</span>
                <GrAttachment />
              </label>
            ) }
            <input
              type="file"
              style={{ display: 'none' }}
              id="file-upload"
              ref={fileInput}
              onChange={(e) => handleChange('document', e.target.files[0])}
            />
          </div>
        )}
      </div>

      { categorySidebarVisible && (
        <CategorySidebar
          onClose={() => { setCategorySidebarVisible(false); setEditableCategory(null); }}
          category={editableCategory}
          onAdded={handleCategoryUpdate}
          onDeleted={handleCategoryUpdate}
          onUpdated={handleCategoryUpdate}
        />
      )}

      { tipSidebarVisible && (
        <TipsSidebar
          onClose={() => { setTipSidebarVisible(false); setEditableTip(null); }}
          tip={editableTip}
          onAdded={handleTipUpdate}
          onDeleted={handleTipUpdate}
          onUpdated={handleTipUpdate}
        />
      )}

      { tagSidebarVisible && (
        <TagsSidebar
          onClose={() => { setTagSidebarVisible(false); setEditableTag(null); }}
          tag={editableTag}
          onAdded={handleTagUpdate}
          onDeleted={handleTagUpdate}
          onUpdated={handleTagUpdate}
        />
      )}
    </div>
  );
}
