import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render, screen } from '@testing-library/react';
import api from '../../../../services/api';

import RuleDetails from '../../../rules/src/RuleDetails';

const favorites = {
  rules: [
    {
      id: 4994,
      created_at: '2016-01-20T23:53:39.048-07:00',
      updated_at: '2023-08-02T03:08:06.228-06:00',
      deleted: false,
      state_court_id: null,
      district_court_id: null,
      regulatory_agency_id: 5,
    },
    {
      id: 4889,
      created_at: '2013-04-30T22:28:03.746-06:00',
      updated_at: '2017-08-04T16:09:01.494-06:00',
      deleted: false,
      state_court_id: null,
      district_court_id: 57,
      regulatory_agency_id: null,
    },
  ],
};

const rule = {
  id: 4994,
  comittee_notes: '<div>Rule #1 in Comittee notes</div>',
  full_text: '<p>This is a text for Rule Details</p>',
  comittee_notes_title: 'Committee Notes',
  document: { url: null },
  number: 'Number for Rule Details',
  summary: 'Summary for Rule Details',
  title: 'This is a Title',
  tag_list: [{ id: 1, name: 'some' }, { id: 2, name: 'tags' }],
};

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });
api.favorites.get = jest.fn().mockResolvedValue({ favorites });
api.rules.federal.favorite = jest.fn().mockResolvedValue({ });

it('renders correctly with props', () => {
  const tree = renderer.create(<RuleDetails
    rule={rule}
    favorites={favorites}
    ruleType="FederalRule"
  />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('render comitee notes', async () => {
  render(<RuleDetails
    rule={rule}
    favorites={favorites}
    ruleType="FederalRule"
  />);

  const notes = await screen.findByText('Committee notes');
  expect(notes).toBeInTheDocument();
  fireEvent.click(notes);
});
