// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="modal"
>
  <div
    className="modal-dialog modal-sm"
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <h4
          className="modal-title"
        >
          Update Successful
          <button
            className="button-clear pull-right"
            onClick={[Function]}
            type="button"
          >
            ✕
          </button>
        </h4>
      </div>
      <div
        className="modal-body"
      >
        Account updated!
      </div>
    </div>
  </div>
</div>
`;
