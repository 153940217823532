import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';

import { renderThumbnail } from '../../../services/thumbnail';
import { videoItem, postItem } from '../__mocks__/lc-data';

describe('account updated Modal', () => {
  it('renders correctly with video props', () => {
    const tree = renderer.create(renderThumbnail(videoItem, 'some url')).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders correctly with post props', () => {
    const tree = renderer.create(renderThumbnail(postItem)).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders correctly with article props', () => {
    const tree = renderer.create(renderThumbnail({ ...postItem, type: 'Article' })).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders correctly with audio props', () => {
    const tree = renderer.create(renderThumbnail({ ...postItem, type: 'Audio' })).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders correctly with audio props', () => {
    const tree = renderer.create(renderThumbnail({ ...postItem, type: 'Document' })).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders correctly with audio props', () => {
    const tree = renderer.create(renderThumbnail({ ...postItem, type: 'Ebook' })).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('return empty div with invalid props', () => {
    expect(renderThumbnail({ type: 'none' })).toStrictEqual(<div />);
  });

  it('return empty div without props', () => {
    expect(renderThumbnail()).toStrictEqual(<div />);
  });
});
