import React from 'react';

export default function SelectedCasesSheet({
  selectedCiteListCases, selectedCiteList, selectAll, downloadSelectedCases, deleteSelectedCases,
}) {
  return (
    <div className="selected-cases-sheet">
      <div className="count">
        {`${selectedCiteListCases.length} out of ${selectedCiteList.cases_count} selected`}
      </div>
      <div className="buttons">
        <button className="outline" onClick={selectAll}>
          Select All
        </button>
        <button onClick={downloadSelectedCases} data-testid="download-cases">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4993 10.6667C16.2783 10.6667 16.0664 10.7545 15.9101 10.9108C15.7538 11.0671 15.666 11.2791 15.666 11.5001V14.8334C15.666 15.0544 15.5782 15.2664 15.4219 15.4227C15.2657 15.579 15.0537 15.6667 14.8327 15.6667H3.16602C2.945 15.6667 2.73304 15.579 2.57676 15.4227C2.42048 15.2664 2.33268 15.0544 2.33268 14.8334V11.5001C2.33268 11.2791 2.24489 11.0671 2.0886 10.9108C1.93232 10.7545 1.72036 10.6667 1.49935 10.6667C1.27834 10.6667 1.06637 10.7545 0.910093 10.9108C0.753813 11.0671 0.666016 11.2791 0.666016 11.5001V14.8334C0.666016 15.4965 0.929408 16.1323 1.39825 16.6012C1.86709 17.07 2.50297 17.3334 3.16602 17.3334H14.8327C15.4957 17.3334 16.1316 17.07 16.6005 16.6012C17.0693 16.1323 17.3327 15.4965 17.3327 14.8334V11.5001C17.3327 11.2791 17.2449 11.0671 17.0886 10.9108C16.9323 10.7545 16.7204 10.6667 16.4993 10.6667ZM8.40768 12.0917C8.48694 12.1676 8.58039 12.2271 8.68268 12.2667C8.78243 12.3108 8.89029 12.3336 8.99935 12.3336C9.10841 12.3336 9.21627 12.3108 9.31602 12.2667C9.41831 12.2271 9.51176 12.1676 9.59102 12.0917L12.9243 8.75842C13.0813 8.6015 13.1694 8.38867 13.1694 8.16675C13.1694 7.94483 13.0813 7.732 12.9243 7.57508C12.7674 7.41816 12.5546 7.33001 12.3327 7.33001C12.1108 7.33001 11.8979 7.41816 11.741 7.57508L9.83268 9.49175V1.50008C9.83268 1.27907 9.74489 1.06711 9.5886 0.910826C9.43232 0.754545 9.22036 0.666748 8.99935 0.666748C8.77834 0.666748 8.56637 0.754545 8.41009 0.910826C8.25381 1.06711 8.16602 1.27907 8.16602 1.50008V9.49175L6.25768 7.57508C6.17998 7.49738 6.08774 7.43575 5.98622 7.3937C5.88471 7.35165 5.7759 7.33001 5.66602 7.33001C5.55613 7.33001 5.44733 7.35165 5.34581 7.3937C5.24429 7.43575 5.15205 7.49738 5.07435 7.57508C4.99665 7.65278 4.93502 7.74502 4.89297 7.84654C4.85092 7.94806 4.82927 8.05687 4.82927 8.16675C4.82927 8.27663 4.85092 8.38544 4.89297 8.48696C4.93502 8.58847 4.99665 8.68072 5.07435 8.75842L8.40768 12.0917Z" fill="#FFF" />
          </svg>
          Download PDF
          {selectedCiteListCases.length === 1 ? '' : 's'}
        </button>
        {!selectedCiteList.search_query && (
          <button
            className="outline"
            onClick={deleteSelectedCases}
          >
            Delete Selected
          </button>
        )}

      </div>
      <div className="deselect">
        <div className="esc">
          esc
        </div>
        to deselect all
      </div>
    </div>
  );
}
