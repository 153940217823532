import React from 'react';
import renderer from 'react-test-renderer';

import StateRules from '../../../rules/src/StateRules';

const favorites = {};

const stateRuleCategories = [
  {
    name: 'Name for Category',
    rules: [
      {
        rule_id: 2,
        number: 'Number for Rule #1',
        title: 'Title for Rule #1',
        tag_list: ['tag A', 'tag B'],
      },
      {
        rule_id: 3,
        number: 'Number for Rule #2',
        title: 'Title for Rule #2',
        tag_list: ['tag C', 'tag D'],
      },
    ],
  },
];

function createNodeMock(element) {
  const firstLink = document.createElement('a');
  const secondLink = document.createElement('a');
  if (element.type === 'div') {
    return {
      querySelectorAll: () => [firstLink, secondLink],
      getBoundingClientRect: () => ({
        bottom: 153.33333206176758,
        height: 28.11111068725586,
        left: 573.8194580078125,
        right: 1589.9027709960938,
        top: 125.22222137451172,
        width: 1016.083312988281,
        x: 573.8194580078125,
        y: 125.22222137451172,
      }),
    };
  }

  return null;
}

const states = [
  {
    name: 'State',
    description: 'This is a description for State Rules',
    state_id: 1,
    stateRuleCategories,
  },
];

it('renders correctly with props', () => {
  const tree = renderer.create(<StateRules
    favorites={favorites}
    loading={false}
    stateRuleCategories={stateRuleCategories}
    states={states}
    toggleRuleBookmark={() => {}}
  />, { createNodeMock }).toJSON();
  expect(tree).toMatchSnapshot();
});
