import React, { useState, useEffect } from 'react';
import { FaBookmark } from 'react-icons/all';
import { renderThumbnail } from '../../services/thumbnail';
import api from '../../services/api';
import Tags from './Tags';
import AccessModal from './AccessModal';

export default function Item({
  topicId, data, isFavorite, maxVisibleTags = 5, breadcrumb, isAdmin = false, isAcademy = false, subscription = false,
}) {
  if (!data) return null;

  const [videoUrl, setVideoUrl] = useState('');
  const [accessModalVisible, setAccessModalVisible] = useState(false);
  const access = (subscription && data.access_level === 'subscribed') || isAdmin || isAcademy || data.access_level === 'public';

  useEffect(() => {
    if (data.domain === 'youtube') setVideoUrl(`https://img.youtube.com/vi/${data.url.split('=').pop()}/hqdefault.jpg`);
    if (data.domain === 'vimeo') {
      api.media_items.vimeo_thumbnail(data.url)
        .then((res) => setVideoUrl(res.thumbnail_url));
    }
  }, []);

  return (
    <>
      <a
        href={topicId ? `/academy/${data.id}?topic_id=${topicId}`
          : `/academy/${data.id}${breadcrumb ? `?page=${breadcrumb}` : ''}`}
        className="lc-item"
        onClick={(e) => {
          if (access) return;
          e.preventDefault();
          setAccessModalVisible(!accessModalVisible);
        }}
      >
        <div className="body">
          <div className="thumbnail">
            { isFavorite && <div className="bookmark"><FaBookmark /></div>}
            {renderThumbnail(data, videoUrl)}
          </div>
          <div className="name-container">
            <p className="name">{data.title}</p>
            { (data.partner_logo && data.partner_logo.url) && <img src={data.partner_logo?.url} alt="Partner logo" className="partner-logo" /> }
          </div>
          <Tags items={data.tags} maxVisibleTags={maxVisibleTags} />
          <div className="separator" />
          <p className="description">{data.description}</p>
        </div>
      </a>
      { accessModalVisible && (<AccessModal closeModal={() => setAccessModalVisible(!accessModalVisible)} />)}
    </>
  );
}
