/**
 * @jest-environment jsdom
 */

import React from 'react';
import renderer from 'react-test-renderer';

import JurisdictionSection from '../../../search/src/JurisdictionSection';

const jurisdictions = {
  Administrative: [
    ['Administrative #1', '1'],
    ['Administrative #2', '2'],
  ],
  Federal: [
    { name: 'Federal #1', id: 'circuit-1', courts: [{ name: 'Federal #1', id: '1', class: 'circuit-1' }] },
    { name: 'Federal #2', id: 'circuit-2', courts: [{ name: 'Federal #2', id: '2', class: 'circuit-2' }] },
  ],
  State: [
    ['State #1', '3', ['3', '4']],
    ['State #2', '5', ['6', '7']],
  ],
};

const selectedJurisdictions = {
  federal: [
    { name: 'Federal #1', id: 'circuit-1' },
  ],
  state: [],
  admin: [],
};

it('renders correctly with props', () => {
  const tree = renderer.create(<JurisdictionSection
    closeSection={() => {}}
    selectJurisdiction={() => {}}
    selectJurisdictionsInCircuit={() => {}}
    selectedJurisdictionsContainsCircuit={() => {}}
    selectedJurisdictions={selectedJurisdictions}
    jurisdictions={jurisdictions}
    selectAllJurisdictions={() => {}}
    clearAllJurisdictions={() => {}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
