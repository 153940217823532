import React from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import api from '../../services/api';

class EditPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      status: '',
      formErrors: {},
      errors: null,
      passwordVisible: false,
    };
  }

  handleChange = (e) => {
    const { data } = this.state;
    const field = e.target.id;
    this.setState({ data: { ...data, [field]: e.target.value } }, () => this.validateField(field));
  };

  validateField = (fieldName) => {
    const { data, formErrors } = this.state;
    const value = data[fieldName];
    let valid;

    switch (fieldName) {
      case 'old_password':
        valid = Boolean(value);
        formErrors[fieldName] = valid ? '' : 'Current password missing';
        break;
      case 'password':
        valid = value.length >= 8;
        formErrors[fieldName] = valid ? '' : 'Password too short (minimum 8 characters)';
        break;
      case 'password_confirmation':
        valid = data.password === value;
        formErrors[fieldName] = valid ? '' : 'Passwords do not match';
        break;
      default:
        break;
    }
    this.setState({ formErrors });
  };

  handleSubmit = () => {
    const { data } = this.state;

    this.setState({ errors: null, status: 'updating' });
    api.reset_password(data).then((res) => {
      if (!res.errors) {
        this.setState({ status: 'success' });
        // window.location.href = 'https://app.ediscoveryassistant.com';
        return;
      }

      this.setState({
        status: 'error',
        errors: res.errors,
      });
    });
  };

  renderSuccessModal = () => (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Password Reset Success
            </h4>
          </div>
          <div className="modal-body">
            <p>
              Your password has been successfully changed. Click below to login to your account.
            </p>
            <div className="button-row">
              <a className="modal-button" href="/login">
                Login here
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  render() {
    const {
      data, errors, formErrors, passwordVisible, status,
    } = this.state;

    return (
      <div className="auth-page new-register new-edit-password">
        <div className="register-header">
          <img src="/logo.svg" className="trademark-header" />
        </div>
        <form className="register-form container">
          <div className="row edit-password-content">
            <div className="col-sm-6">
              <div className={`form-group ${(formErrors.old_password || errors?.old_password) ? 'has-error' : ''}`}>
                <label htmlFor="old_password">Current Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="old_password"
                  id="old_password"
                  value={data.old_password}
                  onChange={this.handleChange}
                  placeholder="Current Password"
                />
                { (formErrors.old_password || errors?.old_password) && <p className="help-block">{formErrors.old_password || errors?.old_password}</p> }
              </div>

              <div className={`form-group ${formErrors.password ? 'has-error' : ''}`}>
                <label htmlFor="password">New Password</label>
                {passwordVisible ? (
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="text"
                      name="password"
                      value={data.password}
                      onChange={this.handleChange}
                      placeholder="New Password"
                      id="password"
                    />
                    <span className="input-group-addon" onClick={() => this.setState({ passwordVisible: false })}>
                      <FaRegEyeSlash />
                    </span>
                  </div>
                ) : (
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={data.password}
                      onChange={this.handleChange}
                      placeholder="New Password"
                      id="password"
                    />
                    <span className="input-group-addon" onClick={() => this.setState({ passwordVisible: true })}>
                      <FaRegEye />
                    </span>
                  </div>
                )}
                { formErrors.password && <p className="help-block">{formErrors.password}</p> }
                <div className="note">
                  Please add at least 1 Uppercase and 1 symbol for increasing password strength.
                </div>
              </div>

              <div className={`form-group ${formErrors.password_confirmation ? 'has-error' : ''}`}>
                <label htmlFor="password_confirmation">Confirm New Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="password_confirmation"
                  value={data.password_confirmation}
                  onChange={this.handleChange}
                  placeholder="Confirm New Password"
                  id="password_confirmation"
                />
                { formErrors.password_confirmation && <p className="help-block">{formErrors.password_confirmation}</p> }
              </div>
            </div>
          </div>

          <div className="register-form-actions">
            <button
              type="button"
              className="btn btn-submit"
              disabled={!data.old_password || !data.password || !data.password_confirmation || Object.values(formErrors).filter(Boolean).length > 0}
              onClick={this.handleSubmit}
            >
              { status === 'updating' ? 'Updating...' : 'Confirm' }
            </button>
          </div>
        </form>

        { status === 'success' && this.renderSuccessModal()}
      </div>
    );
  }
}

EditPassword.propTypes = {
};

EditPassword.defaultProps = {
};

export default EditPassword;
