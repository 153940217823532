// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<a
  className="related-term"
  href="/glossary_terms?term=1"
>
  <div
    className="related-term-name"
  >
    This is a name for Glossary Term
  </div>
</a>
`;
