import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { EditButton } from './EditButton';
import { Tabs } from './Tabs';

export function PostContent({
  mediaItem, tab, setTab, isAdmin, linkedContent,
}) {
  const postAuthor = mediaItem?.author ? `by ${mediaItem.author}, ` : '';
  const postDate = mediaItem?.post_date
    ? new Date(mediaItem.post_date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }) : '';
  return (
    <div className="content">
      <h1>
        {mediaItem?.title}
        {isAdmin && <EditButton mediaItem={mediaItem} />}
      </h1>
      <p>
        {postAuthor}
        {postDate}
      </p>
      <div className="separator" />
      <div className="post-body">
        { ReactHtmlParser(mediaItem.post_body) }
      </div>
      <Tabs
        item={mediaItem}
        tab={tab}
        setTab={setTab}
        isAdmin={isAdmin}
        linkedContent={linkedContent}
      />
    </div>
  );
}
