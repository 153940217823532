import React from 'react';
import renderer from 'react-test-renderer';
import { fireEvent, render } from '@testing-library/react';
import $ from 'jquery';

import AccessModal from '../../learning_center/AccessModal';

global.$ = $;
global.jQuery = $;

it('renders correctly with props', () => {
  const tree = renderer.create(<AccessModal closeModal={() => {}} />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('render button and click', async () => {
  const close = jest.fn(() => {});
  const container = render(<AccessModal closeModal={close} />);
  fireEvent.click(container.getByTestId('modal'));
  expect(close).toHaveBeenCalledTimes(1);
});
