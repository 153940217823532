// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="aba-rules"
>
  <div
    className="aba-rule-list"
  >
    <div
      className="results"
    >
      <div
        className="title"
      />
      <a
        className="result"
        href="/aba_model_rules/1"
      >
        <div
          className="result-number"
        >
          ABA Rule #1
        </div>
        <svg
          fill="currentColor"
          height="1em"
          onClick={[Function]}
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 384 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 512V48C0 21.49 21.49 0 48 0h288c26.51 0 48 21.49 48 48v464L192 400 0 512z"
          />
        </svg>
      </a>
    </div>
    <div
      className="results"
    >
      <a
        className="result"
        href="/aba_model_rules/2"
      >
        <div
          className="result-number"
        >
          ABA Rule #2
        </div>
        <svg
          className="initially-hidden"
          fill="currentColor"
          height="1em"
          onClick={[Function]}
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 384 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
          />
        </svg>
      </a>
    </div>
  </div>
</div>
`;
