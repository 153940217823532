import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FiTrash, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { GoSettings, GoPlus } from 'react-icons/go';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';

export default function Index({ accountTypes, accountCategories, plans }) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [accounts, setAccounts] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [subscribedCount, setSubscribedCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [currentTab, setCurrentTab] = useState('all');

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [category, setCategory] = useState('');
  const [plan, setPlan] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortDir, setSortDir] = useState('asc');

  const fetchData = (opts) => {
    Api.admin.accounts.get(opts).then(({ status, data }) => {
      if (status !== 'OK') return;

      setAccounts(data.accounts);
      setTotalCount(data.totalCount);
      setUsersCount(data.usersCount);
      setActiveCount(data.activeCount);
      setSubscribedCount(data.subscribedCount);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    });
  };

  const handlePage = (page) => {
    fetchData({
      page, name, type, category, plan, sortBy, sortDir, accountStatus,
    });
  };

  const handleFilter = () => {
    fetchData({
      page: 1, name, type, category, plan, sortBy, sortDir, accountStatus,
    });
  };

  const handleSort = (field) => {
    let dir = 'asc';
    if (sortBy === field) {
      if (sortDir === 'asc') dir = 'desc';
      if (sortDir === 'desc') dir = 'asc';
    }
    setSortBy(field);
    setSortDir(dir);
    fetchData({
      page: currentPage, name, type, category, plan, sortBy: field, sortDir: dir, accountStatus,
    });
  };

  const handleDelete = (account) => {
    const res = window.confirm('Are you sure?');
    if (!res) return;
    Api.admin.accounts.delete(account.id).then(({ status }) => {
      if (status !== 'OK') return;

      Snackbar.show(`Account “${account.name}” has been deleted!`);
      fetchData({
        page: currentPage, name, type, category, plan, sortBy, sortDir,
      });
    });
  };

  useEffect(() => fetchData({ page: 1, sortBy, sortDir }), []);

  return (
    <div className={`admin-page react-admin-accounts ${filtersOpen ? 'filters-open' : ''}`}>
      <div className="header">
        <h1>Accounts</h1>
        <button type="button" className={`filters-button ${filtersOpen ? 'active' : ''}`} onClick={() => setFiltersOpen(!filtersOpen)}>
          <GoSettings />
        </button>
      </div>
      <div className="tabs">
        <div
          className={`tab${currentTab === 'all' ? ' active' : ''}`}
          onClick={() => {
            setAccountStatus('');
            setType('');
            setCurrentTab('all');
            fetchData({});
          }}
        >
          All
        </div>
        <div
          className={`tab${currentTab === 'active_free_trial' ? ' active' : ''}`}
          onClick={() => {
            setAccountStatus('true');
            setType('free_trial');
            setCurrentTab('active_free_trial');
            fetchData({ type: 'free_trial', accountStatus: 'true' });
          }}
        >
          Active Free Trial
        </div>
        <div
          className={`tab${currentTab === 'active_aceds_trial' ? ' active' : ''}`}
          onClick={() => {
            setAccountStatus('true');
            setType('aceds_trial');
            setCurrentTab('active_aceds_trial');
            fetchData({ type: 'aceds_trial', accountStatus: 'true' });
          }}
        >
          Active ACEDS Trial
        </div>
        <div
          className={`tab${currentTab === 'active' ? ' active' : ''}`}
          onClick={() => {
            setAccountStatus('true');
            setType('');
            setCurrentTab('active');
            fetchData({ accountStatus: 'true' });
          }}
        >
          Total Active
        </div>
        <div
          className={`tab${currentTab === 'inactive' ? ' active' : ''}`}
          onClick={() => {
            setAccountStatus('false');
            setType('');
            setCurrentTab('inactive');
            fetchData({ subscriptionStatus: 'false' });
          }}
        >
          Inactive
        </div>
      </div>
      <div className="table">
        <div className="table-header">
          <div className="stats">
            <div className="stat">
              Total Accounts
              {' '}
              <span>{totalCount}</span>
            </div>
            <div className="stat">
              Total Users
              {' '}
              <span>{usersCount}</span>
            </div>
            <div className="stat">
              Active
              {' '}
              <span>{activeCount}</span>
            </div>
            <div className="stat">
              Subscribed
              {' '}
              <span>{subscribedCount}</span>
            </div>
          </div>
          <a className="button" href="/admin/accounts/new">
            Add New
            <GoPlus />
          </a>
        </div>

        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('name')}>
                Name
                { sortBy === 'name' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'name' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th onClick={() => handleSort('type')}>
                Type
                { sortBy === 'type' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'type' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              {/* <th onClick={() => handleSort('trial')}>
                Trial
                { sortBy === 'trial' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'trial' && sortDir === 'desc' && <FiChevronDown /> }
              </th> */}
              <th onClick={() => handleSort('subscription')}>
                Subscription
                { sortBy === 'subscription' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'subscription' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th onClick={() => handleSort('subscription_start')}>
                Subscription Start
                { sortBy === 'subscription_start' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'subscription_start' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th onClick={() => handleSort('subscription_end')}>
                Subscription End
                { sortBy === 'subscription_end' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'subscription_end' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th onClick={() => handleSort('users')}>
                Users
                { sortBy === 'users' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'users' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th onClick={() => handleSort('active')}>
                Active?
                { sortBy === 'active' && sortDir === 'asc' && <FiChevronUp /> }
                { sortBy === 'active' && sortDir === 'desc' && <FiChevronDown /> }
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            { (accounts || []).map((account) => (
              <tr key={account.id}>
                <td>
                  <a href={`/admin/accounts/${account.id}/edit`}>
                    {account.name}
                  </a>
                </td>
                <td>{accountTypes.find((t) => t.value === account.account_type)?.label}</td>
                {/* <td>{renderTrialSubscription(account)}</td> */}
                <td>{(account && account?.valid_subscription) ? account.valid_subscription.plan.name : 'N/A'}</td>
                <td>{(account && account?.valid_subscription) ? moment(account.valid_subscription.effective_from).format('MM/DD/YY') : 'N/A'}</td>
                <td>{(account && account?.valid_subscription) ? moment(account.valid_subscription.effective_to).format('MM/DD/YY') : 'N/A'}</td>
                <td>{account.users_count}</td>
                <td>{!account.disabled && Boolean(account.valid_subscription) ? '✓' : '✕'}</td>
                <td>
                  <div className="buttons">
                    <button type="button" className="delete-button" onClick={() => handleDelete(account)}>
                      <FiTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage - 1)}
          >
            &lt;
          </button>
          <span>
            Page
            {' '}
            {currentPage}
            /
            {totalPages}
          </span>
          <button
            disabled={currentPage >= totalPages}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      </div>

      { filtersOpen && (
        <div className="sidebar">
          <div className="sidebar-header">
            <h2>Filter By</h2>
          </div>
          <div className="sidebar-body" onKeyDown={(e) => e.key === 'Enter' && handleFilter()}>
            <div className="form-group">
              <input
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

            <div className="form-group">
              <select className="form-control" value={accountStatus} onChange={(e) => setAccountStatus(e.target.value)}>
                <option value="">Account Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                <option value="">Type</option>
                {accountTypes.map((t) => (
                  <option value={t.value}>{t.label}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="">Category</option>
                {accountCategories.map((t) => (
                  <option value={t.id}>{t.name}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <select className="form-control" value={plan} onChange={(e) => setPlan(e.target.value)}>
                <option value="">Subscription</option>
                {plans.map((p) => (
                  <option value={p.id} key={p.id}>{p.name}</option>
                ))}
              </select>
            </div>

            <button
              type="button"
              className="button"
              onClick={() => {
                setCurrentTab('');
                handleFilter();
              }}
            >
              Filter
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
