import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';

export function DetailsBreadcrumb({ currentUser, topic, breadcrumb }) {
  if (!currentUser) return null;

  if (topic) {
    return (
      <div className="header">
        <a href={`/media_category/${topic.id}`}>
          <FiChevronLeft />
        </a>
        <h2>{topic.name}</h2>
      </div>
    );
  }

  return (
    <div className="header">
      <a href={breadcrumb ? `/academy/${breadcrumb}` : '/academy'}>
        <FiChevronLeft />
      </a>
      <h2>
        {
          breadcrumb === 'favorites' ? 'Bookmarks'
            : breadcrumb === 'progress' ? 'My Progress'
              : 'Dashboard'
        }
      </h2>
    </div>
  );
}
