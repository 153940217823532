import PropTypes from 'prop-types';
import React from 'react';

export default class AudioPlayer extends React.Component {
  render() {
    const { audio, width, height } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        width={width}
        height={height}
        scrolling="no"
        frameBorder="no"
        src={`${audio.url}&amp;auto_play=false&amp;show_user=true&amp;visual=true`}
      />
    );
  }
}

AudioPlayer.propTypes = {
  audio: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AudioPlayer.defaultProps = {
  width: '100%',
  height: 500,
};
