import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';

export default function Sidebar({
  tag, onClose, onAdded, onUpdated, onDeleted,
}) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [associationsCount, setAssociationsCount] = useState(0);

  useEffect(() => {
    setName(tag?.name || '');
    setDescription(tag?.description || '');
    setAssociationsCount(0);
  }, [tag]);

  const handleAdd = () => {
    Api.admin.tags.create({ name, description }).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show('New Tag added');
      if (onAdded) onAdded();
    });
  };

  const handleUpdate = () => {
    Api.admin.tags.update(tag.id, { name, description }).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show('1 Tag updated');
      if (onUpdated) onAdded();
    });
  };

  const handleConfirmDelete = () => {
    setAssociationsCount(0);
    Api.admin.tags.delete(tag.id).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show('1 Tag removed');
      if (onDeleted) onDeleted();
    });
  };

  const handleDelete = () => {
    Api.admin.tags.get(tag.id).then((res) => {
      if (res.status !== 'OK') return;
      if (res.data.associations_count > 0) {
        setAssociationsCount(res.data.associations_count);
      } else {
        handleConfirmDelete();
      }
    });
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>
          { tag ? 'Edit Tag' : 'Add New Tag' }
        </h2>
        <button type="button" className="close" onClick={onClose}><GrClose /></button>
      </div>
      <div className="sidebar-body">
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Tag Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Tag Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        { tag ? (
          <button
            type="button"
            className="button"
            disabled={!name}
            onClick={handleUpdate}
          >
            Update Tag
          </button>
        ) : (
          <button
            type="button"
            className="button"
            disabled={!name}
            onClick={handleAdd}
          >
            Save Tag
          </button>
        )}

        { Boolean(tag) && (
          <button type="button" className="button delete" onClick={handleDelete}>
            Delete Tag
          </button>
        ) }

        { associationsCount > 0 && (
          <div className="note">
            <p>Removing a tag will affect all relevant records.</p>
            <p className="danger">
              There are currently
              {' '}
              {associationsCount}
              {' '}
              records have been applied with this tag.
            </p>
            <p>Do you wish to proceed? This action cannot be undone.</p>
            <div className="buttons">
              <button type="button" className="button danger" onClick={handleConfirmDelete}>Yes</button>
              <button type="button" className="button" onClick={() => setAssociationsCount(0)}>No</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
