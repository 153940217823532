import React from 'react';
import renderer from 'react-test-renderer';

import RelatedChecklist from '../../related_items/RelatedChecklist';

const checklist = {
  id: 1,
  published_revision: {
    name: 'This is a name for Checklist',
  },
};

it('renders correctly with props', () => {
  const tree = renderer.create(<RelatedChecklist
    relatedChecklist={checklist}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
