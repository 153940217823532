import React from 'react';

export default function PdfModal({ citeListName, closeModal }) {
  return (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              Download Cite List
              {' '}
              &quot;
              {citeListName}
              &quot;
            </h4>
          </div>
          <div className="modal-body">
            <p>
              We are compiling your cite list and will deliver it to the email on the account when it is ready.
            </p>
            <div className="button-row">
              <div className="modal-button modal-button-outline" onClick={closeModal}>
                Close
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
