import React from 'react';
import '@testing-library/jest-dom';
import {
  render, screen,
} from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import TermsAndServices from '../../auth/TermsAndServices';

const data = {
  name: 'Name',
  company: 'Company',
  email: 'email',
  password: 'password',
  referral_company: '',
  user_roles: [],
  terms_of_use: true,
  plan: {
    name: 'Individual',
    default_amount: 12000,
    plan_type: 'credit_card',
  },
};

describe('TermsAndServices component', () => {
  it('renders correctly with props', () => {
    render(<TermsAndServices
      data={data}
      onChange={() => {}}
      onCancel={() => {}}
      onSubmit={() => {}}
    />);
    expect(screen).toMatchSnapshot();
  });

  it('change and cancel actions', () => {
    const change = jest.fn(() => {});
    const cancel = jest.fn(() => {});
    render(<TermsAndServices
      data={data}
      onChange={change}
      onCancel={cancel}
      onSubmit={() => {}}
    />);

    const checkbox = screen.getByTestId('terms_of_use');
    userEvent.click(checkbox);
    expect(change).toBeCalledTimes(1);

    const cancelButton = screen.getByText('Back');
    userEvent.click(cancelButton);
    expect(cancel).toBeCalledTimes(1);
  });

  it('next step action with valid data', () => {
    const submit = jest.fn(() => {});
    render(<TermsAndServices
      data={data}
      onChange={() => {}}
      onCancel={() => {}}
      onSubmit={submit}
    />);

    const nextButton = screen.getByText('Continue');
    const checkbox = screen.getByTestId('terms_of_use');
    userEvent.click(checkbox);
    userEvent.click(nextButton);
    expect(submit).toBeCalledTimes(1);
  });

  it('next step action with invalid data', () => {
    const submit = jest.fn(() => {});
    render(<TermsAndServices
      data={{ ...data, terms_of_use: false }}
      onChange={() => {}}
      onCancel={() => {}}
      onSubmit={submit}
    />);

    const nextButton = screen.getByText('Continue');
    userEvent.click(nextButton);
    expect(submit).toBeCalledTimes(0);
  });

  it('render with trial plan', () => {
    const submit = jest.fn(() => {});
    render(<TermsAndServices
      data={{ ...data, plan: { ...data.plan, plan_type: 'trial' } }}
      onChange={() => {}}
      onCancel={() => {}}
      onSubmit={submit}
    />);

    const nextButton = screen.getByText('Create Account');
    expect(nextButton).toBeInTheDocument();
    const checkbox = screen.getByTestId('terms_of_use');
    userEvent.click(checkbox);
    userEvent.click(nextButton);
    expect(submit).toBeCalledTimes(1);
  });
});
