import React from 'react';
import renderer from 'react-test-renderer';
import { fireEvent, render } from '@testing-library/react';

import { EditButton } from '../../../learning_center/DetailsContent/EditButton';
import { videoItem } from '../../__mocks__/lc-data';

const spyWindowOpen = jest.spyOn(window, 'open');
spyWindowOpen.mockImplementation(() => ({ focus: jest.fn() }));

it('renders correctly with props', () => {
  const tree = renderer.create(<EditButton mediaItem={videoItem} />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<EditButton />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('render button and click', async () => {
  const container = render(<EditButton mediaItem={videoItem} />);
  fireEvent.click(container.getByTestId('edit-button'));
  fireEvent.mouseDown(container.getByTestId('edit-button'), { button: 2 });
});
