import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import SignIn from '../../auth/SignIn';

const flash = [['alert', 'You need to sign in before continuing.\n']];

describe('SignIn', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<SignIn
      flash={[]}
      forgotPasswordPath="/password/new"
      url="/login"
    />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders correctly with flash', () => {
    const { queryByText } = render(<SignIn
      flash={flash}
      url="/login"
      forgotPasswordPath="/password/new"
    />);

    expect(queryByText(/You need to sign in/i)).toBeInTheDocument();
  });

  it('shows password', () => {
    const { getByLabelText } = render(<SignIn
      flash={[]}
      url="/login"
      forgotPasswordPath="/password/new"
    />);

    const passwordField = getByLabelText(/password/i);
    expect(passwordField).toBeInTheDocument();

    userEvent.type(passwordField, 'secretPhrase');

    const showPassword = document.querySelector('svg');
    userEvent.click(showPassword);

    expect(screen.getByDisplayValue(/secretPhrase/)).toBeInTheDocument();
  });
});
