import React, { useMemo } from 'react';

function parseQueryString(queryString) {
  if (!queryString) return {};
  const params = {};
  const pairs = queryString.slice(1).split('&');

  // eslint-disable-next-line
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1].replace(/\+/g, ' ') || '');

    // If the key already exists, convert it to an array
    if (params[key]) {
      if (Array.isArray(params[key])) {
        params[key].push(value);
      } else {
        params[key] = [params[key], value];
      }
    } else {
      params[key] = value;
    }
  }

  return params;
}

function itemsSelect(ids, items) {
  return ids.map((id) => {
    const item = items.find((i) => i.id === Number(id));
    return item?.abbreviation || item?.name || '';
  });
}

function renderFederalJurisdictionTags(jurisdictions, selectedJurisdictions) {
  let federalCourtCount = 0;
  jurisdictions.Federal.forEach((circuit) => { federalCourtCount += circuit.courts.length; });
  if (selectedJurisdictions.federal.length === federalCourtCount) {
    return <div className="history-tag" key="All Federal">All Federal</div>;
  }
  const circuitCourtsMap = new Map();
  jurisdictions.Federal.forEach((circuit) => circuitCourtsMap.set(circuit.id, []));
  selectedJurisdictions.federal.forEach((court) => {
    circuitCourtsMap.get(court.class.toString()).push(court);
  });

  const renderList = [];
  circuitCourtsMap.forEach((courts, circuitId) => {
    const circuit = jurisdictions.Federal.find((c) => c.id === circuitId);
    if (courts.length === circuit.courts.length) {
      renderList.push((<div className="history-tag" key={circuit.id}>{ circuit.name }</div>));
    } else {
      courts.forEach((court) => renderList.push((<div className="history-tag" key={court.id}>{ court.name }</div>)));
    }
  });

  return (renderList);
}

function renderDates(start_date, end_date) {
  if (start_date && end_date) {
    return (
      <div className="dates">
        <span className="description-margin">Dates:</span>
        <div className="history-tag">
          {(`${new Date(start_date || '')
            .toLocaleDateString(
              'en-US',
              { year: 'numeric', month: 'short', day: 'numeric' },
            )
          } to ${new Date(end_date || '')
            .toLocaleDateString(
              'en-US',
              { year: 'numeric', month: 'short', day: 'numeric' },
            )}`)}
        </div>
      </div>
    );
  }

  if (start_date && !end_date) {
    return (
      <div className="dates">
        <span className="description-margin">Dates:</span>
        <div className="history-tag">
          {(`${new Date(start_date || '')
            .toLocaleDateString(
              'en-US',
              { year: 'numeric', month: 'short', day: 'numeric' },
            )
          } to present`)}
        </div>
      </div>
    );
  }

  if (!start_date && end_date) {
    return (
      <div className="dates">
        <span className="description-margin">Dates:</span>
        <div className="history-tag">
          {(`Before ${new Date(end_date || '')
            .toLocaleDateString(
              'en-US',
              { year: 'numeric', month: 'short', day: 'numeric' },
            )}`)}
        </div>
      </div>
    );
  }

  return null;
}

export default function DynamicSearchParams({ query, tags, jurisdictions }) {
  const params = useMemo(() => parseQueryString(query), [query]);
  // convert tag ids query into array of ids
  const issuesIds = params?.tag_ids_query ? params.tag_ids_query.replace(/\+/g, ' ').replace(/[^ \d]+/g, '').split(' ').filter((t) => t.trim() !== '') : [];
  const courtIds = params?.court_ids ? params.court_ids.split(',') : [];
  const issues = itemsSelect(issuesIds, tags);
  const selectedJurisdictions = {
    state: [],
    federal: [],
    admin: [],
  };

  const selectJurisdiction = (jurisdiction, type) => {
    const jurisdictionIndex = selectedJurisdictions[type].indexOf(jurisdiction);

    if (jurisdictionIndex !== -1) {
      selectedJurisdictions[type].splice(jurisdictionIndex, 1);
    } else {
      selectedJurisdictions[type].push(jurisdiction);
    }
  };

  if (courtIds && courtIds.length > 0) {
    courtIds.forEach((courtId) => {
      const adminCourt = jurisdictions.Administrative.find((court) => court[1] === courtId);
      if (adminCourt !== undefined) {
        selectJurisdiction(adminCourt, 'admin');
        return;
      }
      const stateCourt = jurisdictions.State.find((court) => court[1] === courtId);
      if (stateCourt !== undefined) {
        selectJurisdiction(stateCourt, 'state');
        return;
      }
      const topLevelFederalCourt = jurisdictions.Federal.find((circuit) => circuit.courts.find((court) => court.id === courtId));
      const federalCourt = topLevelFederalCourt && topLevelFederalCourt.courts.find(((court) => court.id === courtId));
      if (federalCourt !== undefined) {
        selectJurisdiction(federalCourt, 'federal');
      }
    });
  }

  return (
    <div className="history">
      <div className="search-history-container">
        <div className="description-date">
          <div className="search-history-description">
            {(issues && issues.length > 0) && (
            <div className="issues-container">
              <span className="description-margin">Issues:</span>
              { issues.map((tag) => (<div className="history-tag" key={tag}>{ tag }</div>)) }
            </div>
            )}
            { (selectedJurisdictions.state.length > 0 || selectedJurisdictions.federal.length > 0 || selectedJurisdictions.admin.length > 0) && (
              <div className="issues-container">
                <span className="description-margin">Jurisdictions:</span>
                {selectedJurisdictions.state.length === jurisdictions.State.length ? (
                  <div className="history-tag" key="All States">All States</div>
                ) : selectedJurisdictions.state.map((jurisdiction) => (
                  <div className="history-tag" key={jurisdiction[0]}>{jurisdiction[0]}</div>
                ))}
                { renderFederalJurisdictionTags(jurisdictions, selectedJurisdictions) }
                {selectedJurisdictions.admin.length === jurisdictions.Administrative.length ? (
                  <div className="history-tag" key="All Administrative">All Administrative</div>
                ) : selectedJurisdictions.admin.map((jurisdiction) => (
                  <div className="history-tag" key={jurisdiction[0]}>{ jurisdiction[0] }</div>
                ))}
              </div>
            )}
            { params.q && (
            <div className="search-terms-container">
              <span className="description-margin">Search Term:</span>
              <span className="blue-color">{ params.q }</span>
            </div>
            )}
            { (params.start_date || params.end_date) && renderDates(params.start_date, params.end_date) }
            { params.case_title && (
            <div className="search-terms-container">
              <span className="description-margin">Case Title:</span>
              <span className="blue-color">{ params.case_title }</span>
            </div>
            )}
            { params.judge && (
            <div className="search-terms-container">
              <span className="description-margin">Judge:</span>
              <span className="blue-color">{ params.judge }</span>
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
