import React from 'react';
import renderer from 'react-test-renderer';

import Player from '../../../learning_center/DetailsContent/Player';
import { videoItem } from '../../__mocks__/lc-data';

it('renders correctly with props', () => {
  const tree = renderer.create(<Player mediaItem={videoItem} />).toJSON();
  expect(tree).toMatchSnapshot();
});
