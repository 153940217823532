import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import jquery from 'jquery';

import Search from '../../search/Search';

window.$ = jquery;
const tags = [
  {
    id: 1,
    name: 'Issue #1',
    subtype: 'issue',
  },
  {
    id: 2,
    name: 'Issue #2',
    subtype: 'issue',
  },
];

const jurisdictions = {
  Administrative: [
    ['Administrative #1', '1'],
    ['Administrative #2', '2'],
  ],
  Federal: [
    { name: 'Federal #1', id: 'circuit-1', courts: [{ name: 'Fed Cir', id: '203', class: 'circuit-1' }] },
    { name: 'Federal #2', id: 'circuit-2', courts: [{ name: 'D C Cir', id: '205', class: 'circuit-2' }] },
  ],
  State: [
    ['State #1', '3', ['3', '4']],
    ['State #2', '5', ['6', '7']],
  ],
};

const props = {
  allResultIds: [34532],
  caseDigests: [
    {
      digest: {
        id: 34532,
        deleted: false,
        is_digested: false,
        pdf: 'dickinson_frozen_foods_v._fps_food_process_sols._corp.20210617-4-1i5xoae.pdf',
        tags,
      },
      published_revision: {
        id: 94135,
        name: 'Dickinson Frozen Foods v. FPS Food Process Sols. Corp.',
        full_text: null,
        cite: '2021 WL 2444157 (D. Idaho 2021)',
        judge: 'Nye, David C.',
        decision_date: '2021-06-15T00:00:00.000-06:00',
        case_digest_id: 34532,
        published: true,
        judge_type: 'United States District Judge',
        court_id: 2133,
        key_case: false,
        notes: null,
        cached_tag_list: 'Exclusion of Evidence, Protective Order, Search Terms, Proportionality, Cooperation of counsel, Initial Disclosures, Failure to Preserve, Sanctions, Spoliation, 30(b)(6) or corporate designee, Costs and Fees',
        linked_federal_rules: 'Fed. R. Civ. P. 30, Fed. R. Civ. P. 26, Fed. R. Civ. P. 37 -- effective 12/1/15',
        google_scholar_text_type: 'westlaw',
        court_display_name: 'United States District Court, D. Idaho',
        case_title: 'DICKINSON FROZEN FOODS, INC., Plaintiff,\r\nv.\r\nFPS FOOD PROCESS SOLUTIONS CORPORATION, Defendant',
        docket_number: 'Case No. 1:17-cv-00519-DCN',
        counsel: '',
        decision_title: 'MEMORANDUM DECISION AND ORDER',
        additional_dates: [],
        decision_date_label: 'Filed',
        disposition: 'Motion for Attorney Fees',
        is_panel: false,
        is_per_curiam: false,
        panel_members: null,
        tag_list: [
          'Exclusion of Evidence',
          'Protective Order',
          'Search Terms',
          'Proportionality',
          'Cooperation of counsel',
          'Initial Disclosures',
          'Failure to Preserve',
          'Sanctions',
          'Spoliation',
          '30(b)(6) or corporate designee',
          'Costs and Fees',
        ],
        tags,
      },
      slug: '34532-dickinson-frozen-foods-v-fps-food-process-sols-corp',
      full_text: null,
      highlighted_fragmen: null,
    },
  ],
  caseTitle: '',
  courtCounts: {
    circuit_courts: '{9: 1}',
    state_courts: {},
    admin_courts: 0,
    total_count: 1,
    hide_all: false,
  },
  courtIds: [],
  includeUnpublishedDecisions: true,
  isAdmin: true,
  judge: '',
  jurisdictions,
  page: 1,
  query: '',
  resultsPerPage: '25',
  sortBy: 'des_decision_date',
  tagIdsQuery: '1 AND 2',
  tags,
  tutorialVideo: null,
  type: 'issue',
};

test('renders correctly with props', () => {
  const tree = render(
    <Search
      allResultIds={[]}
      isAdmin={false}
      jurisdictions={jurisdictions}
      tags={tags}
      caseDigests={[]}
      query=""
      judge=""
    />,
  );
  expect(tree).toMatchSnapshot();
});

test('correctly interacts with Search', () => {
  render(<Search
    allResultIds={[]}
    isAdmin={false}
    jurisdictions={jurisdictions}
    tags={tags}
    caseDigests={[]}
    query=""
    judge=""
  />);

  const searchInput = screen.getByPlaceholderText('Start typing issues or click on + sign to select issues');
  expect(searchInput).toBeInTheDocument();

  const searchTagsButton = screen.getByTestId('search-tags');
  expect(searchTagsButton).toBeInTheDocument();

  userEvent.click(searchTagsButton);
  expect(searchTagsButton).toBeInTheDocument();

  const searchTagsInput = screen.getByPlaceholderText('Search Issue Tags');
  expect(searchTagsInput).toBeInTheDocument();

  const closeTagsSection = screen.getByTestId('tags-close-button');
  userEvent.click(closeTagsSection);
  expect(searchTagsInput).not.toBeInTheDocument();

  const spoliationTag = screen.getByText('Spoliation');
  userEvent.click(spoliationTag);
  expect(searchInput).toHaveValue('Spoliation ');

  const and = screen.getByText('AND');
  userEvent.click(and);
  expect(searchInput).toHaveValue('Spoliation AND ');

  const sanctionsTag = screen.getByText('Sanctions');
  userEvent.click(sanctionsTag);
  expect(searchInput).toHaveValue('Spoliation AND Sanctions ');

  const clearTagsButton = screen.getByText('✕');
  userEvent.click(clearTagsButton);
  expect(searchInput).toHaveValue('');

  userEvent.type(searchInput, 'issue');
  const issues = screen.queryAllByText('Issue');
  expect(issues.length).toEqual(2);
});

test('renders search correctly', () => {
  window = Object.create(window);
  const url = 'http://https://app.ediscoveryassistant.com/case_law?sort_by=des_decision_date&page=1&results_per_page=25&include_unpublished=true&type=issue&tag_ids_query=1+2';
  Object.defineProperty(window, 'location', {
    value: { href: url },
    writable: true,
  });
  const tree = render(
    <Search {...props} />,
  );
  expect(tree).toMatchSnapshot();
});
