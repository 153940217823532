import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';

export function EditButton({ mediaItem }) {
  if (!mediaItem) return null;
  return (
    <button
      className="edit-button"
      onClick={(e) => {
        e.stopPropagation();
        window.location.href = `/admin/media_items/${mediaItem.id}/edit?academy=1`;
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        if (e.button === 2) {
          window.open(`/admin/media_items/${mediaItem.id}/edit?academy=1`, '_blank').focus();
        }
      }}
      data-testid="edit-button"
    >
      <FaPencilAlt />
    </button>
  );
}
