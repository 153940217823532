import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';
import CCExpiredModal from '../../../account/account_modals/CCExpiredModal';

describe('account updated Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<CCExpiredModal account={{ account_type: 'Individual' }} closeModal={() => {}} togglePaymentMethodModal={() => {}} />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders modal and close click', async () => {
    const toggleModal = jest.fn(() => {});
    const close = jest.fn(() => {});
    const container = render(
      <CCExpiredModal
        account={{ account_type: 'Individual' }}
        closeModal={close}
        togglePaymentMethodModal={toggleModal}
      />,
    );
    const toggleButton = container.queryByText('Renew');
    const closeButton = container.queryByText('✕');
    expect(toggleButton).toBeInTheDocument();
    expect(closeButton).toBeInTheDocument();
    fireEvent.click(toggleButton);
    expect(toggleModal).toHaveBeenCalledTimes(1);
    fireEvent.click(closeButton);
    expect(close).toHaveBeenCalledTimes(1);
  });
});
