import React from 'react';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/all';

export default function PersonalDetails({
  editPasswordPath, user, userRoles, handleChange, updateUserData, selectRole,
}) {
  const {
    firstName, lastName, email, selectedRoles,
  } = user;
  const renderRoleCheckbox = (role) => {
    const _isSelected = selectedRoles.some((selectedRole) => selectedRole.id === role.id);
    return (
      <div className="role" key={role.id}>
        <div className={`role-checkbox${_isSelected ? ' selected' : ''}`}>
          {
            _isSelected ? <ImCheckboxChecked onClick={() => selectRole(role)} />
              : <ImCheckboxUnchecked onClick={() => selectRole(role)} style={{ fill: '#E5E5E8', background: '#E5E5E8', borderRadius: 3 }} />
          }
        </div>
        {role.name}
      </div>
    );
  };

  return (
    <>
      <div className="account-row">
        <div className="account-field">
          <div className="field-title">First Name</div>
          <input type="text" value={firstName} onChange={handleChange} id="firstName" />
        </div>
      </div>
      <div className="account-row">
        <div className="account-field">
          <div className="field-title">Last Name</div>
          <input type="text" value={lastName} onChange={handleChange} id="lastName" />
        </div>
      </div>
      <div className="account-row">
        <div className="account-field">
          <div className="field-title">Email Address</div>
          <input type="text" value={email} onChange={handleChange} id="email" />
        </div>
      </div>
      <div className="account-row">
        <div className="account-field">
          <div className="field-title">Password</div>
          <a className="update" rel="noreferrer" href={editPasswordPath}>
            Reset Password
          </a>
        </div>
      </div>
      <div className="roles">
        <div className="roles-name">Tell us about yourself</div>
        <div className="roles-list">
          {userRoles.map((role) => renderRoleCheckbox(role))}
        </div>
      </div>
      <div className="update" onClick={updateUserData}>
        Update Settings
      </div>
    </>
  );
}
