import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import api from '../../../services/api';

import CaseDigest from '../../case_digest/CaseDigest';

const favorites = {
  forms: [
    {
      created_at: '2017-11-05T23:09:53.005-07:00',
      deleted: false,
      id: 48,
      updated_at: '2017-11-06T07:57:32.383-07:00',
    },
    {
      created_at: '2015-04-06T14:57:10.145-06:00',
      deleted: false,
      id: 45,
      updated_at: '2016-11-28T13:45:34.470-07:00',
    },
  ],
  rules: [
    {
      created_at: '2014-06-11T20:57:16.517-06:00',
      deleted: false,
      id: 306,
      state_court_id: null,
      updated_at: '2018-07-31T09:12:26.121-06:00',
    },
  ],
};

const tags = [
  {
    id: 1,
    name: 'Issue #1',
    subtype: 'issue',
  },
  {
    id: 2,
    name: 'Issue #2',
    subtype: 'issue',
  },
];

const published_revision = {
  additional_dates: [],
  video: {
    id: 1,
    domain: 'vimeo',
    title: 'This is a title for Video Player',
    description: 'This is a description for Video Player',
    thumbnail: 'http://i.vimeocdn.com/video/529956697_640',
    url: 'https://vimeo.com/135593189',
    mozallowfullscreen: 'true',
  },
  additional_decisions: false,
  appellate_case: false,
  body_text: 'This is a body text for Case Digest',
  case_digest_id: 1,
  case_title: 'This is a Case Title',
  cite: 'This is a Cite',
  counsel: 'This is a counsel',
  court_display_name: 'This is a court display name',
  court_id: 1,
  de_further_review: false,
  de_further_review_notes: '',
  decision_title: 'Decision Title',
  display_opinion_file: false,
  disposition: '',
  docket_number: 'Docket Number',
  formatting_errors: false,
  full_case_visibility: true,
  full_name: null,
  full_text: 'The editors have not yet created a digest for this case.',
  full_text_verified: true,
  google_scholar_link: 'https://scholar.google.com/scholar_case?case=4233182295233215789&q=Carrie+Reeves&hl=en&as_sdt=6,31&as_ylo=2009&as_yhi=2009',
  google_scholar_text: '<div>This is a Google Scholar Text</div>',
  google_scholar_text_type: 'westlaw',
  has_table_of_contents: false,
  has_tables: false,
  id: 1,
  image_display_errors: false,
  in_dropbox: false,
  internal_notes: '',
  judge: 'Judge',
  judge_type: 'Judge Type',
  key_case: false,
  last_reviewed_on: '2015-12-21',
  linked_federal_rules: '',
  mdl: null,
  modal_ready: true,
  name: 'This is a Name',
  needs_update: null,
  no_link_found: false,
  non_subscription: false,
  notes: '',
  opinion: { url: null },
  opinion_cache: null,
  opinion_pdf_attached: false,
  parsed_attributes: null,
  parser_issue_type: null,
  parser_qc_complete: false,
  published: true,
  qc_complete: false,
  ready_for_review: false,
  rule_overturn: false,
  sign_off: null,
  style_errors: false,
  tag_list: ['Failure to Preserve', 'Failure to Produce', 'Proportionality'],
  tag_overturn: false,
  unpublished: false,
  use_eda_format: true,
};

const caseDigest = {
  created_at: '2013-05-21T16:39:52.726-06:00',
  deleted: false,
  district_rules: [],
  federal_rules: [],
  id: 16542,
  is_digested: false,
  last_published_at: '2020-05-11T22:08:32.994-06:00',
  pdf: 'reeves_v._case_w._univ.20200512-4-t7jzwa.pdf',
  published_revision,
  state_rules: [],
  regulatory_rules: [],
  updated_at: '2020-05-11T22:08:39.325-06:00',
};

const currentUser = {
  account_id: 378,
  created_at: '2021-04-28T01:39:22.011-06:00',
  customer_id: null,
  deferred: true,
  deprecated_god: null,
  disabled: false,
  email: 'email@mail.com',
  extend_trial: false,
  extend_trial_date: null,
  first_name: 'First',
  hubspot_id: null,
  id: 1,
  isAdmin: true,
  imported: false,
  last_name: 'Second',
  mixpanel_id: null,
  organization: null,
  password_set: true,
  preview_code_id: null,
  subscription_id: 1,
  trial_start: null,
  unique_session_id: 'WyUFHmaafKdbsKgdx2Do',
  updated_at: '2021-06-04T00:04:58.952-06:00',
  utm_campaign: '',
  utm_source: '',
};

const scholarText = '<div>This is a Scholar Text</div>';

const announcementText = '<p>Download the <a target="_blank" rel="nofollow" href="https://www.ediscoveryassistant.com/2020-ediscovery-case-law-year-in-review">2020 Case Law Year in Review</a></p>';

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ message: { some: () => {} } }) });

// api.case_digests.unfavorite = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ case_digests: [] }) });
// api.case_digests.favorite = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ case_digests: [] }) });
// api.favorites.get = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ favorites: [] })});
// api.case_digests.downloadPDF = jest.fn().mockResolvedValueOnce({ json: () => Promise.reject({ error: 'Error' })});
api.cite_lists.get = jest.fn().mockResolvedValue({ message: [{ id: 2313, name: 'Cite List from DB', cite_list_cases: [{ case_digest_id: 16542 }] }] });
// api.cite_lists.post = jest.fn().mockResolvedValue({ json: () => Promise.resolve({ message: { result: 'created', cite_list_name: 'Cite List', addedDigests: ['Case Digest'], conflictingResults: [], operationCiteList: [] }})});
api.cite_list_cases.post = jest.fn().mockResolvedValueOnce({
  message: {
    result: 'created', cite_list_name: 'Cite List', addedDigests: ['Case Digest'], conflictingResults: [], operationCiteList: [],
  },
});

describe('CaseDigest', () => {
  it('renders correctly with props', () => {
    render(<CaseDigest
      tags={tags}
      favorites={favorites}
      caseDigest={caseDigest}
      citeLists={[]}
      scholarText={scholarText}
      relatedCases={[]}
      additionalFilings={[]}
      currentUser={currentUser}
      isAdmin={false}
      isACEDSTrial={false}
      plan_type="trial"
      numberOfDownloads={4}
      announcementText={announcementText}
      announcementTextEnabled={false}
      announcementTextUpdatedAt={1619595240000}
    />);
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with CaseDigest', async () => {
    render(<CaseDigest
      tags={tags}
      favorites={favorites}
      caseDigest={caseDigest}
      citeLists={[]}
      scholarText={scholarText}
      relatedCases={[]}
      additionalFilings={[]}
      currentUser={currentUser}
      isAdmin={false}
      isACEDSTrial={false}
      plan_type="trial"
      numberOfDownloads={4}
      announcementText={announcementText}
      announcementTextEnabled={false}
      announcementTextUpdatedAt={1619595240000}
    />);

    userEvent.click(screen.getByText(/To Cite List/i));
    expect(screen.getByText(/Add New/i)).toBeInTheDocument();
    expect(screen.getByPlaceholderText('Search Cite Lists')).toBeInTheDocument();

    userEvent.click(screen.getByText('✕'));

    const searchField = screen.getByPlaceholderText(/Search text/i);
    userEvent.type(searchField, 'a');

    userEvent.click(document.querySelectorAll('svg')[4]);
    userEvent.click(screen.getByText('✕'));

    userEvent.click(screen.getAllByText('Judge')[0]);
  });
});
