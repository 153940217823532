import PropTypes from 'prop-types';
import React from 'react';

export default function DownloadModal({
  isDownload, plan_name, closeModal, numberOfDownloads,
}) {
  const name = plan_name === 'Trial' ? '7-day Trial' : plan_name;

  const renderModalLanguage = () => (
    <>
      <p>
        {isDownload && 'Your download is in progress. '}
        As part of your
        {' '}
        {name}
        {' '}
        of eDiscovery Assistant you receive four (4) downloads.
      </p>
      <p>
        You currently have
        {' '}
        {numberOfDownloads}
        {' '}
        of 4 remaining.
      </p>
      <p>
        To receive unlimited downloads, upgrade to a paid Individual Annual subscription for $1,895/year.
      </p>
    </>
  );

  return (
    <div className="new-modal">
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <button
                type="button"
                className="button-clear pull-right"
                onClick={closeModal}
              >
                ✕
              </button>
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            { renderModalLanguage() }
            <div className="button-row">
              <button className="modal-button">
                <a href="/account/edit?tab=billingInfo">Upgrade</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DownloadModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  numberOfDownloads: PropTypes.number.isRequired,
};
