// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="side-section"
>
  <div
    className="side-section-header"
  >
    <h4
      className="side-section-title"
    >
      Jurisdiction
      <button
        className="button-clear"
        onClick={[Function]}
        type="button"
      >
        ✕
      </button>
    </h4>
  </div>
  <div
    className="side-section-body"
  >
    <div
      className="side-section-tabs"
    >
      <button
        className="tag active"
        onClick={[Function]}
        type="button"
      >
        Federal
      </button>
      <button
        className="tag "
        onClick={[Function]}
        type="button"
      >
        State
      </button>
      <button
        className="tag "
        onClick={[Function]}
        type="button"
      >
        Administrative
      </button>
    </div>
    <div
      className="section-federal"
    >
      <div
        className="federal-row"
      >
        <div>
          <div>
            <div
              className="summary-checkbox bold"
              onClick={[Function]}
            >
              <svg
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                strokeWidth="0"
                style={
                  Object {
                    "color": undefined,
                  }
                }
                viewBox="0 0 448 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
                />
              </svg>
              Federal #1
            </div>
            <div
              className="circuit-courts"
            >
              <div
                className="tag "
                onClick={[Function]}
              >
                Federal #1
              </div>
            </div>
          </div>
          <div>
            <div
              className="summary-checkbox bold"
              onClick={[Function]}
            >
              <svg
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                strokeWidth="0"
                style={
                  Object {
                    "color": undefined,
                  }
                }
                viewBox="0 0 448 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
                />
              </svg>
              Federal #2
            </div>
            <div
              className="circuit-courts"
            >
              <div
                className="tag "
                onClick={[Function]}
              >
                Federal #2
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
