import React from 'react';
import moment from 'moment';

export default function SubscriptionCanceledModal({ subscriptionCanceledAt, closeModal }) {
  return (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            <p>
              Your subscription will be cancelled effective
              {' '}
              {moment(subscriptionCanceledAt).format('MM/DD/YYYY')}
              .
            </p>
            <div className="button-row">
              <div className="modal-button" onClick={closeModal}>
                OK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
