import React from 'react';

export default function CancelSubscriptionModal({ cancelSubscription, closeModal }) {
  return (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            <p>
              Are you sure you want to cancel your subscription?
            </p>
            <div className="button-row">
              <div className="modal-button" onClick={cancelSubscription}>
                Yes
              </div>

              <div className="modal-button modal-button-outline" onClick={closeModal}>
                No
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
