// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="new-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <h4>
            Payment Method updated
          </h4>
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Thank you for your payment.
        </p>
        <p>
          Your current subscription is Individual Plan, and auto-renews annually on one year from today.
        </p>
        <button
          className="modal-button modal-button-outline"
          onClick={[Function]}
          type="button"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
`;
