import React from 'react';
import { FiChevronDown, FiChevronUp, FiTrash } from 'react-icons/fi';
import moment from 'moment';
import Dropdown, { DropdownItem } from '../Dropdown';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';

function renderTrialSubscription(user) {
  if (user.subscription?.plan?.plan_type !== 'trial') return '-';
  if (user.subscription.status === 'cancelled') return 'Inactive';
  return `${moment(user.subscription.effective_from).format('MM/DD/YY')} - ${moment(user.subscription.effective_to).format('MM/DD/YY')}`;
}

function renderSubscription(user) {
  if (user.account.account_type === 'eda_admin') return 'Admin';
  if (!user.subscription?.plan || user.subscription.plan.plan_type === 'trial') return '-';
  if (user.subscription.status === 'cancelled') return 'Inactive';

  return (
    <>
      {user.subscription.plan.name}
      <br />
      {moment(user.subscription.effective_from).format('MM/DD/YY')}
      {' - '}
      {moment(user.subscription.effective_to).format('MM/DD/YY')}
    </>
  );
}

function renderAcademySubscription(subscription) {
  if (!subscription) return '-';

  return (
    <>
      {moment(subscription.effective_from).format('MM/DD/YY')}
      {' - '}
      {moment(subscription.effective_to).format('MM/DD/YY')}
      {
      subscription.plan.name === 'Academy Free' ? '(free)' : subscription.plan.name === 'Academy Check' ? '(check)' : ''
      }
    </>
  );
}

function renderTrialCancelled(account) {
  if (!account || !account.subscriptions) return '-';
  const trialSubscription = account.subscriptions.find((sub) => sub.plan.name === 'Trial') || null;
  const individualSubscription = account.subscriptions.find((sub) => sub.plan.name.toLowerCase().includes('individual')) || null;
  if (!trialSubscription || !individualSubscription) return '-';

  const startTrial = moment(trialSubscription.effective_from, 'YYYY-MM-DD');
  const startIndividual = moment(individualSubscription.effective_from, 'YYYY-MM-DD');
  const difference = startIndividual.diff(startTrial, 'days');

  return difference > 0 && difference < 15 ? difference : '-';
}

export default function Table({
  users, accountTypes, onDeleted, onMove, sortable, sortBy, sortDir, onSort, handleEnableUser, handleDisableUser, account = false,
}) {
  const handleDelete = (user) => {
    const res = window.confirm('Are you sure?');
    if (!res) return;
    Api.admin.users.delete(user.id).then(({ status }) => {
      if (status !== 'OK') return;

      Snackbar.show(`User “${user.first_name} ${user.last_name}” has been deleted!`);
      onDeleted();
    });
  };

  const handleResetPassword = (user) => {
    Api.admin.users.resetPassword(user.id).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show('A reset password email has been sent to user');
    });
  };

  const handleSendConfirmation = (user) => {
    if (user.subscription_id || user.academy_subscription_id) {
      Api.admin.users.sendConfirmation(user.id).then((res) => {
        if (res.status !== 'OK') return;
        Snackbar.show(`Confirmation has been sent to - ${user.first_name} ${user.last_name}`);
      });
    } else {
      Snackbar.show('Requires valid account subscription before confirmation.');
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th onClick={() => sortable && onSort('name')}>
            User Name
            { sortable && sortBy === 'name' && sortDir === 'asc' && <FiChevronUp /> }
            { sortable && sortBy === 'name' && sortDir === 'desc' && <FiChevronDown /> }
          </th>
          <th onClick={() => sortable && onSort('email')}>
            Email Address
            { sortable && sortBy === 'email' && sortDir === 'asc' && <FiChevronUp /> }
            { sortable && sortBy === 'email' && sortDir === 'desc' && <FiChevronDown /> }
          </th>
          { !account
          && (
          <>
            <th onClick={() => sortable && onSort('account_name')}>
              Firm / Organization
              { sortable && sortBy === 'account_name' && sortDir === 'asc' && <FiChevronUp /> }
              { sortable && sortBy === 'account_name' && sortDir === 'desc' && <FiChevronDown /> }
            </th>
            <th onClick={() => sortable && onSort('account_type')}>
              Account Type
              { sortable && sortBy === 'account_type' && sortDir === 'asc' && <FiChevronUp /> }
              { sortable && sortBy === 'account_type' && sortDir === 'desc' && <FiChevronDown /> }
            </th>
            <th onClick={() => sortable && onSort('trial')}>
              Trial
              { sortable && sortBy === 'trial' && sortDir === 'asc' && <FiChevronUp /> }
              { sortable && sortBy === 'trial' && sortDir === 'desc' && <FiChevronDown /> }
            </th>
          </>
          )}
          <th onClick={() => sortable && onSort('subscription')}>
            Subscription
            { sortable && sortBy === 'subscription' && sortDir === 'asc' && <FiChevronUp /> }
            { sortable && sortBy === 'subscription' && sortDir === 'desc' && <FiChevronDown /> }
          </th>
          { (account && account.account_type === 'individual') && <th> Trial upgraded </th> }
          <th onClick={() => sortable && onSort('academy_subscription')}>
            Academy Subscription
            { sortable && sortBy === 'academy_subscription' && sortDir === 'asc' && <FiChevronUp /> }
            { sortable && sortBy === 'academy_subscription' && sortDir === 'desc' && <FiChevronDown /> }
          </th>
          <th onClick={() => sortable && onSort('last_sign_in_at')}>
            Last Sign In
            { sortable && sortBy === 'last_sign_in_at' && sortDir === 'asc' && <FiChevronUp /> }
            { sortable && sortBy === 'last_sign_in_at' && sortDir === 'desc' && <FiChevronDown /> }
          </th>
          <th onClick={() => sortable && onSort('confirmed')}>
            Confirmed?
            { sortable && sortBy === 'confirmed' && sortDir === 'asc' && <FiChevronUp /> }
            { sortable && sortBy === 'confirmed' && sortDir === 'desc' && <FiChevronDown /> }
          </th>
          <th onClick={() => sortable && onSort('disabled')}>
            Disabled?
            { sortable && sortBy === 'disabled' && sortDir === 'asc' && <FiChevronUp /> }
            { sortable && sortBy === 'disabled' && sortDir === 'desc' && <FiChevronDown /> }
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        { (users || []).map((user) => (
          <tr key={user.id}>
            <td>
              <a href={`/admin/users/${user.id}/edit`}>
                {user.first_name}
                {' '}
                {user.last_name}
              </a>
            </td>
            <td>{user.email}</td>
            { !account
            && (
            <>
              <td>{user.account.name}</td>
              <td>{accountTypes.find((t) => t.value === user.account.account_type)?.label}</td>
              <td>{renderTrialSubscription(user)}</td>
            </>
            )}
            <td>{renderSubscription(user)}</td>
            { (account && account.account_type === 'individual') && <td>{renderTrialCancelled(account)}</td> }
            <td>{renderAcademySubscription(user.academy_subscription)}</td>
            <td>{user.last_sign_in_at ? moment(user.last_sign_in_at).format('MM/DD/YY') : '-'}</td>
            <td>{user['confirmed?'] ? '✓' : '✕'}</td>
            <td>{user.disabled ? '✓' : '✕'}</td>
            <td>
              <div className="buttons">
                <button type="button" className="delete-button" onClick={() => handleDelete(user)}>
                  <FiTrash />
                </button>
                <Dropdown className="dots-dropdown" buttonContent="..." align="right">
                  <DropdownItem onClick={() => { window.location.href = `/admin/users/${user.id}/edit`; }}>Edit User</DropdownItem>
                  <DropdownItem
                    onClick={() => onMove(user)}
                  >
                    Move To Account
                  </DropdownItem>
                  { user.disabled ? (
                    <DropdownItem onClick={() => handleEnableUser(user)}>Enable User</DropdownItem>
                  ) : (
                    <DropdownItem onClick={() => handleDisableUser(user)}>Disable User</DropdownItem>
                  )}
                  <div className="separator" />
                  { user['confirmed?'] ? (
                    <DropdownItem onClick={() => handleResetPassword(user)}>Reset Password</DropdownItem>
                  ) : (
                    <DropdownItem onClick={() => handleSendConfirmation(user)}>
                      Send Confirmation
                    </DropdownItem>
                  )}
                </Dropdown>
              </div>

            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
