import React from 'react';
import renderer from 'react-test-renderer';
import { fireEvent, render } from '@testing-library/react';
import $ from 'jquery';

import Header from '../../learning_center/Header';
import { currentUser } from '../__mocks__/lc-data';

global.$ = $;
global.jQuery = $;

it('renders correctly with props', () => {
  const tree = renderer.create(<Header currentUser={currentUser} isAdmin />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<Header />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('render header and click on menu items', async () => {
  const container = render(<Header currentUser={currentUser} isAdmin />);
  fireEvent.click(container.getByText('My Progress'));
  fireEvent.click(container.getByText('Bookmarks'));
});

it('render header and click on menu items without permission', async () => {
  const container = render(<Header currentUser={currentUser} />);
  fireEvent.click(container.getByText('My Progress'));
  fireEvent.click(container.getByTestId('modal'));
});
