import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import SubscriptionCanceledModal from '../../../account/account_modals/SubscriptionCanceledModal';

describe('subscription canceled Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<SubscriptionCanceledModal subscriptionCanceledAt="2019-10-03 01:24:50.388458" closeModal={() => {}} />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders modal and close click', async () => {
    const close = jest.fn(() => {});
    const container = render(
      <SubscriptionCanceledModal
        subscriptionCanceledAt="2019-10-03 01:24:50.388458"
        closeModal={close}
      />,
    );
    const closeButton = container.queryByText('OK');
    expect(container.queryByText('Your subscription will be cancelled effective 10/03/2019.')).toBeInTheDocument();
    expect(closeButton).toBeInTheDocument();
    fireEvent.click(closeButton);
    expect(close).toHaveBeenCalledTimes(1);
  });
});
