// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SignIn renders correctly with props 1`] = `
<div
  className="auth-page new-login"
>
  <form
    action="/login"
    className="login-form"
    method="post"
  >
    <img
      className="trademark-header"
      src="/logo.svg"
    />
    <div
      className="form-group"
    >
      <label
        className="control-label"
        htmlFor="email"
      >
        Email
      </label>
      <input
        className="form-control"
        id="email"
        name="user[email]"
        onChange={[Function]}
        placeholder="example@email.com"
        type="email"
        value=""
      />
    </div>
    <div
      className="form-group"
    >
      <label
        className="control-label"
        htmlFor="password"
      >
        Password
      </label>
      <div
        className="input-group"
      >
        <input
          className="form-control"
          id="password"
          name="user[password]"
          onChange={[Function]}
          type="password"
          value=""
        />
        <span
          className="input-group-addon"
          onClick={[Function]}
        >
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 576 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
            />
          </svg>
        </span>
      </div>
    </div>
    <a
      className="forgot-password-link"
      href="/password/new"
    >
      Forgot Password
    </a>
    <input
      name="authenticity_token"
      type="hidden"
    />
    <button
      className="btn btn-submit"
      type="submit"
    >
      Sign In
    </button>
    <div
      className="form-footer"
    >
      Don’t have an account yet?
       
      <a
        href="https://www.ediscoveryassistant.com/pricing-plans/"
      >
        Register
      </a>
    </div>
    <div
      className="form-footer"
    >
      <a
        href="/terms_of_use"
      >
        Terms and Conditions
      </a>
         
      <a
        href="/privacy-policy"
      >
        Privacy Policy
      </a>
    </div>
  </form>
</div>
`;
