import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import LoginModal from '../../login_modal/LoginModal';

it('renders correctly without props', () => {
  const tree = renderer.create(<LoginModal
    closeModal={() => {}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders modal and test clicks', async () => {
  const close = jest.fn(() => {});
  const container = render(<LoginModal closeModal={close} />);
  fireEvent.click(container.queryByTestId('login-modal'));
  expect(close).toHaveBeenCalledTimes(1);
});
