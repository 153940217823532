import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Popper } from '@material-ui/core';
import { FaPlus } from 'react-icons/all';
import TagOption from './TagOption';
import {
  appendOperator,
  appendValue, endsWithOperator,
  formatOperators,
  getValues,
  replaceLastValue,
} from '../../../services/booleanSearch';

const TagFilter = React.forwardRef((props, inputRef) => {
  const {
    tags, setSearchQuery, searchQuery, openModal, onSubmit, clearDashboardTags,
  } = props;

  const searchRef = useRef();

  const [activeIndex, setActiveIndex] = useState(-1);
  const [actionAnchorElList, setActionAnchorElList] = useState({});
  const [charsTyped, setCharsTyped] = useState(0);

  const values = useMemo(() => getValues(searchQuery), [searchQuery]);
  const filteredOptions = useMemo(() => tags
    .filter((option) => {
      if (values.length === 0) return false;
      if (endsWithOperator(searchQuery)) return false;
      return option.name.toLowerCase().includes(values[values.length - 1].toLowerCase());
    }), [tags, values]);

  const handleOpen = (event) => {
    setActionAnchorElList({ el: event.currentTarget });
    event.stopPropagation();
  };

  const handleOperatorClick = (tag) => {
    inputRef.current.focus();
    setSearchQuery(appendOperator(searchQuery, tag, inputRef.current.selectionStart));
  };

  const handleTagClick = (tag) => {
    inputRef.current.focus();
    setSearchQuery(appendValue(searchQuery, tag, inputRef.current.selectionStart));
  };

  const handleClose = (event) => {
    setActionAnchorElList({});
    setCharsTyped(0);
    event.stopPropagation();
  };

  const handleSelectOption = (value) => {
    setSearchQuery(replaceLastValue(searchQuery, value.name));
  };

  const optionChange = (event, option) => {
    inputRef.current.focus();
    setActiveIndex(-1);
    setCharsTyped(0);
    handleSelectOption(option);
  };

  const autocompleteOpen = !!actionAnchorElList.el && filteredOptions.length > 0 && charsTyped >= 2;

  useEffect(() => {
    const listener = (event) => {
      if (
        !searchRef.current
        || searchRef.current.contains(event.target)
        || event.target.closest('.drop-down')
      ) return;
      handleClose(event);
    };

    const handleFocusTransfer = (event) => {
      if (event.keyCode === 38) {
        event.preventDefault();
        setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0);
      } else if (event.keyCode === 13 && event.target.id === 'search-input') {
        event.preventDefault();
        document.getElementById('search-input').blur();
        if (autocompleteOpen) {
          optionChange(event, filteredOptions[Math.max(activeIndex, 0)]);
        } else if (onSubmit) onSubmit();
        document.getElementById('search-input').focus();
      } else if (event.keyCode === 9 && filteredOptions.length > 0) {
        event.preventDefault();
        setActiveIndex(0);
      } else if (event.keyCode === 40) {
        event.preventDefault();
        setActiveIndex(activeIndex + 1 < filteredOptions.length ? activeIndex + 1 : 0);
      } else if (event.keyCode === 27) {
        event.preventDefault();
        handleClose(event);
        document.getElementById('search-input').blur();
      }
    };

    document.addEventListener('keydown', handleFocusTransfer);
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('keydown', handleFocusTransfer);
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [filteredOptions, activeIndex, searchRef, autocompleteOpen]);

  const buildSearchQuery = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setCharsTyped(charsTyped + 1);
    buildSearchQuery(value);
  };

  const handleOpenModal = () => {
    inputRef.current.focus();
    openModal();
  };

  const formatOperator = (match) => `<span class="selected-value">${match}</span>`;

  return (
    <>
      <div className="eda-search-container" ref={searchRef}>
        <div className="input-container">
          <textarea
            id="search-input"
            placeholder="Start typing issues or click on + sign to select issues"
            className="search-input"
            autoComplete="off"
            value={searchQuery}
            onChange={handleSearchChange}
            onFocus={handleOpen}
            spellCheck={false}
            ref={inputRef}
          />
          <div
            className="overlay"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: formatOperators(searchQuery, formatOperator),
            }}
          />
        </div>
        { searchQuery.length > 0 && (
        <span
          className="clear-tags"
          onClick={() => {
            setSearchQuery('');
            if (window.location.href.includes('issue_dashboard')) clearDashboardTags();
          }}
        >
          ✕
        </span>
        )}
        <button type="button" className="search-tags-button" onClick={handleOpenModal} data-testid="search-tags">
          <FaPlus />
        </button>
        <Popper
          id="drop-down"
          anchorEl={actionAnchorElList.el ? actionAnchorElList.el : null}
          open={autocompleteOpen}
          keepMounted
          modifiers={{
            flip: {
              enabled: true,
            },
          }}
          placement="bottom-start"
          className="search-drop-down-container"
        >
          <div className="drop-down">
            {filteredOptions.map((option, idx) => (
              <MenuItem key={option.name} onClick={(e) => optionChange(e, option)}>
                <TagOption
                  option={option}
                  inputValue={getValues(searchQuery).pop()}
                  active={activeIndex === idx}
                />
              </MenuItem>
            ))}
          </div>
        </Popper>
      </div>
      <div className="suggestions">
        Try:&nbsp;&nbsp;
        <a href="#" className="tag" onClick={() => handleTagClick('Spoliation')}>
          Spoliation
        </a>
        <a href="#" className="tag" onClick={() => handleTagClick('Sanctions')}>
          Sanctions
        </a>
        <a href="#" className="tag" onClick={() => handleTagClick('Proportionality')}>
          Proportionality
        </a>
      </div>
      <div className="react-search-tips">
        <div className="tips-title">Boolean Search Tips:</div>
        <button
          type="button"
          className="btn btn-flat tag"
          data-place="bottom"
          onClick={() => handleOperatorClick('AND')}
        >
          AND
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-flat tag"
          data-place="bottom"
          onClick={() => handleOperatorClick('OR')}
        >
          OR
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-flat tag"
          data-place="bottom"
          onClick={() => handleOperatorClick('NOT')}
        >
          NOT
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-flat tag"
          data-place="bottom"
          onClick={() => handleOperatorClick('(')}
        >
          (
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-flat tag"
          data-place="bottom"
          onClick={() => handleOperatorClick(')')}
        >
          )
        </button>
      </div>
    </>
  );
});

TagFilter.propTypes = {
  tags: PropTypes.array.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

TagFilter.defaultProps = {
  onSubmit: null,
};

export default TagFilter;
