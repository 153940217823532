import '@testing-library/jest-dom';

import { getValues, endsWithOperator, startsWithOperator } from '../../../services/booleanSearch';

describe('boolean search tests', () => {
  it('returns valid query', () => {
    expect(getValues('text&othertext')).toStrictEqual(['text&othertext']);
  });

  it('returns true if starts with operator', () => {
    expect(startsWithOperator('AND 123')).toStrictEqual(true);
  });

  it('returns true if ends with operator', () => {
    expect(endsWithOperator('123 AND')).toStrictEqual(true);
  });
});
