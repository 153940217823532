// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly without props 1`] = `
<div
  className="login-modal new-modal"
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <div
        className="circle"
      />
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Your session is about to expire. Do you wish to continue?
        </p>
        <div
          className="button-row"
        >
          <button
            className="modal-button"
            data-testid="session-modal"
            onClick={[Function]}
          >
            <div>
              Continue
            </div>
          </button>
          <button
            className="modal-button modal-button-outline"
          >
            <a
              data-method="delete"
              href="/logout"
            >
              Logout
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
