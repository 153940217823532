// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="state-rules"
>
  <div
    className="state-rule-list"
  >
    <div
      className="title"
    >
      State
    </div>
    <div
      className="summary"
    >
      This is a description for State Rules
    </div>
    <div
      className="state-rule-list"
    >
      <div
        className="title"
      >
        Name for Category
      </div>
      <div
        className="results"
      >
        <a
          className="result"
          href="/state_rules/2"
        >
          <div
            className="top"
          >
            <div
              className="result-number"
            >
              <div
                style={
                  Object {
                    "flex": "none",
                    "maxWidth": "50%",
                  }
                }
              >
                Number for Rule #1 - 
              </div>
               
              <div
                style={
                  Object {
                    "fontWeight": 400,
                  }
                }
              >
                Title for Rule #1
              </div>
            </div>
            <svg
              className="initially-hidden"
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "color": undefined,
                }
              }
              viewBox="0 0 384 512"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
              />
            </svg>
          </div>
          <div
            className="result-tags"
          >
            <div
              className="tag btn btn-flat"
            >
              tag A
            </div>
            <div
              className="tag btn btn-flat"
            >
              tag B
            </div>
          </div>
        </a>
        <a
          className="result"
          href="/state_rules/3"
        >
          <div
            className="top"
          >
            <div
              className="result-number"
            >
              <div
                style={
                  Object {
                    "flex": "none",
                    "maxWidth": "50%",
                  }
                }
              >
                Number for Rule #2 - 
              </div>
               
              <div
                style={
                  Object {
                    "fontWeight": 400,
                  }
                }
              >
                Title for Rule #2
              </div>
            </div>
            <svg
              className="initially-hidden"
              fill="currentColor"
              height="1em"
              onClick={[Function]}
              stroke="currentColor"
              strokeWidth="0"
              style={
                Object {
                  "color": undefined,
                }
              }
              viewBox="0 0 384 512"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
              />
            </svg>
          </div>
          <div
            className="result-tags"
          >
            <div
              className="tag btn btn-flat"
            >
              tag C
            </div>
            <div
              className="tag btn btn-flat"
            >
              tag D
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
`;
