import React, { useMemo, useState } from 'react';
import Select from 'react-select';

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    marginTop: '-1rem',
    color: state.isSelected ? '#FFF' : '#000E57',
    backgroundColor: state.isSelected ? 'rgba(0, 14, 87, 0.8)' : styles.color,
    '&:hover': {
      color: '#FFF',
      backgroundColor: 'rgba(0, 14, 87, 0.6)',
    },
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? '0 0 0 0.1rem rgba(0, 14, 87, 0.6)' : 0,
    width: '20rem',
    borderColor: state.isFocused ? 'rgba(0, 14, 87, 0.6)' : '#CED4DA',
    '&:hover': {
      borderColor: state.isFocused ? 'rgba(0, 14, 87, 0.6)' : '#CED4DA',
    },
    marginBottom: '1rem',
  }),
  container: (styles) => ({
    ...styles,
    width: '20rem',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '0',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#000E57',
  }),
};

export default function DistrictRulesNavigator({
  setDistrictStateFilter, selectDistrict, districtStates, districtStateFilter, allStates,
  ruleCategories, setRuleFilter, ruleFilter, rulesCount,
}) {
  const searchParams = new URLSearchParams(window.location.search);
  const stateFilter = searchParams.get('districtState') || '';
  const districtFilter = searchParams.get('district') || '';
  const [stateSearchValue, setStateSearchValue] = useState(stateFilter);
  const [districtSearchValue, setDistrictSearchValue] = useState(districtFilter);

  const buildDistrictOptions = useMemo(() => {
    const districtState = districtStates.find((state) => state.name === districtStateFilter);
    if (districtState === undefined) return '';
    return districtState.district_courts.map((court) => ({
      value: court.name, label: court.name, categories: court.rule_categories, rules_count: court.rules_count,
    }));
  }, [districtStates, districtStateFilter]);

  return (
    <div className="state-rules-navigator">
      <div className="selector district-selector">
        <Select
          placeholder="Filter by State"
          isSearchable={false}
          value={stateSearchValue === '' ? '' : { value: stateSearchValue, label: stateSearchValue }}
          onChange={(e) => {
            setStateSearchValue(e.label);
            setDistrictSearchValue('');
            setDistrictStateFilter(e);
          }}
          options={allStates.map((state) => ({ value: state.name, label: state.name }))}
          styles={customStyles}
        />
        <Select
          placeholder="Filter by District"
          isSearchable={false}
          value={districtSearchValue === '' ? '' : { value: districtSearchValue, label: districtSearchValue }}
          onChange={(e) => {
            setDistrictSearchValue(e.label);
            selectDistrict(e);
          }}
          options={buildDistrictOptions}
          styles={customStyles}
        />
        <div className="category-list">
          <div
            className={`category${ruleFilter === 'All' ? ' selected' : ''}`}
            onClick={() => setRuleFilter('All')}
          >
            <div className="name">
              All
            </div>
            <div className="rule-count">
              {rulesCount}
            </div>
          </div>
          {ruleCategories.map((category) => (
            <div
              className={`category${category.name === ruleFilter ? ' selected' : ''}`}
              key={category.name}
              onClick={() => setRuleFilter(category.name)}
            >
              <div className="name">
                {category.name}
              </div>
              <div className="rule-count">
                {category.rules.length}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
