import React from 'react';
import '@testing-library/jest-dom';
import { render, screen, waitFor } from '@testing-library/react';

import userEvent from '@testing-library/user-event';
import SelectedCasesSheet from '../../cite_lists/SelectedCasesSheet';

const selectedCiteListCases = [1, 2, 3];
const selectedCiteList = { search_query: null };
describe('SelectedCasesSheet', () => {
  it('renders correctly with props', () => {
    render(<SelectedCasesSheet selectedCiteListCases={selectedCiteListCases} selectedCiteList={selectedCiteList} />);
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const selectAll = jest.fn(() => {});
    const downloadCases = jest.fn(() => {});
    const deleteCases = jest.fn(() => {});
    render(
      <SelectedCasesSheet
        selectedCiteListCases={selectedCiteListCases}
        selectedCiteList={selectedCiteList}
        selectAll={selectAll}
        downloadSelectedCases={downloadCases}
        deleteSelectedCases={deleteCases}
      />,
    );

    const selectAllButton = screen.getByText('Select All');
    await waitFor(() => {
      expect(selectAllButton).toBeInTheDocument();
    });

    userEvent.click(selectAllButton);
    expect(selectAll).toBeCalledTimes(1);

    const downloadButton = screen.getByTestId('download-cases');
    await waitFor(() => {
      expect(downloadButton).toBeInTheDocument();
    });

    userEvent.click(downloadButton);
    expect(downloadCases).toBeCalledTimes(1);

    const deleteButton = screen.getByText('Delete Selected');
    await waitFor(() => {
      expect(deleteButton).toBeInTheDocument();
    });

    userEvent.click(deleteButton);
    expect(deleteCases).toBeCalledTimes(1);
  });
});
