import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import ReactPlayer from 'react-player';
import api from '../../../services/api';

export default function Player({ mediaItem, progress = 0 }) {
  const ref = useRef();
  const [durationAvailable, setDurationAvailable] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  const updateProgress = (value) => {
    if (!isReady || !isStarted || !durationAvailable) return;
    api.media_items.progress(mediaItem.id, value);
  };

  useEffect(() => {
    if (!durationAvailable || !isReady) return;
    ref.current?.seekTo(progress, 'fraction');
  }, [ref, durationAvailable, isReady, progress]);

  const url = useMemo(() => {
    if (mediaItem.domain !== 'soundcloud') return mediaItem.url;
    const parsed = new URL(mediaItem.url);
    return parsed.searchParams.get('url') || mediaItem.url;
  }, [mediaItem]);

  return (
    <ReactPlayer
      ref={ref}
      url={url}
      controls
      onStart={() => setIsStarted(true)}
      onReady={() => setIsReady(true)}
      onDuration={() => setDurationAvailable(true)}
      width="100%"
      height="100%"
      progressInterval={5000}
      onProgress={(e) => updateProgress(e.played)}
      onEnded={() => updateProgress(1)}
    />
  );
}
