import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import BillingHistory from '../../../account/screens/BillingHistory';
import {
  plans, account, user, payments, paymentMethod, visa,
} from '../../__mocks__/account-data';

describe('account updated Modal', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(
      <BillingHistory
        plans={plans}
        subscription={{}}
        account={account}
        payments={payments}
        user={user}
        paymentMethod={paymentMethod}
        visa={visa}
        setPaymentMethodModalVisible={() => {}}
        setSubscriptionModalVisible={() => {}}
        setSubscriptionAction={() => {}}
        setIsSubscription={() => {}}
      />,
    ).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('renders billing history for enterprise account', async () => {
    render(
      <BillingHistory
        plans={plans}
        subscription={false}
        account={{ ...account, account_type: 'enterprise' }}
        payments={payments}
        user={user}
        paymentMethod={paymentMethod}
        visa={visa}
        setPaymentMethodModalVisible={() => {}}
        setSubscriptionModalVisible={() => {}}
        setSubscriptionAction={() => {}}
        setIsSubscription={() => {}}
      />,
    );
  });

  it('renders billing history for professional account', async () => {
    render(
      <BillingHistory
        plans={plans}
        subscription={false}
        account={{ ...account, account_type: 'professional' }}
        payments={payments}
        user={user}
        paymentMethod={paymentMethod}
        visa={visa}
        setPaymentMethodModalVisible={() => {}}
        setSubscriptionModalVisible={() => {}}
        setSubscriptionAction={() => {}}
        setIsSubscription={() => {}}
      />,
    );
  });

  it('renders billing history without subsscription', async () => {
    const toggle = jest.fn(() => {});
    const container = render(
      <BillingHistory
        plans={plans}
        subscription={false}
        account={account}
        payments={payments}
        user={user}
        paymentMethod={paymentMethod}
        visa={visa}
        setPaymentMethodModalVisible={toggle}
        setSubscriptionModalVisible={toggle}
        setSubscriptionAction={toggle}
        setIsSubscription={toggle}
      />,
    );
    const toggleEdaButton = container.queryByTestId('eda-toggle');
    const toggleAcademyButton = container.queryByTestId('academy-toggle');

    fireEvent.click(toggleEdaButton);
    fireEvent.click(toggleAcademyButton);
    // 2 calls for eda, 2 for academy (with payment method id)
    expect(toggle).toHaveBeenCalledTimes(4);
  });
});
