import React from 'react';
import '@testing-library/jest-dom';
import {
  act, render, screen, waitFor,
} from '@testing-library/react';

import userEvent from '@testing-library/user-event';
import ShareSection from '../../cite_lists/ShareSection';
import { currentUser } from '../__mocks__/lc-data';

const teamMembers = [
  {
    id: 1122,
    email: 'somemail1@gmail.com',
    first_name: 'Name1',
    last_name: 'Surename1',
    account_id: 628,
  },
  {
    id: 1123,
    email: 'somemail2@gmail.com',
    first_name: 'Name1',
    last_name: 'Surename2',
    account_id: 627,
  },
];

const selectedCiteList = {
  cite_list_shares: teamMembers,
  user: currentUser,
};

const getSharedWithList = () => teamMembers.map((u) => ({ user: { id: u.id, name: u.first_name } }));

describe('ShareSection', () => {
  it('renders correctly with props', () => {
    render(
      <ShareSection
        currentUser={currentUser}
        teamMembers={teamMembers}
        getSharedWithList={getSharedWithList}
        fetchCiteLists={() => {}}
        selectedCiteList={selectedCiteList}
        closeSection={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const fetchLists = jest.fn(() => {});
    const close = jest.fn(() => {});

    render(
      <ShareSection
        currentUser={currentUser}
        teamMembers={teamMembers}
        getSharedWithList={getSharedWithList}
        fetchCiteLists={fetchLists}
        selectedCiteList={selectedCiteList}
        closeSection={close}
      />,
    );

    const closeButton = screen.getByTestId('close-share');
    await waitFor(() => {
      expect(closeButton).toBeInTheDocument();
    });

    await act(async () => {
      userEvent.click(closeButton);
    });

    expect(close).toBeCalledTimes(1);
  });
});
