import React, { useEffect, useState } from 'react';
import { GoSettings, GoPlus } from 'react-icons/go';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';
import MoveToAccountSidebar from './MoveToAccountSidebar';
import Table from './Table';

export default function Index({ accountTypes }) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [users, setUsers] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [subscribedCount, setSubscribedCount] = useState(0);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');

  const [moveUserSidebarVisible, setMoveUserSidebarVisible] = useState(false);
  const [moveUser, setMoveUser] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [sortDir, setSortDir] = useState('asc');

  const fetchData = (opts) => {
    Api.admin.users.get(opts).then(({ status, data }) => {
      if (status !== 'OK') return;

      setUsers(data.users);
      setTotalCount(data.totalCount);
      setActiveCount(data.activeCount);
      setSubscribedCount(data.subscribedCount);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    });
  };

  const handlePage = (page) => {
    fetchData({
      page, name, email, accountName, accountType, sortBy, sortDir,
    });
  };

  const handleFilter = () => {
    fetchData({
      page: 1, name, email, accountName, accountType, sortBy, sortDir,
    });
  };

  const handleDeleted = () => {
    fetchData({
      page: currentPage, name, email, accountName, accountType, sortBy, sortDir,
    });
  };

  const handleUserMove = () => {
    fetchData({
      page: currentPage, name, email, accountName, accountType, sortBy, sortDir,
    });
  };

  const handleSort = (field) => {
    let dir = 'asc';
    if (sortBy === field) {
      if (sortDir === 'asc') dir = 'desc';
      if (sortDir === 'desc') dir = 'asc';
    }
    setSortBy(field);
    setSortDir(dir);
    fetchData({
      page: currentPage, name, email, accountName, accountType, sortBy: field, sortDir: dir,
    });
  };

  const handleEnableUser = (user) => {
    Api.admin.users.enable(user.id).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show(`User ${user.first_name} ${user.last_name} was enabled`);
      fetchData({
        page: currentPage, name, email, accountName, accountType, sortBy, sortDir,
      });
    });
  };

  const handleDisableUser = (user) => {
    Api.admin.users.disable(user.id).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show(`User ${user.first_name} ${user.last_name} was disabled`);
      fetchData({
        page: currentPage, name, email, accountName, accountType, sortBy, sortDir,
      });
    });
  };

  useEffect(() => {
    fetchData({ page: 1, sortBy, sortDir });
  }, []);

  return (
    <div className={`admin-page react-admin-users ${filtersOpen ? 'filters-open' : ''}`}>
      <div className="header">
        <h1>Users</h1>
        <button type="button" className={`filters-button ${filtersOpen ? 'active' : ''}`} onClick={() => setFiltersOpen(!filtersOpen)}>
          <GoSettings />
        </button>
      </div>
      <div className="table">
        <div className="table-header">
          <div className="stats">
            <div className="stat">
              Total Users
              {' '}
              <span>{totalCount}</span>
            </div>
            <div className="stat">
              Active
              {' '}
              <span>{activeCount}</span>
            </div>
            <div className="stat">
              Subscribed
              {' '}
              <span>{subscribedCount}</span>
            </div>
          </div>
          <a className="button" href="/admin/users/new">
            Add New
            <GoPlus />
          </a>
        </div>

        <Table
          users={users}
          accountTypes={accountTypes}
          onDeleted={handleDeleted}
          sortable
          sortBy={sortBy}
          sortDir={sortDir}
          onSort={handleSort}
          onMove={(user) => {
            setMoveUser(user);
            setMoveUserSidebarVisible(true);
          }}
          handleEnableUser={handleEnableUser}
          handleDisableUser={handleDisableUser}
        />

        <div className="pagination">
          <button
            disabled={currentPage === 1}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage - 1)}
          >
            &lt;
          </button>
          <span>
            Page
            {' '}
            {currentPage}
            /
            {totalPages}
          </span>
          <button
            disabled={currentPage >= totalPages}
            type="button"
            className="button"
            onClick={() => handlePage(currentPage + 1)}
          >
            &gt;
          </button>
        </div>
      </div>

      { moveUserSidebarVisible && (
        <MoveToAccountSidebar
          onClose={() => { setMoveUserSidebarVisible(false); setMoveUser(null); }}
          user={moveUser}
          onUpdated={handleUserMove}
        />
      )}

      { filtersOpen && (
        <div className="sidebar">
          <div className="sidebar-header">
            <h2>Filter By</h2>
          </div>
          <div className="sidebar-body" onKeyDown={(e) => e.key === 'Enter' && handleFilter()}>
            <div className="form-group">
              <input
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

            <div className="form-group">
              <input
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>

            <div className="form-group">
              <input
                className="form-control"
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                placeholder="Account Name"
              />
            </div>

            <div className="form-group">
              <select className="form-control" value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                <option value="">Account Type</option>
                {accountTypes.map((type) => (
                  <option value={type.value}>{type.label}</option>
                ))}
              </select>
            </div>

            <button type="button" className="button" onClick={handleFilter}>Filter</button>
          </div>
        </div>
      )}
    </div>
  );
}
