// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="admin-page react-admin-users "
  style={
    Object {
      "marginLeft": "0px",
    }
  }
>
  <div
    className="header"
  >
    <h1>
      User Licenses
    </h1>
    <button
      className="filters-button "
      onClick={[Function]}
      type="button"
    >
      <svg
        fill="currentColor"
        height="1em"
        stroke="currentColor"
        strokeWidth="0"
        style={
          Object {
            "color": undefined,
          }
        }
        viewBox="0 0 16 16"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 7H3V2h1v5zm-1 7h1v-3H3v3zm5 0h1V8H8v6zm5 0h1v-2h-1v2zm1-12h-1v6h1V2zM9 2H8v2h1V2zM5 8H2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1zm5-3H7c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1zm5 4h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1z"
          fillRule="evenodd"
        />
      </svg>
    </button>
  </div>
  <div
    className="tabs"
  >
    <div
      className="tab active"
      onClick={[Function]}
    >
      All
    </div>
    <div
      className="tab"
      onClick={[Function]}
    >
      Active Free Trial
    </div>
    <div
      className="tab"
      onClick={[Function]}
    >
      Active ACEDS Trial
    </div>
    <div
      className="tab"
      onClick={[Function]}
    >
      Total Active
    </div>
    <div
      className="tab"
      onClick={[Function]}
    >
      Inactive
    </div>
  </div>
  <div
    className="table"
  >
    <div
      className="table-header"
    >
      <div
        className="stats"
      >
        <div
          className="stat"
        >
          Total Licenses
           
          <span>
            0
          </span>
        </div>
        <div
          className="stat"
        >
          Active
           
          <span>
            0
          </span>
        </div>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th
            onClick={[Function]}
          >
            User Name
            <svg
              fill="none"
              height="1em"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              style={
                Object {
                  "color": undefined,
                }
              }
              viewBox="0 0 24 24"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline
                points="18 15 12 9 6 15"
              />
            </svg>
          </th>
          <th
            onClick={[Function]}
          >
            Firm / Organization
          </th>
          <th
            onClick={[Function]}
          >
            Account Type
          </th>
          <th
            onClick={[Function]}
          >
            Subscription
          </th>
          <th>
            Active?
          </th>
          <th />
        </tr>
      </thead>
      <tbody />
    </table>
    <div
      className="pagination"
    >
      <button
        className="button"
        disabled={true}
        onClick={[Function]}
        type="button"
      >
        &lt;
      </button>
      <span>
        Page
         
        1
        /
        1
      </span>
      <button
        className="button"
        disabled={true}
        onClick={[Function]}
        type="button"
      >
        &gt;
      </button>
    </div>
  </div>
</div>
`;

exports[`renders correctly without props 1`] = `
<div
  className="admin-page react-admin-users "
  style={
    Object {
      "marginLeft": "0px",
    }
  }
>
  <div
    className="header"
  >
    <h1>
      User Licenses
    </h1>
    <button
      className="filters-button "
      onClick={[Function]}
      type="button"
    >
      <svg
        fill="currentColor"
        height="1em"
        stroke="currentColor"
        strokeWidth="0"
        style={
          Object {
            "color": undefined,
          }
        }
        viewBox="0 0 16 16"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 7H3V2h1v5zm-1 7h1v-3H3v3zm5 0h1V8H8v6zm5 0h1v-2h-1v2zm1-12h-1v6h1V2zM9 2H8v2h1V2zM5 8H2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1zm5-3H7c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1zm5 4h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1z"
          fillRule="evenodd"
        />
      </svg>
    </button>
  </div>
  <div
    className="tabs"
  >
    <div
      className="tab active"
      onClick={[Function]}
    >
      All
    </div>
    <div
      className="tab"
      onClick={[Function]}
    >
      Active Free Trial
    </div>
    <div
      className="tab"
      onClick={[Function]}
    >
      Active ACEDS Trial
    </div>
    <div
      className="tab"
      onClick={[Function]}
    >
      Total Active
    </div>
    <div
      className="tab"
      onClick={[Function]}
    >
      Inactive
    </div>
  </div>
  <div
    className="table"
  >
    <div
      className="table-header"
    >
      <div
        className="stats"
      >
        <div
          className="stat"
        >
          Total Licenses
           
          <span>
            0
          </span>
        </div>
        <div
          className="stat"
        >
          Active
           
          <span>
            0
          </span>
        </div>
      </div>
    </div>
    <div
      className="react-loader"
    >
      <div
        className="css-1ujetdd"
      >
        <div
          className="css-19tdm5o"
        />
        <div
          className="css-qjf637"
        />
        <div
          className="css-1qc2h51"
        />
        <div
          className="css-18lfneh"
        />
        <div
          className="css-1l2nv8r"
        />
        <div
          className="css-d6tiez"
        />
        <div
          className="css-11xa5jo"
        />
        <div
          className="css-13y1ph2"
        />
      </div>
    </div>
    <div
      className="pagination"
    >
      <button
        className="button"
        disabled={true}
        onClick={[Function]}
        type="button"
      >
        &lt;
      </button>
      <span>
        Page
         
        1
        /
        1
      </span>
      <button
        className="button"
        disabled={true}
        onClick={[Function]}
        type="button"
      >
        &gt;
      </button>
    </div>
  </div>
</div>
`;
