import React from 'react';

export const renderTotalPages = (totalPages, currentPage, activeTab) => {
  if (!totalPages || !currentPage) return null;
  const pages = [];
  const end = Math.min(currentPage + 3, totalPages);
  const start = 1;
  let i = 0; // unique key for pages

  for (i = start; i <= end; i += 1) {
    pages.push(
      <a
        className={`page ${i === currentPage && 'active'}`}
        href={`${window.location.pathname}?page=${i}${activeTab ? `&tab=${activeTab}` : ''}`}
        key={i}
      >
        {i}
      </a>,
    );
  }

  if (end < totalPages - 1) {
    pages.push(<div key={i + 1}>&nbsp;...&nbsp;</div>);
  }

  if (end < totalPages) {
    pages.push(
      <a
        className="page"
        href={`${window.location.pathname}?page=${totalPages}${activeTab ? `&tab=${activeTab}` : ''}`}
        key={i + 2}
      >
        {totalPages}
      </a>,
    );
  }

  return pages;
};
