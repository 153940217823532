import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Checkout } from './StripeCheckoutForm';

class StripeContext extends Component {
  render() {
    return (
      <StripeProvider apiKey={this.props.stripeKey}>
        <div className="card-wrapper">
          <Elements>
            <Checkout handleStripeSubmit={this.props.handleStripeSubmit} loading={this.props.loading} loader={this.props.loader} />
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}

export default StripeContext;
