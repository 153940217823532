import React from 'react';

export default function UnshareNotificationModal({ membersToUnshare, submitUnshare, cancelUnshare }) {
  return (
    <div className="new-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md delete-modal">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            <p className="delete-text">
              Do you want to notify
              {' '}
              {membersToUnshare?.name || ' all members '}
              {' '}
              that you have removed sharing?
            </p>
            <div className="button-row">
              <div
                className="modal-button"
                onClick={submitUnshare}
              >
                Yes
              </div>
              <div
                className="modal-button modal-button-outline"
                onClick={cancelUnshare}
              >
                No
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
