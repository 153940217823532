import React, { useEffect, useState } from 'react';
import {
  CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe,
} from 'react-stripe-elements';
import { FaTag } from 'react-icons/fa';
import api from '../../services/api';

const createOptions = () => ({
  classes: { base: 'form-control' },
  style: {
    base: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: `${parseFloat(getComputedStyle(document.documentElement).fontSize) * 1.4}px`,
      color: '#1F1F24',
      '::placeholder': {
        color: '#999999',
      },
    },
  },
});

function Billing({
  status, data, onChange, onCancel, onSubmit, stripe,
}) {
  const [formErrors, setFormErrors] = useState({});
  const [percentOff, setPercentOff] = useState('');
  const [discountVisible, setDiscountVisible] = useState(false);
  const [amountOff, setAmountOff] = useState(null);
  const percent_off = data.percent_off ? 1 - (Number(percentOff) / 100) : 1;

  useEffect(() => { if (data.stripe_token) onSubmit(); }, [data.stripe_token]);

  const priceCalculate = (default_amount) => {
    let price = ((default_amount / 100) * percent_off).toFixed(2);
    if (amountOff) price -= amountOff;
    return price;
  };

  const handleChange = (e) => {
    const field = e.target.id;
    onChange({ ...data, [field]: e.target.value });
  };

  const handlePromoCode = () => {
    if (data.promo_code) {
      api.promo_code(encodeURIComponent(data.promo_code.trim()))
        .then((response) => {
          if (response.success) {
            setFormErrors({ ...formErrors, promo_code: '' });
            setPercentOff(response.percent_off);
            setAmountOff(response.amount_off ? (response.amount_off / 100) : null);
            setDiscountVisible(true);
            onChange({ ...data, percent_off: response.percent_off });
          } else {
            setFormErrors({ ...formErrors, promo_code: 'Promo Code is not valid.' });
            setPercentOff('');
            setDiscountVisible(false);
            onChange({ ...data, percent_off: '' });
          }
        });
    }
  };

  const handleStripeChange = () => {
    const errors = {};
    Object.keys(formErrors).forEach((field) => {
      if (!field.match(/^credit_card/)) return;
      errors[field] = '';
    });

    setFormErrors({ ...formErrors, ...errors });
  };

  const handleStripeErrors = ({ code, message }) => {
    let field = '';
    switch (code) {
      case 'expired_card':
      case 'invalid_expiry_month':
      case 'invalid_expiry_year':
      case 'invalid_expiry_year_past':
      case 'incomplete_expiry':
        field = 'credit_card_exp';
        break;
      case 'invalid_cvc':
      case 'incomplete_cvc':
      case 'incorrect_cvc':
        field = 'credit_card_cvc';
        break;
      default:
        field = 'credit_card_num';
        break;
    }
    setFormErrors({ ...formErrors, [field]: message });
  };

  const handleSubmit = () => {
    const hasErrors = Object.values(formErrors).filter((e) => e).length;
    if (hasErrors) return;

    if (data.plan.plan_type === 'trial') {
      onSubmit();
      return;
    }

    stripe.createToken()
      .then(({ error, token }) => {
        if (error) throw error;
        onChange({ ...data, stripe_token: token.id });
      })
      .catch((error) => {
        handleStripeErrors(error);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="register-form-title">Billing Method</div>
          <div className={`form-group ${formErrors.credit_card_num ? 'has-error' : ''}`}>
            <CardNumberElement placeholder="Card / Account Number" {...createOptions()} onChange={handleStripeChange} />
            { formErrors.credit_card_num && <p className="help-block">{formErrors.credit_card_num}</p> }
          </div>

          <div className={`form-group ${formErrors.credit_card_holder ? 'has-error' : ''}`}>
            <input
              className="form-control"
              type="text"
              name="credit_card_holder"
              id="credit_card_holder"
              value={data.credit_card_holder}
              onChange={handleChange}
              placeholder="Card Holder Name"
            />
            { formErrors.credit_card_holder && <p className="help-block">{formErrors.credit_card_holder}</p> }
          </div>

          <div className="row">
            <div className={`form-group col-md-6 ${formErrors.credit_card_exp ? 'has-error' : ''}`}>
              <CardExpiryElement placeholder="Expiry Date" {...createOptions()} onChange={handleStripeChange} />
              { formErrors.credit_card_exp && <p className="help-block">{formErrors.credit_card_exp}</p> }
            </div>
            <div className={`form-group col-md-6 ${formErrors.credit_card_cvc ? 'has-error' : ''}`} style={{ margin: 0 }}>
              <CardCVCElement placeholder="CVV" {...createOptions()} onChange={handleStripeChange} />
              { formErrors.credit_card_cvc && <p className="help-block">{formErrors.credit_card_cvc}</p> }
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="register-form-title">Summary</div>
          <div className="pricing" style={{ marginTop: '1.1rem' }}>
            <div className="pricing-row plan">
              <div>
                Subscription plan
              </div>
              <div>
                { `${data.plan.name} Plan` }
              </div>
            </div>
            { data.plan.plan_type === 'credit_card' && (
              <div className="row">
                <div className={`form-group col-md-8 ${formErrors.promo_code ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    type="text"
                    name="promo_code"
                    id="promo_code"
                    value={data.promo_code}
                    onChange={(e) => {
                      setDiscountVisible(false);
                      if (e.target.value === '') {
                        setFormErrors({ ...formErrors, promo_code: '' });
                        setPercentOff('');
                      }
                      handleChange(e);
                    }}
                    placeholder="Enter Promo Code..."
                  />
                  { formErrors.promo_code && <p className="help-block">{formErrors.promo_code}</p> }
                </div>
                <div className="form-group col-md-4" style={{ marginTop: '0' }}>
                  <button type="button" className="btn btn-promocode" onClick={handlePromoCode}>Apply</button>
                </div>
              </div>
            )}
            {discountVisible && (
              <div className="pricing-row total">
                <div>
                  Discount
                  <span className="discount">
                    <FaTag size={12} />
                    {' '}
                    {data.promo_code}
                  </span>
                </div>
                { data.promo_code.trim().toLowerCase() === 'happyholidays' ? (
                  <div>
                    1 month free
                  </div>
                ) : (
                  <div>
                    -$
                    { ((data.plan.default_amount / 100) * (1 - percent_off) + amountOff).toFixed(2)}
                  </div>
                )}
              </div>
            )}
            <div className="pricing-row total">
              <div>
                Sub total
              </div>
              <div>
                $
                {priceCalculate(data.plan.default_amount)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="register-form-actions" style={{ marginTop: '2.4rem' }}>
        <button type="button" className="btn btn-cancel" onClick={onCancel}>Back</button>
        <button
          type="button"
          className="btn btn-submit"
          disabled={status === 'creating' || status === 'success'}
          onClick={handleSubmit}
        >
          { status === 'creating' ? 'Creating' : status === 'success' ? 'Logging In...' : 'Create Account' }
        </button>
      </div>
    </>
  );
}

export default injectStripe(Billing);
