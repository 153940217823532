import React from 'react';
import renderer from 'react-test-renderer';
import { render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import api from '../../../services/api';

import Checklist from '../../checklists/Checklist';

const checklist = {
  deleted: false,
  id: 12,
  case_digests: [],
  published_revision: {
    name: 'Meet and Confer/Rule 26 Preparation Checklist',
    description: 'Issues to consider and have mapped out in preparation for your initial meet and confer with opposing counsel.',
    checklists: [],
    templates: [],
  },
};

const checklistItems = [
  {
    autolinked_text: 'Have a strategy in place for dealing with eDiscovery and be prepared to discuss the appropriate amount of time needed and why',
    checklist_revision_id: 250,
    children: [],
    created_at: '2017-03-23T18:52:42.748-06:00',
    id: 1890,
    lft: 3,
    parent_id: null,
    position: 2,
    rgt: 4,
    text: 'Have a strategy in place for dealing with eDiscovery and be prepared to discuss the appropriate amount of time needed and why',
    tips: [
      {
        description: 'When collecting social media, be sure to give yourself enough lead time to get the info from the third party company and review it before producing it.  Consider agreeing to a longer response time up front when requests are received, or even better, agree at the initial conference what to produce and get it early.',
        id: 4,
        name: 'Collecting Social Media',
      },
      {
        description: 'Data stored in the cloud often requires going through a third party to collect the data and can require longer than you have to meet a 30 day deadline. Find out if the client has a process or connection for getting data.  ',
        id: 21,
        name: 'Collecting Cloud-Based Data ',
      },
    ],
    updated_at: '2018-01-11T08:09:30.142-07:00',
  },
];

api.favorites.get = jest.fn().mockResolvedValue({
  favorites: {
    checklists_and_forms: [],
    case_digests: [],
  },
});
api.checklists.unfavorite = jest.fn().mockResolvedValue({ json: () => Promise.resolve({ }) });
api.checklists.favorite = jest.fn().mockResolvedValue({ json: () => Promise.resolve({ }) });
api.checklists.pdf = jest.fn().mockResolvedValue({ json: () => Promise.resolve({ }) });

global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });

describe('Checklist', () => {
  it('renders correctly with props', () => {
    const tree = renderer.create(<Checklist
      checklist={checklist}
      favorites={{}}
      checklistItems={checklistItems}
      plan_type="trial"
    />).toJSON();
    expect(tree).toMatchSnapshot();
  });

  it('correctly interacts with buttons', () => {
    const { queryByText } = render(<Checklist
      checklist={checklist}
      favorites={{}}
      checklistItems={checklistItems}
      plan_type="trial"
    />);

    userEvent.click(document.querySelectorAll('svg')[2]);
    userEvent.click(queryByText(/Add To Bookmarks/i));
  });
});
