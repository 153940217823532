// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="new-modal expired-subscription-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <button
        className="button-clear pull-right"
        onClick={[Function]}
        type="button"
      >
        ✕
      </button>
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
          <h4>
            Your
             
            Individual
             
            Plan has expired. To continue using eDiscovery Assistant, contact your Billing Contact for the account, or reach out to us at
             
            <a
              href="mailto:support@ediscoveryassistant.com"
            >
              support@ediscoveryassistant.com
            </a>
            .
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
`;
