import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

class CheckoutForm extends Component {
  state = {
    errorMessage: '',
    loading: false,
  };

  createOptions = () => ({
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: 'Helvetica Neue',
        fontSize: '15px',

        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  });

  handleChange = (evt) => {
    const {
      error,
      value: { postalCode },
    } = evt;

    if (postalCode) this.setState({ zip: postalCode });
    if (error) this.setState({ errorMessage: error.message });
  };

  handleSubmit = (evt) => {
    const { stripe } = this.props;
    evt.preventDefault();
    if (stripe) {
      stripe
        .createToken({ address_zip: this.state.zip })
        .then((stuff) => {
          this.props.handleStripeSubmit(stuff);
        })
        .catch((e) => this.setState({ errorMessage: e }));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    const { errorMessage, loading } = this.state;
    const { loader } = this.props;
    const parentLoading = this.props.loading;
    return (
      <div className="checkout">
        <h3 className="header-ish">Card details</h3>
        <CardElement {...this.createOptions()} onChange={this.handleChange} />
        <div className="error" role="alert">
          {errorMessage}
        </div>
        { loading || parentLoading ? <div className="image-loader"><img src={loader} /></div> : <button onClick={this.handleSubmit} disabled={loading}>Create Subscription</button> }
      </div>
    );
  }
}

export const Checkout = injectStripe(CheckoutForm);
