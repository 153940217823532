// import React from 'react';
// import renderer from 'react-test-renderer';
// import '@testing-library/jest-dom';
// import { render } from '@testing-library/react';
// import userEvent from '@testing-library/user-event';

// import Dashboard from '../../dashboard/Dashboard';
// import api from '../../../services/api';

// const published_revision = {
//   additional_dates: [],
//   additional_decisions: false,
//   appellate_case: false,
//   body_text: 'This is a body text for Case Digest',
//   case_digest_id: 1,
//   case_title: 'This is a Case Title',
//   cite: 'This is a Cite',
//   counsel: 'This is a counsel',
//   court_display_name: 'This is a court display name',
//   court_id: 1,
//   de_further_review: false,
//   de_further_review_notes: '',
//   decision_title: 'Decision Title',
//   display_opinion_file: false,
//   disposition: '',
//   docket_number: 'Docket Number',
//   formatting_errors: false,
//   full_case_visibility: true,
//   full_name: null,
//   full_text: 'The editors have not yet created a digest for this case.',
//   full_text_verified: true,
//   google_scholar_link: 'https://scholar.google.com/scholar_case?case=4233182295233215789&q=Carrie+Reeves&hl=en&as_sdt=6,31&as_ylo=2009&as_yhi=2009',
//   google_scholar_text: '<div>This is a Google Scholar Text</div>',
//   google_scholar_text_type: 'westlaw',
//   has_table_of_contents: false,
//   has_tables: false,
//   id: 1,
//   image_display_errors: false,
//   in_dropbox: false,
//   internal_notes: '',
//   judge: 'Judge',
//   judge_type: 'Judge Type',
//   key_case: false,
//   last_reviewed_on: '2015-12-21',
//   linked_federal_rules: '',
//   mdl: null,
//   modal_ready: true,
//   name: 'This is a Name',
//   needs_update: null,
//   no_link_found: false,
//   non_subscription: false,
//   notes: '',
//   opinion: { url: null },
//   opinion_cache: null,
//   opinion_pdf_attached: false,
//   parsed_attributes: null,
//   parser_issue_type: null,
//   parser_qc_complete: false,
//   published: true,
//   qc_complete: false,
//   ready_for_review: false,
//   rule_overturn: false,
//   sign_off: null,
//   style_errors: false,
//   tags: [],
//   tag_overturn: false,
//   unpublished: false,
//   use_eda_format: true,
// };

// const recentCases = [{
//   created_at: '2013-05-21T16:39:52.726-06:00',
//   deleted: false,
//   district_rules: [],
//   federal_rules: [],
//   id: 16542,
//   is_digested: false,
//   last_published_at: '2020-05-11T22:08:32.994-06:00',
//   pdf: 'reeves_v._case_w._univ.20200512-4-t7jzwa.pdf',
//   published_revision,
//   state_rules: [],
//   updated_at: '2020-05-11T22:08:39.325-06:00',
//   tag_list: [],
// }];

// const courtCounts = {
//   circuit_courts: {
//     0: 56,
//     1: 270,
//     2: 1299,
//     3: 707,
//     4: 669,
//     5: 905,
//     6: 943,
//     7: 887,
//     8: 713,
//     9: 2534,
//     10: 914,
//     11: 1067,
//   },
// };

// const checklists = [
//   {
//     checklist_id: 23,
//     created_at: '2013-07-03T15:05:51.679-06:00',
//     description: 'A list of hardware that should be used to ask custodians where data might be stored for collection.  ',
//     id: 141,
//     name: 'Common Hardware Types for Collection (Corporate)',
//     non_subscription: true,
//     published: true,
//     tag_list: [],
//     updated_at: '2013-07-22T10:49:05.312-06:00',
//   },
// ];

// const forms = [
//   {
//     autolinked_text: null,
//     created_at: '2015-04-06T14:57:16.479-06:00',
//     district_rule_id: null,
//     document: { url: 'http://localhost:3000/uploads/template_revision/document/1190/chain_of_custody.pdf' },
//     document_cache: null,
//     id: 1190,
//     last_reviewed_on: null,
//     name: 'Chain of Custody Form ',
//     non_subscription: false,
//     pilot_project_rule_id: null,
//     published: true,
//     regulatory_rule_id: null,
//     resource_id: null,
//     summary: 'Use this form to capture all of the pertinent information about how data is collected. Chain of custody is crucial to protecting the integrity of the data and authenticating it at trial. Any claim that data has been tampered with will implicate the chain of custody.  ',
//     tag_list: [],
//     template_id: 45,
//     text: '',
//     updated_at: '2016-11-28T13:45:33.775-07:00',
//     word_document: { url: null },
//     word_document_cache: null,
//   },
// ];

// const rules = [
//   {
//     id: 432,
//     rule_id: 6546,
//     number: 'FederalRule #1',
//   },
//   {
//     id: 3123,
//     rule_id: 35435,
//     number: 'State Rule #2',
//   },
//   {
//     id: 654,
//     rule_id: 6054,
//     number: 'DistrictRule #3',
//   },
//   {
//     id: 6450,
//     rule_id: 65054,
//     number: 'AbaModelRule #4',
//   },
//   {
//     id: 6454,
//     rule_id: 65054,
//     number: 'RegulatoryRule #5',
//   },
// ];

// const glossary_terms = [
//   {
//     id: 1231,
//     glossary_term_id: 32131,
//     name: 'Glossary Term Name',
//   },
// ];

// const favorites = [
//   checklists,
//   forms,
//   rules,
//   glossary_terms,
// ];

// global.fetch = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });

// global.d3 = { json: jest.fn() };
// window.d3.json = jest.fn().mockResolvedValueOnce({ json: () => Promise.resolve({ }) });
// jest.spyOn(api.favorites, 'get').mockResolvedValueOnce({ favorites });
// jest.spyOn(api.cite_lists, 'get').mockResolvedValue({ message: [] });

// Date.now = jest.fn(() => 1623272400000);

// it('renders correctly with props', () => {
//   const tree = renderer.create(<Dashboard
//     recentCases={[]}
//     courtCounts={courtCounts}
//     totalCases={1000}
//     newlyAddedCasesCount={10}
//     topTags={[]}
//     announcementTextEnabled={false}
//     announcementText=""
//     federalCourts={[]}
//     stateCourts={[]}
//     caseOfTheWeek={{
//       published_revision: {
//         name: '',
//         tag_list: [],
//       },
//     }}
//   />).toJSON();
//   expect(tree).toMatchSnapshot();
// });

// it('correctly interacts with Dashboard', async () => {
//   const { queryByText, getByRole, getByText } = render(<Dashboard
//     courtCounts={courtCounts}
//     newlyAddedCasesCount={10}
//     totalCases={1000}
//     topTags={[['tag A', { id: 31, tag_id: 31312 }], ['tag B', { id: 361, tag_id: 3153 }]]}
//     recentCases={recentCases}
//   />);

//   userEvent.click(queryByText('To List'));
//   userEvent.click(queryByText('+'));
//   userEvent.type(getByRole('textbox'), 'Cite List');
//   userEvent.click(queryByText('Create'));
//   userEvent.click(queryByText('✕'));
//   expect(getByText('Loading')).toBeInTheDocument();
// });

it('does nothing yet', async () => {
});
