import React from 'react';
import renderer from 'react-test-renderer';

import VideoPlayer from '../../video/VideoPlayer';

const video = {
  id: 1,
  domain: 'vimeo',
  title: 'This is a title for Video Player',
  description: 'This is a description for Video Player',
  thumbnail: 'http://i.vimeocdn.com/video/529956697_640',
  url: 'https://vimeo.com/135593189',
};

it('renders correctly with props', () => {
  const tree = renderer.create(<VideoPlayer
    video={video}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
