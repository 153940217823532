import React from 'react';
import renderer from 'react-test-renderer';

import Modules from '../../learning_center/Modules';
import { currentUser, isAdmin, topics } from '../__mocks__/lc-data';

it('renders correctly with props', () => {
  const tree = renderer.create(
    <Modules
      currentUser={currentUser}
      isAdmin={isAdmin}
      topics={topics}
    />,
  ).toJSON();
  expect(tree.element).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<Modules />).toJSON();
  expect(tree.element).toMatchSnapshot();
});
