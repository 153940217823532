import React from 'react';

export function BookChapters({
  mediaItem, item, currentItem, setCurrentItem,
}) {
  if (!item?.media_items || item?.media_items.length === 0) return null;

  return (
    <ul>
      {item.media_items.map((chapter) => (
        <li key={chapter.id} onClick={() => setCurrentItem(chapter)}>
          <span className={((currentItem.id === chapter.id && item.id === mediaItem.id) ? ' lc-circle-active' : '')} />
          <div className="lc-chapter-content">
            <p className="lc-chapter-title">{chapter.title}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}
