// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
Array [
  <a
    className="page false"
    href="/?page=1&tab=about"
  >
    1
  </a>,
  <a
    className="page false"
    href="/?page=2&tab=about"
  >
    2
  </a>,
  <a
    className="page false"
    href="/?page=3&tab=about"
  >
    3
  </a>,
  <a
    className="page false"
    href="/?page=4&tab=about"
  >
    4
  </a>,
  <a
    className="page false"
    href="/?page=5&tab=about"
  >
    5
  </a>,
  <a
    className="page false"
    href="/?page=6&tab=about"
  >
    6
  </a>,
  <a
    className="page active"
    href="/?page=7&tab=about"
  >
    7
  </a>,
  <a
    className="page false"
    href="/?page=8&tab=about"
  >
    8
  </a>,
  <a
    className="page false"
    href="/?page=9&tab=about"
  >
    9
  </a>,
  <a
    className="page false"
    href="/?page=10&tab=about"
  >
    10
  </a>,
  <div>
     ... 
  </div>,
  <a
    className="page"
    href="/?page=15&tab=about"
  >
    15
  </a>,
]
`;
