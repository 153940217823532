// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Registration renders correctly with props 1`] = `
<div
  className="auth-page new-register"
>
  <div
    className="register-header"
  >
    <img
      className="trademark-header"
      src="/logo.svg"
    />
  </div>
  <div
    className="dots"
  >
    <span
      className="dot active"
    />
    <span
      className="dot false"
    />
    <span
      className="dot false"
    />
  </div>
  <div
    className="registration-title"
  >
    <h1>
      Create Your Account
    </h1>
    <h2>
      Individual Plan
    </h2>
  </div>
  <form
    className="register-form container"
  >
    <div
      className="row"
    >
      <div
        className="col-sm-6"
      >
        <div
          className="register-form-title"
        >
          Account Details
        </div>
        <div
          className="form-group "
        >
          <input
            className="form-control"
            id="name"
            name="name"
            onChange={[Function]}
            placeholder="Full Name"
            type="text"
            value=""
          />
        </div>
        <div
          className="form-group "
        >
          <input
            className="form-control"
            disabled={false}
            id="email"
            name="email"
            onChange={[Function]}
            placeholder="Email Address"
            type="email"
            value=""
          />
        </div>
        <div
          className="form-group "
        >
          <input
            className="form-control"
            disabled={false}
            id="company"
            name="company"
            onChange={[Function]}
            placeholder="Organization / Firm Name"
            type="text"
            value=""
          />
        </div>
        <div
          className="form-group "
        >
          <input
            className="form-control"
            id="phone"
            name="phone"
            onChange={[Function]}
            placeholder="Phone Number Optional"
            type="number"
            value=""
          />
        </div>
        <label
          className="register-checkbox"
        >
          <input
            checked={true}
            id="newsletter"
            name="newsletter"
            onChange={[Function]}
            type="checkbox"
          />
          Sign me up for eDiscovery Assistant blog
        </label>
      </div>
      <div
        className="col-sm-6"
      >
        <div
          className="register-form-title"
        >
          Create Password
        </div>
        <div
          className="form-group "
        >
          <div
            className="input-group"
          >
            <input
              className="form-control"
              id="password"
              name="password"
              onChange={[Function]}
              placeholder="Password"
              type="password"
              value=""
            />
            <span
              className="input-group-addon"
              onClick={[Function]}
            >
              <svg
                fill="currentColor"
                height="1em"
                stroke="currentColor"
                strokeWidth="0"
                style={
                  Object {
                    "color": undefined,
                  }
                }
                viewBox="0 0 576 512"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"
                />
              </svg>
            </span>
          </div>
          <div
            className="note"
          >
            Please add at least 1 Uppercase and 1 symbol for increasing password strength.
          </div>
        </div>
        <div
          className="form-group "
        >
          <input
            className="form-control"
            id="password2"
            name="password2"
            onChange={[Function]}
            placeholder="Confirm Password"
            type="password"
            value=""
          />
        </div>
      </div>
    </div>
    <div
      className="register-form-actions"
    >
      <button
        className="btn btn-submit"
        onClick={[Function]}
        type="button"
      >
        Continue
      </button>
    </div>
  </form>
</div>
`;
