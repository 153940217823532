// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="subscription-container"
>
  <div
    className="subscription-container-left"
  >
    <div
      className="subscription"
    >
      <div
        className="container-full-width"
      >
        <div
          className="name"
        >
          Payment Plan
        </div>
        <div />
        <div
          className="plan-row"
        >
          <p
            style={
              Object {
                "textTransform": "capitalize",
              }
            }
          >
            Platform:
             
            individual
          </p>
        </div>
        <div
          className="plan-row"
        >
          <p
            style={
              Object {
                "textTransform": "capitalize",
              }
            }
          >
            Academy:
             
            No account plan available
          </p>
          <button
            className="plan"
            data-testid="academy-toggle"
            onClick={[Function]}
            type="button"
          >
            Upgrade
          </button>
        </div>
      </div>
    </div>
    <div
      className="payment-method"
    >
      <div
        className="name"
      >
        Payment Method
      </div>
      <div
        className="payment-method-container"
      >
        <div
          className="card-container"
        >
          <div
            className="card-brand"
          />
          <div
            className="last-4-numbers"
          >
            •••• undefined
          </div>
          <button
            className="update-payment-method"
            onClick={[Function]}
            type="button"
          >
            Change
          </button>
        </div>
      </div>
    </div>
    <div
      className="payment-history"
    >
      <div
        className="name"
      >
        Payment History
      </div>
      <div
        className="payment-history-container"
      >
        <div
          className="payment-history-header"
        >
          <span
            className="header-date"
          >
            PAYMENT DATE
          </span>
          <span
            className="header-amount"
          >
            AMOUNT
          </span>
          <span
            className="header-amount"
          >
            PRODUCT
          </span>
          <span
            className="header-status"
          >
            STATUS
          </span>
        </div>
        <div
          className="payment-container"
        >
          <div
            className="payment-date"
          >
            June 04, 2021
          </div>
          <span
            className="amount"
          >
            $243.00 
          </span>
          <span
            className="amount"
          />
          <div>
            <span
              className="status"
            >
              Paid 
            </span>
          </div>
        </div>
        <div
          className="payment-container"
        >
          <div
            className="payment-date"
          >
            June 03, 2021
          </div>
          <span
            className="amount"
          >
            $243.00 
          </span>
          <span
            className="amount"
          />
          <div>
            <span
              className="status"
            >
              Paid 
            </span>
          </div>
        </div>
      </div>
      <div
        className="additional-info"
      >
        <p>
          If you wish to proceed with cancelling your subscription, please email  
          <a
            href="mailto:support@ediscoveryassistant.com"
          >
            support@ediscoveryassistant.com
          </a>
            and our team will assist you.
        </p>
      </div>
    </div>
    <div
      className="subscription"
      style={
        Object {
          "marginTop": "4rem",
        }
      }
    >
      <div
        className="container-full-width"
      >
        <div
          className="plan-row"
        >
          <button
            className="plan"
            type="button"
          >
            <a
              href="mailto:support@ediscoveryassistant.com?Subject=Cancel My Subscription"
            >
              Cancel My Subscription
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
