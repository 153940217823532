import { FiChevronDown } from 'react-icons/fi';
import Menu, { MenuItem, MenuItemGroup, SubMenu } from 'rc-menu';
import { GoSearch } from 'react-icons/go';
import React, { useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';

export default function Filters({
  tags, topics, initialTagId, initialTopicId, initialQuery, isModule, personalisedSearch,
}) {
  const [selectedTagId, setSelectedTagId] = useState(initialTagId ? Number(initialTagId) : null);
  const [selectedTopicId, setSelectedTopicId] = useState(initialTopicId ? Number(initialTopicId) : null);
  const [query, setQuery] = useState(initialQuery || '');

  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false);
  const [typeDropdownVisible, setTypeDropdownVisible] = useState(false);

  const { tagCategories, tagsByCategory } = useMemo(() => {
    const categories = {};
    const map = {};

    tags?.forEach((tag) => {
      map[tag.issue_category?.id || 0] = map[tag.issue_category?.id || 0] || [];
      map[tag.issue_category?.id || 0].push(tag);
      categories[tag.issue_category?.id || 0] = tag.issue_category || { id: 0, name: 'Other' };
    });

    return { tagCategories: sortBy(Object.values(categories), 'position'), tagsByCategory: map };
  }, [tags]);

  useEffect(
    () => {
      const listener = (event) => {
        if (event.target.closest('.lc-menu, .lc-submenu')) return;
        setCategoryDropdownVisible(false);
        setTypeDropdownVisible(false);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [],
  );

  const handleSelectType = ({ key }) => {
    if (!key) return;
    if (key.startsWith('topic-')) {
      setSelectedTopicId(Number(key.replace('topic-', '')));
      setCategoryDropdownVisible(false);
    }
    setTypeDropdownVisible(false);
  };

  const handleSelectCategory = ({ key }) => {
    if (!key) return;
    if (key.startsWith('tag-')) {
      setSelectedTagId(Number(key.replace('tag-', '')));
      setCategoryDropdownVisible(false);
    }
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
  };

  const handleFilter = () => {
    window.location.href = `/academy/search?tag_id=${selectedTagId || ''}&category_id=${selectedTopicId || ''}&q=${query.trim() || ''}&personalised_search=${personalisedSearch || ''}`;
  };

  const clearFilter = () => {
    if (personalisedSearch) {
      setSelectedTagId(null);
      setQuery('');
      if (personalisedSearch !== 'module') setSelectedTopicId(null);
    } else window.location.href = '/academy/search';
    return null;
  };

  const tag_name = tags?.find((t) => t.id === selectedTagId)?.name;
  const topic_name = topics?.find((t) => t.id === selectedTopicId)?.name;

  return (
    <div className="search-header">
      {!isModule
        && (
          <div className="lc-select">
            <div
              className="search-input"
              onClick={() => setTypeDropdownVisible(true)}
            >
              {topic_name || 'Select Module'}
              <FiChevronDown />
            </div>
            {typeDropdownVisible && (
              <Menu
                className="lc-menu"
                onSelect={handleSelectType}
              >
                <MenuItemGroup title="Modules" key="modules">
                  <MenuItem key="topic-">Select all modules</MenuItem>
                  {topics.map((topic) => (
                    <MenuItem key={`topic-${topic.id}`}>
                      {topic.name}
                    </MenuItem>
                  ))}
                </MenuItemGroup>
              </Menu>
            )}
          </div>
        )}

      <div className="lc-select">
        <div
          className="search-input"
          onClick={() => setCategoryDropdownVisible(true)}
        >
          {tag_name || 'Select Issue'}
          <FiChevronDown />
        </div>
        {categoryDropdownVisible && (
          <Menu
            className="lc-menu"
            onSelect={handleSelectCategory}
          >
            <MenuItemGroup title="Issues" key="issues">
              <MenuItem key="tag-">Select all issues</MenuItem>
              {tagCategories?.map((cat) => (
                <SubMenu popupClassName="lc-submenu" title={cat.name} key={`cat-${cat.id}`}>
                  {tagsByCategory[cat.id]?.map((tag) => (
                    <MenuItem key={`tag-${tag.id}`}>{tag.name}</MenuItem>
                  ))}
                </SubMenu>
              ))}
            </MenuItemGroup>
          </Menu>
        )}
      </div>

      <div className="search-input-container">
        <GoSearch />
        <input
          style={{ width: 280 }}
          type="search"
          className="search-input"
          placeholder="Keyword"
          value={query}
          onChange={handleSearchChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleFilter();
          }}
        />
        { query && <span className="clear-tags" onClick={() => setQuery('')}>✕</span> }
      </div>
      <button type="button" className="search-button" onClick={handleFilter}>
        <GoSearch />
        Search
      </button>
      <button type="button" className="clear-button" onClick={clearFilter}>
        Clear
      </button>
    </div>
  );
}
