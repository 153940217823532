import React from 'react';

export default class RelatedForm extends React.Component {
  render() {
    const { relatedForm } = this.props;
    return (
      <a
        className="related-form"
        href={`/forms/${relatedForm.id}`}
        key={relatedForm.id}
      >
        <div className="related-form-name">{relatedForm.published_revision.name}</div>
      </a>
    );
  }
}
