import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';
import AsyncSelect from '../AsyncSelect';
import Select from '../Select';

export default function Sidebar({
  user, onClose, onUpdated,
}) {
  const [account, setAccount] = useState({
    value: '', label: 'None', subscriptions: [], academy_subscriptions: [],
  });
  const [subscription, setSubscription] = useState({});
  const [academySubscription, setAcademySubscription] = useState({});
  const subscriptions = [...account.subscriptions, ...account.academy_subscriptions] || [];
  const loadOptions = (input) => Api.admin.accounts.get({ page: 1, name: input }).then(({ data }) => data.accounts.map((acc) => ({
    value: acc.id, label: acc.name, subscriptions: acc.valid_subscriptions, academy_subscriptions: acc.valid_academy_subscriptions,
  })));

  const handleMove = () => {
    Api.admin.users.update(user.id, {
      account_id: account.value,
      subscription_id: subscription.id || '',
      academy_subscription_id: academySubscription.id || '',
    }).then((res) => {
      if (res.status !== 'OK') return;

      Snackbar.show('User has been updated!');
      if (onUpdated) onUpdated();
    });
  };

  const getSubscriptionLabel = (id) => {
    const sub = subscriptions.find((s) => s.id === id);
    if (sub) return `${sub.plan.name} (${sub.effective_from}/${sub.effective_to})`;
    return 'None';
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>
          Move User To Account
        </h2>
        <button type="button" className="close" onClick={onClose}><GrClose /></button>
      </div>
      <div className="sidebar-body">
        <div className="form-group">
          <label>Account</label>
          <AsyncSelect
            value={account}
            onChange={setAccount}
            loadOptions={loadOptions}
          />
        </div>

        <div className="form-group">
          <label>Subscription</label>
          <Select
            value={{
              value: subscription.id,
              label: getSubscriptionLabel(subscription.id),
            }}
            onChange={(sub) => setSubscription(subscriptions.find((s) => s.id === sub.value) || { id: '' })}
            options={[
              { value: '', label: 'None' },
              ...subscriptions.filter((sub) => sub.plan.name !== 'Academy')
                .map((sub) => ({ value: sub.id, label: getSubscriptionLabel(sub.id) })),
            ]}
          />
        </div>

        <div className="form-group">
          <label>Academy Subscription</label>
          <Select
            value={{
              value: academySubscription.id,
              label: getSubscriptionLabel(academySubscription.id),
            }}
            onChange={(sub) => setAcademySubscription(subscriptions.find((s) => s.id === sub.value) || { value: '' })}
            options={[
              { value: '', label: 'None' },
              ...subscriptions.filter((sub) => sub.plan.name === 'Academy')
                .map((sub) => ({ value: sub.id, label: getSubscriptionLabel(sub.id) })),
            ]}
          />
        </div>

        <button
          type="button"
          className="button"
          disabled={!account}
          onClick={handleMove}
        >
          Update
        </button>
      </div>
    </div>
  );
}
