// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="new-modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Are you sure you want to cancel your subscription?
        </p>
        <div
          className="button-row"
        >
          <div
            className="modal-button"
            onClick={[Function]}
          >
            Yes
          </div>
          <div
            className="modal-button modal-button-outline"
            onClick={[Function]}
          >
            No
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
