import React from 'react';
import renderer from 'react-test-renderer';
import { fireEvent, render } from '@testing-library/react';

import Header from '../../header/Header';
import { currentUser } from '../__mocks__/lc-data';

it('renders correctly without props', () => {
  const tree = renderer.create(<Header />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly with props', () => {
  const tree = renderer.create(<Header currentUser={currentUser} isAdmin />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('render header and click without current user', async () => {
  const container = render(<Header />);
  fireEvent.click(container.getByTestId('eda-header'));
  fireEvent.click(container.getByTestId('login-modal'));
});

it('render header and click with current user', async () => {
  const container = render(<Header currentUser={currentUser} />);
  fireEvent.click(container.getByTestId('eda-header'));
});
