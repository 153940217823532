import React from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

function getOrdinal(num) {
  if (num === 1) return '1st';
  if (num === 2) return '2nd';
  if (num === 3) return '3rd';
  return `${num}th`;
}

class AdditionalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        seats: [],
      },
    };
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    const field = e.target.id;
    onChange({ [field]: e.target.value });
  };

  handleChangeNumberOfSeats = (number) => {
    if (number < 1 || number > 4) return;
    const { onChange, data } = this.props;
    const { formErrors } = this.state;
    if (number < data.seats.length) {
      data.seats = data.seats.slice(0, number);
      formErrors.seats = formErrors.seats.slice(0, number);
    } else {
      data.seats = data.seats.concat(
        new Array(number - data.seats.length)
          .fill(null)
          .map(() => ({
            first_name: '', last_name: '', email: '',
          })),
      );

      formErrors.seats = formErrors.seats.concat(
        new Array(number - data.seats.length)
          .fill(null)
          .map(() => ({
            first_name: '', last_name: '', email: '',
          })),
      );
    }
    onChange({ number_of_seats: number, seats: [...data.seats] });
    this.setState({ formErrors });
  };

  handleChangeSeat = (idx, seat) => {
    const { onChange, data } = this.props;
    data.seats[idx] = seat;
    onChange({ seats: [...data.seats] });
  };

  handleSubmit = () => {
    const { data, onSubmit } = this.props;
    Object.keys(data.seats).forEach((_, idx) => this.validateSeat(idx));

    const { formErrors } = this.state;
    const hasErrors = formErrors.seats.filter((e) => Object.values(e).some(Boolean)).length;
    if (hasErrors) return;
    onSubmit();
  };

  validateSeat(idx) {
    const { data } = this.props;
    const { formErrors } = this.state;
    const value = data.seats[idx];
    let valid;
    formErrors.seats[idx] = formErrors.seats[idx] || {};

    if (!value.email) {
      formErrors.seats[idx].email = 'Email missing';
    } else {
      valid = value.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      formErrors.seats[idx].email = valid ? '' : 'Email is invalid';
    }

    formErrors.seats[idx].first_name = !value.first_name ? 'First name missing' : null;
    formErrors.seats[idx].last_name = !value.last_name ? 'Last name missing' : null;

    this.setState({ formErrors: { ...formErrors } });
  }

  render() {
    const {
      data, plans, onCancel, status,
    } = this.props;
    const { formErrors } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-sm-6">
            <div className={`form-group ${formErrors.number_of_seats ? 'has-error' : ''}`}>
              <label className="register-form-title control-label" htmlFor="number_of_seats">Select Seat Number (Up to 4)</label>
              <div className="input-group number-of-seats-input">
                <span
                  className={`input-group-addon ${data.number_of_seats === 4 && 'disabled'}`}
                  onClick={() => this.handleChangeNumberOfSeats(data.number_of_seats + 1)}
                >
                  <FaPlus />
                </span>
                <input
                  className="form-control"
                  type="number"
                  name="number_of_seats"
                  id="number_of_seats"
                  readOnly
                  max="4"
                  min="1"
                  value={data.number_of_seats}
                />
                <span
                  className={`input-group-addon ${data.number_of_seats === 1 && 'disabled'}`}
                  onClick={() => this.handleChangeNumberOfSeats(data.number_of_seats - 1)}
                >
                  <FaMinus />
                </span>
              </div>
              { formErrors.number_of_seats && <p className="help-block">{formErrors.number_of_seats}</p> }
            </div>

            <div className="register-form-title">Seat Email Address (Billing contact excluded)</div>
            { data.seats.map((seat, idx) => (
              <div className="seats-input">
                <h3>
                  {getOrdinal(idx + 1)}
                  {' '}
                  User
                </h3>
                <div className="row">
                  <div className="col-sm-6">
                    <div className={`form-group ${formErrors.seats[idx]?.first_name ? 'has-error' : ''}`}>
                      <input
                        className="form-control"
                        type="text"
                        name="seat_first_name"
                        value={seat.first_name}
                        disabled={idx === 0 && (data.permission === 'seat' || data.permission === 'billing_and_seat')}
                        onChange={(e) => this.handleChangeSeat(idx, { ...seat, first_name: e.target.value })}
                        placeholder="First Name"
                      />
                      { formErrors.seats[idx]?.first_name && <p className="help-block">{formErrors.seats[idx].first_name}</p> }
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className={`form-group ${formErrors.seats[idx]?.last_name ? 'has-error' : ''}`}>
                      <input
                        className="form-control"
                        type="text"
                        name="seat_last_name"
                        value={seat.last_name}
                        disabled={idx === 0 && (data.permission === 'seat' || data.permission === 'billing_and_seat')}
                        onChange={(e) => this.handleChangeSeat(idx, { ...seat, last_name: e.target.value })}
                        placeholder="Last Name"
                      />
                      { formErrors.seats[idx]?.last_name && <p className="help-block">{formErrors.seats[idx].last_name}</p> }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className={`form-group ${formErrors.seats[idx]?.email ? 'has-error' : ''}`}>
                      <input
                        className="form-control"
                        type="email"
                        name="seat_email"
                        value={seat.email}
                        disabled={idx === 0 && (data.permission === 'seat' || data.permission === 'billing_and_seat')}
                        onChange={(e) => this.handleChangeSeat(idx, { ...seat, email: e.target.value })}
                        placeholder="Add user email address"
                      />
                      { formErrors.seats[idx]?.email && <p className="help-block">{formErrors.seats[idx].email}</p> }
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="note">
              Please enter the email address for each additional user.
              After payment is complete, each user will receive an email to activate their account.
            </div>
          </div>
          <div className="col-sm-6">
            <label className="register-form-title">Total Pricing</label>

            <div className={`form-group ${formErrors.promo_code ? 'has-error' : ''}`}>
              <input
                className="form-control"
                type="text"
                name="promo_code"
                id="promo_code"
                value={data.promo_code}
                onChange={this.handleChange}
                placeholder="Promo Code (If Applicable)"
              />
              { formErrors.promo_code && <p className="help-block">{formErrors.promo_code}</p> }
            </div>

            <div className="pricing">
              <div className="pricing-row">
                <div>
                  Seat Price
                  <div className="small">Per User</div>
                </div>
                <div>
                  $
                  {plans[data.interval].default_amount / 100}
                </div>
              </div>

              <div className="pricing-row">
                <div>
                  Seat Number
                </div>
                <div>
                  {data.number_of_seats}
                </div>
              </div>

              <div className="pricing-row total">
                <div>
                  Sub total
                </div>
                <div>
                  $
                  {(data.number_of_seats * plans[data.interval].default_amount) / 100}
                </div>
              </div>
            </div>
            { /* data.plan_type === 'trial' && (
              <div className="note primary">
                You will not be charged during your free trial period. Once period ends, we will ask you if you prefer to continue with our payment subscription plans or not.
              </div>
            ) */}
            <div className="note">
              Need to add more than 4 users? Click below to contact Sales.
            </div>
            <a className="btn btn-sales" href="https://www.ediscoveryassistant.com/contact-us">Contact Sales</a>
          </div>
        </div>

        <div className="register-form-actions">
          <button type="button" className="btn btn-cancel" onClick={onCancel}>Back</button>
          { data.plan_type === 'trial' ? (
            <button
              type="button"
              className="btn btn-submit"
              disabled={status === 'creating' || status === 'success'}
              onClick={this.handleSubmit}
            >
              { status === 'creating' ? 'Creating' : status === 'success' ? 'Logging In...' : 'Create Account' }
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-submit"
              onClick={this.handleSubmit}
            >
              Continue
            </button>
          ) }

        </div>
      </>
    );
  }
}

export default AdditionalInfo;
