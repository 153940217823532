import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';

import userEvent from '@testing-library/user-event';
import ConfirmCiteListDeletionModalOpen from '../../../cite_lists/modals/ConfirmCiteListDeletionModalOpen';

describe('ConfirmCiteListDeletionModalOpen', () => {
  it('renders correctly with props', () => {
    render(
      <ConfirmCiteListDeletionModalOpen
        confirmDelete={() => {}}
        cancelDelete={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const confirm = jest.fn(() => {});
    const close = jest.fn(() => {});

    render(
      <ConfirmCiteListDeletionModalOpen
        confirmDelete={confirm}
        cancelDelete={close}
      />,
    );

    const confirmButton = screen.getByText('Yes');
    expect(confirmButton).toBeInTheDocument();
    userEvent.click(confirmButton);
    expect(confirm).toBeCalledTimes(1);

    const closeButton = screen.getByText('No');
    expect(confirmButton).toBeInTheDocument();
    userEvent.click(closeButton);
    expect(close).toBeCalledTimes(1);
  });
});
