export const plan = {
  name: 'Individual',
  default_amount: 134900,
};

// random data from https://stripe.com/docs/api/
export const paymentMethod = {
  id: 'cu_19YMK02eZvKYlo2Cvcb2J41W',
};

export const account = {
  id: 1,
  name: 'User',
  account_type: 'individual',
};

export const payments = [
  { date: 'June 04, 2021', amount: '$243.00' },
  { date: 'June 03, 2021', amount: '$243.00' },
];

export const plans = [
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 12900,
    id: 9,
    interval: 'monthly',
    name: 'Monthly Subscription',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay1n6YGaEucre',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
  {
    active: true,
    created_at: '2019-08-20T18:08:22.597-06:00',
    default_amount: 0,
    id: 6,
    interval: 'none',
    name: 'Trial',
    plan_type: 'trial',
    stripe_plan_id: null,
    updated_at: '2019-08-20T18:08:22.597-06:00',
  },
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 118800,
    id: 10,
    interval: 'yearly',
    name: 'Annual Subscription',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay32J3e0wSmIZ',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 12900,
    id: 9,
    interval: 'monthly',
    name: 'Individual Monthly',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay1n6YGaEucre',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 12900,
    id: 9,
    interval: 'yearly',
    name: 'Individual',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay1n6YGaEucre',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 12900,
    id: 9,
    interval: 'yearly',
    name: 'Academy',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay1n6YGaEucre',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
  {
    active: true,
    created_at: '2020-02-04T22:27:48.000-07:00',
    default_amount: 12900,
    id: 9,
    interval: 'monthly',
    name: 'Academy Monthly',
    plan_type: 'credit_card',
    stripe_plan_id: 'plan_Gay1n6YGaEucre',
    updated_at: '2020-02-04T22:27:48.000-07:00',
  },
];

export const user = {
  account_id: 1,
  email: 'email@mail.com',
  first_name: 'First',
  id: 1,
  last_name: 'Last',
  settings: {
    email_preferences: {
      send_case_law_email: true,
    },
  },
  subscription_id: 1,
  user_roles: [],
  academy_subscription: { stripe_cancelled: true },
};

export const visa = {
  image: '/some-image-url',
};
