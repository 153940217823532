// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with props 1`] = `
<div
  className="notifications"
>
  <div
    className="notifications-column"
  >
    <div
      className="notifications-desc"
    >
      Receive a weekly update with the latest cases.
    </div>
    <div
      className="react-toggle"
      onClick={[Function]}
      onTouchEnd={[Function]}
      onTouchMove={[Function]}
      onTouchStart={[Function]}
    >
      <div
        className="react-toggle-track"
      >
        <div
          className="react-toggle-track-check"
        />
        <div
          className="react-toggle-track-x"
        />
      </div>
      <div
        className="react-toggle-thumb"
      />
      <input
        checked={false}
        className="react-toggle-screenreader-only"
        data-testid="toggle"
        onBlur={[Function]}
        onChange={[Function]}
        onFocus={[Function]}
        type="checkbox"
      />
    </div>
  </div>
  <br />
  <div
    className="notifications-column"
    onClick={[Function]}
  >
    <div
      className="notifications-desc"
    >
      Receive a daily update with the latest cases in dynamic cite lists.
    </div>
    <div
      className="react-toggle react-toggle--disabled"
      onClick={[Function]}
      onTouchEnd={[Function]}
      onTouchMove={[Function]}
      onTouchStart={[Function]}
    >
      <div
        className="react-toggle-track"
      >
        <div
          className="react-toggle-track-check"
        />
        <div
          className="react-toggle-track-x"
        />
      </div>
      <div
        className="react-toggle-thumb"
      />
      <input
        checked={false}
        className="react-toggle-screenreader-only"
        data-testid="dynamic-toggle"
        disabled={true}
        onBlur={[Function]}
        onChange={[Function]}
        onFocus={[Function]}
        type="checkbox"
      />
    </div>
  </div>
</div>
`;
