import React, { useState } from 'react';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import Header from './Header';
import api from '../../services/api';
import Tags from './Tags';
import { VideoContent } from './DetailsContent/VideoContent';
import { PostContent } from './DetailsContent/PostContent';
import { FileContent } from './DetailsContent/FileContent';
import { BookChapters } from './DetailsContent/BookChapters';
import { DetailsBreadcrumb } from './DetailsContent/DetailsBreadcrumb';
import AccessModal from './AccessModal';

export default function Details({
  linkedContent, mediaItem, currentUser, isAdmin, isFavorite, topic, mediaItems, progress, breadcrumb, academySubscription, subscription, userSession,
}) {
  const [tab, setTab] = useState('about');
  const [favorite, setFavorite] = useState(isFavorite);
  const [currentItem, setCurrentItem] = useState(
    mediaItem?.media_items.length > 0 ? mediaItem.media_items[0] : mediaItem,
  );
  const isBook = !mediaItems && mediaItem?.type === 'Ebook' && mediaItem?.media_items.length > 0;
  const [accessModalVisible, setAccessModalVisible] = useState(false);
  const [mobileOpened, setMobileOpened] = useState(false);

  const handleFavorite = () => {
    if (favorite) {
      api.media_items.unfavorite(mediaItem.id).then((res) => {
        if (res.success) setFavorite(false);
      });
    } else {
      api.media_items.favorite(mediaItem.id).then((res) => {
        if (res.success) setFavorite(true);
      });
    }
  };

  const renderContent = (item) => {
    switch (item.type) {
      case 'Video':
      case 'Audio':
        return (
          <VideoContent
            mediaItem={item}
            progress={progress}
            tab={tab}
            setTab={setTab}
            isAdmin={isAdmin}
            linkedContent={linkedContent}
          />
        );
      case 'Post':
        return (
          <PostContent
            mediaItem={item}
            tab={tab}
            setTab={setTab}
            isAdmin={isAdmin}
            linkedContent={linkedContent}
          />
        );
      case 'Document':
      case 'Article':
      case 'Ebook':
        return (
          <FileContent
            mediaItem={item}
            tab={tab}
            setTab={setTab}
            isAdmin={isAdmin}
            linkedContent={linkedContent}
          />
        );
      default:
        return null;
    }
  };

  if (!mediaItem) {
    return (
      <div className="react-lc-page lc-details">
        <Header currentUser={currentUser} isAdmin={isAdmin} academySubscription={academySubscription} userSession={userSession} />
        <DetailsBreadcrumb currentUser={currentUser} topic={topic} breadcrumb={breadcrumb} />
        <div className="body">
          <p style={{
            maxWidth: '600px', textAlign: 'center', margin: 'auto', marginTop: '63px',
          }}
          >
            Thank you for visiting eDiscovery Academy. Academy is not currently part of your subscription.
            To gain access, click subscribe or contact
            &nbsp;
            <a href="mailto: sales@ediscoveryassistant.com?subject=Access to eDiscovery Academy">sales@ediscoveryassistant.com</a>
            &nbsp;
            for assistance in upgrading your subscription.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="react-lc-page lc-details">
      <div className="mobile-search-open" onClick={() => setMobileOpened(!mobileOpened)} />
      <Header currentUser={currentUser} isAdmin={isAdmin} academySubscription={academySubscription} userSession={userSession} />
      <DetailsBreadcrumb currentUser={currentUser} topic={topic} breadcrumb={breadcrumb} />
      <div className="body">
        { mediaItems?.length > 0 && (
          <div className={`left ${mobileOpened ? 'opened' : ''}`}>
            { mediaItems.map((item) => (
              <a
                href={item.id !== mediaItem.id ? `/academy/${item.id}?topic_id=${topic?.id}` : '#'}
                onClick={(e) => {
                  if (academySubscription || isAdmin || item.access_level === 'public' || (subscription && item.access_level === 'subscribed')) return;
                  e.preventDefault();
                  setAccessModalVisible(!accessModalVisible);
                }}
                className={`topic-item ${(item.id === mediaItem.id) && 'active'}`}
                key={item.id}
              >
                <p className="title">{item.title}</p>
                <p className="type">
                  {item.type}
                  {' '}
                  {item.media_items?.length > 0 && `| ${item.media_items.length} Chapters`}
                </p>
                {item.media_items?.length > 0
                  ? <BookChapters mediaItem={mediaItem} item={item} currentItem={currentItem} setCurrentItem={setCurrentItem} />
                  : <Tags items={item.tags} />}
              </a>
            ))}
          </div>
        )}
        { isBook && (
          <div className="left">
            <a
              href="#"
              className="topic-item active"
            >
              <p className="title">{mediaItem.title}</p>
              <p className="type">
                {mediaItem.type}
                {' '}
                {mediaItem.media_items?.length > 0 && `| ${mediaItem.media_items.length} Chapters`}
              </p>
              <BookChapters mediaItem={mediaItem} item={mediaItem} currentItem={currentItem} setCurrentItem={setCurrentItem} />
            </a>
          </div>
        )}
        <div className="right" id="right">
          { currentUser
            && (
            <button type="button" className={`bookmark-button ${favorite && 'active'}`} onClick={handleFavorite}>
              { favorite ? <FaBookmark /> : <FaRegBookmark /> }
            </button>
            )}
          { currentItem && renderContent(currentItem)}
        </div>
      </div>
      { accessModalVisible && (<AccessModal closeModal={() => setAccessModalVisible(!accessModalVisible)} />)}
    </div>
  );
}
