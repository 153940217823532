import React from 'react';
import '@testing-library/jest-dom';
import { render, screen } from '@testing-library/react';

import userEvent from '@testing-library/user-event';
import DeleteListModal from '../../../cite_lists/modals/DeleteListModal';

describe('DeleteListModal', () => {
  it('renders correctly with props', () => {
    render(
      <DeleteListModal
        citeListUserId={1}
        currentUserId={1}
        deleteCiteList={() => {}}
        closeModal={() => {}}
      />,
    );
    expect(screen).toMatchSnapshot();
  });

  it('correctly interacts with callbacks', async () => {
    const confirm = jest.fn(() => {});
    const close = jest.fn(() => {});

    render(
      <DeleteListModal
        citeListUserId={1}
        currentUserId={1}
        deleteCiteList={confirm}
        closeModal={close}
      />,
    );

    const confirmButton = screen.getByText('Delete');
    expect(confirmButton).toBeInTheDocument();
    userEvent.click(confirmButton);
    expect(confirm).toBeCalledTimes(1);

    const closeButton = screen.getByText('Cancel');
    expect(confirmButton).toBeInTheDocument();
    userEvent.click(closeButton);
    expect(close).toBeCalledTimes(1);
  });
});
