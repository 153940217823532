import React from 'react';
import renderer from 'react-test-renderer';

import { UserMenu } from '../../learning_center/UserMenu';
import { currentUser } from '../__mocks__/lc-data';

it('renders correctly with props', () => {
  const tree = renderer.create(<UserMenu currentUser={currentUser} isAdmin />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly without props', () => {
  const tree = renderer.create(<UserMenu />).toJSON();
  expect(tree).toMatchSnapshot();
});
