import React from 'react';
import { FiHexagon, FiFolder } from 'react-icons/fi';
import { FaRegUserCircle } from 'react-icons/fa';
import Logo from '../../../assets/images/academy.svg';

export default function Navbar({ currentUser, topics, activeTopicId }) {
  const location = window.location.pathname;

  return (
    <nav className="react-lc-navbar">
      <img className="logo" alt="logo" src={Logo} />
      <div className="separator" />
      <div>
        <a href="/academy" className={`link ${location === '/academy' && 'active'}`}>
          <FiHexagon />
          Dashboard
        </a>
        <div className="link">
          <FaRegUserCircle />
          {currentUser.first_name}
          {' '}
          {currentUser.last_name}
        </div>
      </div>
      <div className="separator" />
      <div>
        <div className="section-title">Modules</div>
        { topics.map((topic) => (
          <a
            href={`/media_category/${topic.id}`}
            className={`link ${(location === `/media_category/${topic.id}` || activeTopicId === topic.id) && 'active'}`}
          >
            <FiFolder />
            {topic.name}
          </a>
        ))}
      </div>
    </nav>
  );
}
