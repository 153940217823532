import React from 'react';
import capitalize from 'lodash/capitalize';

export default function ExpiredModal({ account, closeModal, plan }) {
  return (
    <div className="new-modal expired-subscription-modal" onClick={(e) => e.stopPropagation()}>
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <button
            type="button"
            className="button-clear pull-right"
            onClick={closeModal}
          >
            ✕
          </button>
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
              { (plan.plan_type === 'check' && account.account_type === 'individual') ? (
                <h4>
                  Your
                  {' '}
                  {capitalize(account.account_type)}
                  {' '}
                  Plan has expired. To renew your subscription,
                  {' '}
                  <a href="/account/edit?tab=billingInfo&modal=payment_method&action=eda">update your payment information</a>
                  . If you’d like to upgrade your subscription, please email
                  {' '}
                  <a href="mailto:support@ediscoveryassistant.com">support@ediscoveryassistant.com</a>
                  .
                </h4>
              ) : (
                <h4>
                  Your
                  {' '}
                  {capitalize(account.account_type)}
                  {' '}
                  Plan has expired. To continue using eDiscovery Assistant, contact your
                  Billing Contact for the account, or reach out to us at
                  {' '}
                  <a href="mailto:support@ediscoveryassistant.com">support@ediscoveryassistant.com</a>
                  .
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
