import React, { useState, useEffect } from 'react';
import api from '../../services/api';

export function ProgressItem({ progressItem }) {
  const [videoUrl, setVideoUrl] = useState('');
  const mediaItem = progressItem?.media_item;

  useEffect(() => {
    if (!mediaItem) return null;
    if (mediaItem.domain === 'youtube') setVideoUrl(`https://img.youtube.com/vi/${mediaItem.url.split('=').pop()}/hqdefault.jpg`);
    if (mediaItem.domain === 'vimeo') {
      api.media_items.vimeo_thumbnail(mediaItem.url)
        .then((res) => setVideoUrl(res.thumbnail_url));
    }
    return null;
  }, []);

  const renderTags = (item) => {
    if (!item) return null;
    if (item.tags?.length === 0) return <div className="lc-progress-item-tag"><p>No tags</p></div>;
    const moreCount = parseInt(item.tags.length) - 2;
    return (
      <>
        { item.tags?.slice(0, 2).map((tag) => (
          <div className="lc-progress-item-tag" key={tag.id}><p>{tag.name}</p></div>
        )) }
        { moreCount > 2 && (
        <div className="lc-progress-more">
          <p>
            +
            {moreCount}
            {' '}
            More
          </p>
        </div>
        ) }
      </>
    );
  };

  if (!progressItem) return null;

  return (
    <div className="lc-progress-item" key={progressItem.id}>
      <div className="lc-progress-content">
        <div className="lc-progress-img">
          <img alt="thumbnail" src={videoUrl} />
        </div>
        <div className="lc-progress-item-text">
          <a href={`/academy/${progressItem.media_item?.id}?page=progress`}>
            <p>{progressItem.media_item?.title}</p>
          </a>
        </div>
        <div className="play-img" />
      </div>
      <div className="lc-progress-item-tags">
        { renderTags(progressItem.media_item) }
      </div>
    </div>
  );
}
