import React from 'react';
import renderer from 'react-test-renderer';

import StateRulesNavigator from '../../../rules/src/StateRulesNavigator';

const allStates = [
  {
    id: 1,
    code: 'AL',
    name: 'Alabama',
  },
  {
    id: 2,
    code: 'AK',
    name: 'Alaska',
  },
  {
    id: 3,
    code: 'AZ',
    name: 'Arizona',
  },
];

it('renders correctly with props', () => {
  const tree = renderer.create(<StateRulesNavigator
    allStates={allStates}
    setStateFilter={() => {}}
    setStateRuleCategoryFilter={() => {}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
