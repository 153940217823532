import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const EdaSwitch = withStyles(() => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    marginLeft: 12,
    marginRight: 12,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: '#FFF',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#FFF',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#515C9E',
        borderColor: 'transparent',
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 100,
    opacity: 1,
    backgroundColor: '#515C9E',
    borderColor: 'transparent',
  },
  checked: {},
}))(Switch);

export default EdaSwitch;
