import React from 'react';
import renderer from 'react-test-renderer';
import '@testing-library/jest-dom';
import { fireEvent, render } from '@testing-library/react';

import SearchFailedModal from '../../search_modal/SearchFailedModal';

it('renders correctly without props', () => {
  const tree = renderer.create(<SearchFailedModal
    closeModal={() => {}}
  />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders correctly with prefix_too_broad error', () => {
  const tree = renderer.create(<SearchFailedModal
    closeModal={() => {}}
    error="prefix_too_broad"
  />).toJSON();
  expect(tree).toMatchSnapshot();
});

it('renders modal and test clicks', async () => {
  const close = jest.fn(() => {});
  const container = render(<SearchFailedModal closeModal={close} error="prefix_too_broad" />);
  fireEvent.click(container.queryByTestId('login-modal'));
  expect(close).toHaveBeenCalledTimes(1);
});
