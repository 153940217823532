// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<iframe
  frameBorder="no"
  height={500}
  scrolling="no"
  src="https://music.com/audio.mp3&amp;auto_play=false&amp;show_user=true&amp;visual=true"
  width="100%"
/>
`;
