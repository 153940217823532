import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

export function Pages({ pageNumber, pages, setPageNumber }) {
  return (
    <div className="pagination">
      <a
        className="page arrow"
        data-testid="previous"
        onClick={() => {
          setPageNumber(pageNumber === 1 ? pageNumber : (pageNumber - 1));
          const container = document.querySelector('#pdf-container');
          if (container) container.scrollTop = 0;
        }}
      >
        <FiChevronLeft />
      </a>
      <p>
        Page
        {' '}
        {pageNumber}
        {' '}
        of
        {' '}
        {pages}
      </p>
      <a
        className="page arrow"
        data-testid="next"
        onClick={() => {
          setPageNumber(pageNumber === pages ? pageNumber : (pageNumber + 1));
          const container = document.querySelector('#pdf-container');
          if (container) container.scrollTop = 0;
        }}
      >
        <FiChevronRight />
      </a>
    </div>
  );
}
