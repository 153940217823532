// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`account updated Modal renders correctly with article props 1`] = `
<img
  alt="thumbnail"
  src="budget.jpeg"
/>
`;

exports[`account updated Modal renders correctly with audio props 1`] = `
<svg
  fill="currentColor"
  height="1em"
  stroke="currentColor"
  strokeWidth="0"
  style={
    Object {
      "color": undefined,
    }
  }
  viewBox="0 0 352 512"
  width="1em"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M176 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96zm160-160h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16z"
  />
</svg>
`;

exports[`account updated Modal renders correctly with audio props 2`] = `
<svg
  fill="currentColor"
  height="1em"
  stroke="currentColor"
  strokeWidth="0"
  style={
    Object {
      "color": undefined,
    }
  }
  viewBox="0 0 384 512"
  width="1em"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
  />
</svg>
`;

exports[`account updated Modal renders correctly with audio props 3`] = `
<svg
  fill="currentColor"
  height="1em"
  stroke="currentColor"
  strokeWidth="0"
  style={
    Object {
      "color": undefined,
    }
  }
  viewBox="0 0 448 512"
  width="1em"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
  />
</svg>
`;

exports[`account updated Modal renders correctly with post props 1`] = `
<img
  alt="thumbnail"
  src="budget.jpeg"
/>
`;

exports[`account updated Modal renders correctly with video props 1`] = `
<img
  alt="thumbnail"
  src="some url"
/>
`;
