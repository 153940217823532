// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<iframe
  allowFullScreen={true}
  frameBorder="0"
  height={500}
  mozallowfullscreen={true}
  src="https://player.vimeo.com/video/135593189"
  width="100%"
/>
`;
