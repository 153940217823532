// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="new-modal"
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <button
            className="button-clear pull-right"
            onClick={[Function]}
            type="button"
          >
            ✕
          </button>
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          As part of your
           
           
          of eDiscovery Assistant you receive four (4) downloads.
        </p>
        <p>
          You currently have
           
          2
           
          of 4 remaining.
        </p>
        <p>
          To receive unlimited downloads, upgrade to a paid Individual Annual subscription for $1,895/year.
        </p>
        <div
          className="button-row"
        >
          <button
            className="modal-button"
          >
            <a
              href="/account/edit?tab=billingInfo"
            >
              Upgrade
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
